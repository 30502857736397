import { useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { Dispatch, connect } from 'react-redux'
import { browserHistory } from 'react-router'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { AppState } from '../../redux/app-state'
import { Account } from '../../redux/accounts/accounts.model'
import {
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
} from '../../redux/layout/layout.actions'
import AccountDetailsHeader from '../../components/account-details-header/AccountDetailsHeader'
import AccountDetailsInformation from '../../components/account-details-information/AccountDetailsInformation'
import AccountTabs from '../../components/account-tabs/AccountTabs'
import SwitchModal from '../../components/switch-contentful-modal/SwitchModal'
import { canSwitchModal } from '../../common/switch-helper'
import { HASH_MODALS, hashIncludes, removeHash } from '../../common/hash-helper'
import './AccountDetails.scss'
import { ProgressBarContextProvider } from '../../hooks/useProgressBar'
import IPQ from '../../components/ipq/IPQ'
import WithdrawFundsModal from '../../components/withdraw-funds-modal/WithdrawFundsModal'
import { UserState } from '../../redux/user/user.model'
import { canWithdrawFunds } from '../../common/withdraw-funds-helper'
import AddFundsModal from '../../components/add-funds-modal/AddFundsModal'
import ZeroBalanceMessage from '../../components/zero-balance-message/ZeroBalanceMessage'
import { hasWriteAuthorisation } from '../../common/accounts-helper'
import { canUseIPQTool } from '../../components/ipq/ipq-helper'

export interface AccountDetailsProps {
  accounts: Account[]
  setActiveMenu: (activeMenu: string) => any
  setActiveAccountTab: (activeAccountTab: string) => any
  params: {
    id: string
  }
  user: UserState
  children: any
}

const AccountDetails = ({
  accounts = [],
  user,
  setActiveAccountTab,
  setActiveMenu,
  params: { id },
  children,
}: AccountDetailsProps) => {
  const accountId = id.toString()
  const account = useMemo(
    () => accounts.find((a) => a.accountID === accountId),
    [accounts, accountId]
  )

  useEffect(() => {
    setActiveAccountTab('' + id)
    setActiveMenu('account-details')

    const mainContent = document.querySelector('.main-content')

    if (mainContent) {
      window.scrollTo(0, 0)
      mainContent.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const showAddFundsModal = useMemo(
    () => hashIncludes(HASH_MODALS.ADD_FUNDS),
    [window.location?.hash] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const showSwitchModal = useMemo(
    () => hashIncludes(HASH_MODALS.SWITCH) && canSwitchModal(account),
    [window.location?.hash, account?.productExternalRef] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const showIPQModal = useMemo(
    () =>
      hashIncludes(HASH_MODALS.IPQ) &&
      hasWriteAuthorisation(account) &&
      canUseIPQTool(account?.productExternalRef),
    [window.location?.hash, account?.productExternalRef] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const showWithdrawFundsModal = useMemo(
    () =>
      hashIncludes(HASH_MODALS.WITHDRAW) &&
      canWithdrawFunds(account, user?.birthDate),
    [window.location?.hash, canWithdrawFunds, account, user?.birthDate] // eslint-disable-line react-hooks/exhaustive-deps
  )

  if (!account) {
    browserHistory.replace('/accounts')
    return null
  }

  return (
    <>
      <div className="account-details-component main-content-padding">
        <AccountDetailsHeader accountId={id} />
        <Paper className="account-details-content" elevation={0}>
          <AccountDetailsInformation accountId={id} />
          <Container maxWidth={false} className="account-details-tabs-content">
            {!account.isDepositMode ? (
              <AccountTabs>{children}</AccountTabs>
            ) : (
              <ZeroBalanceMessage className="mt-lg" account={account} />
            )}
          </Container>
        </Paper>
      </div>

      {showSwitchModal && (
        <SwitchModal account={account} onClose={removeHash} />
      )}

      {showIPQModal && (
        <ProgressBarContextProvider>
          <IPQ onClose={removeHash} account={account} />
        </ProgressBarContextProvider>
      )}

      {showWithdrawFundsModal && (
        <WithdrawFundsModal account={account} onClose={removeHash} />
      )}

      {showAddFundsModal && (
        <AddFundsModal
          accountId={account.accountID}
          externalRef={account.productExternalRef}
          productExternalName={account.productExternalName}
          onClose={removeHash}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ accounts: { accounts }, user }: AppState) => ({
  accounts,
  user,
})

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
