import { ThirdPartyAppState } from './third-party-app.model'
export enum actionTypes {
  THIRD_PARTY_APP_REQUEST = 'THIRD_PARTY_APP_REQUEST',
  THIRD_PARTY_APP_REQUEST_SUCCESS = 'THIRD_PARTY_APP_REQUEST_SUCCESS',
  THIRD_PARTY_APP_REQUEST_FAILURE = 'THIRD_PARTY_APP_REQUEST_FAILURE',
  THIRD_PARTY_UPDATE_HAS_LOADED_REQUEST = 'THIRD_PARTY_UPDATE_HAS_LOADED_REQUEST',
  THIRD_PARTY_FORM_DATA_REQUEST = 'THIRD_PARTY_FORM_DATA_REQUEST',
  THIRD_PARTY_FORM_DATA_SUCCESS = 'THIRD_PARTY_FORM_DATA_SUCCESS',
  THIRD_PARTY_FORM_DATA_FAILURE = 'THIRD_PARTY_FORM_DATA_FAILURE',
}

// Payload
export type ThirdPartyAppRequestPayload = {
  userId: string
  accountId: string
  url: string
  activeLinkId: string
}
export type ThirdPartyFormDataRequestPayload = {
  userId: string
  accountId: string
  resource: string
  encodedModel: string
}
export type ThirdPartyAppUpdateHasLoadedPayload = { hasLoaded: boolean }

// Types
export type ThirdPartyAppRequestActionType = {
  type: actionTypes.THIRD_PARTY_APP_REQUEST
  payload: ThirdPartyAppRequestPayload
}
export type ThirdPartyAppRequestSuccessActionType = {
  type: actionTypes.THIRD_PARTY_APP_REQUEST_SUCCESS
  payload: ThirdPartyAppState
}
export type ThirdPartyAppRequestFailureActionType = {
  type: actionTypes.THIRD_PARTY_APP_REQUEST_FAILURE
  payload: string
}
export type ThirdPartyAppUpdateHasLoadedActionType = {
  type: actionTypes.THIRD_PARTY_UPDATE_HAS_LOADED_REQUEST
  payload: ThirdPartyAppUpdateHasLoadedPayload
}

export type ThirdPartyFormDataRequestActionType = {
  type: actionTypes.THIRD_PARTY_FORM_DATA_REQUEST
  payload: ThirdPartyFormDataRequestPayload
}
export type ThirdPartyFormDataSuccessActionType = {
  type: actionTypes.THIRD_PARTY_FORM_DATA_SUCCESS
  payload: ThirdPartyAppState
}
export type ThirdPartyFormDataFailureActionType = {
  type: actionTypes.THIRD_PARTY_FORM_DATA_FAILURE
  payload: string
}

// Actions
export const ThirdPartyAppRequestAction = (
  payload: ThirdPartyAppRequestPayload
): ThirdPartyAppRequestActionType => ({
  type: actionTypes.THIRD_PARTY_APP_REQUEST,
  payload,
})

export const ThirdPartyAppUpdateHasLoadedAction = (
  payload: ThirdPartyAppUpdateHasLoadedPayload
): ThirdPartyAppUpdateHasLoadedActionType => ({
  type: actionTypes.THIRD_PARTY_UPDATE_HAS_LOADED_REQUEST,
  payload,
})

export const ThirdPartyAppRequestSuccessAction = (
  ThirdPartyApp: ThirdPartyAppState
): ThirdPartyAppRequestSuccessActionType => ({
  type: actionTypes.THIRD_PARTY_APP_REQUEST_SUCCESS,
  payload: ThirdPartyApp,
})

export const ThirdPartyAppRequestFailureAction = (
  message: string
): ThirdPartyAppRequestFailureActionType => ({
  type: actionTypes.THIRD_PARTY_APP_REQUEST_FAILURE,
  payload: message,
})

export const ThirdPartyFormDataRequestAction = (
  payload: ThirdPartyFormDataRequestPayload
): ThirdPartyFormDataRequestActionType => ({
  type: actionTypes.THIRD_PARTY_FORM_DATA_REQUEST,
  payload,
})

export const ThirdPartyFormDataSuccessAction = (
  ThirdPartyFormData: ThirdPartyAppState
): ThirdPartyFormDataSuccessActionType => ({
  type: actionTypes.THIRD_PARTY_FORM_DATA_SUCCESS,
  payload: ThirdPartyFormData,
})

export const ThirdPartyFormDataFailureAction = (
  message: string
): ThirdPartyAppRequestFailureActionType => ({
  type: actionTypes.THIRD_PARTY_APP_REQUEST_FAILURE,
  payload: message,
})

export type ThirdPartyAppActions =
  | ThirdPartyAppRequestActionType
  | ThirdPartyAppRequestSuccessActionType
  | ThirdPartyAppRequestFailureActionType
  | ThirdPartyAppUpdateHasLoadedActionType
  | ThirdPartyFormDataRequestActionType
  | ThirdPartyFormDataSuccessActionType
  | ThirdPartyFormDataFailureActionType
