import { useEffect, useState } from 'react'
import { getProductReturnsSummary } from '.'

import { IInvestmentOption } from '../../utils/investmentOption'
import { Product } from '../../common/product-helper'
import {
  ProductReturnsMap,
  ReturnsSummaryItem,
  getReturnByDisplayName,
} from '../../common/returns-helper'

export const useInvestmentReturnSummary = (
  product: Product,
  investmentOption: IInvestmentOption
) => {
  const [returnsSummary, setReturnsSummary] = useState<
    ReturnsSummaryItem | undefined
  >()
  useEffect(() => {
    getProductReturnsSummary(product.productCode).then((result: any) => {
      if (!result?.items) {
        return
      }
      const [productReturns] = ProductReturnsMap.filter((p) =>
        p.product.includes(product.productCode)
      )
      const { items: resultItems } = result

      setReturnsSummary(() => {
        return getReturnByDisplayName(
          resultItems,
          investmentOption.shortName.includes('Strategy')
            ? productReturns[investmentOption.shortName]
            : investmentOption.shortName
        )
      })
    })
  }, [product, investmentOption])
  return {
    returnsSummary,
  }
}
