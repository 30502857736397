import { Button } from '../clickable/button/Button'
import Link from '../clickable/link/Link'
import Modal from '../modal/Modal'

import './ChangeContributionsModal.scss'

export interface AddFundsModalProps {
  onClose: () => void
  accountId: string
}

const ChangeContributionsModal = ({
  onClose,
  accountId,
}: AddFundsModalProps) => {
  return (
    <Modal
      className="change-contributions__modal"
      title="Change your employee contribution rate"
      subtitle={accountId}
      open
      onClose={onClose}
    >
      <p>
        The quickest way to change your KiwiSaver contribution rate is to log in
        to your MyIR account or complete the Inland Revenue{' '}
        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={
            'https://www.ird.govt.nz/-/media/project/ir/home/documents/forms-and-guides/ir1---ir99/ks2/ks2-09-2020.pdf?modified=20230911211019&modified=20230911211019'
          }
        >
          KiwiSaver deduction form
        </Link>{' '}
        and give it to your employer. Visit the{' '}
        <Link
          target="_blank"
          rel="noreferrer noopener"
          to={
            'https://www.ird.govt.nz/kiwisaver/kiwisaver-individuals/making-changes-to-my-kiwisaver/changing-my-kiwisaver-contribution-rate/change-my-kiwisaver-contribution-rate'
          }
        >
          IRD website
        </Link>{' '}
        to learn more.
      </p>
      <p>
        You can choose to contribute 3%, 4%, 6%, 8%, or 10% of your salary or
        wages to your KiwiSaver account.
      </p>
      <div className="change-contributions__footer">
        <Button
          className="my-md p-xs"
          variant="filled"
          size="sm"
          aria-controls="account-options-menu"
          aria-haspopup="true"
          onClick={() => {
            onClose()
          }}
        >
          Got it
        </Button>
      </div>
    </Modal>
  )
}

export default ChangeContributionsModal
