import moment from 'moment'

export const getUserAge = (birthDate: string) => {
  return moment().diff(moment(birthDate, 'dd/MM/yyyy'), 'years')
}

export const isAdult = (birthDate: string) => {
  const age = getUserAge(birthDate)
  return age >= 18
}

export const isOverRetirementAge = (birthDate: string) => {
  const age = getUserAge(birthDate)
  return age >= 65
}
