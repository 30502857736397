import { useMedia } from '../../hooks/use-media'
import { Button } from '../clickable/button/Button'
import PrivacyStatement from '../privacy-statement/PrivacyStatement'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
interface IPQIntroScreenProps {
  onClose?: () => void
  onNext: () => void
  loading: boolean
  isApexProduct?: boolean
}

const IPQIntroScreen = ({
  onClose,
  onNext,
  loading,
  isApexProduct,
}: IPQIntroScreenProps) => {
  const { isTabletLandscape } = useMedia()
  if (loading) {
    return <SpinnerOnLoad className="my-md" isLoading centerSpinner={true} />
  }
  const productType = isApexProduct ? 'fund' : 'strategy'

  return (
    <>
      <div>
        <p>
          When thinking about your investment {productType}, it's important to
          consider your investment timeframe and risk tolerance. The following
          short questionnaire is designed to help you decide which investment{' '}
          {productType} might be best for you.
        </p>
      </div>
      <div className="ipq__form__buttons mt-sm">
        <Button
          type="submit"
          variant="filled"
          color="primary"
          size={isTabletLandscape ? 'md' : 'sm'}
          onClick={onNext}
        >
          Next
        </Button>
        {!!onClose && (
          <Button variant="link" color="primary" size="sm" onClick={onClose}>
            Cancel
          </Button>
        )}
      </div>
      <div className="ipq__form__notice mb-md px-xs">
        By clicking &apos;Next&apos;, you agree to our
        <PrivacyStatement />
        and to us contacting you.
      </div>
    </>
  )
}

export default IPQIntroScreen
