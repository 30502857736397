import { BalanceGraphData, GraphConfiguration } from './account-balance.model'

export enum actionTypes {
  ACCOUNT_BALANCE_REQUEST = 'ACCOUNT_BALANCE_REQUEST',
  ACCOUNT_BALANCE_REQUEST_SUCCESS = 'ACCOUNT_BALANCE_REQUEST_SUCCESS',
  ACCOUNT_BALANCE_REQUEST_FAILURE = 'ACCOUNT_BALANCE_REQUEST_FAILURE',
  ACCOUNT_BALANCE_GRAPH_CONFIGURATION = 'ACCOUNT_BALANCE_GRAPH_CONFIGURATION',
}

export type AccountsBalanceRequest = {
  userId: string
  accountNumber: string
}

export type AccountBalanceRequestActionType = {
  type: actionTypes.ACCOUNT_BALANCE_REQUEST
  payload: AccountsBalanceRequest
}

export type AccountBalanceRequestSuccessActionType = {
  type: actionTypes.ACCOUNT_BALANCE_REQUEST_SUCCESS
  payload: {
    balanceGraph: BalanceGraphData
  }
}

export type AccountBalanceRequestFailureActionType = {
  type: actionTypes.ACCOUNT_BALANCE_REQUEST_FAILURE
  payload: string
}

export type AccountBalanceGraphConfigurationActionType = {
  type: actionTypes.ACCOUNT_BALANCE_GRAPH_CONFIGURATION
  payload: {
    graphConfiguration: GraphConfiguration
  }
}

export const AccountBalanceRequestAction = (
  userId: string,
  accountNumber: string
): AccountBalanceRequestActionType => ({
  type: actionTypes.ACCOUNT_BALANCE_REQUEST,
  payload: {
    userId: userId,
    accountNumber: accountNumber,
  },
})

export const AccountBalanceRequestSuccessAction = (
  balanceGraph: BalanceGraphData
): AccountBalanceRequestSuccessActionType => ({
  type: actionTypes.ACCOUNT_BALANCE_REQUEST_SUCCESS,
  payload: {
    balanceGraph: balanceGraph,
  },
})

export const AccountBalanceRequestFailureAction = (
  message: string
): AccountBalanceRequestFailureActionType => ({
  type: actionTypes.ACCOUNT_BALANCE_REQUEST_FAILURE,
  payload: message,
})

export const AccountBalanceGraphConfigurationAction = (
  graphConfiguration: GraphConfiguration
): AccountBalanceGraphConfigurationActionType => ({
  type: actionTypes.ACCOUNT_BALANCE_GRAPH_CONFIGURATION,
  payload: {
    graphConfiguration: graphConfiguration,
  },
})

export type AccountBalanceActions =
  | AccountBalanceRequestActionType
  | AccountBalanceRequestSuccessActionType
  | AccountBalanceRequestFailureActionType
  | AccountBalanceGraphConfigurationActionType
