import { useState } from 'react'
import './FirstHomeStepsModal.scss'
import Modal from '../modal/Modal'
import { useSteps } from './FirstHomeStepsModal.hooks'
import { Content } from './components'
import { YesNo } from './FirstHomeStepsModal.types'
import { requestLetter, requestCallback } from './FirstHomeStepsModal.api'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'

export type OwnProps = {
  onClose: () => void
}

type StateProps = {
  authToken: string
  userId: string
  accountNumber: string
}

type Props = OwnProps &
  StateProps & {
    hasKiwiSaverForThreeYears: boolean
    loading: boolean
    runCallbackRequestAsync: (promise: any) => void
    runGetLetterAsync: (promise: any) => void
  }

const FirstHomeStepsModal = (props: Props) => {
  const {
    accountNumber,
    userId,
    authToken,
    hasKiwiSaverForThreeYears,
    loading,
  } = props
  const { step, stepName, setStepName, addResponse, responses } = useSteps(
    'INITIAL_STEP'
  )
  const [previousAnswer, setPreviousAnswer] = useState(null)
  const [message, setMessage] = useState(null)

  const submitAnswer = (answer: YesNo) => {
    setPreviousAnswer(answer)
    const { nextStep } = step[answer]

    if (nextStep === 'EXIT') {
      props.onClose()
      return
    }

    if (nextStep === 'PREVIOUS_WITHDRAWAL' && !hasKiwiSaverForThreeYears) {
      setStepName('NOT_MINIMUM_MEMBERSHIP_LENGTH')
      return
    }

    if (nextStep === 'DOWNLOAD_LETTER') {
      props.runGetLetterAsync(requestLetter(userId, accountNumber, authToken))
    }

    const { message } = step[answer]

    if (message) {
      setMessage(message)
    } else {
      addResponse({ stepName, answer })
    }
  }

  const acknowledgeMessage = () => {
    setMessage(null)
    addResponse({ stepName, answer: previousAnswer })
  }

  function onSubmitContactForm(phone: string) {
    props.runCallbackRequestAsync(
      requestCallback(
        userId,
        accountNumber,
        {
          answers: responses,
          phone,
          hasKiwiSaverForThreeYears,
        },
        authToken
      )
    )

    setStepName('CONTACT_REQUEST_SENT')
  }

  return (
    <Modal
      title="First Home Letter"
      open
      onClose={props.onClose}
      className="first-home-steps-modal"
    >
      <SpinnerOnLoad isLoading={loading} centerSpinner={true}>
        <Content
          stepName={stepName}
          step={step}
          message={message}
          submitAnswer={submitAnswer}
          acknowledgeMessage={acknowledgeMessage}
          submitContactForm={onSubmitContactForm}
        />
      </SpinnerOnLoad>
    </Modal>
  )
}

export default FirstHomeStepsModal
