import React from 'react'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import DetailsFormAlert from '../details-form-alert/DetailsFormAlert'

import ContactUsModal from '../../components/contact-us-modal'
import useToggle from '../../hooks/use-toggle'
import { ArrowForward } from '@material-ui/icons'

type PIRChangeMessageType = {
  isLoading: boolean
  isSaving: boolean
  hasPIRChanged: boolean
  hasError: boolean
  errorMessage: string
}

export const PIRChangeMessage = (props: PIRChangeMessageType) => {
  return (
    <div className="mb-md">
      <DetailsFormAlert
        isVisible={!(props.isLoading || props.isSaving)}
        hasSucceed={props.hasPIRChanged}
        successMessage={'Your PIR has been changed successfully.'}
        hasError={props.hasError}
        errorMessage={props.errorMessage}
        onClose={() => {}}
      />

      <Typography component="p">
        This is the current Prescribed Investor Rate (PIR) which is applied to
        earnings on your investment.
      </Typography>
    </div>
  )
}

export const PIRChangeUnavailableMessage = () => {
  const { toggle, toggleOn, toggleOff } = useToggle(false)
  return (
    <Typography component="p" className="mb-md">
      <ContactUsModal open={toggle} onClose={toggleOff} />
      If you want to change the PIR for your company or trust, please{' '}
      <Link color="secondary" underline="hover" onClick={toggleOn}>
        get in contact with us
      </Link>
      .
    </Typography>
  )
}

export const WhatIsPIRLink = () => (
  <Typography component="p" className="mb-sm">
    <Link
      color="secondary"
      underline="hover"
      href="https://fisherfunds.co.nz/assets/PDFs/What-is-my-PIR-Rate.pdf"
      target="_blank"
      rel="noreferrer noopener"
    >
      What is my Prescribed Investor Rate (PIR)?
      <ArrowForward className="ml-xs" />
    </Link>
  </Typography>
)
