import { Reducer, AnyAction } from 'redux'
import moment from 'moment'

import { getDefaultState, setFailState } from '../state/state.model'
import { actionTypes } from './authorisation.actions'
import { AuthorisationState } from './authorisation.model'

import { userExpiresAtConfig } from '../config'

export const initialState: AuthorisationState = {
  authorisationToken: null,
  isAuthenticated: false,
  isLoggedOut: null,
  expiresAt: null,
  filesToken: null,
  lastAuthenticationTime: null,
  ...getDefaultState(),
}

export const authorisationReducer: Reducer<AuthorisationState> = (
  state: AuthorisationState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.AUTHORISATION_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        hasError: false,
        errorMessage: null,
        isLoggedOut: null,
      }
    case actionTypes.AUTHORISATION_REQUEST_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoggedOut: null,
        authorisationToken: action.payload.authorisationToken,
        filesToken: action.payload.filesToken,
        isLoading: false,
        lastAuthenticationTime: action.payload.lastAuthenticationTime,
        expiresAt: moment()
          .add(userExpiresAtConfig.value, userExpiresAtConfig.unit)
          .format(),
      }
    case actionTypes.AUTHORISATION_REQUEST_FAILURE:
      return { ...state, ...setFailState(action.payload) }
    case actionTypes.AUTHORISATION_FORM_RESET:
      return { ...state, ...getDefaultState() }
    case actionTypes.DEAUTHORISATION_REQUEST:
      return { ...state, isLoading: true, isLoggedOut: false }
    case actionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return {
        ...initialState,
        isLoggedOut: true,
        hasError: true,
        errorMessage: 'You have been logged out successfully.',
      }
    case actionTypes.DEAUTHORISATION_REQUEST_FAILURE:
      return {
        ...initialState,
        isLoggedOut: true,
        hasError: true,
        errorMessage:
          'Your account has been logged out due to inactivity. Please log in again.',
      }
    case actionTypes.DEAUTHORISATION_RESET_LOGOUT_MESSAGE:
      return {
        ...state,
        ...getDefaultState(),
        isLoggedOut: null,
      }
    default:
      return state
  }
}
