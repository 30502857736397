import { ProductShortCodes } from './../../common/product-variables'
import axios from 'axios'
import {
  EmploymentStatus,
  RetirementProjectorState,
} from '../../containers/retirement-projector/RetirementProjector.types'
import { getAnnualContribution } from '../../containers/retirement-projector/RetirementProjector.utils'
import { UserData } from '../../redux/user/user.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type RetirementProjectorPostRequest = {
  firstName: string
  lastName: string
  email: string
  mobile: string
  status: string
  source: string
  productShortCode: string
}

type RetirementProjectorInputs = {
  age: number
  startBalance: number
  income: number
  fundCode: string
  employeeContributionPercent: number
  employerContributionPercent: number
  voluntaryContribution: number
  includeSuper: boolean
}

export type RetirementProjectorPutRequest = {
  projectorInputs: RetirementProjectorInputs
  status: string
  outcome: {
    type: 'Remain' | 'Switch'
    option: string
  }
  accountNumber: string
}

export const postRetirementProjection = async (
  values: RetirementProjectorState,
  status: 'Complete' | 'Incomplete',
  user: UserData,
  authToken: string
) => {
  const retirementProjectionPostRequest: RetirementProjectorPostRequest = {
    firstName: user.firstName,
    lastName: user.surname,
    email: user.email,
    mobile: user.mobilePhoneNumber,
    source: 'FFO Web',
    status: status,
    productShortCode: ProductShortCodes[values.productExternalRef],
  }

  try {
    const response = await axios.post(
      `${ffmAppServerUrl}/api/v1/advice/retirement-projector`,
      retirementProjectionPostRequest,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    return response.data
  } catch (e) {
    throw Error(`Bridge post retirement-projector failure: ${e}`)
  }
}

export const putRetirementProjection = async (
  status: 'Complete' | 'Incomplete',
  authToken: string,
  dalId: string,
  accountNumber: string,
  outcome?: { type: 'Remain' | 'Switch'; option: string },
  values?: RetirementProjectorState
) => {
  let projectorInputs

  if (values) {
    const {
      employmentStatus,
      salary = 0,
      salaryFrequency,
      contributionRate,
      employerContributionRate,
      fund: selectedFund,
      voluntaryContribution,
      voluntaryContributionFrequency,
    } = values.aboutYou
    const income = employmentStatus === EmploymentStatus.EMPLOYED ? salary : 0
    const fundCode = selectedFund?.telCode

    if (!fundCode) {
      throw Error('No fund is selected or user has no fund')
    }

    projectorInputs = {
      age: values.age,
      startBalance: values.currentKiwiSaverBalance,
      income: getAnnualContribution(income, salaryFrequency),
      fundCode: fundCode,
      employeeContributionPercent: contributionRate,
      employerContributionPercent: employerContributionRate,
      voluntaryContribution: getAnnualContribution(
        voluntaryContribution,
        voluntaryContributionFrequency
      ),
      includeSuper: values.includeSuper,
    }
  }

  const retirementProjectionPutRequest: RetirementProjectorPutRequest = {
    status,
    outcome: outcome ?? undefined,
    projectorInputs,
    accountNumber,
  }

  try {
    const response = await axios.put(
      `${ffmAppServerUrl}/api/v1/advice/retirement-projector/${dalId}`,
      retirementProjectionPutRequest,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    return response.data
  } catch (e) {
    throw Error(`Bridge put retirement-projector failure: ${e}`)
  }
}
