import { Tooltip } from '@material-ui/core'

import { Detail } from './AccountPerformanceSummary'
import InfoIcon from '@material-ui/icons/Info'

type SummaryDetailLabelProps = {
  detail: Partial<Detail>
}

export function SummaryDetailLabel({ detail }: SummaryDetailLabelProps) {
  if (detail.detailLabel === 'As of last valuation date') return null

  if (!detail.detailTooltip) return <span>{detail.detailLabel}</span>

  return (
    <Tooltip
      title={detail.detailTooltip}
      disableFocusListener
      enterTouchDelay={200}
    >
      <div className="tooltip">
        <span className="tooltip-label">{detail.detailLabel}</span>
        <span className="tooltip-icon">
          <InfoIcon />
        </span>
      </div>
    </Tooltip>
  )
}
