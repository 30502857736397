const deserialize = (data: any) => {
  const { transactions } = data.accountTransactions

  return transactions.edges.map((edge: { cursor: string; node: any }) => {
    const description = edge.node.reasonTypeDescription
      ? edge.node.reasonTypeDescription
      : edge.node.eventTypeShortDescription
    return { ...edge.node, description }
  })
}

export default deserialize
