import { Reducer, AnyAction } from 'redux'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './switch-notifications.actions'
import { SwitchNotificationsState } from './switch-notifications.model'

export const initialState: SwitchNotificationsState = {
  ...getDefaultState(),
}

export const switchNotificationsReducer: Reducer<SwitchNotificationsState> = (
  state: SwitchNotificationsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.SWITCH_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true, hasError: false, errorMessage: null }
    case actionTypes.SWITCH_NOTIFICATIONS_REQUEST_SUCCESS:
      return { ...state, notifications: action.payload, isLoading: false }

    case actionTypes.SWITCH_NOTIFICATIONS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }
    // Logging out resets the account details
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
