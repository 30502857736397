import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`investments-details-tabpanel-${index}`}
      aria-labelledby={`investments-details-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  )
}
