import { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { ChartOptions } from 'chart.js'
import './account-funds-pie-chart.scss'
import { Fund } from '../account-investment-tab/AccountInvestmentTab'

interface AccountFundsPieChartProps {
  funds: Fund[]
}

type PieChartData = {
  labels: Array<string>
  datasets: Array<{
    data: Array<number>
    backgroundColor: Array<string>
    hoverBackgroundColor: Array<string>
  }>
}

type ChartTooltipItem = {
  index?: number
}

const CHART_OPTIONS: ChartOptions = {
  tooltips: {
    callbacks: {
      title: function () {
        return ''
      },
      label: (tooltipItem: ChartTooltipItem, chartData: PieChartData) => {
        return chartData.labels[tooltipItem.index!][0]
      },
      afterLabel: (tooltipItem: ChartTooltipItem, chartData: PieChartData) => {
        return chartData.labels[tooltipItem.index!][1]
      },
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  animation: {
    duration: 1000,
  },
  elements: {
    arc: {
      borderWidth: 0.5,
    },
  },
}

const getDataForChart = (funds: Fund[]) => {
  const fundColors = funds.map((fund) => fund.color)
  return {
    labels: funds.map((fund) => [
      `${fund.name}:`,
      `${fund.balanceFormatted} (${fund.percentageFormatted})`,
    ]),
    datasets: [
      {
        data: funds.map((fund) => fund.balance),
        backgroundColor: fundColors,
        hoverBackgroundColor: fundColors,
      },
    ],
  }
}

const AccountFundsPieChart = ({ funds }: AccountFundsPieChartProps) => {
  const data = useMemo(() => getDataForChart(funds), [funds])
  return (
    <div className="account-funds-chart">
      <Doughnut
        data={data}
        height={100}
        legend={{ display: false }}
        options={CHART_OPTIONS}
        width={100}
      />
    </div>
  )
}

export default AccountFundsPieChart
