import {
  actionTypes,
  UsernameActions,
  UsernameChangeActionType,
  UsernameChangeFailureAction,
  UsernameChangeSuccessAction,
  UsernameRequestActionType,
  UsernameRequestFailureAction,
  UsernameRequestSuccessAction,
} from './username.actions'
import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import { UsernameState } from './username.model'

type LightStore = { getState: Function; dispatch: Function }

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

export const requestUsernameEpic: Epic<UsernameActions, UsernameState> = (
  action$: ActionsObservable<UsernameRequestActionType>,
  store: LightStore
) =>
  action$.ofType(actionTypes.USERNAME_REQUEST).switchMap((action) =>
    ajax
      .get(`${ffmAppServerUrl}/api/users/${action.payload.userid}/alias`, {
        Authorization:
          'Bearer ' + store.getState().authorisation.authorisationToken,
      })
      .map((response) => {
        return UsernameRequestSuccessAction({
          username: response.response.alias,
          isChangeable: response.response.isChangeable,
        })
      })
      .catch(() =>
        Observable.of(
          UsernameRequestFailureAction(
            'Error retrieving your Username. Please try again later.'
          )
        )
      )
  )

export const changeUsernameEpic: Epic<UsernameActions, UsernameState> = (
  action$: ActionsObservable<UsernameChangeActionType>,
  store: LightStore
) =>
  action$.ofType(actionTypes.USERNAME_CHANGE).switchMap((action) => {
    const requestBody: any = {
      newAlias: action.payload.newUsername,
      ...action.payload,
    }

    return ajax
      .put(
        `${ffmAppServerUrl}/api/users/${action.payload.userid}/alias`,
        requestBody,
        {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        }
      )
      .map(() =>
        UsernameChangeSuccessAction({
          username: action.payload.newUsername,
        })
      )
      .catch((err) => {
        let message =
          'Your username could not be changed. Please try again later.'
        if (
          typeof err === 'object' &&
          err.hasOwnProperty('status') &&
          err.status === 412
        ) {
          message = "Sorry, you can't use that username. Please try another."
        }
        return Observable.of(UsernameChangeFailureAction(message))
      })
  })
