import React from 'react'
import cn from 'classnames'
import './TextAreaInput.scss'

export type TextAreaInputProps = {
  error?: boolean
  dark?: boolean
} & React.HTMLProps<HTMLTextAreaElement>

export const TextAreaInput = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(
  (
    { className = '', error, disabled, dark, ...rest }: TextAreaInputProps,
    ref
  ) => (
    <textarea
      className={cn('input-textarea__element', {
        [`${className}`]: !!className,
        'input-textarea__element--error': !!error,
        'input-textarea__element--disabled': !!disabled,
        'input-textarea__element--dark': dark,
      })}
      disabled={disabled}
      type="text"
      ref={ref}
      {...rest}
    />
  )
)

TextAreaInput.displayName = 'TextAreaInput'
