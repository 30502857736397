import React, { useEffect } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import './ContactDetails.scss'
import ContactDetailsForm, {
  SavingStatus,
} from '../contact-details-form/ContactDetailsForm'
import {
  UserActions,
  UserRequestDetailsAction,
} from '../../redux/user/user.actions'
import { Address, PhoneEmailDetails } from '../../common/addresses.types'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'

export interface User extends PhoneEmailDetails, Address {
  userid: string
  surname: string
  firstName: string
  secondName: string
  birthDate: string
  hasError: boolean
  errorMessage: string
  errorStatus: number
  isLoading: boolean
  savingStatus: SavingStatus
}

interface ContactDetailsProps {
  lastQueried?: string
  user?: User
  extraClass?: string
  setActiveBlock?: (block: string) => void
  actions?: {
    getUserDetails: (uuid: string) => void
  }
}

export function ContactDetailsPage(props: ContactDetailsProps) {
  useEffect(() => {
    props.actions.getUserDetails(props.user.userid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SpinnerOnLoad isLoading={props.user.isLoading}>
      <ContactDetailsForm {...props.user} />
    </SpinnerOnLoad>
  )
}

const mapStateToProps = (state: AppState) => {
  const user = { ...state.user }
  return {
    user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<UserActions>) => ({
  actions: {
    getUserDetails: bindActionCreators(UserRequestDetailsAction, dispatch),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ContactDetailsPage)
