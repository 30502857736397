import cn from 'classnames'
import Slider from './Slider'
import { FundAllocation } from './CustomStrategySelector'
import { Button } from '../../clickable/button/Button'
import CustomIcon from '../../../common/CustomIcon'
import './CustomStrategyBar.scss'

export type CustomStrategyBarProps = {
  allocation: FundAllocation
  min: number
  max: number
  disableMax: boolean
  onChange: (value: FundAllocation) => void
  lite: boolean
  onMoreInfoClick: (salesforceCode: string) => void
  stepIncrement?: number
}

export const CustomStrategyBar = ({
  allocation,
  min,
  max,
  disableMax,
  onChange,
  lite,
  onMoreInfoClick,
  stepIncrement = 1,
}: CustomStrategyBarProps) => {
  const handleChange = (value: number) =>
    onChange({
      ...allocation,
      ratio: Math.round(value),
    })

  const handleIncrement = () => {
    if (allocation.ratio < max) {
      onChange({
        ...allocation,
        ratio: allocation.ratio + Math.abs(stepIncrement),
      })
    }
  }

  const handleDecrement = () => {
    if (allocation.ratio > min) {
      onChange({
        ...allocation,
        ratio: allocation.ratio - Math.abs(stepIncrement),
      })
    }
  }

  return (
    <div
      className={cn('custom-strategy-bar', {
        'custom-strategy-bar--lite': lite,
      })}
    >
      <div className="flex-row-center">
        <h6 className="font-fund text-regular mb-0">{allocation.name}</h6>
        <Button
          className="more-info ml-xs"
          data-cy="custom-strategy-label"
          variant="link"
          onClick={() => onMoreInfoClick(allocation.id)}
        >
          <span className="text-small flex-row-center">
            More info <CustomIcon icon="link-out" />
          </span>
        </Button>
      </div>
      <p className="custom-strategy-bar__ratio text-small mt-0 mb-2xs">
        {allocation.ratio}%
      </p>
      <div className="custom-strategy-bar__slider-btns">
        <button
          className="custom-strategy-bar__btn"
          onClick={handleDecrement}
          disabled={allocation.ratio === min}
        >
          <CustomIcon icon="minus" />
        </button>
        <Slider
          className="custom-strategy-bar__slider"
          value={allocation.ratio}
          min={min}
          max={max}
          onChange={handleChange}
          lite={lite}
          step={stepIncrement}
        />
        <button
          className="custom-strategy-bar__btn"
          onClick={handleIncrement}
          disabled={allocation.ratio === max || disableMax}
        >
          <CustomIcon icon="plus" />
        </button>
      </div>
    </div>
  )
}
