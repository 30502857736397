import React from 'react'
import { Link } from 'react-router'

import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'

export interface LinkBoxProps {
  type: string
  displayTitle?: boolean
}

export default function RegisterLoginLinkBox(props: LinkBoxProps) {
  const websiteUrl =
    process.env.REACT_APP_WEBSITE_URL || 'https://fisherfunds.co.nz'

  const BoxTitle = (props: LinkBoxProps) => {
    if (!props.displayTitle) {
      return null
    }

    return (
      <Typography component="h3" variant="h6" align="left">
        {props.type === 'login'
          ? 'Already have an account setup?'
          : 'Online access is available to all of our clients.'}
      </Typography>
    )
  }

  const BoxLink = (props: LinkBoxProps) => {
    let path = websiteUrl + '/investing-with-us'
    let label = 'Invest with us.'
    let text = "Don't have an account? "

    if (props.type === 'login') {
      path = '/accounts'
      label = 'Back to login'
      text = ''
    }

    return (
      <Typography variant="body1">
        {text}
        {props.type === 'login' ? (
          <MuiLink component={Link} to={path} className="back-to-login">
            <ArrowBackOutlinedIcon /> {label}
          </MuiLink>
        ) : (
          <MuiLink
            href={path}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
          >
            {label}
          </MuiLink>
        )}
      </Typography>
    )
  }

  return (
    <Box className="register-login-link-box">
      <BoxTitle {...props} />
      <Typography component="p">
        <BoxLink {...props} />
      </Typography>
    </Box>
  )
}
