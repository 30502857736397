import React, { useState } from 'react'

import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  FocusedInputShape,
} from 'react-dates'

import moment from 'moment'

import { MobileDatePicker } from './components'
import './CustomRangePicker.scss'

import { CustomDateRangeProps } from './CustomRangePicker.types'

export default function CustomDateRange(props: CustomDateRangeProps) {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>()

  if (!window.innerWidth || window.innerWidth <= 768) {
    return <MobileDatePicker {...props} />
  }

  return (
    <div className="custom-range-picker-component">
      <DateRangePicker
        startDate={props.filters.startDate ? props.filters.startDate : null}
        startDateId="desktop-start-date"
        displayFormat="DD/MM/YYYY"
        orientation="horizontal"
        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        endDate={props.filters.endDate ? props.filters.endDate : null}
        endDateId="desktop-end-date"
        onDatesChange={({ startDate, endDate }) => {
          if (Boolean(startDate) && Boolean(endDate)) {
            props.onCustomDateChanges(startDate, endDate)
          }
        }}
        disabled={props.isDisabled}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      />
    </div>
  )
}
