import { Reducer } from 'redux'

export const chartColorsReducer: Reducer<String[]> = () => [
  '#06AE9F',
  '#6A8AB3',
  '#14467B',
  '#707173',
  '#D4E7E1',
  '#BDBEC2',
  '#9BCDC4',
  '#4A91A8',
  '#00798D',
  '#4C8AB1',
]
