import * as React from 'react'
const moment = require('moment')

interface DateNZProps {
  value: string
}

export default class DateNZ extends React.Component<DateNZProps> {
  render() {
    const { value } = this.props
    // If dateString is an ISO 8601 format string, parse and format the date using Moment.js
    let dateFromISOString = moment(value, moment.ISO_8601)
    if (dateFromISOString.isValid()) {
      return (
        <span className="DateNZ">{dateFromISOString.format('DD/MM/YYYY')}</span>
      )
    }
    // Otherwise parse against a specific string and format the date using Moment.js
    let dateFromNonISOString = moment(value, 'ddd MMM DD YYYY HH:mm:ss Z')
    if (dateFromNonISOString.isValid()) {
      return (
        <span className="DateNZ">
          {dateFromNonISOString.format('DD/MM/YYYY')}
        </span>
      )
    } else {
      // If dateString cannot be parsed, return the date unformatted
      return <span className="DateNZ">{value}</span>
    }
  }
}
