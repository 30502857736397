import './CopyableCard.scss'
import { useState } from 'react'
import cn from 'classnames'
import { ReactComponent as CopyIcon } from '../../assets/images/icons/icon_copy_paper.svg'
import CheckIcon from '@material-ui/icons/Check'

interface CopyableCardProps {
  subTitle: string
  value: string
}

const CopyableCard = ({ subTitle, value }: CopyableCardProps) => {
  const [copied, setCopied] = useState(false)

  const copyContent = () => {
    // eslint-disable-next-line
    navigator.clipboard
      .writeText(value.replace(/-/g, ''))
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 1200)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const iconClass = cn('copyable-card__icon', {
    copied: copied,
  })

  return (
    <button className={`copyable-card`} onClick={copyContent} tabIndex={0}>
      <div>
        <p className="copyable-card__subtitle color-text-dark my-2xs">
          {!!subTitle && subTitle}
        </p>
        <p className="copyable-card__value color-midnight">
          <strong>{!!value && value}</strong>
        </p>
      </div>
      <div>
        {!!copied ? (
          <CheckIcon className={iconClass} />
        ) : (
          <CopyIcon className={iconClass} />
        )}
      </div>
    </button>
  )
}

export default CopyableCard
