import { EnterHook } from 'react-router'
import { store } from '../redux/store'
import { AppState } from '../redux/app-state'
import { routerActions } from 'react-router-redux'

export const onboardingHashHook = (): EnterHook => (
  nextState,
  replace,
  callback
) => {
  ;(store.getState() as AppState).routing.locationBeforeTransitions.query.q
    ? callback()
    : store.dispatch(routerActions.replace('/login'))
}

/**
 * Check if the users DOB has been confirmed during onboarding.
 * If it has been confirmed then continue to the route.
 * Otherwise route them to the confirm DOB screen.
 */
export const confirmDOBHook = (): EnterHook => (
  nextState,
  replace,
  callback
) => {
  const isDobConfirmed = (store.getState() as AppState).onboarding
    .hasBeenConfirmedByDob
  isDobConfirmed
    ? callback()
    : store.dispatch(routerActions.replace('/onboard/confirm-dob'))
}
