import { combineEpics, Epic } from 'redux-observable'

import { accountDetailsEpic } from './account-details/account-details.epics'
import {
  accountFundsEpic,
  investmentProfileEpic,
} from './account-funds/account-funds.epics'
import { accountGrowthEpic } from './account-growth/account-growth.epics'
import {
  accountTransactionsEpic,
  updateTransactionFiltersEpic,
} from './account-transactions/account-transactions.epics'
import { accountsEpic, accountsFundsEpic } from './accounts/accounts.epics'
import {
  authorisationEpic,
  checkAuthorisationEpic,
  deauthorisationEpic,
} from './authorisation/authorisation.epics'
import {
  checkUsernameEpic,
  confirmDOBEpic,
  createCredentialsEpic,
  onboardingEpic,
  onboardingSearchEpic,
} from './onboarding/onboarding.epics'
import { getUserPirRateEpic, savePirRateEpic } from './pir/pir.epics'
import { reportsEpic } from './reports/reports.epics'
import {
  changePasswordEpic,
  checkConfirmCodeEpic,
  requestConfirmCodeEpic,
  requestDetailsEpic,
  resetPasswordEpic,
  updateDetailsEpic,
  usernameConfirmationEpic,
} from './user/user.epics'
import {
  getThirdPartyAppSecureUrlEpic,
  getThirdPartyFormDataEpic,
} from './third-party-app/third-party-app.epics'
import {
  usernameRecoverySubmitEpic,
  usernameRecoveryVerificationEpic,
} from './username-recovery/username-recovery.epic'
import {
  changeUsernameEpic,
  requestUsernameEpic,
} from './username/username.epics'
import { switchNotificationsEpic } from './switch-notifications/switch-notifications.epics'
import accountBalanceEpic from './account-balance/account-balance.epics'

export default combineEpics(
  confirmDOBEpic as Epic<any, any>,
  checkUsernameEpic as Epic<any, any>,
  accountsEpic as Epic<any, any>,
  accountBalanceEpic as Epic<any, any>,
  accountDetailsEpic as Epic<any, any>,
  accountGrowthEpic as Epic<any, any>,
  accountFundsEpic as Epic<any, any>,
  accountTransactionsEpic as Epic<any, any>,
  updateTransactionFiltersEpic as Epic<any, any>,
  accountsFundsEpic as Epic<any, any>,
  investmentProfileEpic as Epic<any, any>,
  authorisationEpic as Epic<any, any>,
  deauthorisationEpic as Epic<any, any>,
  checkAuthorisationEpic as Epic<any, any>,
  checkConfirmCodeEpic as Epic<any, any>,
  usernameConfirmationEpic as Epic<any, any>,
  createCredentialsEpic as Epic<any, any>,
  onboardingEpic as Epic<any, any>,
  onboardingSearchEpic as Epic<any, any>,
  changePasswordEpic as Epic<any, any>,
  requestConfirmCodeEpic as Epic<any, any>,
  requestDetailsEpic as Epic<any, any>,
  requestUsernameEpic as Epic<any, any>,
  changeUsernameEpic as Epic<any, any>,
  usernameRecoverySubmitEpic as Epic<any, any>,
  resetPasswordEpic as Epic<any, any>,
  getUserPirRateEpic as Epic<any, any>,
  getThirdPartyAppSecureUrlEpic as Epic<any, any>,
  getThirdPartyFormDataEpic as Epic<any, any>,
  updateDetailsEpic as Epic<any, any>,
  savePirRateEpic as Epic<any, any>,
  reportsEpic as Epic<any, any>,
  usernameRecoveryVerificationEpic as Epic<any, any>,
  switchNotificationsEpic as Epic<any, any>
)
