import React, { useState } from 'react'
import { Account } from '../../redux/accounts/accounts.model'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import { hasOwnKiwiSaverAccount } from '../../common/accounts-helper'
import './AccountsOverview.scss'
import { UserData } from '../../redux/user/user.model'
import { NewAccountCard, NewAccountOptionModal } from './NewAccountCard'
import AccountCard from './AccountCard'
import { isAdult } from '../../common/user-helper'

export interface AccountsOverviewProps {
  allAccounts?: Account[]
  isLoading: boolean
  user: UserData
}

export default function AccountsOverview(props: AccountsOverviewProps) {
  const { user } = props
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className="accounts-overview-component">
      <SpinnerOnLoad isLoading={props.isLoading} centerSpinner={true}>
        <h6>Your accounts</h6>
        <div className="accounts-grid">
          {props.allAccounts.map((account: Account, index) => (
            <AccountCard key={index} userId={user.userid} account={account} />
          ))}
          {isAdult(user.birthDate) && (
            <>
              <NewAccountCard
                user={user}
                hasOwnKiwiSaverAccount={hasOwnKiwiSaverAccount(
                  props.allAccounts
                )}
                handleClick={() => {
                  setModalOpen(true)
                }}
              />
              <NewAccountOptionModal
                user={user}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
              />
            </>
          )}
        </div>
      </SpinnerOnLoad>
    </div>
  )
}
