import React, { useEffect } from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import numeral from 'numeral'
import { bindActionCreators, Dispatch } from 'redux'

import {
  AccountFundsActions,
  AccountFundsInvestmentProfileRequestAction,
} from '../../redux/account-funds/account-funds.actions'
import { Account } from '../../redux/accounts/accounts.model'
import AccountInvestmentProfile from '../account-investment-profile/AccountInvestmentProfile'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import TabPanel from '../tab-panel/TabPanel'
import { AppState } from '../../redux/app-state'
import { getAccountByID } from '../../common/accounts-helper'
import './AccountInvestmentTab.scss'

export type Fund = {
  name: string
  priceDate: string
  color: string
  balance: number
  unitBalance: number
  price: number
  balanceFormatted: string
  percentageFormatted: string
  pieTaxAccrued: number
}

interface AccountInvestmentTabProps {
  userId: string
  hasError: boolean
  errorMessage: string
  isLoading: boolean
  getAccountFunds: (userId: string, accountId: string) => void
  currentAccount: Account
  funds: Fund[]
  params: {
    id: string
  }
}

function AccountInvestmentTab(props: AccountInvestmentTabProps) {
  const { isLoading, getAccountFunds, userId, params, funds = [] } = props

  useEffect(() => {
    getAccountFunds(userId, params.id)
  }, [userId, params.id, getAccountFunds])

  return (
    <TabPanel value={1} index={1}>
      <div className="account-investment-tab-component">
        <SpinnerOnLoad isLoading={isLoading} centerSpinner={true}>
          <AccountInvestmentProfile
            currentAccount={props.currentAccount}
            funds={funds}
          />
        </SpinnerOnLoad>
      </div>
    </TabPanel>
  )
}

const mapStateToProps = (state: AppState, props: AccountInvestmentTabProps) => {
  return {
    userId: state.user.userid,
    isLoading: state.accountFunds.isLoading,
    hasError: state.accountFunds.hasError,
    errorMessage: state.accountFunds.errorMessage,
    currentAccount: getAccountByID(
      props.params.id.toString(),
      state.accounts.accounts
    ),
    funds: state.accountFunds.funds.map((fund, index) => {
      const balanceFormatted = numeral(fund.dollarBalance)
        .subtract(fund.pieTaxLiability)
        .format('$0,0.00')
      const percentageFormatted = numeral(fund.dollarBalance)
        .subtract(fund.pieTaxLiability)
        .divide(state.accountFunds.accountTotal)
        .format('0%')

      return {
        name: fund.fundDisplayName,
        priceDate: fund.priceDate,
        color: state.chartColors[index],
        balance: fund.dollarBalance,
        unitBalance: fund.unitBalance,
        price: fund.price,
        pieTaxAccrued: fund.pieTaxLiability * -1,
        balanceFormatted,
        percentageFormatted,
      }
    }),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountFundsActions>) => ({
  getAccountFunds: bindActionCreators(
    AccountFundsInvestmentProfileRequestAction,
    dispatch
  ),
  nextStep: (path: string) => dispatch(routerActions.push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountInvestmentTab)
