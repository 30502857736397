import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import numeral from 'numeral'
import { bindActionCreators, Dispatch } from 'redux'
import {
  Transaction,
  TransactionFilters,
} from '../../redux/account-transactions/account-transactions.model'
import {
  AccountTransactionsRequestAction,
  AccountTransactionsActions,
} from '../../redux/account-transactions/account-transactions.actions'
import { AppState } from '../../redux/app-state'

import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import downloadFile from '../../common/downloads-helper'

interface CSVDownloadProps {
  accountNumber: string
  productName: string
  filters: TransactionFilters
  transactions: Transaction[]
  userId?: string
  numberOfTransactions?: number
  isLoadingAllTransactions?: boolean
  getTransactions?: any
}

export const CSVDownload: React.FC<CSVDownloadProps> = (props) => {
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false)

  const downloadAllTransactions = () => {
    setIsDownloadingCSV(true)

    props.getTransactions({
      userId: props.userId,
      accountId: props.accountNumber,
      offset: 0,
      shouldReturnAll: true,
      filters: props.filters,
      isCSV: true,
    })
    return
  }

  const downloadCSV = useCallback(() => {
    // Remove any non digit characters from dates
    const startDate = props.transactions[
      props.transactions.length - 1
    ].effectiveDatetime.replace(/[\D]/g, '')
    const endDate = props.transactions[0].effectiveDatetime.replace(/[\D]/g, '')
    let fileName = `${props.productName} ${props.accountNumber} Transactions ${startDate}-${endDate}`
    // Remove any non file safe characters and replace with underscores
    fileName = fileName.trim().replace(/[\s\\/:*?"<>|.]/g, '_')
    fileName = `${fileName}.csv`

    // download func
    let output = ''

    // transactions each
    props.transactions.forEach((trans, idx) => {
      if (trans.transactionAssets) {
        let assets = trans.transactionAssets.map((asset, idx) => {
          const assetDisplayNameNullChecked =
            asset.displayName == null ? '' : asset.displayName
          const assetUnitNullChecked = asset.units == null ? '' : asset.units
          const assetPriceNullCheckedAndFormatted =
            asset.price == null
              ? ''
              : numeral(asset.price).format('$0.0000', Math.floor)
          return `${trans.effectiveDatetime},${assetDisplayNameNullChecked},${trans.description},${assetUnitNullChecked},${assetPriceNullCheckedAndFormatted},${asset.amount}\n`
        })
        output += assets.join('')
      }
    })

    let csv = 'Date,Fund,Description,Units,Unit Price,Amount\n'
    csv += output
    const blob = new Blob([csv], { type: 'text/csv;' })
    downloadFile(blob, fileName)
    setIsDownloadingCSV(false)
  }, [
    props.transactions,
    props.productName,
    props.accountNumber,
    setIsDownloadingCSV,
  ])

  useEffect(() => {
    if (
      isDownloadingCSV &&
      !props.isLoadingAllTransactions &&
      props.transactions.length > 0
    ) {
      downloadCSV()
    }
  }, [
    downloadCSV,
    isDownloadingCSV,
    props.transactions.length,
    props.isLoadingAllTransactions,
  ])

  if (props.numberOfTransactions <= 0) {
    return null
  }

  return (
    <Button
      disableRipple
      variant="outlined"
      onClick={downloadAllTransactions}
      disabled={props.isLoadingAllTransactions}
      startIcon={<GetAppIcon />}
      color="secondary"
    >
      CSV
    </Button>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    transactions: state.accountTransactions.allTransactions,
    numberOfTransactions: state.accountTransactions.transactions
      ? state.accountTransactions.transactions.length
      : null,
    isLoadingAllTransactions:
      state.accountTransactions.isLoadingAllTransactions,
    userId: state.user.userid,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AccountTransactionsActions>
) => ({
  getTransactions: bindActionCreators(
    AccountTransactionsRequestAction,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(CSVDownload)
