import { Reducer, AnyAction } from 'redux'
import { actionTypes } from './layout.actions'

export type LayoutState = {
  showSidebar: boolean
  showHeader: boolean
  activeAccountTab: string
  activeMenu: string
}

export const initialState: LayoutState = {
  showSidebar: false,
  showHeader: false,
  activeAccountTab: '',
  activeMenu: '',
}

export const layoutReducer: Reducer<LayoutState> = (
  state: LayoutState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        showSidebar:
          action.payload !== undefined ? action.payload : !state.showSidebar,
      }
    case actionTypes.CHANGE_ACTIVE_ACCOUNT_TAB:
      return {
        ...state,
        activeAccountTab: action.payload,
      }
    case actionTypes.CHANGE_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      }
    case actionTypes.CHANGE_SHOW_HEADER:
      return {
        ...state,
        showHeader: action.payload,
      }
    default:
      return state
  }
}
