import { State } from '../state/state.model'

export interface AccountBalanceState extends State {
  graphConfiguration: GraphConfiguration
  balanceGraphs: BalanceGraphData[]
}

export type BalanceGraphData = {
  accountNumber: string
  graphConfigurationDateType: string
  balancePeriods: BalancePeriod[]
}

export type BalancePeriod = {
  marketValue: number
  accumCashFlow: number
  toDate: string
}

export type GraphConfiguration = {
  type: GraphConfigurationType
  displayName: string
  dateType: string
  minimumAccountAgeInMonths: number
}

export enum GraphConfigurationType {
  DAILY,
  MONTHLY,
}
