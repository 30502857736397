import React from 'react'
import useToggle from '../../hooks/use-toggle'
import cn from 'classnames'
import ContentfulTermsAndConditionsModal from './ContentfulTermsAndConditionsModal'
import Link from '@material-ui/core/Link'

export const TermsAndConditionsLink = (props: { className?: string }) => (
  <ContentModalLink
    contentfulEntryName="Digital Platforms - Terms and Conditions"
    title="Terms and Conditions"
    className={props.className}
  />
)

export const PrivacyPolicyLink = (props: { className?: string }) => (
  <ContentModalLink
    contentfulEntryName="Privacy Policy"
    title="Privacy Policy"
    className={props.className}
  />
)

export type ContentModalLinkProps = {
  contentfulEntryName: string
  title: string
  className?: string
}

const ContentModalLink = (props: ContentModalLinkProps) => {
  const { contentfulEntryName, title, className } = props
  const { toggle, toggleOn, toggleOff } = useToggle(false)
  return (
    <>
      {toggle && (
        <ContentfulTermsAndConditionsModal
          contentfulEntryName={contentfulEntryName}
          title={title}
          open={toggle}
          onClose={toggleOff}
        />
      )}
      <Link
        className={cn('MuiTypography-colorPrimary', {
          [`${className}`]: !!className,
        })}
        onClick={toggleOn}
      >
        {title}
      </Link>
    </>
  )
}
