import { AnyAction, Reducer } from 'redux'
import { getDefaultState, setFailState } from '../state/state.model'
import { UsernameRecoveryState } from './username-recovery.model'
import { actionTypes } from './username-recovery.actions'

export const initialUsernameRecoveryState: UsernameRecoveryState = {
  submitted: false,
  verified: false,
  credentials: null,
  ...getDefaultState(),
}

export const usernameRecoveryReducer: Reducer<UsernameRecoveryState> = (
  state: UsernameRecoveryState = initialUsernameRecoveryState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.USERNAME_RECOVERY_SUBMIT:
      return { ...state, ...getDefaultState(), isLoading: true }
    case actionTypes.USERNAME_RECOVERY_SUBMIT_SUCCESS:
      return { ...state, isLoading: false, submitted: true }
    case actionTypes.USERNAME_RECOVERY_SUBMIT_FAILURE:
      return { ...state, ...setFailState(action.payload) }
    case actionTypes.USERNAME_RECOVERY_CLEAR_STATE:
      return { ...initialUsernameRecoveryState }
    case actionTypes.USERNAME_RECOVERY_VERIFICATION:
      return { ...state, ...getDefaultState(), isLoading: true }
    case actionTypes.USERNAME_RECOVERY_VERIFICATION_SUCCESS:
      return { ...state, isLoading: false, verified: true, ...action.payload }
    case actionTypes.USERNAME_RECOVERY_VERIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...setFailState(
          action.payload.errorMessage,
          action.payload.errorStatus
        ),
      }
    default:
      return state
  }
}
