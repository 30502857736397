import numeral from 'numeral'
import './AccountInvestmentProfile.scss'
import DateNZ from '../date-nz/DateNZ'
import { Account } from '../../redux/accounts/accounts.model'
import AccountFundsPieChart from '../account-funds-pie-chart/AccountFundsPieChart'
import { Fund } from '../account-investment-tab/AccountInvestmentTab'
import { FOUR_DECIMAL_PLACES_FORMAT } from '../currency/Currency'

interface AccountInvestmentProfileProps {
  funds: Fund[]
  currentAccount: Account
}

const colorLabel = (color: string) => (
  <span className={`color-label`} style={{ background: color }}></span>
)

const AccountInvestmentProfile = (props: AccountInvestmentProfileProps) => (
  <div className="account-investment-profile-component">
    <AccountFundsPieChart funds={props.funds} />
    <div className="investment-funds">
      {props.funds.map((fund: Fund) => {
        const unitPriceFormatted = numeral(fund.price).format(
          FOUR_DECIMAL_PLACES_FORMAT,
          Math.floor
        )
        const pieTaxLabel = fund.pieTaxAccrued >= 0 ? ' refund' : ''

        return (
          <div className="investment-fund" key={fund.name}>
            <div className="fund-header pairs">
              <div className="fund-name-and-color">
                {colorLabel(fund.color)}
                <span className="font-fund color-midnight">{fund.name}</span>
              </div>
              <span className="color-midnight text-bold">
                {fund.balanceFormatted}
              </span>
            </div>
            <hr />
            <div className="pairs">
              <span className="color-text-light">Valuation date</span>
              <span className="color-text-dark">
                <DateNZ value={fund.priceDate} />
              </span>
            </div>
            <div className="pairs">
              <span className="color-text-light">Units</span>
              <span className="color-text-dark">{fund.unitBalance}</span>
            </div>
            <div className="pairs">
              <span className="color-text-light">Unit price</span>
              <span className="color-text-dark">{unitPriceFormatted}</span>
            </div>
            <div className="pairs">
              <span className="color-text-light">Balance before tax</span>
              <span className="color-text-dark">
                {numeral(fund.balanceFormatted)
                  .subtract(fund.pieTaxAccrued)
                  .format('$0,0.00')}
              </span>
            </div>
            <div className="pairs">
              <span className="color-text-light">
                PIE tax {pieTaxLabel} accrued year to date
              </span>
              <span className="color-text-dark">
                {numeral(fund.pieTaxAccrued).format('$0,0.00')}
              </span>
            </div>
            <hr />
            <div className="pairs mb-0">
              <span className="color-text-light">Balance after tax</span>
              <span className="color-text-dark">{fund.balanceFormatted}</span>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default AccountInvestmentProfile
