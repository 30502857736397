import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import './Slider.scss'

type SliderProps = {
  value: number
  min: number
  max: number
  onChange: (value: number) => void
  className?: string
  lite?: boolean
  step?: number
}

export default function Slider({
  value,
  min,
  max,
  onChange,
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lite = false,
  step = 1,
}: SliderProps) {
  const [railLength, setRailLength] = useState(0)
  const [railOffset, setRailOffset] = useState(0)

  const [thumbOffset, setThumbOffset] = useState(0)

  const [draggingThumb, setDraggingThumb] = useState(false)

  const sliderRailRef = useRef<HTMLSpanElement>(null)

  const handleResize = () => {
    if (sliderRailRef && sliderRailRef.current) {
      const sliderRailEl = sliderRailRef.current
      setRailLength(sliderRailEl.clientWidth)
      const viewportOffset = sliderRailEl.getBoundingClientRect()
      setRailOffset(viewportOffset.left)

      setThumbOffset(sliderRailEl.clientWidth * (value / (max - min)))
    }
  }

  const persistedHandleResize = useCallback(handleResize, [value, min, max])

  // re render when window size change
  useEffect(() => {
    window.addEventListener('resize', persistedHandleResize)
    return () => {
      window.removeEventListener('resize', persistedHandleResize)
    }
  }, [persistedHandleResize])

  useEffect(() => {
    persistedHandleResize()
  }, [sliderRailRef, persistedHandleResize])

  // init slider
  useEffect(() => {
    setThumbOffset(railLength * (value / (max - min)))
  }, [value, min, max, railLength])

  const calcThumbOffset = (mouseOffset: number) => {
    if (mouseOffset > railOffset && mouseOffset < railOffset + railLength) {
      const newValue = max * ((mouseOffset - railOffset) / railLength)
      const remainder = newValue % step
      const multiplier = Math.floor(newValue / step)
      if (remainder >= step / 2) {
        const closestValue = step * (multiplier + 1)
        onChange(closestValue <= max ? closestValue : max)
      } else {
        const closestValue = step * multiplier
        onChange(closestValue >= min ? closestValue : min)
      }
    } else if (mouseOffset >= railOffset + railLength && value < max) {
      onChange(max)
    } else if (mouseOffset <= railOffset && value > min) {
      onChange(min)
    }
  }

  const handleMouseMove = (e: MouseEventInit) => {
    const event = e as MouseEvent
    calcThumbOffset(event.clientX)
  }

  const handleMouseUp = () => {
    setDraggingThumb(false)
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e.target) {
      // move thumb to the position where cursor is when in range
      calcThumbOffset(e.clientX)

      setDraggingThumb(true)
      window.addEventListener('mousemove', handleMouseMove, false)
      window.addEventListener('mouseup', handleMouseUp, false)
    }
  }

  const handleTouchMove = (e: TouchEventInit) => {
    if (e.touches) {
      calcThumbOffset(e.touches[0].clientX)
    }
  }

  const handleTouchEnd = () => {
    setDraggingThumb(false)
    window.removeEventListener('touchmove', handleTouchMove)
    window.removeEventListener('touchend', handleTouchEnd)
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    // move thumb to the position where cursor is when in range
    calcThumbOffset(e.touches[0].clientX)

    setDraggingThumb(true)
    window.addEventListener('touchmove', handleTouchMove, false)
    window.addEventListener('touchend', handleTouchEnd, false)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className={cn('slider-v2', { [`${className}`]: !!className })}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <span className="slider-v2__rail" ref={sliderRailRef} />
      <span
        className="slider-v2__track"
        style={{ width: `${thumbOffset}px` }}
      />
      <span
        className={cn('slider-v2__thumb', {
          'slider-v2__thumb--active': draggingThumb,
        })}
        style={{ left: `${thumbOffset}px` }}
      />
    </span>
  )
}
