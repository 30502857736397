import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'

import {
  ThirdPartyLink,
  ThirdPartyLinkProps,
  thirdPartyMapDispatchToProps,
  thirdPartyMapStateToProps,
} from '../third-party-link/ThirdPartyLink'
import { Account } from '../../redux/accounts/accounts.model'
import { getKiwisaverAccount } from '../../common/accounts-helper'

export class FirstTimeBuyerLink extends ThirdPartyLink {
  protected linkLabel: string = 'Calculate my first home'
}

const mapStateToProps = (state: AppState, props: ThirdPartyLinkProps) => {
  const kiwisaverAccount: Account = getKiwisaverAccount(state.accounts.accounts)

  // default props
  let stateToProps = thirdPartyMapStateToProps(state, props)

  // page specific props - url & account id
  stateToProps.url = state.user.firstHomeCalcURL
  stateToProps.accountId = kiwisaverAccount ? kiwisaverAccount.accountID : null
  stateToProps.resource = 'first-home'

  // returning props
  return stateToProps
}

export default connect(
  mapStateToProps,
  thirdPartyMapDispatchToProps,
  null
)(FirstTimeBuyerLink)
