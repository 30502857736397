import React, { useEffect, useState } from 'react'
import { Link, withRouter, WithRouterProps } from 'react-router'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import './AccountTabs.scss'

type AccountTabsProps = {
  children?: React.ReactNode
} & WithRouterProps

const a11yProps = (index: any) => {
  return {
    id: `account-tabs-${index}`,
    'aria-controls': `investments-details-tabpanel-${index}`,
  }
}

enum TabPaths {
  Performance = 'performance',
  Investment = 'funds',
  Transactions = 'transactions',
}

const parentPath = (pathname: string): string => {
  return pathname.substring(0, pathname.lastIndexOf('/'))
}

const currentPath = (pathname: string): string => {
  const path = pathname.substring(pathname.lastIndexOf('/') + 1)
  if (Object.values(TabPaths).includes(path as TabPaths)) {
    return path
  } else {
    return TabPaths.Performance
  }
}

function AccountTabs(props: AccountTabsProps) {
  const {
    location: { pathname },
  } = props
  const [activeTab, setActiveTab] = useState(currentPath(pathname))

  useEffect(() => {
    setActiveTab(currentPath(pathname))
  }, [pathname])

  return (
    <div className="account-tabs-component">
      <Tabs
        id="account-tabs"
        variant="scrollable"
        className="dark-oval"
        value={activeTab}
      >
        <Tab
          component={Link}
          {...a11yProps(0)}
          label="Performance"
          value="performance"
          to={(location: { pathname: string }) =>
            `${parentPath(location.pathname)}`
          }
        />
        <Tab
          component={Link}
          {...a11yProps(1)}
          label="Investment"
          value="funds"
          to={(location: { pathname: string }) =>
            `${parentPath(location.pathname)}/funds`
          }
        />
        <Tab
          component={Link}
          {...a11yProps(2)}
          label="Transactions"
          value="transactions"
          to={(location: { pathname: string }) =>
            `${parentPath(location.pathname)}/transactions`
          }
        />
      </Tabs>
      {props.children}
    </div>
  )
}

export default withRouter(AccountTabs)
