import { AppState } from './redux/app-state'
import { connect } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import MuiTheme from './common/MuiTheme'
import ContactMenu from './components/contact-menu/ContactMenu'
import { UserData } from './redux/user/user.model'
import { LiveChatContextProvider } from './components/live-chat/useLiveChatContext'
import './styles/styles.scss'

type AppProps = {
  user: UserData
  children: any
}

const App = ({ user, children }: AppProps) => (
  <LiveChatContextProvider user={user}>
    <div className="app">
      {children}
      <MuiThemeProvider theme={MuiTheme}>
        <ContactMenu />
      </MuiThemeProvider>
    </div>
  </LiveChatContextProvider>
)

const mapStateToProps = (state: AppState) => ({
  user: state.user as UserData,
})

export default connect(mapStateToProps)(App)
