import React from 'react'
import MainTheme from '../main-theme'
import { LoggedOut } from '../../components/logged-out/LoggedOut'

type PageLoggedOutProps = {
  isTimeout: boolean
}

const PageLoggedOut = ({ isTimeout }: PageLoggedOutProps) => {
  return (
    <MainTheme>
      <LoggedOut isTimeout={isTimeout} />
    </MainTheme>
  )
}

export default PageLoggedOut
