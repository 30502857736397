import {
  useReducer,
  useCallback,
  useDebugValue,
  useLayoutEffect,
  useRef,
} from 'react'

type ActionType = 'idle' | 'pending' | 'resolved' | 'rejected'

type Statuses = {
  [action in ActionType]: action
}

type State = {
  status: ActionType
  data?: any
  error?: any
}

type Action = {
  type: ActionType
  data?: any
  error?: any
}

const asyncStatuses: Statuses = {
  idle: 'idle',
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
}

function useSafeDispatch(dispatch: React.Dispatch<Action>) {
  const mounted = useRef(false)

  useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  return useCallback(
    (action) => (mounted.current ? dispatch(action) : void 0),
    [dispatch]
  )
}

function asyncReducer(state: State, action: Action): State {
  switch (action.type) {
    case asyncStatuses.pending:
      return {
        ...state,
        status: asyncStatuses.pending,
        data: null,
        error: null,
      }
    case asyncStatuses.resolved:
      return {
        ...state,
        status: asyncStatuses.resolved,
        data: action.data,
        error: null,
      }
    case asyncStatuses.rejected:
      return {
        ...state,
        status: asyncStatuses.rejected,
        data: null,
        error: action.error,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export default function useAsync(initialState = {}) {
  const [state, unSafeDispatch] = useReducer(asyncReducer, {
    status: asyncStatuses.idle,
    data: null,
    error: null,
    ...initialState,
  })

  useDebugValue(state.status)

  const dispatch = useSafeDispatch(unSafeDispatch)

  const run = useCallback(
    (promise) => {
      dispatch({ type: asyncStatuses.pending })
      promise.then(
        (data: any) => {
          dispatch({ type: asyncStatuses.resolved, data })
        },
        (error: any) => {
          dispatch({ type: asyncStatuses.rejected, error })
        }
      )
    },
    [dispatch]
  )

  return { ...state, run }
}
