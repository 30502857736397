import { ContributionFrequency } from './RetirementProjector.types'

export const getAnnualContribution = (
  value: number,
  frequency: ContributionFrequency
): number => {
  switch (frequency) {
    case ContributionFrequency.WEEKLY:
      return value * 52
    case ContributionFrequency.FORTNIGHTLY:
      return value * 26
    case ContributionFrequency.MONTHLY:
      return value * 12
    case ContributionFrequency.YEARLY:
      return value
    default:
      return 0
  }
}
