import React from 'react'
import './ReportFilters.scss'

import { ReportFilters as ReportFiltersModel } from '../../redux/reports/reports.model'
import { AccountsActions } from '../../redux/accounts/accounts.actions'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { ReportsUpdateFiltersAction } from '../../redux/reports/reports.actions'
import {
  mergeInActiveAccountsToReportsAccounts,
  ReportAccount,
} from './reports-helper'

interface ReportFiltersProps {
  allAccounts?: ReportAccount[]
  filters?: ReportFiltersModel
  updateFilters?: typeof ReportsUpdateFiltersAction
}

export function ReportFilters(props: ReportFiltersProps) {
  if (props.allAccounts?.length <= 1) {
    return null
  }

  return (
    <div className="filters-component">
      <FormControl fullWidth variant="outlined" margin="normal">
        <Select
          native
          fullWidth
          variant="outlined"
          value={props.filters ? props.filters.accountNumber : ''}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            let filters: ReportFiltersModel = props.filters

            // check if filters is valid
            if (!filters) {
              filters = {
                accountNumber: '',
              } as ReportFiltersModel
            }

            // update account number with selected option and trigger update
            filters.accountNumber = event.target.value as string
            props.updateFilters({ filters })
          }}
          inputProps={{
            name: 'report',
            id: 'report-select-label',
          }}
        >
          <option value="">Show all</option>
          {props.allAccounts.map(({ accountId, accountName }, index) => {
            return (
              <option key={index} value={accountId}>
                {accountId}
                {accountName ? `- ${accountName}` : ''}
              </option>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  const allAccounts = mergeInActiveAccountsToReportsAccounts(
    state.reports?.reports,
    state.accounts?.accounts
  )

  return {
    allAccounts,
    filters: state.reports.filters,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountsActions>) => ({
  updateFilters: bindActionCreators(ReportsUpdateFiltersAction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(ReportFilters)
