import React, { useEffect } from 'react'
import Modal from '../modal/Modal'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import './AuthorizationModal.scss'
import { Button, TextField, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'

type Props = {
  capsLockEnabled: boolean
  uuid: string
  username: string
  open: boolean
  onClose: () => void
  isSubmitting: boolean
  onLoginSubmit: (password: string, uuid: string) => void
}
const AuthorizationModal = (props: Props) => {
  const {
    capsLockEnabled,
    isSubmitting,
    open,
    onClose,
    onLoginSubmit,
    username,
  } = props
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    password: string
  }>()

  useEffect(() => {
    if (open) {
      reset()
    }
  }, [open, reset])

  const getPasswordError = (errors: any) => {
    if (errors && errors.password) {
      return 'Password is a required field'
    } else if (capsLockEnabled) {
      return 'Your CAPS-LOCK key is turned on'
    }
    return ''
  }
  const typeText = 'password'
  const passwordErrorMessage = getPasswordError(errors)

  return (
    <Modal
      open={open}
      title=""
      className="auto-height auth-modal"
      onClose={onClose}
    >
      <div className="content-container">
        <div>
          <Typography variant="h4" className="mb-sm">
            Enter your {typeText}
          </Typography>
          <Typography variant="body2">
            Please enter your {typeText} to update your details.
          </Typography>
        </div>
        <div>
          <form
            onSubmit={handleSubmit(({ password }) =>
              onLoginSubmit(password, username)
            )}
          >
            <TextField
              size="small"
              className="mb-md"
              label="Password"
              name="password"
              id="password"
              variant="outlined"
              margin="normal"
              type="password"
              {...{
                ...register('password', { required: true }),
                ref: undefined,
                inputRef: register('password', { required: true }).ref,
              }}
              fullWidth
              disabled={isSubmitting}
              placeholder="Enter your password"
              helperText={passwordErrorMessage}
              error={!!passwordErrorMessage}
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
            <Button
              className="modal-button"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  )
}
const mapStateToProps = (
  state: AppState,
  props: Omit<Props, 'capsLockEnabled' | 'uuid' | 'username'>
) => {
  return {
    capsLockEnabled: state.capslock.capsLockEnabled,
    uuid: state.user.userid,
    username: state.username.username,
    ...props,
  }
}

export default connect(mapStateToProps)(AuthorizationModal)
