import { AccountFundsState } from './account-funds.model'

export enum actionTypes {
  ACCOUNT_FUNDS_REQUEST = 'ACCOUNT_FUNDS_REQUEST',
  ACCOUNT_FUNDS_INVESTMENT_PROFILE_REQUEST = 'ACCOUNT_FUNDS_INVESTMENT_PROFILE_REQUEST',
  ACCOUNT_FUNDS_REQUEST_SUCCESS = 'ACCOUNT_FUNDS_REQUEST_SUCCESS',
  ACCOUNT_FUNDS_REQUEST_FAILURE = 'ACCOUNT_FUNDS_REQUEST_FAILURE',
  REPORTS_REQUEST = 'REPORTS_REQUEST',
}

type AccountFundsRequestCredentials = { userId: string; accountId: string }

export type AccountFundsRequestActionType = {
  type: actionTypes.ACCOUNT_FUNDS_REQUEST
  payload: AccountFundsRequestCredentials
}
export type AccountFundsRequestSuccessActionType = {
  type: actionTypes.ACCOUNT_FUNDS_REQUEST_SUCCESS
  payload: AccountFundsState
}
export type AccountFundsRequestFailureActionType = {
  type: actionTypes.ACCOUNT_FUNDS_REQUEST_FAILURE
  payload: string
}
export type AccountFundsInvestmentProfileRequestActionType = {
  type: actionTypes.ACCOUNT_FUNDS_INVESTMENT_PROFILE_REQUEST
  payload: AccountFundsRequestCredentials
}

export const AccountFundsRequestAction = (
  payload: AccountFundsRequestCredentials
): AccountFundsRequestActionType => ({
  type: actionTypes.ACCOUNT_FUNDS_REQUEST,
  payload,
})

export const AccountFundsInvestmentProfileRequestAction = (
  userId: string,
  accountId: string
): AccountFundsInvestmentProfileRequestActionType => ({
  type: actionTypes.ACCOUNT_FUNDS_INVESTMENT_PROFILE_REQUEST,
  payload: {
    userId,
    accountId,
  },
})

export const AccountFundsRequestSuccessAction = (
  accountFunds: AccountFundsState
): AccountFundsRequestSuccessActionType => ({
  type: actionTypes.ACCOUNT_FUNDS_REQUEST_SUCCESS,
  payload: accountFunds,
})

export const AccountFundsRequestFailureAction = (
  message: string
): AccountFundsRequestFailureActionType => ({
  type: actionTypes.ACCOUNT_FUNDS_REQUEST_FAILURE,
  payload: message,
})

export type AccountFundsActions =
  | AccountFundsRequestActionType
  | AccountFundsRequestSuccessActionType
  | AccountFundsRequestFailureActionType
  | AccountFundsInvestmentProfileRequestActionType
