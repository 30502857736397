import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'

import {
  ThirdPartyLink,
  ThirdPartyLinkProps,
  thirdPartyMapDispatchToProps,
  thirdPartyMapStateToProps,
} from '../third-party-link/ThirdPartyLink'
import { Account } from '../../redux/accounts/accounts.model'
import { getAccountByID } from '../../common/accounts-helper'

export class SwitchLink extends ThirdPartyLink {
  protected popupTitle: string = 'Change Your Investment'
  protected linkLabel: string = 'Change Your Investment'
}

const mapStateToProps = (state: AppState, props: ThirdPartyLinkProps) => {
  const account: Account = getAccountByID(
    props.accountId,
    state.accounts.accounts
  )

  // default props
  let stateToProps = thirdPartyMapStateToProps(state, props)

  // page specific props - url & account id
  stateToProps.url = account.switchURL
  stateToProps.accountId = props.accountId
  stateToProps.resource = props.resource

  // returning props
  return stateToProps
}

export default connect(
  mapStateToProps,
  thirdPartyMapDispatchToProps,
  null
)(SwitchLink)
