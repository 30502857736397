import { AuthorizationFormValues } from '../../components/authorization-modal/use-authorized-request'

export enum actionTypes {
  // Get username
  USERNAME_REQUEST = 'USERNAME_REQUEST',
  USERNAME_REQUEST_SUCCESS = 'USERNAME_REQUEST_SUCCESS',
  USERNAME_REQUEST_FAILURE = 'USERNAME_REQUEST_FAILURE',
  // Change username
  USERNAME_CHANGE = 'USERNAME_CHANGE',
  USERNAME_CHANGE_SUCCESS = 'USERNAME_CHANGE_SUCCESS',
  USERNAME_RESET_SUCCESS = 'USERNAME_RESET_SUCCESS',
  USERNAME_CHANGE_FAILURE = 'USERNAME_CHANGE_FAILURE',
  //Clear state
  USERNAME_CLEAR_STATE = 'USERNAME_CLEAR_STATE',
}

//////////     Get username

export type UsernameRequestCredentials = { userid: string }
export type UsernameRequestActionType = {
  type: actionTypes.USERNAME_REQUEST
  payload: UsernameRequestCredentials
}
export const UsernameRequestAction = (
  payload: UsernameRequestCredentials
): UsernameRequestActionType => ({
  type: actionTypes.USERNAME_REQUEST,
  payload,
})

export type UsernameType = { username: string; isChangeable?: boolean }
export type UsernameRequestSuccessActionType = {
  type: actionTypes.USERNAME_REQUEST_SUCCESS
  payload: UsernameType
}
export const UsernameRequestSuccessAction = (
  payload: UsernameType
): UsernameRequestSuccessActionType => ({
  type: actionTypes.USERNAME_REQUEST_SUCCESS,
  payload,
})

export type UsernameRequestFailureActionType = {
  type: actionTypes.USERNAME_REQUEST_FAILURE
  payload: string
}
export const UsernameRequestFailureAction = (
  message: string
): UsernameRequestFailureActionType => ({
  type: actionTypes.USERNAME_REQUEST_FAILURE,
  payload: message,
})

//////////     Change username

export type UsernameChangeCredentials = {
  userid: string
  currentUsername: string
  newUsername: string
} & AuthorizationFormValues
export type UsernameChangeActionType = {
  type: actionTypes.USERNAME_CHANGE
  payload: UsernameChangeCredentials
}
export const UsernameChangeAction = (
  payload: UsernameChangeCredentials
): UsernameChangeActionType => ({
  type: actionTypes.USERNAME_CHANGE,
  payload,
})

export type UsernameChangeSuccessActionType = {
  type: actionTypes.USERNAME_CHANGE_SUCCESS
  payload: UsernameType
}
export const UsernameChangeSuccessAction = (
  payload: UsernameType
): UsernameChangeSuccessActionType => ({
  type: actionTypes.USERNAME_CHANGE_SUCCESS,
  payload,
})

export type UsernameChangeFailureActionType = {
  type: actionTypes.USERNAME_CHANGE_FAILURE
  payload: string
}
export const UsernameChangeFailureAction = (
  message: string
): UsernameChangeFailureActionType => ({
  type: actionTypes.USERNAME_CHANGE_FAILURE,
  payload: message,
})

export type UsernameResetSuccessActionType = {
  type: actionTypes.USERNAME_RESET_SUCCESS
}
export const UsernameResetSuccessAction = (): UsernameResetSuccessActionType => ({
  type: actionTypes.USERNAME_RESET_SUCCESS,
})

//////////     Clear state
export type UsernameClearStateActionType = {
  type: actionTypes.USERNAME_CLEAR_STATE
}
export const UsernameClearStateAction = (): UsernameClearStateActionType => ({
  type: actionTypes.USERNAME_CLEAR_STATE,
})

export type UsernameActions =
  | UsernameRequestActionType
  | UsernameRequestSuccessActionType
  | UsernameRequestFailureActionType
  | UsernameChangeActionType
  | UsernameChangeSuccessActionType
  | UsernameChangeFailureActionType
  | UsernameClearStateActionType
