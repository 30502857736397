/**
 * This component spits out an anchor or a gatsby Link
 * depending if the link should points to the legacy website or the new one.
 */
import React from 'react'
import cn from 'classnames'
import { ClickableProps } from '../clickable.model'
import '../clickable.scss'

type AnchorProps = SpecificLinkProps &
  Omit<React.HTMLProps<HTMLAnchorElement>, 'size'>

export type SpecificLinkProps = {
  to: string
  isLegacy?: boolean
  rel?: string
} & ClickableProps

export type LinkProps = AnchorProps

const Link: React.FC<LinkProps> = ({
  to,
  isLegacy,
  className,
  children,
  target = '_self',
  variant = 'default',
  color = 'primary',
  size = 'md',
  iconLeft: IconLeft,
  iconRight: IconRight,
  inline,
  ref,
  ...rest
}: LinkProps) => {
  const baseClasses = [
    'link',
    `link--${variant}`,
    `link--${color}`,
    `link--${size}`,
  ]
  const classesList = cn(
    {
      [className as string]: !!className,
      'link--inline': inline,
      'link--button': variant === 'filled' || variant === 'outlined',
    },
    baseClasses
  )

  return (
    <a className={classesList} href={to} target={target} ref={ref} {...rest}>
      {IconLeft && (
        <span className="link__icon-left">
          <IconLeft />
        </span>
      )}
      <span className="link__text">{children}</span>
      {IconRight && (
        <span className="link__icon-right">
          <IconRight />
        </span>
      )}
    </a>
  )
}

export default Link
