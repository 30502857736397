import getAccountsApi from '../../api/accounts/'

export function requestLetter(
  userId: string,
  accountNumber: string,
  authToken: string
) {
  const api = getAccountsApi(authToken)
  return api.getFirstHomeLetter({ userId, accountNumber })
}

type CallbackData = {
  answers: any[]
  phone: string
  hasKiwiSaverForThreeYears: boolean
}

export function requestCallback(
  userId: string,
  accountNumber: string,
  data: CallbackData,
  authToken: string
) {
  const api = getAccountsApi(authToken)

  return api.sendEnquiryEmail({ userId, accountNumber }, { data })
}
