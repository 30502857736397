import './Welcome.scss'

import * as React from 'react'
import { connect } from 'react-redux'
import { RouterAction, routerActions } from 'react-router-redux'
import { Dispatch } from 'redux'
import { AppState } from '../../../redux/app-state'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

interface WelcomeProps {
  hash: string
  nextStep: (path: string) => void
}

export class WelcomePage extends React.Component<WelcomeProps> {
  render() {
    return (
      <div className="action-group welcome-onboarding">
        <Typography variant="h6">
          Great! There are just 3 more steps to get you setup.
        </Typography>
        <Button
          disableRipple
          onClick={() =>
            this.props.nextStep('/onboard/confirm-dob?q=' + this.props.hash)
          }
          type="submit"
          id="set-up"
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </div>
    )
  }
}

export default connect(
  (state: AppState) => ({
    hash: state.routing.locationBeforeTransitions.query.q,
  }),
  (dispatch: Dispatch<RouterAction>) => ({
    nextStep: (path: string) => dispatch(routerActions.push(path)),
  }),
  null
)(WelcomePage)
