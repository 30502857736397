import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AppState } from '../../redux/app-state'
import { routerActions } from 'react-router-redux'
import Alert from 'react-s-alert'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NameEmailDOBForm from '../../components/name-email-dob-form/NameEmailDOBForm'
import ContactUsModal from '../../components/contact-us-modal'

import {
  OnboardingActions,
  OnboardingClearState,
  OnboardingSearch,
} from '../../redux/onboarding/onboarding.actions'

import useToggle from '../../hooks/use-toggle'
import RegisterLoginLinkBox from '../../components/register-login-link-box/RegisterLoginLinkBox'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'

interface RegisterProps {
  setTitle: (newTitle: string) => void
  hasError: boolean
  isLoading: boolean
  errorMessage: string
  errorStatus: number
  canBeOnboarded: boolean
  actions: {
    onboardingSearch?: typeof OnboardingSearch
    onboardingClearState?: typeof OnboardingClearState
    next?: (path: string) => void
  }
  setActiveMenu: (activeMenu: string) => void
}

const ContactUsLink = () => {
  const { toggle, toggleOn, toggleOff } = useToggle(false)
  return (
    <>
      <ContactUsModal open={toggle} onClose={toggleOff} />
      <Link color="primary" underline="always" onClick={toggleOn}>
        contact us
      </Link>
    </>
  )
}

export class RegisterForm extends React.Component<RegisterProps> {
  constructor(props: RegisterProps) {
    super(props)

    this.props.actions.onboardingSearch = this.props.actions.onboardingSearch.bind(
      this
    )
  }

  UNSAFE_componentWillMount() {
    this.props.actions.onboardingClearState()
    this.props.setTitle('Register')
    this.props.setActiveMenu('login')
  }

  UNSAFE_componentWillReceiveProps(props: RegisterProps) {
    if (props.hasError) {
      const { errorStatus } = props
      if (!!errorStatus) {
        if (errorStatus >= 400 && errorStatus < 500) {
          Alert.info(
            `Thank you. Keep an eye on your inbox for an email with next steps. If you don't receive one within a few minutes, please <a href="http://fisherfunds.co.nz/contact/">contact us</a> and we can help you out.`,
            { timeout: 'none' }
          )
        } else {
          Alert.error('Search request failed', { timeout: 'none' })
        }
      } else {
        Alert.error(props.errorMessage, { timeout: 'none' })
      }
    }

    if (props.canBeOnboarded) {
      this.props.actions.next('/register-success')
    }
  }

  componentDidMount() {
    const mainThemeContent = document.querySelector(
      '.main-theme-container .left-content'
    )

    if (mainThemeContent) {
      window.scrollTo(0, 0)
      mainThemeContent.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <div>
        <Typography variant="h6">
          Please enter your details to register for online access. For help,{' '}
          <ContactUsLink />
        </Typography>
        <NameEmailDOBForm
          onSubmit={this.props.actions.onboardingSearch}
          isLoading={this.props.isLoading}
          submitButtonText="Register"
          submittingButtonText="Submitting..."
          success={this.props.canBeOnboarded}
        />
        <RegisterLoginLinkBox type="login" />
      </div>
    )
  }
}

export default connect(
  (state: AppState) => ({
    hasError: state.onboarding.hasError,
    errorMessage: state.onboarding.errorMessage,
    errorStatus: state.onboarding.errorStatus,
    canBeOnboarded: state.onboarding.canBeOnboarded,
    isLoading: state.onboarding.isLoading,
  }),
  (dispatch: Dispatch<OnboardingActions>) => {
    return {
      actions: {
        onboardingSearch: bindActionCreators(OnboardingSearch, dispatch),
        onboardingClearState: bindActionCreators(
          OnboardingClearState,
          dispatch
        ),
        next: (path: string) => dispatch(routerActions.push(path)),
        setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
      },
    }
  },
  null
)(RegisterForm)
