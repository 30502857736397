import React from 'react'
import cn from 'classnames'
import './NumberInput.scss'

export type NumberInputProps = {
  error?: boolean
  dark?: boolean
} & React.HTMLProps<HTMLInputElement>

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      className = '',
      dark,
      error,
      disabled,
      autoComplete,
      ...rest
    }: NumberInputProps,
    ref
  ) => (
    <input
      className={cn(`input-number__element ${className}`, {
        'input-number__element--error': error,
        'input-number__element--disabled': disabled,
        'input-number__element--dark': dark,
      })}
      disabled={disabled}
      autoComplete={autoComplete}
      type="number"
      ref={ref}
      {...rest}
    />
  )
)

NumberInput.displayName = 'NumberInput'
