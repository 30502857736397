import getAccountsApi from '../../api/accounts'

export async function renameAccount(
  userId: string,
  accountNumber: string,
  accountName: string,
  authToken: string
) {
  const api = getAccountsApi(authToken)
  return await api.renameAccount(
    { userId, accountNumber },
    { data: { accountName } }
  )
}
