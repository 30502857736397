import { Report, ReportFilters } from './reports.model'

export enum actionTypes {
  ALL_REPORTS_REQUEST = 'ALL_REPORTS_REQUEST',
  REPORTS_REQUEST = 'REPORTS_REQUEST',
  REPORTS_SKIP_REQUEST = 'REPORTS_SKIP_REQUEST',
  REPORTS_REQUEST_SUCCESS = 'REPORTS_REQUEST_SUCCESS',
  REPORTS_REQUEST_FAILURE = 'REPORTS_REQUEST_FAILURE',
  REPORTS_DOWNLOAD = 'REPORTS_DOWNLOAD',
  REPORTS_FILTERS = 'REPORTS_FILTERS',
  REPORTS_DOWNLOAD_SUCCESS = 'REPORTS_DOWNLOAD_SUCCESS',
  REPORTS_DOWNLOAD_FAILURE = 'REPORTS_DOWNLOAD_FAILURE',
}

export type AllReportsRequestActionType = {
  type: actionTypes.ALL_REPORTS_REQUEST
  payload: ReportsRequestPayload
}
export type ReportsRequestPayload = { userId: string; offset: number }
export type ReportsFiltersPayload = { filters: ReportFilters }
export type ReportsRequestActionType = {
  type: actionTypes.REPORTS_REQUEST
  payload: ReportsRequestPayload
}
export type ReportsRequestSuccessActionType = {
  type: actionTypes.REPORTS_REQUEST_SUCCESS
  payload: { reports: Report[] }
}
export type ReportsRequestFailureActionType = {
  type: actionTypes.REPORTS_REQUEST_FAILURE
  payload: string
}

export type ReportsSkipRequestActionType = {
  type: actionTypes.REPORTS_SKIP_REQUEST
}

export const ReportsRequestAction = (
  payload: ReportsRequestPayload
): ReportsRequestActionType => ({
  type: actionTypes.REPORTS_REQUEST,
  payload,
})

export const AllReportsRequestAction = (
  payload: ReportsRequestPayload
): AllReportsRequestActionType => ({
  type: actionTypes.ALL_REPORTS_REQUEST,
  payload,
})

export const ReportsRequestSuccessAction = (
  reports: Report[]
): ReportsRequestSuccessActionType => ({
  type: actionTypes.REPORTS_REQUEST_SUCCESS,
  payload: { reports },
})

export const ReportsRequestFailureAction = (
  message: string
): ReportsRequestFailureActionType => ({
  type: actionTypes.REPORTS_REQUEST_FAILURE,
  payload: message,
})

export const ReportsSkipRequestAction = (): ReportsSkipRequestActionType => ({
  type: actionTypes.REPORTS_SKIP_REQUEST,
})

// Filters

export type ReportsUpdateFiltersActionType = {
  type: actionTypes.REPORTS_FILTERS
  payload: ReportsFiltersPayload
}

export const ReportsUpdateFiltersAction = (
  payload: ReportsFiltersPayload
): ReportsUpdateFiltersActionType => ({
  type: actionTypes.REPORTS_FILTERS,
  payload,
})

// Requesting a download sends a protected XHR request that returns binary file contents,
// that we then handle in the browser and trigger a download.
export type ReportsDownloadPayload = {
  url: string
}
export type ReportsDownloadActionType = {
  type: actionTypes.REPORTS_DOWNLOAD
  payload: ReportsDownloadPayload
}

export type ReportsDownloadSuccessActionType = {
  type: actionTypes.REPORTS_DOWNLOAD_SUCCESS
}
export const ReportsDownloadSuccessAction = (): ReportsDownloadSuccessActionType => ({
  type: actionTypes.REPORTS_DOWNLOAD_SUCCESS,
})

export type ReportsDownloadFailureActionType = {
  type: actionTypes.REPORTS_DOWNLOAD_FAILURE
  payload: string
}
export const ReportsDownloadFailureAction = (
  message: string
): ReportsDownloadFailureActionType => ({
  type: actionTypes.REPORTS_DOWNLOAD_FAILURE,
  payload: message,
})

export type ReportsActions =
  | ReportsRequestActionType
  | ReportsRequestSuccessActionType
  | ReportsRequestFailureActionType
  | AllReportsRequestActionType
  | ReportsDownloadActionType
  | ReportsDownloadSuccessActionType
  | ReportsSkipRequestActionType
  | ReportsDownloadFailureActionType
