import * as React from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import {
  UsernameRecoveryActions,
  UsernameRecoverySubmitAction,
} from '../../redux/username-recovery/username-recovery.actions'
import Alert from 'react-s-alert'
import { AppState } from '../../redux/app-state'
import { UsernameRecoveryCredentialsType } from '../../redux/username-recovery/username-recovery.model'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { SMSOrEmailNotification } from '../../components/sms-or-email-notification/SMSOrEmailNotification'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'

interface ForgotUsernameMethodProps {
  hasError: boolean
  isLoading: boolean
  errorMessage: string
  submitted: boolean
  credentials: UsernameRecoveryCredentialsType
  mobilePhoneNumber: string
  actions: {
    usernameRecoverySubmit: typeof UsernameRecoverySubmitAction
    next: (path: string) => void
  }
  setActiveMenu: (activeMenu: string) => void
}

export class ForgotUsernameMethod extends React.Component<
  ForgotUsernameMethodProps
> {
  constructor(props: ForgotUsernameMethodProps) {
    super(props)
    this.submitCredentialsAndMethod = this.submitCredentialsAndMethod.bind(this)
  }

  componentDidMount() {
    if (this.props.credentials === null) {
      this.props.actions.next('/forgot/username/')
    }
  }

  componentDidUpdate() {
    this.props.setActiveMenu('login')

    if (this.props.submitted) {
      Alert.success('Your username has been sent.', {
        onClose: () => {
          this.props.actions.next('/login')
        },
      })
    }
  }

  submitCredentialsAndMethod(method: 'sms' | 'email') {
    this.props.actions.usernameRecoverySubmit({
      credentials: this.props.credentials,
      method: method,
    })
  }

  render() {
    return (
      <SMSOrEmailNotification
        isLoading={this.props.isLoading}
        handleChoice={this.submitCredentialsAndMethod}
        title="How would you like us to send your username?"
        titleNoMobile="We will send your username to your email address."
        emailButtonLabelNoMobile="Email me my username"
        submitted={this.props.submitted}
        mobilePhoneNumber={this.props.mobilePhoneNumber}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    hasError: state.usernameRecovery.hasError,
    errorMessage: state.usernameRecovery.errorMessage,
    submitted: state.usernameRecovery.submitted,
    isLoading: state.usernameRecovery.isLoading,
    credentials: state.usernameRecovery.credentials,
    mobilePhoneNumber: state.usernameRecovery.mobilePhoneNumber,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<UsernameRecoveryActions>) => ({
  actions: {
    usernameRecoverySubmit: bindActionCreators(
      UsernameRecoverySubmitAction,
      dispatch
    ),
    next: (path: string) => dispatch(routerActions.push(path)),
    setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ForgotUsernameMethod)
