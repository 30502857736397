import React, { useState } from 'react'
import { Account } from '../../redux/accounts/accounts.model'
import Modal from '../modal/Modal'
import { Button } from '../clickable/button/Button'
import Alert from 'react-s-alert'
import './RenameAccountModal.scss'
import { renameAccount } from './RenameAccountModal.api'
import {
  AccountsActions,
  AccountsOverviewRenameSuccessAction,
} from '../../redux/accounts/accounts.actions'
import { bindActionCreators } from 'redux'
import { Dispatch, connect } from 'react-redux'
import Input from '../../common/forms/Input'

export interface RenameAccountModalProps {
  userId: string
  account: Account
  authToken: string
  onClose: () => void
  renameAccountSuccessAction?: typeof AccountsOverviewRenameSuccessAction
}

const RenameAccountModal = (props: RenameAccountModalProps) => {
  const {
    userId,
    account,
    authToken,
    onClose,
    renameAccountSuccessAction,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [accountName, setAccountName] = useState('')

  const onConfirm = async () => {
    setIsSubmitted(true)

    if (!isAccountNameValid()) {
      return
    }

    setIsLoading(true)

    await renameAccount(
      userId,
      account.accountNumber,
      accountName.trim(),
      authToken
    )
      .then(() => {
        renameAccountSuccessAction(account.accountNumber, accountName)
        Alert.success('Account renamed successfully.')
        onClose()
      })
      .catch(() => {
        setIsLoading(false)
        Alert.error('Something went wrong. Please try again later.')
      })
  }

  const handleAccountNameChange = (event: any) => {
    let { value } = event.target
    if (value.length > 70) {
      value = value.slice(0, 70)
    }
    value = value.trimStart()
    setAccountName(value.replace(/[^A-Za-z0-9 ]/g, ''))
  }

  const isAccountNameValid = () => {
    return accountName !== ''
  }

  return (
    <Modal
      open
      title="Rename account"
      subtitle={account.accountNumber}
      onClose={onClose}
      className="rename-account-modal"
    >
      <div className="rename-account-modal__content">
        <label htmlFor="account-name">
          Choose a name for your account.
          <Input
            className="mt-sm"
            label="Account name"
            id="account-name"
            placeholder={account.accountMailingName}
            value={accountName}
            onChange={(e) => handleAccountNameChange(e)}
            error={!isAccountNameValid() && isSubmitted}
          />
        </label>
        <Button
          className="mt-md"
          disabled={isLoading}
          onClick={() => onConfirm()}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AccountsActions>) => ({
  renameAccountSuccessAction: bindActionCreators(
    AccountsOverviewRenameSuccessAction,
    dispatch
  ),
})

export default connect(null, mapDispatchToProps, null)(RenameAccountModal)
