import { Reducer, AnyAction } from 'redux'

import { actionTypes } from './capslock.actions'
import { CapsLockState } from './capslock.model'

export const initialState: CapsLockState = {
  capsLockEnabled: false,
}

export const capsLockReducer: Reducer<CapsLockState> = (
  state: CapsLockState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.SET_CAPS_LOCK:
      return {
        capsLockEnabled: action.payload,
      }
    default:
      return state
  }
}
