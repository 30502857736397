import { useState } from 'react'
import { connect } from 'react-redux'
import Modal from '../modal/Modal'
import Link from '../clickable/link/Link'
import { Button } from '../clickable/button/Button'
import SwitchModalPremiumServiceForm from './SwitchModalPremiumServiceForm'
import { ReactComponent as IconPhone } from '../../assets/images/icons/icon_phone.svg'
import { ReactComponent as IconDownload } from '../../assets/images/icons/icon_download.svg'
import BuildYourOwnStrategy, {
  BuildYourOwnSplit,
} from '../switch-contentful-modal/BuildYourOwnStrategy'
import { useContentfulProduct } from '../../api/contentful/useContentfulProduct'
import { AppState } from '../../redux/app-state'
import { getStringBetween } from '../../utils/strings'
import { getAccountByID } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import './SwitchModalPremiumService.scss'
import InvestmentSwitchNotification from '../switch-contentful-modal/InvestmentSwitchNotification'
import { SwitchNotification } from '../../redux/switch-notifications/switch-notifications.model'

type SwitchModalPremiumServiceProps = {
  accountId: string
  onClose?: any
}

type PropsFromRedux = {
  account: Account
  clientId: string
  switchNotifications?: SwitchNotification[]
}

const PREMIUM_CONTACT_ADVISER_ENABLED =
  process.env.REACT_APP_FEATURE_TOGGLE_PREMIUM_CONTACT_ADVISER === 'true'
const BUILD_YOUR_OWN_ENABLED =
  process.env.REACT_APP_FEATURE_TOGGLE_PREMIUM_BUILD_YOUR_OWN === 'true'

const SwitchModalPremiumService = ({
  account,
  accountId,
  onClose,
  clientId,
  switchNotifications,
}: SwitchModalPremiumServiceProps & PropsFromRedux) => {
  const { contentfulEntry: contentfulProduct } = useContentfulProduct(
    account.productExternalName
  )
  const [showContactForm, setShowContactForm] = useState(false)
  const [showBuildYourOwn, setShowBuildYourOwn] = useState(false)

  const switchSuspended = contentfulProduct?.isSuspended

  const strategyFundAllocations = (contentfulProduct?.funds || []).map(
    (fund) => ({
      allocationPercentage: 0,
      fund,
    })
  )

  const onSubmitBuildYourOwn = (formData: BuildYourOwnSplit) => {
    console.log(formData, clientId)
    // TODO: Create case in SalesForce and check if including AccountId as a field will make the match work (regardless of the email)
  }

  const renderBody = () => {
    if (showContactForm) {
      return (
        <SwitchModalPremiumServiceForm
          onCancel={() => setShowContactForm(false)}
        />
      )
    }

    if (showBuildYourOwn) {
      return (
        <BuildYourOwnStrategy
          depositsLabel="investments"
          strategyFundAllocations={strategyFundAllocations}
          onCancel={() => setShowBuildYourOwn(false)}
          onSubmit={(formData) => onSubmitBuildYourOwn(formData)}
          onMoreInfoClick={() => {}}
          isApexProductAccount={false}
        />
      )
    }

    if (BUILD_YOUR_OWN_ENABLED) {
      return (
        <>
          <div className="build-your-own-strategy switch-strategy-option">
            <div className="strategy-option">
              <p className="text-small color-text-light mt-0 mb-2xs">
                Strategy
              </p>
              <div className="strategy-option__actions">
                <h5 className="color-midnight">Build your own strategy</h5>
                <Button
                  className="ml-auto"
                  variant="outlined"
                  size="sm"
                  onClick={() => setShowBuildYourOwn(true)}
                  disabled={switchSuspended}
                >
                  {switchSuspended ? 'Suspended' : 'Build'}
                </Button>
              </div>
            </div>
          </div>
          <div className="switch__modal-buttons mt-md">
            <Button
              variant="link"
              aria-controls="account-options-menu"
              aria-haspopup="true"
              onClick={onClose}
            >
              Cancel
            </Button>
            {renderRequestACallButton()}
          </div>
        </>
      )
    }

    return (
      <>
        <p>
          To change your investment options, download and complete the switch
          form. Or if you'd like to chat to your adviser, you can request a
          call.
        </p>
        <div className="switch__modal-buttons">
          <Button
            variant="link"
            aria-controls="account-options-menu"
            aria-haspopup="true"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Link
            className="switch__modal-buttons-download"
            variant="outlined"
            target="_blank"
            rel="noreferrer noopener"
            to="https://assets.fisherfunds.co.nz/premium-service-ff688-premium-service-investment-switch-form"
          >
            <IconDownload className="icon_download" /> Download the form
          </Link>
          {renderRequestACallButton()}
        </div>
      </>
    )
  }

  const renderRequestACallButton = () =>
    PREMIUM_CONTACT_ADVISER_ENABLED ? (
      <Button
        variant="filled"
        aria-controls="account-options-menu"
        aria-haspopup="true"
        onClick={() => {
          setShowContactForm(true)
        }}
      >
        <span>
          <IconPhone /> Request a call
        </span>
      </Button>
    ) : (
      <Link
        variant="filled"
        target="_blank"
        rel="noreferrer noopener"
        to="https://fisherfunds.co.nz/talk-to-an-adviser"
      >
        <IconPhone /> Request a call
      </Link>
    )

  return (
    <>
      <Modal
        className="switch__modal"
        title="Change your investment options"
        subtitle={accountId}
        open
        onClose={onClose}
      >
        <div>
          <InvestmentSwitchNotification
            isApexProduct={false}
            productName={contentfulProduct?.name}
            switchNotifications={switchNotifications}
          />
          {renderBody()}
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (
  state: AppState,
  props: SwitchModalPremiumServiceProps
) => {
  const { pathname } = state.routing.locationBeforeTransitions
  const accountId = getStringBetween(pathname, '/accounts/', '/')
  return {
    account: getAccountByID(accountId, state.accounts.accounts),
    clientId: state.user?.clientId,
    switchNotifications: state.switchNotifications.notifications,
  }
}

export default connect(mapStateToProps)(SwitchModalPremiumService)
