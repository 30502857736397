import Alert from 'react-s-alert'
import { AppState } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { withRouter, WithRouterProps } from 'react-router'
import { bindActionCreators, Dispatch } from 'redux'
import { getOwnKiwiSaverPlanAccount } from '../../common/accounts-helper'
import {
  AccountsOverviewRequestActionType,
  AccountsOverviewFundsRequestActionType,
  AccountsOverviewRequestAction,
  AccountsOverviewFundsRequestAction,
} from '../../redux/accounts/accounts.actions'
import { connect } from 'react-redux'
import { history } from '../../routing/history'
import { Account } from '../../redux/accounts/accounts.model'
import { ReportsRequestActionType } from '../../redux/reports/reports.actions'
import { UserState } from '../../redux/user/user.model'
import { isOverRetirementAge } from '../../common/user-helper'
import { AccountFundsRequestAction } from '../../redux/account-funds/account-funds.actions'
import SpinnerOnLoad from '../../components/spinner-on-load/SpinnerOnLoad'
import {
  actionTypes,
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
} from '../../redux/layout/layout.actions'
import { RetirementProjector } from './RetirementProjector'

type RetirementProjectorPageProps = {
  accounts: Account[]
  account: Account
  user: UserState
  isAccountLoading: boolean
  accountIdInPath: string
  authToken: string
  getAccountsOverview: (userId: string) => AccountsOverviewRequestActionType
  fetchFunds?: (
    userId: string,
    nextAction: any,
    nextActionParams: any
  ) => AccountsOverviewFundsRequestActionType | ReportsRequestActionType
  setActiveAccountTab: (value: string) => { type: actionTypes; payload: string }
  setActiveMenu: (activeMenu: string) => { type: actionTypes; payload: string }
}

export const RetirementProjectorPage = (
  props: RetirementProjectorPageProps
) => {
  const {
    user,
    account,
    accounts,
    isAccountLoading,
    accountIdInPath,
    authToken,
    getAccountsOverview,
    fetchFunds,
  } = props

  const [isAccountFundsLoading, setIsAccountFundsLoading] = useState<boolean>(
    false
  )

  useEffect(() => {
    props.setActiveMenu('investment')
    props.setActiveAccountTab('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.userid) {
      getAccountsOverview(user.userid)
    }
  }, [user.userid]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account?.accountID) {
      fetchFunds(user.userid, AccountFundsRequestAction, {
        userId: user?.userid,
        accountId: account?.accountID,
      })
    }
  }, [user.userid, account?.accountID, fetchFunds])

  // check if account matches account id from the url path, and check eligibility
  useEffect(() => {
    if (
      (account && accountIdInPath && account.accountID !== accountIdInPath) ||
      ((accounts || []).length > 0 && !account) ||
      (user && isOverRetirementAge(user?.birthDate))
    ) {
      Alert.warning(`<p>You are unable to access this right now.</p>`, {
        timeout: 10000,
      })
      redirectToAccountsOverview()
    }
  }, [isAccountLoading, account, user, accountIdInPath, accounts])

  useEffect(() => {
    // not ideal, but looks like works
    if (account) {
      const isFundsLoading = (account?.funds || []).length === 0
      setIsAccountFundsLoading(isFundsLoading)
    }
  }, [account, account?.funds])

  const redirectToAccountsOverview = () => {
    history.push('/accounts')
  }

  if (!account || isAccountLoading || isAccountFundsLoading) {
    return <SpinnerOnLoad isLoading centerSpinner={true} />
  }

  return (
    <div className="main-content-padding">
      <RetirementProjector
        account={account}
        user={user}
        authToken={authToken}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState, { location }: WithRouterProps) => {
  const account = getOwnKiwiSaverPlanAccount(state.accounts.accounts)

  return {
    user: state.user,
    account,
    accounts: state.accounts.accounts,
    accountFunds: account?.funds, // trigger page to refresh when funds are updated
    isAccountLoading: state.accounts.isLoading,
    accountIdInPath: location?.query?.accountId,
    authToken: state.authorisation.authorisationToken,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  getAccountsOverview: bindActionCreators(
    AccountsOverviewRequestAction,
    dispatch
  ),
  fetchFunds: bindActionCreators(AccountsOverviewFundsRequestAction, dispatch),
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RetirementProjectorPage)
)
