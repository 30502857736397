import * as React from 'react'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Alert from 'react-s-alert'
import {
  UsernameRecoveryActions,
  UsernameRecoveryClearStateAction,
  UsernameRecoveryVerificationAction,
} from '../../redux/username-recovery/username-recovery.actions'
import { UsernameRecoveryCredentialsType } from '../../redux/username-recovery/username-recovery.model'
import { routerActions } from 'react-router-redux'
import NameEmailDOBForm from '../../components/name-email-dob-form/NameEmailDOBForm'
import Typography from '@material-ui/core/Typography'
import RegisterLoginLinkBox from '../../components/register-login-link-box/RegisterLoginLinkBox'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'

interface ForgotUsernameProps {
  hasError: boolean
  isLoading: boolean
  errorMessage: string
  errorStatus: number
  verified: boolean
  actions: {
    usernameRecoveryClearState: typeof UsernameRecoveryClearStateAction
    recoveryVerification: typeof UsernameRecoveryVerificationAction
    next: (path: string) => void
  }
  setTitle: (newTitle: string) => void
  setActiveMenu: (activeMenu: string) => void
}

const NO_MATCH_MESSAGE = `Thank you. Keep an eye on your inbox for an email with next steps. If you don't receive one within a few minutes, please <a href="http://fisherfunds.co.nz/contact/">contact us</a> and we can help you out.`

const getPrettyMessage = (status?: number) => {
  if (status === 403) {
    return `We've noticed you've not registered yet, would you like to <a href="/register">register</a> instead?`
  }
  if (status < 500) {
    return NO_MATCH_MESSAGE
  }
  return `Search request failed`
}

export class ForgotUsername extends React.Component<ForgotUsernameProps> {
  constructor(props: ForgotUsernameProps) {
    super(props)
    this.saveCredentials = this.saveCredentials.bind(this)
  }

  componentDidMount() {
    const mainThemeContent = document.querySelector(
      '.main-theme-container .left-content'
    )

    if (mainThemeContent) {
      window.scrollTo(0, 0)
      mainThemeContent.scrollTo(0, 0)
    }

    this.props.actions.usernameRecoveryClearState()
    this.props.setTitle('Forgot username?')
    this.props.setActiveMenu('login')
  }

  UNSAFE_componentWillReceiveProps(nextProps: ForgotUsernameProps) {
    if (nextProps.hasError) {
      const message =
        nextProps.errorMessage || getPrettyMessage(nextProps.errorStatus)
      if (message === NO_MATCH_MESSAGE) {
        // When there's no match we show a success message.
        Alert.info(message, { timeout: 'none' })
      } else {
        Alert.error(message, { timeout: 'none' })
      }
    }

    if (nextProps.verified) {
      this.props.actions.next('/forgot/username/method')
    }
  }

  saveCredentials(
    firstName: string,
    surname: string,
    email: string,
    dob: string
  ) {
    const credentials: UsernameRecoveryCredentialsType = {
      firstName,
      surname,
      email,
      dateOfBirth: dob,
    }
    this.props.actions.recoveryVerification({ credentials })
  }

  render() {
    return (
      <div className="forgot-username-component">
        <Typography component="h6" variant="h6">
          Confirm your details and we'll send you a reminder.
        </Typography>
        <NameEmailDOBForm
          isLoading={this.props.isLoading}
          success={this.props.verified}
          submitButtonText="Submit"
          submittingButtonText={'Retrieving Username...'}
          onSubmit={this.saveCredentials}
        />
        <RegisterLoginLinkBox type="login" />
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    hasError: state.usernameRecovery.hasError,
    errorMessage: state.usernameRecovery.errorMessage,
    errorStatus: state.usernameRecovery.errorStatus,
    isLoading: state.usernameRecovery.isLoading,
    verified: state.usernameRecovery.verified,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<UsernameRecoveryActions>) => ({
  actions: {
    usernameRecoveryClearState: bindActionCreators(
      UsernameRecoveryClearStateAction,
      dispatch
    ),
    recoveryVerification: bindActionCreators(
      UsernameRecoveryVerificationAction,
      dispatch
    ),
    next: (path: string) => dispatch(routerActions.push(path)),
    setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ForgotUsername)
