import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { persistReducer, persistStore } from 'redux-persist'
import createExpirationTransform from 'redux-persist-transform-expire'
import { KEY_PREFIX, REHYDRATE } from 'redux-persist/lib/constants'
import sessionStorage from 'redux-persist/lib/storage/session'

import RootEpic from './epics'
import RootReducer from './reducers'

import * as Sentry from '@sentry/react'

const expireTransform = createExpirationTransform({
  expireKey: 'expiresAt',
  defaultState: {
    custom: 'values',
  },
})

/* Specify session storage for specific reducers. */
const rootPersistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: [
    'pir',
    'user',
    'authorisation',
    'accounts',
    'onboarding',
    'accountDetails',
  ],
  transforms: [expireTransform],
}

const persistedReducer = persistReducer(rootPersistConfig, RootReducer)

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    : compose

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === 'AUTHORISATION_REQUEST') {
      return {
        ...action,
        password: null,
      }
    }
    if (action.type === 'AUTHORISATION_REQUEST_SUCCESS') {
      return {
        ...action,
        authorisationToken: null,
      }
    }
    if (action.type === 'ONBOARDING_CREATE_CREDENTIALS') {
      return {
        ...action,
        password: null,
        hash: null,
      }
    }
    if (action.type === 'ONBOARDING_CREATE_CREDENTIALS_SUCCESS') {
      return {
        ...action,
        password: null,
      }
    }
    if (action.type === 'USER_CHANGE_PASSWORD') {
      return {
        ...action,
        currentPassword: null,
        newPassword: null,
      }
    }
    return action
  },
})

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(browserHistory),
      createEpicMiddleware(RootEpic)
    ),
    sentryReduxEnhancer
  )
)

export const persistor = persistStore(store)

// Sync parts of state across tabs - user, account and authorisation info
window.addEventListener(
  'storage',
  function (e: StorageEvent) {
    if (e.key && e.key.indexOf(KEY_PREFIX) === 0) {
      if (e.oldValue === e.newValue) {
        return
      }

      const statePartial = JSON.parse(e.newValue)
      Object.keys(statePartial).forEach((reducerKey) => {
        if (reducerKey === 'onboarding') {
          store.dispatch({
            key: reducerKey,
            payload: JSON.parse(statePartial[reducerKey]),
            type: REHYDRATE,
          })
        }
      })
    }
  },
  false
)

/**
 * Detect whether the current device is an iDevice, as
 * capslock isn't detected correctly on these devices.
 *
 * @return boolean
 */
const isIOS = () => {
  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ]

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true
      }
    }
  }

  return false
}

/**
 * Set up a listener for caps-lock key changes on non-IOS devices.
 */
if (!isIOS()) {
  window.addEventListener('keyup', (e) => {
    // Sometimes this triggers for generated events that are not keyup so we have to check
    if (!e.getModifierState) {
      return
    }

    store.dispatch({
      type: 'SET_CAPS_LOCK',
      payload: e.getModifierState('CapsLock'),
    })
  })
}
