import React from 'react'
import './TextInput.scss'
import cn from 'classnames'

export type TextInputProps = {
  autoComplete?: AutocompleteType
  error?: boolean
  dark?: boolean
} & React.HTMLProps<HTMLInputElement>

export type AutocompleteType =
  | 'no-autofill'
  | 'name'
  | 'honorific-prefix'
  | 'given-name'
  | 'additional-name'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'email'
  | 'username'
  | 'tel'
  | 'country-name'
  | 'postal-code'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-level1'
  | 'address-level2'
  | 'address-level3'
  | 'address-level4'
  | 'nope'

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className = '',
      dark,
      error,
      disabled,
      autoComplete,
      ...rest
    }: TextInputProps,
    ref
  ) => (
    <input
      className={cn(`input-text__element ${className}`, {
        'input-text__element--error': error,
        'input-text__element--disabled': disabled,
        'input-text__element--dark': dark,
      })}
      disabled={disabled}
      autoComplete={autoComplete}
      type="text"
      ref={ref}
      {...rest}
    />
  )
)

TextInput.displayName = 'TextInput'
