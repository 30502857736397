import { useState } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { YesNo } from './FirstHomeStepsModal.types'
import { StepOptions } from './FirstHomeStepsModal.constants'
import { AppState } from '../../redux/app-state'
import { UserState } from '../../redux/user/user.model'
import { Button } from '../clickable/button/Button'
import Input from '../../common/forms/Input'

type StepDialogProps = {
  step: StepOptions
  message: string
  submitAnswer: (answer: YesNo) => void
  acknowledgeMessage: () => void
}

type ContentProps = StepDialogProps & {
  stepName: string
  submitContactForm: (phone: string) => void
}

export const Content = ({
  stepName,
  step,
  message,
  submitAnswer,
  acknowledgeMessage,
  submitContactForm,
}: ContentProps) => {
  if (stepName === 'CONTACT_FORM') {
    return <ContactForm submitContactForm={submitContactForm} />
  }

  return (
    <StepDialog
      step={step}
      message={message}
      submitAnswer={submitAnswer}
      acknowledgeMessage={acknowledgeMessage}
    />
  )
}

export const ContactFormElement = ({
  user,
  submitContactForm,
}: {
  user: UserState
  submitContactForm: (phone: string) => void
}) => {
  // Get value from state
  const [phone, setPhone] = useState(
    user.mobilePhoneNumber || user.homePhoneNumber || user.businessPhoneNumber
  )

  // TODO handle form errors
  const onSubmit = () => submitContactForm(phone)

  return (
    <>
      <DialogContent className="mb-md">
        <p className="question mt-0">
          Great! Please enter the best phone number for us to contact you on.
        </p>
        <Input
          className="firstHomeLetterContactForm w-100"
          type="tel"
          value={phone}
          theme="white"
          placeholder="Phone number"
          onChange={(e) => {
            setPhone(e.currentTarget.value)
          }}
        />
      </DialogContent>
      <DialogActions className="actions">
        <Button color="primary" className="continue" onClick={() => onSubmit()}>
          Request Callback
        </Button>
      </DialogActions>
    </>
  )
}
const mapStateToProps = (
  state: AppState,
  props: { submitContactForm: (phone: string) => void }
) => {
  return {
    user: state.user,
    submitContactForm: props.submitContactForm,
  }
}

const ContactForm = connect(mapStateToProps)(ContactFormElement)
const StepDialog = ({
  step,
  message,
  submitAnswer,
  acknowledgeMessage,
}: StepDialogProps) => {
  return (
    <>
      <DialogContent className="mb-md">
        <p
          className="question mt-0"
          dangerouslySetInnerHTML={{
            __html: step.question,
          }}
        />
        {message && (
          <p
            className="message"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        )}
      </DialogContent>
      <DialogActions className="actions">
        {!message && <Actions step={step} submitAnswer={submitAnswer} />}
        {message && (
          <Button
            color="primary"
            className="continue"
            onClick={() => acknowledgeMessage()}
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </>
  )
}

type ActionProps = {
  step: StepOptions
  submitAnswer: (answer: YesNo) => void
}

export const Actions = ({ step, submitAnswer }: ActionProps) => {
  const { yes, no } = step
  const isCancelButton = no?.label.toLowerCase() === 'cancel'
  return (
    <>
      {!!no && (
        <Button
          className={cn({
            'mr-auto': isCancelButton,
          })}
          variant="outlined"
          color={isCancelButton ? 'transparent' : 'primary'}
          data-id="no-button"
          onClick={() => submitAnswer('no')}
        >
          {no.label}
        </Button>
      )}
      <Button
        color="primary"
        className="ml-md"
        data-id="yes-button"
        onClick={() => submitAnswer('yes')}
      >
        {yes.label}
      </Button>
    </>
  )
}
