import React, { createContext, ReactNode, useContext, useState } from 'react'

type ProgressBarContextProps = {
  total: number
  setTotal: (total: number) => void
  progress: number
  setProgress: (progress: number) => void
  step: number
  setStep: (step: number) => void
}

const ProgressBarContext = createContext<ProgressBarContextProps | null>(null)

export const ProgressBarContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [total, setTotal] = useState(0)
  const [progress, setProgress] = useState(0)
  const [step, setStep] = useState(0)

  return (
    <ProgressBarContext.Provider
      value={{
        total,
        setTotal,
        progress,
        setProgress,
        step,
        setStep,
      }}
    >
      {children}
    </ProgressBarContext.Provider>
  )
}

export const useProgressBarContext = () => {
  const progressBarContext = useContext(ProgressBarContext)
  if (!progressBarContext) {
    throw new Error(
      "Can't find search context, nest the component under ProgressBarContextProvider"
    )
  }

  return progressBarContext
}
