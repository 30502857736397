import React, { useEffect } from 'react'
import { Link } from 'react-router'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import RegisterLoginLinkBox from '../../components/register-login-link-box/RegisterLoginLinkBox'
import { useForm } from 'react-hook-form'

export type FormValues = {
  username: string
}

interface ForgotPasswordCredentialsProps {
  isLoading: boolean
  heading: string
  buttonText: string
  submit: (submittedValues: FormValues) => void
}

export default function ForgotPasswordCredentials(
  props: ForgotPasswordCredentialsProps
) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { isLoading, submit } = props

  const onSubmitEvent = (data: any) => {
    if (!data.username) {
      return
    }

    submit({
      username: data.username.trim(),
    })
  }

  useEffect(() => {
    const mainThemeContent = document.querySelector(
      '.main-theme-container .left-content'
    )

    if (mainThemeContent) {
      window.scrollTo(0, 0)
      mainThemeContent.scrollTo(0, 0)
    }
  })

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitEvent)}
        className="form forgot-password-credentials-component"
      >
        <Typography variant="h6">
          Enter your username to reset your password.
        </Typography>
        <div className="fieldGroup login-field-group mb-md">
          <Typography
            component="p"
            align="left"
            className="login-field-group__label"
          >
            Username
            <MuiLink underline="always" to="/forgot/username" component={Link}>
              Forgot username?
            </MuiLink>
          </Typography>
          <TextField
            name="username"
            id="username"
            variant="outlined"
            margin="normal"
            fullWidth
            disabled={isLoading}
            {...{
              ...register('username', { required: true }),
              ref: undefined,
              inputRef: register('username', { required: true }).ref,
            }}
            helperText={
              errors && errors.username ? 'Username is a required field' : ''
            }
            error={errors && errors.username ? true : false}
          />
        </div>
        <div className="action-group">
          <Button
            disableRipple
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            {props.isLoading ? 'Please wait...' : props.buttonText}
          </Button>
        </div>
      </form>
      <RegisterLoginLinkBox type="login" />
    </>
  )
}
