import { State } from '../state/state.model'
import { AccountFund } from '../account-funds/account-funds.model'

export interface AccountsState extends State {
  clientID: string
  clientTotal: number
  accounts: Account[]
  customMessageAccounts: string[]
  notifications: CustomerNotification[]
  switchAccounts?: Account[]
  directDebitAccounts?: Account[]
  expiresAt?: string
  noAccountsMessage: string
}

export enum GovernmentContributionEligibilityType {
  FULL_ELIGIBILITY = 'FULL_ELIGIBILITY',
  PARTIAL_ELIGIBILITY_TURNED_18 = 'PARTIAL_ELIGIBILITY_TURNED_18',
  PARTIAL_ELIGIBILITY_REACHED_AGE = 'PARTIAL_ELIGIBILITY_REACHED_AGE',
  PARTIAL_ELIGIBILITY_NEW_TO_KS = 'PARTIAL_ELIGIBILITY_NEW_TO_KS',
  INELIGIBLE_ELIGIBILITY_UNDER_18 = 'INELIGIBLE_ELIGIBILITY_UNDER_18',
  INELIGIBLE_ELIGIBILITY_OVERSEAS = 'INELIGIBLE_ELIGIBILITY_OVERSEAS',
  INELIGIBLE_ELIGIBILITY_PAST_AGE = 'INELIGIBLE_ELIGIBILITY_PAST_AGE',
}

export type GovernmentContributionDetail = {
  currentGvcAmount: number
  eligibility: GovernmentContributionEligibilityType
  maxGcvEligibility: number
  topUpAmount: number
  totalContributions: number
}

export type Account = {
  productName: string
  accountTotal: number
  productDisplayName: string
  accountID: string
  accountNumber: string
  accountMailingName: string
  productExternalRef: string
  productExternalName: string
  simpleProductName: string
  pieTaxAccrued?: number
  status: 'active' | 'pending' // https://fisherfunds.atlassian.net/browse/DPL-935
  isDepositMode: boolean
  isOwner: boolean
  canRead: boolean
  canWrite: boolean
  isAuthRep: boolean
  switchURL: string
  directDebitURL: string
  governmentContributionDetail: GovernmentContributionDetail
  commenceDate: string
  latestPriceDate?: string
  kiwiSaverOriginalStart: string
  rebalance: boolean
  funds: AccountFund[]
  owners: [
    {
      clientID: string
      clientMailingName: string
    }
  ]
  returnSinceInception?: number // only KW accounts will have this
}

export type CustomerNotification = {
  id: string
  title: string
  message: any
  linkUrl: string
  linkText: string
}
