import React from 'react'
import { Link } from 'react-router'
import './NavigationMobile.scss'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'

import CustomIcon from '../../common/CustomIcon'
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from '../contentful-terms-and-conditions'

export type StateProps = {
  showInvestmentTools?: boolean
}

type OwnProps = {
  activeMenu: string
}

type NavigationMobileProps = StateProps & OwnProps

const NavigationMobile = (props: NavigationMobileProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isActive = (currentMenu: string) => {
    return currentMenu === props.activeMenu
  }

  const getCurrentValue = () => {
    switch (props.activeMenu) {
      case 'accounts':
      case 'account-details':
      case 'account-funds':
      case 'account-transactions':
        return 0
      case 'reports':
        return 1
      case 'details':
        return 2
      case 'investment':
      case 'terms':
      case 'privacy':
      default:
        return 3
    }
  }

  return (
    <>
      <BottomNavigation showLabels value={getCurrentValue()}>
        <BottomNavigationAction
          disableRipple
          label="Accounts"
          icon={
            <CustomIcon icon="accounts" className="MuiSvgIcon-root" light />
          }
          component={Link}
          to="/accounts"
        />
        <BottomNavigationAction
          disableRipple
          label="Reports"
          icon={<CustomIcon icon="reports" className="MuiSvgIcon-root" light />}
          component={Link}
          to="/reports"
        />
        <BottomNavigationAction
          disableRipple
          label="Details"
          icon={<CustomIcon icon="details" className="MuiSvgIcon-root" light />}
          component={Link}
          to="/details"
        />
        <BottomNavigationAction
          disableRipple
          label="More"
          icon={<MoreHorizIcon />}
          aria-haspopup="true"
          onClick={handleClick}
        />
      </BottomNavigation>

      <Menu
        anchorEl={anchorEl}
        keepMounted={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navigation-mobile"
      >
        {props.showInvestmentTools ? (
          <MenuItem
            disableRipple
            selected={isActive('investment')}
            onClick={handleClose}
          >
            <Link to="/investment-tools" className="navigation-mobile-link">
              Investment tools
            </Link>
          </MenuItem>
        ) : null}

        <MenuItem disableRipple selected={isActive('terms')}>
          <TermsAndConditionsLink className="navigation-mobile-link" />
        </MenuItem>
        <MenuItem disableRipple selected={isActive('privacy')}>
          <PrivacyPolicyLink className="navigation-mobile-link" />
        </MenuItem>
      </Menu>
    </>
  )
}

export default NavigationMobile
