import { Box, Button } from '@material-ui/core'
import './LoggedOut.scss'
import {
  AuthorisationActions,
  DeauthorisationRequestAction,
  DeauthorisationResetLogoutMessageAction,
} from '../../redux/authorisation/authorisation.actions'
import { connect, Dispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AppState } from '../../redux/app-state'

interface LoggedOutProps {
  userId?: string
  logoutRequest?: typeof DeauthorisationRequestAction
  resetLogOut?: typeof DeauthorisationResetLogoutMessageAction
  isTimeout: boolean
  setTitle?: (title: string) => void
}

export const LoggedOut = (props: LoggedOutProps) => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (props.userId) {
      props.logoutRequest(props.userId)
    }

    if (props.setTitle) {
      props.setTitle('Logged out')
    }
  }, [props])

  const handleLoginAgain = async () => {
    if (props.resetLogOut) {
      props.resetLogOut()
    }

    await loginWithRedirect()
  }

  const message = props.isTimeout
    ? 'Your account has been logged out due to inactivity. Please log in again.'
    : 'Your account has been logged out.'

  return (
    <>
      <p>{message}</p>
      <Box mt={3}>
        <Button
          color="primary"
          disableRipple
          onClick={handleLoginAgain}
          variant="contained"
        >
          Log in
        </Button>
      </Box>
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    userId: state.user.userid,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AuthorisationActions>) => ({
  logoutRequest: bindActionCreators(DeauthorisationRequestAction, dispatch),
  resetLogOut: bindActionCreators(
    DeauthorisationResetLogoutMessageAction,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(LoggedOut)
