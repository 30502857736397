// Copied from fisher-funds-website
import { FormikErrors } from 'formik'
import { ObjectSchema } from 'joi'

export const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{1,4})(?: *x(\d+))?\s*$/
export const isPhoneNumber = (text: string) => text.match(phoneNumberRegex)

/* eslint-disable-next-line */
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isEmail = (text: string) => text.match(emailRegex)

export const firstNameMaxLength = 40
export const middleNameMaxLength = 80
export const lastNameMaxLength = 80
export const nameMaxLength = firstNameMaxLength + lastNameMaxLength + 1

export const addressLineMaxLength = 100
export const suburbMaxLength = 55
export const cityMaxLength = 40
export const postcodeMaxLength = 4
export const postcodeMinLength = 4

export const dateFormat = 'dd/MM/yyyy'

// eslint-disable-next-line
export const schemaValidation = <T>(schema: ObjectSchema) => async (
  values: T
): Promise<FormikErrors<T> | void> => {
  const validationResult = schema.validate(values, {
    abortEarly: false,
  })

  if (validationResult.error) {
    const errors: { [key: string]: any } = {} // eslint-disable-line
    const formikErrors = validationResult.error.details.reduce(
      (errs, detail) => {
        if (detail.path.length === 1) {
          errs[detail.path[0] as string] = detail.message // eslint-disable-line no-param-reassign
          return errs
        }

        detail.path.reduce((acc, p, index) => {
          if (index < detail.path.length - 1) {
            if (!(p in acc)) {
              acc[p] = {}
            }
          } else {
            acc[p] = detail.message
          }
          return acc[p]
        }, errs)

        return errs
      },
      errors
    )

    return formikErrors as FormikErrors<T>
  }
}
