import { useEffect, useState } from 'react'
import cn from 'classnames'
import { Notification } from '../notification/Notification'
import './loading-message.scss'

interface LoadingMessageProps {
  wait: number
  loadingMessage?: string
}

const LoadingMessage = ({
  wait,
  loadingMessage = 'Won’t be long, we’re just fetching your information.',
}: LoadingMessageProps) => {
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setHidden(false), wait)
    return () => {
      clearTimeout(timer)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Notification className={cn({ hidden: hidden })}>
      {loadingMessage}
    </Notification>
  )
}

export default LoadingMessage
