/**
 * @deprecated
 * These are our legacy icons. Please use BrandIcon instead.
 */
import React from 'react'
import { BsChatLeftDots } from 'react-icons/bs'
import {
  IoSyncCircle,
  IoAlertCircle,
  IoCloseCircle,
  IoCheckmarkCircle,
  IoSyncCircleOutline,
  IoAlertCircleOutline,
  IoCloseCircleOutline,
  IoCheckmarkCircleOutline,
  IoChevronBack,
  IoChevronForward,
  IoArrowDownCircleOutline,
  IoChevronDown,
  IoChevronUp,
  IoRefresh,
  IoEllipse,
  IoClose,
  IoAlert,
  IoChatbubble,
  IoAdd,
  IoRemove,
  IoSync,
} from 'react-icons/io5'
import { IoMdInformationCircle, IoMdCalendar, IoMdMail } from 'react-icons/io'
import { RiPencilFill, RiArrowGoBackLine } from 'react-icons/ri'
import {
  HiLightBulb,
  HiOutlineExternalLink,
  HiPhone,
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowLeft,
} from 'react-icons/hi'
import { FiDownload, FiLogOut } from 'react-icons/fi'
import { GoArrowRight, GoArrowUp } from 'react-icons/go'
import { FaSearch } from 'react-icons/fa'
// import { CommonBurgerProps, Squeeze as Hamburger } from 'hamburger-react'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdAlternateEmail, MdCallMade, MdError, MdHome } from 'react-icons/md'
import { IconBaseProps } from 'react-icons/lib/cjs/iconBase'

enum Size {
  '2xs' = '0.5rem',
  xs = '0.8rem',
  sm = '1rem',
  md = '1.3rem',
  lg = '2rem',
  xl = '2.7rem',
}

export type IconSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type IconProps = {
  size?: IconSize
} & IconBaseProps

export const IconHome = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <MdHome
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconSyncCircle = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoSyncCircle
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconErrorDisclaimerCircle = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <MdError
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconMdInformationCircle = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoMdInformationCircle
    focusable={focusable}
    size={Size[size as keyof typeof Size]}
    color="#003B70"
    {...restProps}
  />
)

export const IconAlertCircle = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoAlertCircle
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconAlert = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoAlert
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconCloseCircle = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoCloseCircle
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconCheckmarkCircle = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoCheckmarkCircle
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconSyncCircleOutline = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoSyncCircleOutline
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconAlertCircleOutline = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoAlertCircleOutline
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconCloseCircleOutline = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoCloseCircleOutline
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconClose = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoClose
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconCheckmarkCircleOutline = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoCheckmarkCircleOutline
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconChevronForward = ({
  size = 'sm',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoChevronForward
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconArrowForward = ({
  size = 'sm',
  focusable = false,
  ...restProps
}: IconProps) => (
  <GoArrowRight
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconArrowUpForward = ({
  size = 'sm',
  focusable = false,
  ...restProps
}: IconProps) => (
  <GoArrowUp
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconChevronBack = ({ size = 'md', ...restProps }: IconProps) => (
  <IoChevronBack size={Size[size as keyof typeof Size]} {...restProps} />
)

export const IconChatBubblesOutline = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <BsChatLeftDots
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconChatBubble = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoChatbubble
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconAdd = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoAdd
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconRemove = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoRemove
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconSync = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoSync
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconArrowDownCircleOutline = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoArrowDownCircleOutline
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconRiArrowGoBackLine = ({ ...restProps }: IconProps) => (
  <RiArrowGoBackLine {...restProps} />
)

export const IconChevronDown = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoChevronDown
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconChevronUp = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoChevronUp
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconRefresh = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoRefresh
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconEllipse = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoEllipse
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconLightBulb = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <HiLightBulb
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconCalendar = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <IoMdCalendar
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconDownload = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <FiDownload
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconPencilFill = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <RiPencilFill
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconSearch = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <FaSearch
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconExternalLink = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <HiOutlineExternalLink
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

// export type IconHamburgerProps = {
//   size?: IconSize
// } & Omit<CommonBurgerProps, 'size'>

// export const IconHamburger = ({
//   size = 'md',
//   ...restProps
// }: IconHamburgerProps) => {
//   const sizeInRem = parseFloat(
//     Size[size as keyof typeof Size].replace('rem', '')
//   )
//   const sizeInPixel = sizeInRem * 18

//   return <Hamburger size={sizeInPixel} {...restProps} />
// }
export const IconCheck = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <AiOutlineCheck
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconLogout = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <FiLogOut
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconEmail = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <MdAlternateEmail
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconAnchor = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <MdCallMade
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconMail = () => <IoMdMail />

export const IconPhoneAlt = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <HiPhone
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconArrowLeft = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <HiOutlineArrowNarrowLeft
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)

export const IconArrowRight = ({
  size = 'md',
  focusable = false,
  ...restProps
}: IconProps) => (
  <HiOutlineArrowNarrowRight
    size={Size[size as keyof typeof Size]}
    focusable={focusable}
    {...restProps}
  />
)
