import { Reducer, AnyAction } from 'redux'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './account-growth.actions'
import { AccountGrowthState } from './account-growth.model'

export const initialState: AccountGrowthState = {
  accountNumber: null,
  productName: null,
  productTotal: 0,
  history: [],
  ...getDefaultState(),
}

export const accountGrowthReducer: Reducer<AccountGrowthState> = (
  state: AccountGrowthState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_GROWTH_REQUEST:
      return { ...state, isLoading: true, hasError: false, errorMessage: null }

    case actionTypes.ACCOUNT_GROWTH_REQUEST_SUCCESS:
      return { ...state, ...action.payload, isLoading: false }

    case actionTypes.ACCOUNT_GROWTH_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }
    // Logging out resets the accounts growth state
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
