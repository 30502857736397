import { ProductCode } from './product-variables'

export interface ReturnsSummaryItem {
  fundCode: string | null
  displayName: string
  shortName: string
  inceptionDate: Date
  priceDate: Date
  return3Years: number
  return3Months: number
  return6Months: number
  return7Years: number
  return1Month: number
  return1Year: number
  return2Years: number
  return5Years: number
  returnSinceInception: number
  performanceChartLink?: string
  isLegacy: boolean
  legacySince?: Date
}

type ProductReturnMap = {
  product: ProductCode
  [key: string]: string
}

//used to map the performance api to the matching fund or strategy
export const ProductReturnsMap: ProductReturnMap[] = [
  {
    product: ProductCode.FFKS,
    'Conservative Strategy': 'KiwiSaver Conservative Fund',
    'Balanced Strategy': 'KiwiSaver Balanced Strategy++',
    'Growth Strategy': 'KiwiSaver Growth Fund',
  },
  {
    product: ProductCode.FF_TWO,
    'Conservative Strategy': 'KiwiSaver TWO Conservative Fund',
    'Balanced Strategy': 'KiwiSaver TWO Balanced Fund',
    'Growth Strategy': 'KiwiSaver TWO Growth Fund',
  },
  {
    product: ProductCode.FFMF,
    'Conservative Strategy': 'Managed Funds Conservative Fund',
    'Balanced Strategy': 'Managed Funds Balanced Strategy 40/60',
    'Growth Strategy': 'Managed Funds Growth Fund',
  },
  {
    product: ProductCode.FF_LIFE,
    'Conservative Strategy': 'LifeSaver Conservative Fund',
    'Balanced Strategy': 'LifeSaver Balanced Fund',
    'Growth Strategy': 'LifeSaver Growth Fund',
  },
  {
    product: ProductCode.FFKP,
    'Cash Fund': 'KiwiSaver Plan Cash Fund',
    'Default Conservative Fund': 'KiwiSaver Plan Default Conservative Fund',
    'Conservative Fund': 'KiwiSaver Plan Conservative Fund',
    'Default Fund': 'KiwiSaver Plan Default Fund',
    'Balanced Fund': 'KiwiSaver Plan Balanced Fund',
    'Growth Fund': 'KiwiSaver Plan Growth Fund',
  },
  {
    product: ProductCode.FFIF,
    'Conservative Fund': 'Investment Funds Conservative Fund',
    'Balanced Fund': 'Investment Funds Balanced Fund',
    'Growth Fund': 'Investment Funds Growth Fund',
  },
]

export const getReturnByDisplayName = (
  items: ReturnsSummaryItem[],
  displayName: string
) => items.find((item) => item.displayName.includes(displayName))
