import { RegisterOptions } from 'react-hook-form'

/*
  Username field functions
*/

export const getUsernameError = (errors: any) => {
  if (errors && errors.username) {
    if (errors.username.type === 'pattern') {
      return 'Invalid username. Please use only letters, numbers and @ . - characters.'
    } else if (errors.username.type === 'minLength') {
      return 'Username must be at least 4 characters long'
    } else if (errors.username.type === 'maxLength') {
      return 'Username must be 150 characters or less.'
    }
    return 'Username is a required field'
  }

  return ''
}

export const usernameValidation = {
  required: true,
  minLength: 4,
  maxLength: 150,
  pattern: /^[A-Za-z0-9@.-]+$/i,
}

const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{1,4})(?: *x(\d+))?\s*$/

export const phoneNumberValidation = {
  required: true,
  minLength: 7,
  maxLength: 14,
  pattern: phoneNumberRegex,
}

export const getPhoneNumberError = (errors: any) => {
  if (errors && errors.phoneNumber) {
    if (errors.phoneNumber.type === 'pattern') {
      return 'Invalid format. Please enter a valid phone number.'
    }
    return 'Please enter your phone number'
  }
  return ''
}

/*
  DOB field functions
*/

export const getDobError = (errors: any, dob: string) => {
  if (errors) {
    if (errors.dob && !dob) return 'Date of birth is a required field'
    else return 'The date of birth entered is invalid. Please re-enter'
  }
  return ''
}

export const dobValidation: RegisterOptions = {
  required: true,
  pattern: /(\d{2})\/(\d{2})\/(\d{4})/i,
  minLength: 10,
}

/*
  Email field functions
*/

export const getEmailError = (errors: any) => {
  if (errors && errors.email) {
    if (errors.email.type === 'pattern') {
      return 'Invalid email. Please enter a valid email address.'
    }
    return 'Email address is a required field'
  }

  return ''
}

export const emailValidation = {
  required: true,
  // eslint-disable-next-line no-useless-escape
  pattern: /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
}
