import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { routerActions } from 'react-router-redux'
import isNumber from 'lodash/isNumber'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Divider from '@material-ui/core/Divider'
import moment from 'moment'
import './AccountDetailsInformation.scss'
import { getAccountByID } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import { AppState } from '../../redux/app-state'
import { AccountDetailsActions } from '../../redux/account-details/account-details.actions'
import { SwitchNotificationsRequestAction } from '../../redux/switch-notifications/switch-notifications.actions'
import CurrencyTotal from '../currency/CurrencyTotal'
import CurrencyDifference from '../currency/CurrencyDifference'
import AccountDetailsAddFundsButton from '../account-details-add-funds-button/AccountDetailsAddFundsButton'
import { GovernmentContributionInDetail } from '../government-contribution/GovernmentContribution'
import { Button } from '../clickable/button/Button'

interface AccountDetailsInformationProps {
  currentAccount?: Account
  details?: AccountDetail
  nextStep?: (path: string) => void
  accountId?: string
  hasError?: boolean
  errorMessage?: string
  switchNotifications?: Notification[]
  loadSwitchNotifications?: () => void
  marketEarnings?: number
}

function AccountDetailsInformation(props: AccountDetailsInformationProps) {
  useEffect(() => {
    props.loadSwitchNotifications()
  })

  const openInvestmentTabs = () => {
    props.nextStep(`/accounts/${props.currentAccount.accountNumber}/funds`)

    setTimeout(() => {
      const element: HTMLDivElement = document.getElementById(
        'account-tabs'
      ) as HTMLDivElement

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 250)
  }

  const { currentAccount: account, marketEarnings } = props

  const {
    governmentContributionDetail,
    commenceDate,
    isDepositMode,
    kiwiSaverOriginalStart,
  } = account

  const renderSinceInceptionReturn = () => {
    if (isDepositMode) {
      return null
    }
    return (
      <>
        <Divider />
        <div className="account-details-information__numbers">
          <div>
            <p className="details-label account-details-information__returns__label">
              Since inception return
            </p>
            {isNumber(marketEarnings) ? (
              <CurrencyDifference value={marketEarnings} />
            ) : (
              <div className="account-details-information__returns__loading-shimmer my-auto" />
            )}
          </div>
          <div>
            <p className="text-large details-description">After fees and tax</p>
            {!!account.commenceDate && (
              <p className="text-large details-description color-smoke">
                {'since ' + moment(account.commenceDate).format('DD MMM YYYY')}
              </p>
            )}
          </div>
        </div>
      </>
    )
  }

  const renderGovernmentContributionDetail = () => {
    if (!governmentContributionDetail) {
      return null
    }
    return (
      <>
        <Divider />
        <GovernmentContributionInDetail
          governmentContributionDetail={governmentContributionDetail}
          commenceDate={commenceDate}
          kiwiSaverOriginalStart={kiwiSaverOriginalStart}
          accountId={account.accountID}
          externalRef={account.productExternalRef}
          productExternalName={account.productExternalName}
        />
      </>
    )
  }

  return (
    <div className="account-details-information">
      <div className="account-details-information__balance">
        <div className="account-details-information__balance-information">
          <Typography
            component="h6"
            variant="caption"
            className="current-balance-title"
          >
            Current balance
          </Typography>
          <p className="account-details-information__total">
            <CurrencyTotal value={account.accountTotal} />
          </p>
          <p className="text-large details-description">
            {account.latestPriceDate
              ? 'as at ' + moment(account.latestPriceDate).format('DD MMM YYYY')
              : 'at most recent valuation'}
          </p>
        </div>
        <div className="account-details-information__balance-actions">
          <AccountDetailsAddFundsButton />
          {!isDepositMode && (
            <Button
              variant="link"
              iconRight={ArrowForwardIcon}
              onClick={openInvestmentTabs}
              className="view-unit-information mt-md"
            >
              View unit information
            </Button>
          )}
        </div>
      </div>
      {renderSinceInceptionReturn()}
      {renderGovernmentContributionDetail()}
    </div>
  )
}

const mapStateToProps = (
  state: AppState,
  props: AccountDetailsInformationProps
) => {
  const cachedAccountDetails = state.accountDetails.accounts.find(
    (acc: AccountDetail) => acc.accountID === props.accountId
  )

  return {
    currentAccount: getAccountByID(props.accountId, state.accounts.accounts),
    marketEarnings: cachedAccountDetails?.marketEarnings,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountDetailsActions>) => ({
  loadSwitchNotifications: bindActionCreators(
    SwitchNotificationsRequestAction,
    dispatch
  ),
  nextStep: (path: string) => dispatch(routerActions.push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountDetailsInformation)
