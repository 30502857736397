import axios, { Method } from 'axios'

const getOptions = (options: any, method: Method, authToken: string) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      'x-requesting-platform': 'FFO_WEB',
    },
    ...options,
  }
}

const apiPath = process.env.REACT_APP_FFM_ONLINE_API_URL!
const getUrl = (path: string) => `${apiPath}/api/${path}`

export default function getApiCore(authToken: string) {
  return {
    get(path: string, options = {}) {
      return axios(getUrl(path), getOptions(options, 'GET', authToken))
    },
    post(path: string, options = {}) {
      return axios(getUrl(path), getOptions(options, 'POST', authToken))
    },
    put(path: string, options = {}) {
      return axios(getUrl(path), getOptions(options, 'PUT', authToken))
    },
    delete(path: string) {
      return axios(getUrl(path), getOptions({}, 'DELETE', authToken))
    },
  }
}
