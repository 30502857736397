// Our brand icons
export { default as IconAccount } from './IconAccount'
export { default as IconAnnouncement } from './IconAnnouncement'
export { default as IconCommunication } from './IconCommunication'
export { default as IconInformation } from './IconInformation'
export { default as IconPhone } from './IconPhone'
export { default as IconSupport } from './IconSupport'

export enum Size {
  '2xs' = '0.5rem',
  xs = '0.8rem',
  sm = '1rem',
  md = '1.3rem',
  lg = '2rem',
  xl = '2.7rem',
}

export type IconSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type IconProps = {
  size?: IconSize
  theme?: 'light' | 'dark' | 'dusk'
}

export const THEME_COLOURS = {
  light: {
    fill: '#1B3D6F',
  },
  dark: {
    fill: 'white',
  },
  dusk: {
    fill: '#218094',
  },
}
