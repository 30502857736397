// mostly copied from fisher funds website

import { getUserAge } from '../../common/user-helper'
import { Fund } from '../../components/switch-contentful-modal/SwitchContentfulModal.types'
import { AccountFund } from '../../redux/account-funds/account-funds.model'
import { Account } from '../../redux/accounts/accounts.model'
import { UserData } from '../../redux/user/user.model'

export enum RetirementProjectorStep {
  ABOUT_YOU,
  RESULTS,
}

export enum EmploymentStatus {
  EMPLOYED,
  SELF_EMPLOYED,
  NOT_IN_PAID_EMPLOYMENT,
}

export enum ContributionFrequency {
  WEEKLY,
  FORTNIGHTLY,
  MONTHLY,
  YEARLY,
}

export type RetirementProjectorState = {
  age: number
  currentKiwiSaverBalance: number
  productExternalName: string
  productExternalRef: string
  aboutYou: RetirementProjectorAboutYouInputs
  kiwiSaverFunds: AccountFund[]
  includeSuper: boolean
  dalId: string | undefined
}

export type RetirementProjectorAboutYouInputs = {
  withdrawForFirstHome: boolean
  employmentStatus: EmploymentStatus
  salary?: number
  salaryFrequency: ContributionFrequency
  contributionRate: number
  employerContributionRate: number
  fund?: Fund
  voluntaryContribution?: number
  voluntaryContributionFrequency: ContributionFrequency
}

export type RetirementProjectorYear = {
  age: number
  startValue: number
  endValue: number
  startValueAdjusted: number
  endValueAdjusted: number
  resources: {
    id: number
    contributions: {
      id: number
      amount: number
      amountAdjusted: number
    }[]
    startValue: number
    endValue: number
    startValueAdjusted: number
    endValueAdjusted: number
    investmentReturns: number
    investmentReturnsRate: number
    investmentReturnsAdjusted: number
    kiwiSaver: {
      employeeContribution: number
      employerContribution: number
      governmentContribution: number
      employeeContributionAdjusted: number
      employerContributionAdjusted: number
      governmentContributionAdjusted: number
    }
  }[]
  goals: {
    id: number
    amount: number
    amountFunded: number
    amountAdjusted: number
    amountFundedAdjusted: number
  }[]
}

export type RetirementProjectorOutput = {
  startValue: number
  endValue: number
  investmentReturnsTotal: number
  contributionTotal: number
  goalTotal: number
  startValueAdjusted: number
  endValueAdjusted: number
  incomeInRetirement: number
  incomeInRetirementAdjusted: number
  startBalanceInRetirement: number
  startBalanceInRetirementAdjusted: number
  superWeeklyIncome?: number
  goalSeeks: {
    type: string
    id: number
    success: boolean
    result: number
  }[]
  years: RetirementProjectorYear[]
}

// https://master.fisherfunds-apibridge-prod.pages.dev/#/ffb-gateway-pub-cx
// "Adjusted" values take in account inflation as projection in "in today's dollars"
export type RetirementProjectorResponse = {
  projectorOutputs: RetirementProjectorOutput
  dalId: string
}

export const createInitialState = (
  user: UserData,
  account: Account
): RetirementProjectorState => {
  const kiwiSaverFunds = account?.funds || []

  return {
    age: getUserAge(user?.birthDate),
    currentKiwiSaverBalance: account.accountTotal,
    productExternalName: account.productExternalName,
    productExternalRef: account.productExternalRef,
    kiwiSaverFunds,
    includeSuper: false,
    dalId: undefined,
    aboutYou: {
      withdrawForFirstHome: false,
      employmentStatus: EmploymentStatus.EMPLOYED,
      salary: undefined,
      salaryFrequency: ContributionFrequency.YEARLY,
      contributionRate: 3,
      employerContributionRate: 3,
      fund: undefined,
      voluntaryContribution: 0,
      voluntaryContributionFrequency: ContributionFrequency.WEEKLY,
    },
  }
}
