import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { PirRate } from '../../redux/pir/pir.model'
import {
  PIRUserRateRequestAction,
  PIRUserRateSaveAction,
  PIRResetSuccessAction,
} from '../../redux/pir/pir.actions'
import { PirRateActions } from '../../redux/pir/pir.actions'

import { hasOwnAccount, hasCompanyAccount } from '../../common/accounts-helper'

import ChangePirComponent from './ChangePIR.component'

import { Props as OwnProps } from './ChangePIR.component'
import { AuthorizationFormValues } from '../authorization-modal/use-authorized-request'

export type DispatchProps = {
  resetPirSuccess: () => void
  getPirRate: (userId: string) => void
  savePirRate: (
    values: { pirRate: string } & AuthorizationFormValues,
    uuid: string
  ) => void
}

export type StateProps = {
  hasError: boolean
  errorMessage?: string
  isLoading: boolean
  isSaving: boolean
  hasPIRChanged: boolean
  currentPirRate: PirRate
  pirRates: PirRate[]
  userId: string
  hasOwnAccount: boolean
  hasCompanyAccount: boolean
}

const mapStateToProps = (state: AppState, props: OwnProps): StateProps => {
  return {
    userId: state.user.userid,
    hasOwnAccount: hasOwnAccount(state.accounts.accounts),
    hasCompanyAccount: hasCompanyAccount(state.accounts.accounts),
    ...state.pir,
    ...props,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<PirRateActions>
): DispatchProps => ({
  getPirRate: bindActionCreators(PIRUserRateRequestAction, dispatch),
  savePirRate: bindActionCreators(PIRUserRateSaveAction, dispatch),
  resetPirSuccess: bindActionCreators(PIRResetSuccessAction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePirComponent)
