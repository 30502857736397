import { Account } from '../redux/accounts/accounts.model'
import {
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
} from './accounts-helper'
import { isOverRetirementAge } from './user-helper'

export const canWithdrawFunds = (account: Account, birthDate?: string) => {
  if (!account) {
    return false
  }
  return (
    isInvestmentFundsAccount(account) ||
    (!!birthDate &&
      isKiwiSaverPlanAccount(account) &&
      isOverRetirementAge(birthDate))
  )
}
