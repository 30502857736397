import React, { useState } from 'react'
import cn from 'classnames'
import MuiLink from '@material-ui/core/Link'
import { Account } from '../../redux/accounts/accounts.model'
import SwitchLink from '../switch-link/SwitchLink'
import {
  hasWriteAuthorisation,
  isApexProductAccount,
  isPremiumServiceAccount,
} from '../../common/accounts-helper'
import {
  getAccountProductSlug,
  getProductSwitchRequestUrl,
} from '../../common/product-helper'
import { ProductSlug } from '../../common/product-variables'
import SwitchModalPremiumService from '../switch-modal-ps/SwitchModalPremiumService'
import { HASH_MODALS } from '../../common/hash-helper'
import { hasContentfulSwitchDef } from '../../common/switch-helper'

type InvestmentStrategySwitchProps = {
  currentAccount: Account
  onClose?: () => any
  className?: string
  children?: React.ReactNode
}

const InvestmentStrategySwitch = (props: InvestmentStrategySwitchProps) => {
  const [premiumSwitchModalOpen, setPremiumSwitchModalOpen] = useState(false)
  const { currentAccount, className = '', children } = props

  const isApexProduct = isApexProductAccount(currentAccount.productExternalRef)

  const renderLink = () => {
    const productSlug = getAccountProductSlug(currentAccount)
    const content =
      children || `Review your ${isApexProduct ? 'fund' : 'strategy'}`
    if (!hasWriteAuthorisation(currentAccount)) {
      return (
        <MuiLink
          className={className}
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          href={getProductSwitchRequestUrl(productSlug)}
        >
          {content}
        </MuiLink>
      )
    }
    if (hasContentfulSwitchDef(currentAccount.productExternalRef)) {
      return (
        <a
          className={cn('text-decoration-none', className)}
          href={`#${HASH_MODALS.SWITCH}`}
          data-modalid="switch-link"
          onClick={() => {
            if (props.onClose) {
              props.onClose()
            }
          }}
        >
          {content}
        </a>
      )
    }

    const { accountID } = currentAccount

    if (isPremiumServiceAccount(currentAccount)) {
      return (
        <>
          <MuiLink
            className={className}
            underline="none"
            onClick={() => {
              setPremiumSwitchModalOpen(true)
            }}
          >
            {content}
          </MuiLink>
          {premiumSwitchModalOpen && (
            <SwitchModalPremiumService
              accountId={accountID}
              onClose={() => {
                setPremiumSwitchModalOpen(false)
                if (props.onClose) {
                  props.onClose()
                }
              }}
            />
          )}
        </>
      )
    }

    return (
      <SwitchLink
        className={className}
        popupTitle={`Change your investment strategy for account ${accountID}`}
        accountId={accountID}
        label={content}
        activeLinkId={`change-investment-switch-${accountID}`}
        type="link"
        resource={
          productSlug === ProductSlug.FF_PREMIUM ? 'switch-premium' : 'switch'
        }
        onClose={props.onClose}
      />
    )
  }

  return renderLink()
}

export default InvestmentStrategySwitch
