import { ForwardedRef, forwardRef, useMemo } from 'react'
import cn from 'classnames'
import CheckIcon from '@material-ui/icons/Check'
import './Checkbox.scss'

interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  size?: 'small' | 'medium'
  theme?: 'white' | 'gray'
}

const Checkbox = forwardRef(
  (props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const randomNumber = useMemo(() => Math.floor(Math.random() * 10000), [])
    const {
      className,
      size = 'medium',
      theme = 'gray',
      id,
      ...inputProps
    } = props
    const inputId = id || `checkbox-${randomNumber}`
    return (
      <label
        htmlFor={inputId}
        className={cn(
          `form-checkbox form-checkbox--${size} form-checkbox--${theme}`,
          {
            [`${className}`]: !!className,
            'form-checkbox--disabled': inputProps.disabled,
            'form-checkbox--checked': inputProps.checked,
          }
        )}
      >
        <div className="form-checkbox__wrapper">
          <input
            type="checkbox"
            className="form-checkbox__input"
            id={inputId}
            ref={ref}
            {...inputProps}
          />
          <CheckIcon className="form-checkbox__check-icon" />
        </div>
      </label>
    )
  }
)

export default Checkbox
