import { actionTypes } from '../username/username.actions'
import { getDefaultState, setFailState } from '../state/state.model'
import { AnyAction, Reducer } from 'redux'
import { UsernameState } from './username.model'

export const initialUsernameState: UsernameState = {
  username: null,
  isChangeable: false,
  hasUsernameChanged: false,
  isSubmitting: false,
  ...getDefaultState(),
}

export const usernameReducer: Reducer<UsernameState> = (
  state: UsernameState = initialUsernameState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.USERNAME_REQUEST:
      return {
        ...state,
        ...getDefaultState(),
        hasUsernameChanged: false,
        isLoading: true,
      }
    case actionTypes.USERNAME_REQUEST_SUCCESS:
      return { ...state, ...action.payload, isLoading: false }
    case actionTypes.USERNAME_REQUEST_FAILURE:
      return { ...state, ...setFailState(action.payload) }
    case actionTypes.USERNAME_CHANGE:
      return {
        ...state,
        ...getDefaultState(),
        hasUsernameChanged: false,
        isSubmitting: true,
      }
    case actionTypes.USERNAME_CHANGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        hasUsernameChanged: true,
        isSubmitting: false,
      }
    case actionTypes.USERNAME_RESET_SUCCESS:
      return { ...state, hasUsernameChanged: false, isSubmitting: false }
    case actionTypes.USERNAME_CHANGE_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload),
        hasUsernameChanged: false,
        isSubmitting: false,
      }
    case actionTypes.USERNAME_CLEAR_STATE:
      return { ...initialUsernameState }
    default:
      return state
  }
}
