import React from 'react'
import Alert from 'react-s-alert'
import { Notification } from '../notification/Notification'

const SiteAlert = (props: any) => (
  <Notification
    backgroundColor="white"
    type={props.condition}
    onClose={() => Alert.close(props.id)}
  >
    {props.message}
  </Notification>
)

export default SiteAlert
