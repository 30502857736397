import { Notification } from '../../components/notification/Notification'
import Link from '../../components/clickable/link/Link'
import {
  RetirementProjectorAboutYouInputs,
  RetirementProjectorResponse,
  RetirementProjectorState,
  RetirementProjectorStep,
  createInitialState,
} from './RetirementProjector.types'
import { useEffect, useState } from 'react'
import { RetirementProjectorAboutYou } from './RetirementProjectorAboutYou'
import { RetirementProjectorResults } from './RetirementProjectorResults'
import { useContentfulProduct } from '../../api/contentful/useContentfulProduct'
import { Account } from '../../redux/accounts/accounts.model'
import { UserState } from '../../redux/user/user.model'
import { postRetirementProjection } from '../../api/advice/advice-api'

import './RetirementProjector.scss'

type RetirementProjectorProps = {
  account: Account
  user: UserState
  authToken: string
}

export const RetirementProjector = (props: RetirementProjectorProps) => {
  const { account, user, authToken } = props
  const [retirementProjectorStep, setRetirementProjectorStep] = useState<
    RetirementProjectorStep
  >(RetirementProjectorStep.ABOUT_YOU)

  const { contentfulEntry: contentfulProduct } = useContentfulProduct(
    account?.productExternalName
  )

  const activeFunds = (contentfulProduct?.funds || []).filter(
    (fund) => !fund.legacy
  )

  const [projectionState, setProjectionState] = useState<
    RetirementProjectorState
  >(createInitialState(user, account))

  const handleSubmitAboutYou = (
    aboutYouInputs: RetirementProjectorAboutYouInputs
  ) => {
    setProjectionState({
      ...projectionState,
      aboutYou: aboutYouInputs,
    })
    setRetirementProjectorStep(RetirementProjectorStep.RESULTS)
  }

  const handleBackToAboutYou = () =>
    setRetirementProjectorStep(RetirementProjectorStep.ABOUT_YOU)

  useEffect(() => {
    postRetirementProjection(
      projectionState,
      'Incomplete',
      user,
      authToken
    ).then((response: RetirementProjectorResponse) => {
      setProjectionState({
        ...projectionState,
        dalId: response.dalId,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderStep = () => {
    switch (retirementProjectorStep) {
      case RetirementProjectorStep.ABOUT_YOU:
        return (
          <RetirementProjectorAboutYou
            activeFunds={activeFunds}
            currentAccount={account}
            handleSubmit={handleSubmitAboutYou}
            contentfulProduct={contentfulProduct}
            state={projectionState}
          />
        )
      case RetirementProjectorStep.RESULTS:
        return (
          <RetirementProjectorResults
            activeFunds={activeFunds}
            currentAccount={account}
            state={projectionState}
            setState={setProjectionState}
            handleBackToAboutYou={handleBackToAboutYou}
            authToken={authToken}
            contentfulProduct={contentfulProduct}
          />
        )
    }
  }

  return (
    <div className="retirement-projector__paper">
      <h6 className="mb-md color-dusk">Retirement projector</h6>
      {renderStep()}
      <Notification>
        <p className="text-bold mb-xs">Our financial advice services</p>
        <p className="mt-xs">
          Please read the{' '}
          <Link
            to="https://assets.fisherfunds.co.nz/ff-advice-disclosure"
            target="_blank"
            inline
          >
            Fisher Funds' Advice Disclosure{' '}
          </Link>{' '}
          to find out about our financial advice services
        </p>
      </Notification>
    </div>
  )
}
