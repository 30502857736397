import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MuiLink from '@material-ui/core/Link'
import { Link } from 'react-router'
import MenuIcon from '@material-ui/icons/Menu'
import BirdLogo from '../../assets/images/fisher-funds-logo-bird.svg'
import AccountsTabs from '../../components/header-accounts-tabs/HeaderAccountsTabs'
import ProfileQuickActions from '../../components/profile-quick-actions/ProfileQuickActions'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { bindActionCreators, Dispatch } from 'redux'
import Button from '@material-ui/core/Button'
import {
  LayoutChangeActiveAccountTab,
  LayoutToggleSidebar,
} from '../../redux/layout/layout.actions'
import './AccountsHeader.scss'

type AccountsHeaderProps = {
  activeAccountTab: string
  setActiveAccountTab: (account: string) => void
  toggleSidebar: (show?: boolean) => void
}

export const AccountsHeader = ({
  activeAccountTab,
  setActiveAccountTab,
  toggleSidebar,
}: AccountsHeaderProps) => (
  <AppBar position="static" className="accounts-header">
    <Toolbar disableGutters>
      <MuiLink
        component={Link}
        title="Online Fisher Funds"
        to="/accounts"
        className="logo-link"
      >
        <img src={BirdLogo} alt="Fisher Funds" className="logo" />
      </MuiLink>
      <AccountsTabs
        activeAccountTab={activeAccountTab}
        setActiveAccountTab={setActiveAccountTab}
      />
      <ProfileQuickActions />
      <Button
        className="menu-toggle"
        disableRipple
        onClick={() => toggleSidebar()}
      >
        <MenuIcon className="menu-icon" />
      </Button>
    </Toolbar>
  </AppBar>
)

const mapStateToProps = (state: AppState) => ({
  activeAccountTab: state.layout.activeAccountTab,
})

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  toggleSidebar: bindActionCreators(LayoutToggleSidebar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountsHeader)
