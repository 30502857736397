import * as React from 'react'

import TextField from '@material-ui/core/TextField'
import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import RegisterLoginLinkBox from '../../components/register-login-link-box/RegisterLoginLinkBox'
import Button from '@material-ui/core/Button'

// @ts-ignore
import { useForm } from 'react-hook-form'

export type FormValues = {
  confirmCode: string
}

interface ResetPasswordConfirmProps {
  isLoading: boolean
  submit: (submittedValues: FormValues) => void
  setTitle: (title: string) => void
}

export default function ResetPasswordConfirm(props: ResetPasswordConfirmProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { isLoading, submit } = props

  const onSubmitEvent = (data: any) => {
    if (!data.confirmCode) {
      return
    }

    submit({
      confirmCode: data.confirmCode.trim(),
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitEvent)} className="form">
        <Typography variant="h6" className="mt-sm">
          Please enter the code we have sent you. If you do not receive it,
          please{' '}
          <MuiLink
            href="https://fisherfunds.co.nz/contact/"
            underline="always"
            rel="noopener noreferrer"
          >
            contact us
          </MuiLink>
          .
        </Typography>
        <div className="fieldGroup login-field-group mb-md">
          <Typography component="p" align="left">
            Confirmation code
          </Typography>
          <TextField
            name="confirmCode"
            id="confirmCode"
            type="tel"
            variant="outlined"
            margin="normal"
            fullWidth
            disabled={isLoading}
            {...{
              ...register('confirmCode', { required: true }),
              ref: undefined,
              inputRef: register('confirmCode', { required: true }).ref,
            }}
            helperText={
              errors && errors.confirmCode
                ? 'Confirmation code is a required field'
                : ''
            }
            error={errors && errors.confirmCode ? true : false}
          />
        </div>
        <div className="action-group">
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Reset password
          </Button>
        </div>
      </form>
      <RegisterLoginLinkBox type="login" />
    </>
  )
}
