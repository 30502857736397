import './Complete.scss'

import { AppState, useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@material-ui/core'
import { bindActionCreators, Dispatch } from 'redux'
import {
  OnboardingActions,
  OnboardingClearCredentials,
} from '../../../redux/onboarding/onboarding.actions'
import { connect } from 'react-redux'

interface OnbopardingCompleteProps {
  clearOnboardingCredentials: typeof OnboardingClearCredentials
}

export const OnboardingComplete = (props: OnbopardingCompleteProps) => {
  const { loginWithRedirect } = useAuth0()

  props.clearOnboardingCredentials()

  const handleLoginAgain = async () => {
    await loginWithRedirect({
      prompt: 'login',
    })
  }

  return (
    <div className="action-group welcome-onboarding">
      <Typography variant="h6">
        Your onboarding is now complete. Please click the login button below to
        access your account.
        <Box mt={4}>
          <Button
            disableRipple
            variant="contained"
            color="primary"
            onClick={handleLoginAgain}
          >
            Login
          </Button>
        </Box>
      </Typography>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {}
}

const mapDispatchToProps = (dispatch: Dispatch<OnboardingActions>) => ({
  clearOnboardingCredentials: bindActionCreators(
    OnboardingClearCredentials,
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(OnboardingComplete)
