import React, { ReactNode, useState } from 'react'
import cn from 'classnames'
import { Tooltip } from '.'
import './Tooltip.scss'
import './InputWrapper.scss'
import { IconMdInformationCircle } from '../iconography'

export type InputWrapperLabelSize = 'sm' | 'md'

export type InputWrapperProps = {
  label?: ReactNode
  error?: string
  showErrorLabel?: boolean
  tooltip?: ReactNode
  tooltipLabel?: ReactNode
  children?: ReactNode
  className?: string
  action?: ReactNode
  labelSize?: InputWrapperLabelSize
  htmlFor?: string
  hidden?: boolean
}

export const InputWrapper = (props: InputWrapperProps) => {
  const {
    label,
    error,
    showErrorLabel = true,
    tooltip,
    tooltipLabel,
    children,
    labelSize = 'md',
    className = '',
    action,
    htmlFor,
    hidden,
    ...rest
  } = props
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  return (
    <span
      className={cn('input-wrapper__wrapper', {
        'input-wrapper__hidden': hidden,
        [`${className}`]: className,
      })}
      {...rest}
    >
      {label && (
        <div className="input-wrapper__label">
          <label
            className={`input-wrapper__label__text input-wrapper__label__text-${labelSize}`}
            htmlFor={htmlFor}
          >
            {label}
          </label>
          {tooltip && (
            <span className="input-wrapper__tooltip-icon input-tooltip__parent ">
              <span className="input-wrapper__tooltip-label">
                {tooltipLabel}
              </span>
              <Tooltip
                direction="top"
                label={tooltip}
                referenceElement={referenceElement}
              />
              <span ref={setReferenceElement}>
                <IconMdInformationCircle
                  className="input-wrapper__label__icon"
                  size="sm"
                />
              </span>
            </span>
          )}
          {action}
        </div>
      )}
      {children}
      {showErrorLabel && error && (
        <div className="input-wrapper__error-label">{error}</div>
      )}
    </span>
  )
}
