import * as React from 'react'

import {
  Transaction,
  TransactionFilters,
} from '../../redux/account-transactions/account-transactions.model'
import DateFilters from '../../components/date-filters/DateFilters'

import CSVDownload from '../csv-download/CSVDownload'
import PDFDownload from '../pdf-download/PDFDownload'

import { Moment } from 'moment'
import { isApexProductAccount } from '../../common/accounts-helper'

interface TransactionFiltersProps {
  filters: TransactionFilters
  productName: string
  accountNumber: string
  transactions: Transaction[]
  updateFilters: (filters: TransactionFilters) => void
  productExternalRef: string
}

interface AccountTransactionFilterState {
  selectedType: string
  focusedInput: any
}

export class AccountTransactionFilter extends React.Component<
  TransactionFiltersProps,
  AccountTransactionFilterState
> {
  constructor(props: TransactionFiltersProps) {
    super(props)
    this.onPeriodChange = this.onPeriodChange.bind(this)
    this.onCustomDateChanges = this.onCustomDateChanges.bind(this)

    // selected state
    this.state = {
      selectedType: 'all',
      focusedInput: null,
    }
  }

  onPeriodChange(dateType: string) {
    let filters = this.props.filters
    filters['dateType'] = dateType
    this.props.updateFilters(filters)

    // odd
    this.setState({
      selectedType: dateType,
    })

    return true
  }

  getAvailableDate() {
    return [
      {
        id: 'all',
        label: 'All time',
      },
      {
        id: 'last-3-months',
        label: 'Last 3 months',
      },
      {
        id: 'last-6-months',
        label: 'Last 6 months',
      },
      {
        id: 'last-12-months',
        label: 'Last 12 months',
      },
      {
        id: 'custom',
        label: 'Custom dates',
      },
    ]
  }

  onCustomDateChanges(startDate: Moment, endDate: Moment) {
    let filters = this.props.filters
    filters['startDate'] = startDate
    filters['endDate'] = endDate
    this.props.updateFilters(filters)

    return true
  }

  render() {
    return (
      <div className="transactions-filter">
        <DateFilters
          onChange={this.onPeriodChange}
          onCustomDateChanges={this.onCustomDateChanges}
          availableDates={this.getAvailableDate()}
          filters={this.props.filters}
        />
        <div className="download-buttons">
          <CSVDownload
            productName={this.props.productName}
            accountNumber={this.props.accountNumber}
            transactions={this.props.transactions}
            filters={this.props.filters}
          />

          {!isApexProductAccount(this.props.productExternalRef) && (
            <PDFDownload
              productName={this.props.productName}
              accountNumber={this.props.accountNumber}
            />
          )}
        </div>
      </div>
    )
  }
}
