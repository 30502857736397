import { useState, useEffect } from 'react'
import moment from 'moment'

import useAsync from '../../hooks/use-async'
import getTransactionApi from '../../api/accounts/transactions'
import deserializeTransactions from '../../deserializers/account/transactions'

import Steps, { StepName } from './FirstHomeStepsModal.constants'
import { YesNo } from './FirstHomeStepsModal.types'

type Response = {
  stepName: StepName
  answer: YesNo
}

export const useSteps = (initialStep: StepName) => {
  const [stepName, setStepName] = useState(initialStep)
  const [responses, setResponses] = useState<Response[]>([])

  const addResponse = (response: Response) => {
    setResponses(responses.concat(response))

    const { stepName, answer } = response
    const { nextStep } = Steps[stepName][answer]
    setStepName(nextStep)
  }

  const step = Steps[stepName]

  return { step, stepName, setStepName, addResponse, responses }
}

export function useTransactions(
  userId: string,
  accountNumber: string,
  authToken: string
) {
  const { status, data, run } = useAsync({})

  useEffect(() => {
    if (!userId || !accountNumber) return
    const api = getTransactionApi(authToken)
    const params = {
      userId,
      accountNumber,
      data: {
        filters: {
          dateType: 'custom',
          startDate: moment('1990-01-01').format('YYYY-MM-DD'),
          endDate: moment().subtract(3, 'years').format('YYYY-MM-DD'),
        },
      },
    }

    run(api.getTransactions(params))
  }, [accountNumber, userId, authToken, run])

  const transactions = data ? deserializeTransactions(data.data) : []

  return { status, data: transactions }
}
