import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'

import * as actions from './pir.actions'
import { PirRateState } from './pir.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type LightStore = { getState: Function; dispatch: Function }

/**
 * Get the current PIR rate from the API
 */
export const getUserPirRateEpic: Epic<actions.PirRateActions, PirRateState> = (
  action$: ActionsObservable<actions.PIRUserRateRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.PIR_USER_RATE_REQUEST)
    .switchMap((action) =>
      ajax
        .get(`${ffmAppServerUrl}/api/users/${action.payload.uuid}/pir`, {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        })
        .map((response) =>
          actions.PIRUserRateRequestSuccessAction(response.response)
        )
        .catch((err) =>
          Observable.of(
            actions.PIRUseRateRequestFailureAction('PIR rate request failed')
          )
        )
    )

/**
 * Save the new PIR rate to the API
 */
export const savePirRateEpic: Epic<actions.PirRateActions, PirRateState> = (
  action$: ActionsObservable<actions.PIRUserRateSaveActionType>,
  store: LightStore
) =>
  action$.ofType(actions.actionTypes.PIR_USER_RATE_SAVE).switchMap((action) =>
    ajax
      .put(
        `${ffmAppServerUrl}/api/users/${action.payload.uuid}/pir`,
        {
          ...action.payload,
        },
        {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        }
      )
      .map((response) =>
        actions.PIRUserRateSaveSuccessAction(action.payload.pirRate)
      )
      .catch((err) =>
        Observable.of(
          actions.PIRUserRateSaveFailureAction('PIR rate save failed')
        )
      )
  )
