import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'

import * as actions from './onboarding.actions'
import { OnboardingState } from './onboarding.model'
import { UserCredentials } from './onboarding.actions'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

const fallbackErrorMessage =
  'Something went wrong. Please try again or <a href="http://fisherfunds.co.nz/contact/">contact us</a> if the problem persists.'

export const confirmDOBEpic: Epic<
  actions.OnboardingActions,
  OnboardingState
> = (action$: ActionsObservable<actions.OnboardingConfirmDobActionType>) =>
  action$
    .ofType(actions.actionTypes.ONBOARDING_CONFIRM_DOB)
    .switchMap((action) =>
      ajax
        .post(
          ffmAppServerUrl +
            '/api/onboarding/verification/dateofbirth?q=' +
            action.payload.hash,
          {
            dateOfBirth: action.payload.dateOfBirth,
          }
        )
        .map((response) =>
          actions.OnboardingConfirmDobSuccessAction(action.payload.dateOfBirth)
        )
        .catch((err, caught) => {
          const message =
            err.response && err.response.message
              ? err.response.message
              : fallbackErrorMessage
          return Observable.of(
            actions.OnboardingConfirmDobFailureAction(message)
          )
        })
    )

export const checkUsernameEpic: Epic<
  actions.OnboardingActions,
  OnboardingState
> = (action$: ActionsObservable<actions.OnboardingCheckUsernameActionType>) =>
  action$
    .ofType(actions.actionTypes.ONBOARDING_CHECK_USERNAME)
    .switchMap((action) =>
      ajax
        .post(
          ffmAppServerUrl +
            '/api/onboarding/verification/username?q=' +
            action.payload.hash,
          {
            username: action.payload.username,
          }
        )
        .map((response) =>
          actions.OnboardingCheckUsernameSuccessAction(action.payload.username)
        )
        .catch((err, caught) => {
          const message =
            err.response && err.response.message
              ? err.response.message
              : fallbackErrorMessage
          return Observable.of(
            actions.OnboardingCheckUsernameFailureAction(message)
          )
        })
    )

export const createCredentialsEpic: Epic<
  actions.OnboardingActions,
  OnboardingState
> = (
  action$: ActionsObservable<actions.OnboardingCreateCredentialsActionType>
) =>
  action$
    .ofType(actions.actionTypes.ONBOARDING_CREATE_CREDENTIALS)
    .switchMap((action) => {
      let payload: UserCredentials = {
        dateOfBirth: action.payload.dateOfBirth,
        username: action.payload.username,
        password: action.payload.password,
      }
      return ajax
        .patch(
          ffmAppServerUrl + '/api/onboarding?q=' + action.payload.hash,
          payload
        )
        .map((response) => actions.OnboardingCreateCredentialsSuccess(payload))
        .catch((err, caught) => {
          const message =
            err.response && err.response.message
              ? err.response.message
              : fallbackErrorMessage
          if (err.status < 500) {
            return Observable.of(
              actions.OnboardingCreateCredentialsFailure(message)
            )
          } else {
            return Observable.of(
              actions.OnboardingCreateCredentialsFailure(fallbackErrorMessage)
            )
          }
        })
    })

export const onboardingEpic: Epic<
  actions.OnboardingActions,
  OnboardingState
> = (action$: ActionsObservable<actions.OnboardingRequestActionType>) =>
  action$.ofType(actions.actionTypes.ONBOARDING_REQUEST).switchMap((action) => {
    return ajax
      .get(ffmAppServerUrl + '/api/onboarding?q=' + action.payload)
      .map((response) => actions.OnboardingRequestSuccess(action.payload))
      .catch((err, caught) => {
        const message =
          err.response && err.response.message
            ? err.response.message
            : fallbackErrorMessage
        return Observable.of(actions.OnboardingRequestFailure(message))
      })
  })

export const onboardingSearchEpic: Epic<
  actions.OnboardingActions,
  OnboardingState
> = (action$: ActionsObservable<actions.OnboardingSearchActionType>) =>
  action$.ofType(actions.actionTypes.ONBOARDING_SEARCH).switchMap((action) => {
    return ajax
      .post(ffmAppServerUrl + '/api/onboarding/registration', {
        surname: action.surname.trim(),
        firstname: action.firstName.trim(),
        email: action.email.trim(),
        dateOfBirth: action.dob.trim(),
      })
      .map((response) => actions.OnboardingSearchSuccess())
      .catch((err, caught) => {
        if (typeof err === 'object' && err.hasOwnProperty('status')) {
          if (err.status >= 400 && err.status < 500) {
            return Observable.of(
              actions.OnboardingSearchFailure({
                errorStatus: err.status,
              })
            )
          }
          return Observable.of(
            actions.OnboardingSearchFailure({
              errorMessage: fallbackErrorMessage,
            })
          )
        }
        return Observable.of(
          actions.OnboardingSearchFailure({
            errorMessage: 'Search request failed',
          })
        )
      })
  })
