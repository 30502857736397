import React from 'react'
import { Link } from 'react-router'

import MuiLink from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from '../../components/contentful-terms-and-conditions'

export const LoginLink = () => {
  return (
    <MuiLink
      underline="always"
      component={Link}
      to="login"
      title="Back to login"
      className="login-link"
    >
      Back to login
    </MuiLink>
  )
}

export const FooterLinks = (props: { isFullWidth: boolean }) => {
  return (
    <>
      <Typography
        component="p"
        variant="body1"
        align="center"
        className="footer-links"
      >
        {props.isFullWidth ? (
          <>
            <LoginLink />
            <br />
            <br />
          </>
        ) : null}
        <TermsAndPrivacyLinks />
      </Typography>
    </>
  )
}

const TermsAndPrivacyLinks = () => {
  return (
    <>
      <TermsAndConditionsLink />
      &nbsp;|&nbsp;
      <PrivacyPolicyLink />
    </>
  )
}
