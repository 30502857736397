import { Address, PhoneEmailDetails } from '../../common/addresses.types'
import { SavingStatus } from '../../components/contact-details-form/ContactDetailsForm'

export enum actionTypes {
  // Change password
  USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS',
  USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE',
  USER_CHANGE_PASSWORD_RESET = 'USER_CHANGE_PASSWORD_RESET',
  // Forgot password / PIN
  USER_FP_INIT = 'USER_FP_INIT',
  USER_SET_FP_CREDENTIALS = 'USER_SET_FP_CREDENTIALS',
  USER_REQUEST_CONFIRM_CODE = 'USER_REQUEST_CONFIRM_CODE',
  USER_REQUEST_CONFIRM_CODE_SUCCESS = 'USER_REQUEST_CONFIRM_CODE_SUCCESS',
  USER_REQUEST_CONFIRM_CODE_FAILURE = 'USER_REQUEST_CONFIRM_CODE_FAILURE',
  USERNAME_CONFIRMATION = 'USERNAME_CONFIRMATION',
  USERNAME_CONFIRMATION_SUCCESS = 'USERNAME_CONFIRMATION_SUCCESS',
  USERNAME_CONFIRMATION_FAILURE = 'USERNAME_CONFIRMATION_FAILURE',
  // Reset Password / PIN (second part of forgot password / PIN)
  USER_RP_INIT = 'USER_RP_INIT',
  USER_CHECK_CONFIRM_CODE = 'USER_CHECK_CONFIRM_CODE',
  USER_CHECK_CONFIRM_CODE_SUCCESS = 'USER_CHECK_CONFIRM_CODE_SUCCESS',
  USER_CHECK_CONFIRM_CODE_FAILURE = 'USER_CHECK_CONFIRM_CODE_FAILURE',
  USER_RESET = 'USER_RESET',
  USER_RESET_SUCCESS = 'USER_RESET_SUCCESS',
  USER_RESET_FAILURE = 'USER_RESET_FAILURE',
  // Log in - TODO: refactor out, use authorisation action instead
  USER_AUTHORISATION_REQUEST_SUCCESS = 'USER_AUTHORISATION_REQUEST_SUCCESS',
  // Get user details
  USER_REQUEST_DETAILS = 'USER_REQUEST_DETAILS',
  USER_REQUEST_DETAILS_SUCCESS = 'USER_REQUEST_DETAILS_SUCCESS',
  USER_REQUEST_DETAILS_FAILURE = 'USER_REQUEST_DETAILS_FAILURE',
  // Update user details
  USER_UPDATE_DETAILS = 'USER_UPDATE_DETAILS',
  USER_UPDATE_DETAILS_SUCCESS = 'USER_UPDATE_DETAILS_SUCCESS',
  USER_UPDATE_DETAILS_FAILURE = 'USER_UPDATE_DETAILS_FAILURE',
  USER_UPDATE_SAVING_STATUS = 'USER_UPDATE_SAVING_STATUS',
  // Update User Address upon retrieval from Experian
  USER_ADDRESS_UPDATE = 'USER_ADDRESS_UPDATE',
}

//////////     Change password

export type UserChangePasswordCredentials = {
  userid: string
  currentPassword: string
  newPassword: string
}
export type UserChangePasswordActionType = {
  type: actionTypes.USER_CHANGE_PASSWORD
  payload: UserChangePasswordCredentials
}
export const UserChangePasswordAction = (
  payload: UserChangePasswordCredentials
): UserChangePasswordActionType => ({
  type: actionTypes.USER_CHANGE_PASSWORD,
  payload,
})

export type UserChangePasswordSuccessActionType = {
  type: actionTypes.USER_CHANGE_PASSWORD_SUCCESS
}
export const UserChangePasswordSuccessAction = (): UserChangePasswordSuccessActionType => ({
  type: actionTypes.USER_CHANGE_PASSWORD_SUCCESS,
})

export type UserChangePasswordResetActionType = {
  type: actionTypes.USER_CHANGE_PASSWORD_RESET
}
export const UserChangePasswordResetAction = (): UserChangePasswordResetActionType => ({
  type: actionTypes.USER_CHANGE_PASSWORD_RESET,
})

export type UserChangePasswordFailureActionType = {
  type: actionTypes.USER_CHANGE_PASSWORD_FAILURE
  payload: string
}
export const UserChangePasswordFailureAction = (
  message: string
): UserChangePasswordFailureActionType => ({
  type: actionTypes.USER_CHANGE_PASSWORD_FAILURE,
  payload: message,
})

// Start a new Forgot Password / PIN process

export type UserInitForgotProcessType = { type: actionTypes.USER_FP_INIT }
export const UserInitForgotProcess = (): UserInitForgotProcessType => ({
  type: actionTypes.USER_FP_INIT,
})

// Forgot Password / PIN - Username / DOB screen

export type UserForgotPasswordCredentials = { username: string; dob: string }
export type UserSetForgotPasswordCredentialsActionType = {
  type: actionTypes.USER_SET_FP_CREDENTIALS
  payload: UserForgotPasswordCredentials
}
export const UserSetForgotPasswordCredentials = (
  payload: UserForgotPasswordCredentials
): UserSetForgotPasswordCredentialsActionType => ({
  type: actionTypes.USER_SET_FP_CREDENTIALS,
  payload,
})

// Forgot Password / PIN - Create a new confirmation code, send email / SMS

export type UserRequestConfirmCodeCredentials = {
  username: string
  method: 'email' | 'sms'
}
export type UserRequestConfirmCodeActionType = {
  type: actionTypes.USER_REQUEST_CONFIRM_CODE
  payload: UserRequestConfirmCodeCredentials
}
export const UserRequestConfirmCodeAction = (
  payload: UserRequestConfirmCodeCredentials
): UserRequestConfirmCodeActionType => ({
  type: actionTypes.USER_REQUEST_CONFIRM_CODE,
  payload,
})

export type UserRequestConfirmCodeSuccessActionType = {
  type: actionTypes.USER_REQUEST_CONFIRM_CODE_SUCCESS
  payload: { id: string; type: string }
}
export const UserRequestConfirmCodeSuccessAction = (
  id: string,
  type: string
): UserRequestConfirmCodeSuccessActionType => ({
  type: actionTypes.USER_REQUEST_CONFIRM_CODE_SUCCESS,
  payload: {
    id,
    type,
  },
})

export type UsernameConfirmationActionType = {
  type: actionTypes.USERNAME_CONFIRMATION
  payload: UserRequestConfirmCodeCredentials
}
export const UsernameConfirmationAction = (
  payload: UserRequestConfirmCodeCredentials
): UsernameConfirmationActionType => ({
  type: actionTypes.USERNAME_CONFIRMATION,
  payload,
})

export type UsernameConfirmationSuccessActionType = {
  type: actionTypes.USERNAME_CONFIRMATION_SUCCESS
  mobilePhoneNumber: string
}
export const UsernameConfirmationSuccessAction = (
  mobilePhoneNumber: string
): UsernameConfirmationSuccessActionType => ({
  type: actionTypes.USERNAME_CONFIRMATION_SUCCESS,
  mobilePhoneNumber,
})

export type UsernameConfirmationFailureActionType = {
  type: actionTypes.USERNAME_CONFIRMATION_FAILURE
  payload: string
}
export const UsernameConfirmationFailureAction = (
  message: string
): UsernameConfirmationFailureActionType => ({
  type: actionTypes.USERNAME_CONFIRMATION_FAILURE,
  payload: message,
})

export type UserRequestConfirmCodeFailureActionType = {
  type: actionTypes.USER_REQUEST_CONFIRM_CODE_FAILURE
  payload: string
}
export const UserRequestConfirmCodeFailureAction = (
  message: string
): UserRequestConfirmCodeFailureActionType => ({
  type: actionTypes.USER_REQUEST_CONFIRM_CODE_FAILURE,
  payload: message,
})

// Forgot Password / PIN - Start a new code check process

export type UserInitResetProcessType = { type: actionTypes.USER_RP_INIT }
export const UserInitResetProcess = (): UserInitResetProcessType => ({
  type: actionTypes.USER_RP_INIT,
})

// Forgot Password / PIN - Check a user's confirmation code from SMS / email

export type UserCheckConfirmCodeCredentials = {
  id: string
  confirmCode: string
}
export type UserCheckConfirmCodeActionType = {
  type: actionTypes.USER_CHECK_CONFIRM_CODE
  payload: UserCheckConfirmCodeCredentials
}
export const UserCheckConfirmCodeAction = (
  payload: UserCheckConfirmCodeCredentials
): UserCheckConfirmCodeActionType => ({
  type: actionTypes.USER_CHECK_CONFIRM_CODE,
  payload,
})

export type UserCheckConfirmCodeSuccessActionType = {
  type: actionTypes.USER_CHECK_CONFIRM_CODE_SUCCESS
  payload: { username: string }
}
export const UserCheckConfirmCodeSuccessAction = (
  username: string
): UserCheckConfirmCodeSuccessActionType => ({
  type: actionTypes.USER_CHECK_CONFIRM_CODE_SUCCESS,
  payload: { username },
})

export type UserCheckConfirmCodeFailureActionType = {
  type: actionTypes.USER_CHECK_CONFIRM_CODE_FAILURE
  payload: { message: string }
}
export const UserCheckConfirmCodeFailureAction = (
  message: string
): UserCheckConfirmCodeFailureActionType => ({
  type: actionTypes.USER_CHECK_CONFIRM_CODE_FAILURE,
  payload: {
    message,
  },
})

// Forgot Password - Actually reset a user's password or PIN using a confirmation code

export type UserResetCredentials = {
  id: string
  confirmCode: string
  newValue: string
}
export type UserResetActionType = {
  type: actionTypes.USER_RESET
  payload: UserResetCredentials
}
export const UserResetAction = (
  payload: UserResetCredentials
): UserResetActionType => ({
  type: actionTypes.USER_RESET,
  payload,
})

export type UserResetSuccessActionType = {
  type: actionTypes.USER_RESET_SUCCESS
}
export const UserResetSuccessAction = (): UserResetSuccessActionType => ({
  type: actionTypes.USER_RESET_SUCCESS,
})

export type UserResetFailureActionType = {
  type: actionTypes.USER_RESET_FAILURE
  payload: { message: string }
}
export const UserResetFailureAction = (
  message: string
): UserResetFailureActionType => ({
  type: actionTypes.USER_RESET_FAILURE,
  payload: {
    message,
  },
})

// Log a user in (TODO: refactor out, use authorisation action instead)

export type UserAuthorisationSuccessActionType = {
  type: actionTypes.USER_AUTHORISATION_REQUEST_SUCCESS
  payload: string
}
export const UserAuthorisationSuccessAction = (
  uuid: string
): UserAuthorisationSuccessActionType => ({
  type: actionTypes.USER_AUTHORISATION_REQUEST_SUCCESS,
  payload: uuid,
})

// Get user's details

export type UserDetailsType = {
  ffmUserId: string
  surname: string
  firstName: string
  secondName: string
  birthDate: string
  email: string
  businessPhoneNumber: string
  homePhoneNumber: string
  mobilePhoneNumber: string
  ird: string
  firstHomeCalcURL?: string
  retirementCalcURL?: string
}
export type UserRequestDetailsActionType = {
  type: actionTypes.USER_REQUEST_DETAILS
  payload: string
}
export const UserRequestDetailsAction = (
  uuid: string
): UserRequestDetailsActionType => ({
  type: actionTypes.USER_REQUEST_DETAILS,
  payload: uuid,
})

export type UserRequestDetailsSuccessActionType = {
  type: actionTypes.USER_REQUEST_DETAILS_SUCCESS
  payload: UserDetailsType
}
export const UserRequestDetailsSuccessAction = (
  payload: UserDetailsType
): UserRequestDetailsSuccessActionType => ({
  type: actionTypes.USER_REQUEST_DETAILS_SUCCESS,
  payload,
})

export type UserRequestDetailsFailureActionType = {
  type: actionTypes.USER_REQUEST_DETAILS_FAILURE
  payload: string
}
export const UserRequestDetailsFailureAction = (
  message: string
): UserRequestDetailsFailureActionType => ({
  type: actionTypes.USER_REQUEST_DETAILS_FAILURE,
  payload: message,
})

// Update user's details
export type UserContactDetailsType = PhoneEmailDetails &
  Address &
  ({ password: string } | { pin: string })

export type UserUpdateDetailsActionType = {
  type: actionTypes.USER_UPDATE_DETAILS
  payload: UserContactDetailsType
  ffmUserId: string
}
export const UserUpdateDetailsAction = (
  payload: UserContactDetailsType,
  ffmUserId: string
): UserUpdateDetailsActionType => ({
  type: actionTypes.USER_UPDATE_DETAILS,
  payload,
  ffmUserId,
})

export type UserUpdateDetailsSuccessActionType = {
  type: actionTypes.USER_UPDATE_DETAILS_SUCCESS
  payload: UserContactDetailsType
}
export const UserUpdateDetailsSuccessAction = (
  payload: UserContactDetailsType
): UserUpdateDetailsSuccessActionType => ({
  type: actionTypes.USER_UPDATE_DETAILS_SUCCESS,
  payload,
})

export type UserUpdateDetailsFailureActionType = {
  type: actionTypes.USER_UPDATE_DETAILS_FAILURE
  payload: string
}
export const UserUpdateDetailsFailureAction = (
  message: string
): UserUpdateDetailsFailureActionType => ({
  type: actionTypes.USER_UPDATE_DETAILS_FAILURE,
  payload: message,
})

export type UserUpdateSavingStatusType = {
  type: actionTypes.USER_UPDATE_SAVING_STATUS
  status: SavingStatus
}
export const UserUpdateSavingStatusAction = (
  status: SavingStatus
): UserUpdateSavingStatusType => ({
  type: actionTypes.USER_UPDATE_SAVING_STATUS,
  status,
})

export type UserAddressUpdateActionType = {
  type: actionTypes.USER_ADDRESS_UPDATE
  payload: Address
}
export const UserAddressUpdateAction = (
  payload: Address
): UserAddressUpdateActionType => ({
  type: actionTypes.USER_ADDRESS_UPDATE,
  payload,
})

export type UserActions =
  | UserChangePasswordActionType
  | UserChangePasswordSuccessActionType
  | UserChangePasswordFailureActionType
  | UserInitForgotProcessType
  | UserRequestConfirmCodeActionType
  | UserRequestConfirmCodeSuccessActionType
  | UserRequestConfirmCodeFailureActionType
  | UsernameConfirmationActionType
  | UsernameConfirmationSuccessActionType
  | UsernameConfirmationFailureActionType
  | UserSetForgotPasswordCredentialsActionType
  | UserInitForgotProcessType
  | UserCheckConfirmCodeActionType
  | UserCheckConfirmCodeSuccessActionType
  | UserCheckConfirmCodeFailureActionType
  | UserResetActionType
  | UserResetSuccessActionType
  | UserResetFailureActionType
  | UserAuthorisationSuccessActionType
  | UserRequestDetailsActionType
  | UserRequestDetailsSuccessActionType
  | UserRequestDetailsFailureActionType
  | UserUpdateDetailsActionType
  | UserUpdateDetailsSuccessActionType
  | UserUpdateDetailsFailureActionType
  | UserUpdateSavingStatusType
  | UserAddressUpdateActionType
