import Modal from '../modal/Modal'
import {
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
} from '../../common/accounts-helper'
import FFKPWithdrawFundsContent from './FFKPWithdrawFundsContent'
import FFIFWithdrawFundsContent from './FFIFWithdrawFundsContent'
import { Account } from '../../redux/accounts/accounts.model'

export interface WithdrawModalProps {
  account: Account
  onClose: () => void
}

const WithdrawFundsModal = ({ account, onClose }: WithdrawModalProps) => {
  const renderContent = () => {
    if (isKiwiSaverPlanAccount(account)) {
      return <FFKPWithdrawFundsContent onClose={onClose} />
    }

    if (isInvestmentFundsAccount(account)) {
      return <FFIFWithdrawFundsContent onClose={onClose} />
    }

    return null
  }

  return (
    <Modal
      open
      title="Withdraw Funds"
      subtitle={account.accountNumber}
      onClose={onClose}
      className="withdraw-funds-modal"
    >
      {renderContent()}
    </Modal>
  )
}

export default WithdrawFundsModal
