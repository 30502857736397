import {
  IndexRoute,
  Route,
  Router,
  Redirect,
  IndexRedirect,
} from 'react-router'
import App from '../App'
import NotFound from '../components/not-found/NotFound'
import withTracker from '../components/with-tracker/withTracker'
import AccountDetails from '../containers/account-details/AccountDetails'
import Accounts from '../containers/accounts/Accounts'
import InvestmentTool from '../containers/investment-tool/InvestmentTool'
import MyDetails from '../containers/my-details/MyDetails'

import Register from '../containers/register/Register'
import SSO from '../containers/sso/sso'
import Reports from '../containers/reports/Reports'
import { OnboardingRoutes } from '../routing/onboarding.routes'
import RegisterSuccess from '../containers/register-success/RegisterSuccess'

import MainTheme from '../containers/main-theme/MainTheme'
import DashboardTheme from '../containers/dashboard-theme/DashboardTheme'
import AccountInvestmentTab from '../components/account-investment-tab/AccountInvestmentTab'
import AccountPerformanceTab from '../components/account-performance-tab/AccountPerformanceTab'
import AccountDetailsSwitch from '../components/account-details-switch/AccountDetailsSwitch'
import AccountTransactionsTab from '../components/account-transactions-tab/AccountTransactionsTab'
import LoggedOut from '../components/logged-out/LoggedOut'

import ForgotPassword from '../containers/forgot-password/ForgotPassword'
import ResetPassword from '../containers/forgot-password/ResetPassword'
import ForgotUsername from '../containers/forgot-username/ForgotUsername'
import ForgotUsernameMethod from '../containers/forgot-username/ForgotUsernameMethod'
import { AonTheme } from '../containers/main-theme'
import AonRegister from '../containers/register/AonRegister'
import { history } from './history'
import AccountDetailsIPQ from '../components/account-details-ipq/AccountDetailsIPQ'
import RetirementProjectorPage from '../containers/retirement-projector/RetirementProjectorPage'
import OnlyUnauthenticated from '../containers/only-unauthenticated/OnlyUnauthenticated'

export const AppRouter = () => {
  return (
    <Router history={history}>
      <Route path="/" component={withTracker(App)}>
        <Route component={DashboardTheme}>
          <Route path="details">
            <IndexRoute component={MyDetails} />
            <Route path=":type" component={MyDetails} />
          </Route>
          <Route path="reports" component={Reports} />
          <Route path="investment-tools">
            <IndexRoute component={InvestmentTool} />
            <Route
              path="retirement-projector"
              component={RetirementProjectorPage}
            />
          </Route>
          <Route path="accounts/:id" component={AccountDetails}>
            <IndexRedirect to="performance" />
            <Route path="performance" component={AccountPerformanceTab} />
            <Route path="funds" component={AccountInvestmentTab} />
            <Route path="transactions" component={AccountTransactionsTab} />
            <Route path="switch" component={AccountDetailsSwitch} />
            <Route path="ipq" component={AccountDetailsIPQ} />
            <Redirect from="*" to="/accounts/:id" />
          </Route>
          <Route path="accounts" component={Accounts} />
        </Route>
        <Route component={AonTheme}>
          <Route path="register/welcome" component={AonRegister} />
          <Route path="register/welcome/success" component={RegisterSuccess} />
        </Route>
        <Route component={MainTheme}>
          <Route path="sso" component={SSO} />
          <Route path="login">
            <IndexRedirect to="/" />
            <Redirect from="*" to="/" />
          </Route>
          <Route path="logged-out" component={LoggedOut} />
          {OnboardingRoutes()}

          <Route path="forgot" component={OnlyUnauthenticated}>
            <IndexRoute component={NotFound} />
            <Route path="username" component={ForgotUsername} />
            <Route path="username/method" component={ForgotUsernameMethod} />
            <Route path=":action" component={ForgotPassword} />
            <Route path="confirm/:id" component={ResetPassword} />
          </Route>
          <Route path="register" component={Register} />
          <Route path="register-success" component={RegisterSuccess} />

          {/* Not found must be the last route */}
          <Route path="*" component={NotFound} />
        </Route>
        <IndexRedirect to="/accounts" />
      </Route>
    </Router>
  )
}
