import { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { Dispatch, connect } from 'react-redux'
import { Link } from 'react-router'
import { Button } from '../../components/clickable/button/Button'
import { AppState } from '../../redux/app-state'
import RetirementCalculatorLink from '../../components/retirement-calculator-link/RetirementCalculatorLink'
import FirstTimeBuyerLink from '../../components/first-time-buyer-link/FirstTimeBuyerLink'
import {
  canDisplayFirstHomeBuyerTools,
  canDisplayRetirementTool,
  getOwnKiwiSaverPlanAccount,
} from '../../common/accounts-helper'
import FirstHomeStepsModal from '../../components/first-home-steps-modal'
import {
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
} from '../../redux/layout/layout.actions'
import './InvestmentTool.scss'

export interface InvestmentToolProps {
  showRetirementTool: boolean
  showFirstHomeBuyerTools: boolean
  ownFFKPAccountId?: string
  setActiveAccountTab: (value: string) => void
  setActiveMenu: (activeMenu: string) => void
}

export function InvestmentTool(props: InvestmentToolProps) {
  const {
    showRetirementTool,
    showFirstHomeBuyerTools,
    ownFFKPAccountId,
  } = props
  const [modalOpen, setModalOpen] = useState(false)
  useEffect(() => {
    props.setActiveMenu('investment')
    props.setActiveAccountTab('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderRetirementProjectorLink = () => {
    const isRetirementProjectorEnabled =
      process.env.REACT_APP_FEATURE_TOGGLE_FFKP_RETIREMENT_PROJECTOR !== 'false'
    const showNewRetirementProjector =
      isRetirementProjectorEnabled && !!ownFFKPAccountId

    if (showNewRetirementProjector) {
      return (
        <Link
          className="investment-tool-retirement-projector-button link--button link link--filled link--primary link--md"
          to={`/investment-tools/retirement-projector?accountId=${ownFFKPAccountId}`}
        >
          Calculate my retirement
        </Link>
      )
    }

    return (
      <RetirementCalculatorLink
        activeLinkId="retirement-calculator"
        buttonColor="primary"
        buttonVariant="contained"
        popupTitle="Retirement projector"
      />
    )
  }

  return (
    <div className="investment-tools-container main-content-padding">
      <div className="grid-container">
        {showRetirementTool && (
          <div className="investment-tool-card">
            <h5>Retirement Projector</h5>
            <p className="color-text-dark">
              Check if you are on track to achieve your desired retirement
              lifestyle.
            </p>
            <div className="mt-lg">{renderRetirementProjectorLink()}</div>
          </div>
        )}

        {showFirstHomeBuyerTools && (
          <>
            <div className="investment-tool-card">
              <h5>First Home Buyer</h5>
              <p className="color-text-dark">
                See how your KiwiSaver account can help you buy your first home.
              </p>
              <div className="mt-lg">
                <FirstTimeBuyerLink
                  activeLinkId="first-time-buyer"
                  buttonColor="primary"
                  buttonVariant="contained"
                  popupTitle="First home calculator"
                />
              </div>
            </div>
            {modalOpen && (
              <FirstHomeStepsModal onClose={() => setModalOpen(false)} />
            )}

            <div className="investment-tool-card">
              <h5>First Home Buyer's Letter</h5>
              <p className="color-text-dark">
                Download a copy of your first home buyer's letter which includes
                how much you may be eligible to withdraw from your KiwiSaver
                account for your first home purchase.
              </p>
              <div className="mt-lg">
                <Button
                  color="primary"
                  variant="filled"
                  onClick={() => setModalOpen(true)}
                >
                  Get your letter
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  showRetirementTool: canDisplayRetirementTool(
    state.accounts.accounts,
    state.user
  ),
  showFirstHomeBuyerTools: canDisplayFirstHomeBuyerTools(
    state.accounts.accounts
  ),
  ownFFKPAccountId: getOwnKiwiSaverPlanAccount(state.accounts.accounts)
    ?.accountID,
})

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentTool)
