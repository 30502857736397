import { connect } from 'react-redux'

import NavigationMobile from './NavigationMobile'

import { AppState } from '../../redux/app-state'
import {
  canDisplayFirstHomeBuyerTools,
  canDisplayRetirementTool,
} from '../../common/accounts-helper'
import { StateProps } from './NavigationMobile'

const mapStateToProps = (state: AppState): StateProps => {
  return {
    showInvestmentTools:
      canDisplayRetirementTool(state.accounts.accounts, state.user) ||
      canDisplayFirstHomeBuyerTools(state.accounts.accounts),
  }
}

export default connect(mapStateToProps)(NavigationMobile)
