import React from 'react'
import './DateFilters.scss'

import { TransactionFilters } from '../../redux/account-transactions/account-transactions.model'
import CustomRangePicker from '../../components/custom-range-picker'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { Moment } from 'moment'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

interface DateFiltersProps {
  availableDates: any
  onChange: (id: string) => void
  onCustomDateChanges: (startDate: Moment, endDate: Moment) => void
  filters: TransactionFilters
}

export default function DateFilters(props: DateFiltersProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isDatePickerDisabled, setIsDatePickerDisabled] = React.useState<
    boolean
  >(true)

  const handleMenuClick = (newValue: string) => {
    props.onChange(newValue)
    setIsDatePickerDisabled(newValue !== 'custom')
    handleClose()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selections = props.availableDates.filter(
    (date: any) => date.id === props.filters.dateType
  )

  const filterLength = props.availableDates.length

  return (
    <div className="date-filters-component">
      <div className="date-filter-menu">
        <Button
          className="date-filter-menu-button"
          disableRipple
          endIcon={<ArrowDropDownIcon />}
          onClick={handleClick}
          variant="outlined"
        >
          {selections[0] ? selections[0].label : 'All time'}
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={'date-filter-menu menu-items-' + filterLength}
        >
          {props.availableDates.map((date: any, index: number) => {
            return (
              <MenuItem
                disableRipple
                onClick={() => handleMenuClick(date.id)}
                selected={props.filters.dateType === date.id}
                key={'tab-item-' + index}
              >
                {date.label}
              </MenuItem>
            )
          })}
        </Menu>
      </div>

      <CustomRangePicker
        filters={props.filters}
        isDisabled={isDatePickerDisabled}
        onCustomDateChanges={props.onCustomDateChanges}
      />
    </div>
  )
}
