import React from 'react'
import moment from 'moment'

import TextField from '@material-ui/core/TextField'
import ArrowForward from '@material-ui/icons/ArrowForward'

import { CustomDateRangeProps } from './CustomRangePicker.types'

export const MobileDatePicker = (props: CustomDateRangeProps) => {
  const onDateChange = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    event.preventDefault()
    const { name, value } = event.target

    const momentValue = moment(value)

    if (name === 'startDate') {
      props.onCustomDateChanges(momentValue, props.filters.endDate)
    } else if (name === 'endDate') {
      props.onCustomDateChanges(props.filters.startDate, momentValue)
    }
  }

  return (
    <div className="custom-range-picker-component">
      <div className="mobile-date-picker">
        <TextField
          variant="outlined"
          className="date-input"
          type="date"
          inputProps={{ max: props.filters.endDate.format('YYYY-MM-DD') }}
          name="startDate"
          onChange={onDateChange}
          value={
            props.filters.startDate
              ? props.filters.startDate.format('YYYY-MM-DD')
              : null
          }
          required
        />
        <ArrowForward />
        <TextField
          variant="outlined"
          className="date-input"
          type="date"
          name="endDate"
          inputProps={{
            min: props.filters.startDate.format('YYYY-MM-DD'),
            max: moment().format('YYYY-MM-DD'),
          }}
          onChange={onDateChange}
          value={
            props.filters.endDate
              ? props.filters.endDate.format('YYYY-MM-DD')
              : null
          }
          required
        />
      </div>
    </div>
  )
}
