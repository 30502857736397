import { uniq } from 'lodash'
import { Account } from '../../redux/accounts/accounts.model'
import { Report } from '../../redux/reports/reports.model'

export type ReportAccount = {
  accountId: string
  accountName: string
}

/**
 * Reports response has both active accounts and close accounts. Merge active accounts' name in
 * @param activeAccounts
 * @param reports
 * @returns an array of account ID and account mailing name. For closed accounts, the name is empty
 */
export const mergeInActiveAccountsToReportsAccounts = (
  reports: Report[] = [],
  activeAccounts: Account[] = []
): ReportAccount[] => {
  const activeAccountsMap = new Map(
    activeAccounts.map((account) => [
      account.accountID,
      account.accountMailingName,
    ])
  )
  // All distinct account ids from reports response, which include closed accounts
  const allAccounts = uniq(reports.map((r) => r.accountId)).map((id) => ({
    accountId: id,
    accountName: activeAccountsMap.get(id) || '',
  }))

  return allAccounts
}
