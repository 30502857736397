import React, { useState } from 'react'
import './ZeroBalanceMessage.scss'
import cn from 'classnames'
import { Account } from '../../redux/accounts/accounts.model'
import { isKiwisaverAccount } from '../../common/accounts-helper'
import { Notification } from '../notification/Notification'
import AddFundsModal from '../add-funds-modal/AddFundsModal'
import { Button } from '../clickable/button/Button'

interface ZeroBalanceMessageProps {
  account: Account
  switchRequested?: boolean
  className?: string
}

const ZeroBalanceMessage: React.FC<ZeroBalanceMessageProps> = ({
  account,
  switchRequested,
  className,
}) => {
  const { accountID, productExternalRef, productExternalName } = account

  const [addFundsModalOpen, setAddFundsModalOpen] = useState(false)

  function renderTimeFrame() {
    if (isKiwisaverAccount(account, true)) {
      return switchRequested
        ? 'If you have recently made a deposit into your account it can take up to 10 business days to be displayed here.'
        : 'If you have recently changed providers it can take up to 10 business days to be displayed here.'
    }
    return 'If you have recently made a deposit into your account it can take up to three business days to be displayed here.'
  }

  return (
    <div
      className={cn('zero-balance-message', {
        [`${className}`]: !!className,
      })}
    >
      <Notification type="warning" backgroundColor="orange">
        <p>
          Your current account balance is $0.00. To view your investment
          information you need to have funds in your account.{' '}
          <Button
            variant="link"
            onClick={() => {
              setAddFundsModalOpen(true)
            }}
          >
            Add funds here
          </Button>
          .
        </p>
        <p>{renderTimeFrame()}</p>
        {addFundsModalOpen && (
          <AddFundsModal
            accountId={accountID}
            externalRef={productExternalRef}
            productExternalName={productExternalName}
            onClose={() => setAddFundsModalOpen(false)}
          />
        )}
      </Notification>
    </div>
  )
}
export default ZeroBalanceMessage
