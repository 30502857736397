import { Reducer, AnyAction } from 'redux'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'
import moment from 'moment'

import { getDefaultState, setFailState } from '../state/state.model'
import { actionTypes } from './user.actions'
import {
  UserState,
  UserForgotPasswordState,
  UserResetPasswordState,
} from './user.model'

import { userExpiresAtConfig } from '../config'
import { SavingStatus } from '../../components/contact-details-form/ContactDetailsForm'

// User details

export const initialUserState: UserState = {
  ffmUserId: '',
  userid: '',
  clientId: '',
  hasPasswordChanged: false,
  isSaving: false,
  surname: '',
  titleCode: undefined,
  firstName: '',
  secondName: '',
  birthDate: '',
  email: '',
  businessPhoneNumber: '',
  homePhoneNumber: '',
  mobilePhoneNumber: '',
  ird: '',
  expiresAt: null,
  retirementCalcURL: '',
  firstHomeCalcURL: '',
  savingStatus: SavingStatus.NONE,
  addressLine1: '',
  addressLine2: '',
  suburb: '',
  city: '',
  country: 'NZ',
  postCode: '',
  gender: '',
  ...getDefaultState(),
}

export const userReducer: Reducer<UserState> = (
  state: UserState = initialUserState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.USER_CHANGE_PASSWORD:
      return { ...state, isLoading: true }
    case actionTypes.USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        hasPasswordChanged: true,
        isLoading: false,
        hasError: false,
        errorMessage: null,
      }
    case actionTypes.USER_CHANGE_PASSWORD_RESET:
      return {
        ...state,
        hasPasswordChanged: false,
        isLoading: false,
        hasError: false,
        errorMessage: null,
      }
    case actionTypes.USER_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        hasPasswordChanged: false,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }
    case actionTypes.USER_AUTHORISATION_REQUEST_SUCCESS:
      return {
        ...state,
        userid: action.payload,
      }
    case actionTypes.USER_REQUEST_DETAILS:
      return { ...state, isLoading: true }
    case actionTypes.USER_REQUEST_DETAILS_SUCCESS:
      const missingFields = {
        suburb: '',
        homePhoneNumber: '',
        mobilePhoneNumber: '',
        businessPhoneNumber: '',
        addressLine2: '',
      }
      let fullPayload = {
        ...missingFields,
        ...action.payload,
      }
      return {
        ...state,
        isLoading: false,
        hasError: false,
        errorMessage: null,
        ...fullPayload,
        country:
          action.payload.country && action.payload.country.alpha2Code
            ? action.payload.country.alpha2Code
            : 'NZ',
        savingStatus: SavingStatus.NONE,
        expiresAt: moment()
          .add(userExpiresAtConfig.value, userExpiresAtConfig.unit)
          .format(),
      }
    case actionTypes.USER_REQUEST_DETAILS_FAILURE:
      return {
        ...state,
        hasPasswordChanged: false,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
        savingStatus: SavingStatus.ERROR,
      }
    case actionTypes.USER_ADDRESS_UPDATE:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.USER_UPDATE_DETAILS:
      return { ...state, savingStatus: SavingStatus.SAVING }
    case actionTypes.USER_UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        savingStatus: SavingStatus.SAVED,
        hasError: false,
        errorMessage: null,
      }
    case actionTypes.USER_UPDATE_DETAILS_FAILURE:
      return {
        ...state,
        hasPasswordChanged: false,
        savingStatus: SavingStatus.ERROR,
        hasError: true,
        errorMessage: action.payload,
      }
    case actionTypes.USER_UPDATE_SAVING_STATUS:
      return {
        ...state,
        savingStatus: action.status,
      }
    // Logging out resets the user details
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialUserState
    default:
      return state
  }
}

// Forgot password / PIN

const initialUserForgotPasswordState: UserForgotPasswordState = {
  screen: 'credentials',
  // Credentials
  username: '',
  dob: '',
  // Request confirm code
  method: 'email',
  confirmId: '',
  ...getDefaultState(),
}

export const userForgotPasswordReducer: Reducer<UserForgotPasswordState> = (
  state: UserForgotPasswordState = initialUserForgotPasswordState,
  action: AnyAction
) => {
  switch (action.type) {
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
    case actionTypes.USER_FP_INIT:
      return initialUserForgotPasswordState
    // Submit username / DOB on first screen
    case actionTypes.USER_SET_FP_CREDENTIALS:
      return {
        ...state,
        screen: 'method', // If moved into an Epic, this should be in success case
        username: action.payload.username,
        dob: action.payload.dob,
      }
    case actionTypes.USERNAME_CONFIRMATION:
      return {
        ...state,
        ...getDefaultState(),
        username: action.payload.username,
        dob: action.payload.dob,
        isLoading: true,
      }
    case actionTypes.USERNAME_CONFIRMATION_SUCCESS:
      return {
        ...state,
        ...getDefaultState(),
        mobilePhoneNumber: action.mobilePhoneNumber,
        screen: 'method',
        isLoading: false,
      }
    case actionTypes.USERNAME_CONFIRMATION_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload),
        isLoading: false,
      }
    // Request confirm code
    case actionTypes.USER_REQUEST_CONFIRM_CODE:
      return {
        ...state,
        ...getDefaultState(),
        isLoading: true,
      }
    case actionTypes.USER_REQUEST_CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        confirmId: action.payload.id,
        screen: 'confirm',
        isLoading: false,
      }
    case actionTypes.USER_REQUEST_CONFIRM_CODE_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload),
        screen: 'credentials',
      }
    default:
      return state
  }
}

// Reset password / PIN (second part of Forgot Password / PIN process)

const initialUserResetPasswordState: UserResetPasswordState = {
  // Whole process
  screen: 'confirm',
  // Check confirm code
  confirmCode: '',
  // Reset password
  username: '',
  newValue: '',
  hasReset: false,
  ...getDefaultState(),
}

export const userResetPasswordReducer: Reducer<UserResetPasswordState> = (
  state: UserResetPasswordState = initialUserResetPasswordState,
  action: AnyAction
) => {
  switch (action.type) {
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
    case actionTypes.USER_RP_INIT:
      return initialUserResetPasswordState
    // Check confirm code
    case actionTypes.USER_CHECK_CONFIRM_CODE:
      return {
        ...state,
        ...getDefaultState(),
        isLoading: true,
        confirmCode: action.payload.confirmCode,
      }
    case actionTypes.USER_REQUEST_CONFIRM_CODE_SUCCESS:
      return {
        ...state,
      }
    case actionTypes.USER_CHECK_CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        screen: 'reset',
        isLoading: false,
        username: action.payload.username,
      }
    case actionTypes.USER_RESET_FAILURE:
    case actionTypes.USER_CHECK_CONFIRM_CODE_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload.message),
      }
    // Reset the user's password / PIN
    case actionTypes.USER_RESET:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null,
        newValue: action.payload.newValue,
      }
    case actionTypes.USER_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        screen: 'complete',
        hasReset: true,
      }
    default:
      return state
  }
}
