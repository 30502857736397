import React from 'react'
import { AppState } from '../../redux/app-state'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  GraphConfiguration,
  GraphConfigurationType,
} from '../../redux/account-balance/account-balance.model'
import {
  AccountBalanceActions,
  AccountBalanceGraphConfigurationAction,
} from '../../redux/account-balance/account-balance.actions'
import moment from 'moment'
import cn from 'classnames'

import './BalanceGraphFilters.scss'

interface AccountBalanceFiltersProps {
  graphConfiguration?: GraphConfiguration
  accountCommenceDate?: string
  updateGraphConfiguration?: typeof AccountBalanceGraphConfigurationAction
}

const graphConfigurations: GraphConfiguration[] = [
  {
    type: GraphConfigurationType.DAILY,
    displayName: '1M',
    dateType: 'last-month',
    minimumAccountAgeInMonths: 0,
  },
  {
    type: GraphConfigurationType.DAILY,
    displayName: '3M',
    dateType: 'last-3-months',
    minimumAccountAgeInMonths: 4,
  },
  {
    type: GraphConfigurationType.MONTHLY,
    displayName: '6M',
    dateType: 'last-6-months',
    minimumAccountAgeInMonths: 7,
  },
  moment().month() <= 3
    ? {
        type: GraphConfigurationType.DAILY,
        displayName: 'YTD',
        dateType: 'year-to-date',
        minimumAccountAgeInMonths: 0,
      }
    : {
        type: GraphConfigurationType.MONTHLY,
        displayName: 'YTD',
        dateType: 'year-to-date',
        minimumAccountAgeInMonths: 0,
      },
  {
    type: GraphConfigurationType.MONTHLY,
    displayName: '1Y',
    dateType: 'last-year',
    minimumAccountAgeInMonths: 13,
  },
  {
    type: GraphConfigurationType.MONTHLY,
    displayName: '3Y',
    dateType: 'last-3-years',
    minimumAccountAgeInMonths: 37,
  },
  {
    type: GraphConfigurationType.MONTHLY,
    displayName: '5Y',
    dateType: 'last-5-years',
    minimumAccountAgeInMonths: 61,
  },
  {
    type: GraphConfigurationType.MONTHLY,
    displayName: 'ALL',
    dateType: 'all',
    minimumAccountAgeInMonths: 0,
  },
]

const AccountBalanceFilters = (props: AccountBalanceFiltersProps) => {
  const {
    graphConfiguration: selectedGraphConfiguration,
    accountCommenceDate,
    updateGraphConfiguration,
  } = props

  const accountAge = moment().diff(moment(accountCommenceDate), 'months')

  const validConfigurations = graphConfigurations.filter((configuration) => {
    return accountAge >= configuration.minimumAccountAgeInMonths
  })

  return (
    <div className="account-balance-filters">
      {validConfigurations.map((graphConfiguration) => {
        return (
          <button
            className={cn('account-balance-filters__button', {
              selected:
                graphConfiguration.dateType ===
                selectedGraphConfiguration.dateType,
            })}
            key={graphConfiguration.displayName}
            onClick={() => {
              updateGraphConfiguration(graphConfiguration)
            }}
          >
            {graphConfiguration.displayName}
          </button>
        )
      })}
    </div>
  )
}

const mapStateToProps = (
  state: AppState,
  props: AccountBalanceFiltersProps
) => ({
  graphConfiguration: state.accountBalance.graphConfiguration,
})

const mapDispatchToProps = (dispatch: Dispatch<AccountBalanceActions>) => ({
  updateGraphConfiguration: bindActionCreators(
    AccountBalanceGraphConfigurationAction,
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountBalanceFilters)
