import { Reducer, AnyAction } from 'redux'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './third-party-app.actions'
import { ThirdPartyAppState } from './third-party-app.model'

export const initialState: ThirdPartyAppState = {
  resourceUrl: null,
  hasLoaded: null,
  activeLinkId: null,
  encodedModel: null,
  ...getDefaultState(),
}

export const ThirdPartyAppReducer: Reducer<ThirdPartyAppState> = (
  state: ThirdPartyAppState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.THIRD_PARTY_APP_REQUEST:
      return {
        ...state,
        isLoading: true,
        activeLinkId: action.payload.activeLinkId,
        encodedModel: null,
        hasError: false,
        errorMessage: null,
      }

    case actionTypes.THIRD_PARTY_APP_REQUEST_SUCCESS:
      return { ...state, ...action.payload, isLoading: false, hasLoaded: true }

    case actionTypes.THIRD_PARTY_UPDATE_HAS_LOADED_REQUEST:
      return { ...state, ...action.payload }

    case actionTypes.THIRD_PARTY_APP_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }

    case actionTypes.THIRD_PARTY_FORM_DATA_REQUEST:
      return {
        ...state,
      }

    case actionTypes.THIRD_PARTY_FORM_DATA_SUCCESS:
      return { ...state, ...action.payload }

    case actionTypes.THIRD_PARTY_FORM_DATA_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
      }

    default:
      return state
  }
}
