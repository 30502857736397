import { ForwardedRef, forwardRef, useMemo } from 'react'
import cn from 'classnames'
import './Toggle.scss'

type ToggleProps = React.HTMLProps<HTMLInputElement>

const Toggle = forwardRef(
  (props: ToggleProps, ref: ForwardedRef<HTMLInputElement>) => {
    const randomNumber = useMemo(() => Math.floor(Math.random() * 10000), [])
    const { className, id, ...inputProps } = props
    const inputId = id || `toggle-${randomNumber}`
    return (
      <label
        htmlFor={inputId}
        className={cn(`toggle`, {
          [`${className}`]: !!className,
        })}
      >
        <input type="checkbox" id={inputId} ref={ref} {...inputProps} />
        <span className="slider">
          <span className="option-text">Yes</span>
          <span className="option-text">No</span>
        </span>
      </label>
    )
  }
)

export default Toggle
