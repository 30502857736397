import { HASH_MODALS } from '../../common/hash-helper'
import Link from '../clickable/link/Link'

const AccountDetailsAddFundsButton = () => {
  return (
    <Link
      variant="filled"
      aria-controls="account-options-menu"
      aria-haspopup="true"
      to={`#${HASH_MODALS.ADD_FUNDS}`}
    >
      <span>Deposit</span>
    </Link>
  )
}

export default AccountDetailsAddFundsButton
