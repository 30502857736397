import React, { useState } from 'react'
import AuthorizationModal from '../authorization-modal/AuthorizationModal'

export type AuthorizationFormValues =
  | { password: string; username: string }
  | { pin: string; pinToken: string; uuid: string }
type SubHandler = (formValues: AuthorizationFormValues) => void

type Handler = (...params: unknown[]) => SubHandler //TODO: Type this correctly with inferred types.

export const createRequestAuthorizer = (
  isOpen: boolean,
  onOpen: () => void,
  onClose: () => void,
  setExecutingHandler: (handler: SubHandler) => void,
  executingHandler?: SubHandler
): [JSX.Element, typeof protectFunction] => {
  const protectFunction = <T extends Handler>(handler: T) => {
    return (...parameters: unknown[]) => {
      onOpen()
      setExecutingHandler(handler(...parameters))
    }
  }
  return [
    React.createElement(AuthorizationModal, {
      open: isOpen,
      onClose,
      isSubmitting: false,
      onLoginSubmit: (password, username) => {
        executingHandler?.({
          password: encodeURIComponent(password),
          username,
        })
      },
    }),
    protectFunction,
  ]
}

export const useAuthorizedRequest = (): [
  JSX.Element,
  typeof protectFunction,
  typeof setOpen
] => {
  const [open, setOpen] = useState(false)
  const [executingHandler, setExecutingHandler] = useState<{
    handler: SubHandler
  }>(null)

  const protectFunction = <T extends Handler>(handler: T) => {
    return (...parameters: unknown[]) => {
      const finalHandler = handler(...parameters)
      setOpen(true)
      setExecutingHandler({ handler: finalHandler })
    }
  }
  return [
    React.createElement(AuthorizationModal, {
      open,
      onClose: () => setOpen(false),
      isSubmitting: false,
      onLoginSubmit: (password, username) => {
        executingHandler.handler?.({
          password: encodeURIComponent(password),
          username,
        })
      },
    }),
    protectFunction,
    setOpen,
  ]
}
