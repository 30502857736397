import { AnyAction, Reducer } from 'redux'
import { getDefaultState, setFailState } from './../state/state.model'
import {
  AccountBalanceState,
  GraphConfigurationType,
} from './account-balance.model'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'
import { actionTypes } from './account-balance.actions'

export const initialState: AccountBalanceState = {
  graphConfiguration: {
    type: GraphConfigurationType.MONTHLY,
    displayName: 'ALL',
    dateType: 'all',
    minimumAccountAgeInMonths: 0,
  },
  balanceGraphs: [],
  ...getDefaultState(),
}

export const AccountBalanceReducer: Reducer<AccountBalanceState> = (
  state: AccountBalanceState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_BALANCE_REQUEST:
      return { ...state, ...getDefaultState(), isLoading: true }
    case actionTypes.ACCOUNT_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        ...getDefaultState(),
        balanceGraphs: [...state.balanceGraphs, action.payload.balanceGraph],
      }
    case actionTypes.ACCOUNT_BALANCE_REQUEST_FAILURE:
      return { ...state, ...setFailState(action.payload) }
    case actionTypes.ACCOUNT_BALANCE_GRAPH_CONFIGURATION:
      return {
        ...state,
        graphConfiguration: action.payload.graphConfiguration,
      }
    // Logging out resets the account balance
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return {
        ...initialState,
        isLoading: true,
      }

    default:
      return state
  }
}
