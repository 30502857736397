import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AppState } from '../../redux/app-state'
import { routerActions } from 'react-router-redux'
import Alert from 'react-s-alert'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NameEmailDOBForm from '../../components/name-email-dob-form/NameEmailDOBForm'
import ContactUsModal from '../../components/contact-us-modal'

import {
  OnboardingActions,
  OnboardingClearState,
  OnboardingSearch,
} from '../../redux/onboarding/onboarding.actions'

import useToggle from '../../hooks/use-toggle'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'

interface RegisterProps {
  setTitle: (newTitle: string) => void
  hasError: boolean
  isLoading: boolean
  errorMessage: string
  canBeOnboarded: boolean
  actions: {
    onboardingSearch?: typeof OnboardingSearch
    onboardingClearState?: typeof OnboardingClearState
    next?: (path: string) => void
  }
  setActiveMenu: (activeMenu: string) => void
}

const ContactUsLink = () => {
  const { toggle, toggleOn, toggleOff } = useToggle(false)
  return (
    <>
      <ContactUsModal open={toggle} onClose={toggleOff} />
      <Link color="primary" underline="always" onClick={toggleOn}>
        contact us
      </Link>
    </>
  )
}

export class RegisterForm extends React.Component<RegisterProps> {
  constructor(props: RegisterProps) {
    super(props)

    this.props.actions.onboardingSearch = this.props.actions.onboardingSearch.bind(
      this
    )
  }

  UNSAFE_componentWillMount() {
    this.props.actions.onboardingClearState()
    this.props.setTitle('Welcome!')
    this.props.setActiveMenu('login')
  }

  UNSAFE_componentWillReceiveProps(props: RegisterProps) {
    if (props.hasError) {
      Alert.error(props.errorMessage, { timeout: 'none' })
    }

    if (props.canBeOnboarded) {
      this.props.actions.next('/register/welcome/success')
    }
  }

  componentDidMount() {
    const mainThemeContent = document.querySelector(
      '.main-theme-container .left-content'
    )

    if (mainThemeContent) {
      window.scrollTo(0, 0)
      mainThemeContent.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <div>
        <Typography component="h2" variant="h5">
          You’re one step away from managing your new Fisher Funds KiwiSaver
          account online. Register here to view and manage your account. If you
          require any assistance, please <ContactUsLink />.
        </Typography>
        <NameEmailDOBForm
          onSubmit={this.props.actions.onboardingSearch}
          isLoading={this.props.isLoading}
          submitButtonText="Register"
          submittingButtonText="Submitting..."
          success={this.props.canBeOnboarded}
        />
      </div>
    )
  }
}

export default connect(
  (state: AppState) => ({
    hasError: state.onboarding.hasError,
    errorMessage: state.onboarding.errorMessage,
    canBeOnboarded: state.onboarding.canBeOnboarded,
    isLoading: state.onboarding.isLoading,
  }),
  (dispatch: Dispatch<OnboardingActions>) => {
    return {
      actions: {
        onboardingSearch: bindActionCreators(OnboardingSearch, dispatch),
        onboardingClearState: bindActionCreators(
          OnboardingClearState,
          dispatch
        ),
        next: (path: string) => dispatch(routerActions.push(path)),
        setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
      },
    }
  },
  null
)(RegisterForm)
