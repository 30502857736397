import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MuiLink from '@material-ui/core/Link'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Account } from '../../redux/accounts/accounts.model'
import FirstHomeStepsModal from '../../components/first-home-steps-modal'
import { Button } from '../clickable/button/Button'
import InvestmentStrategySwitch from '../investment-strategy-switch/InvestmentStrategySwitch'
import ChangeContributionsModal from '../change-contributions-modal/ChangeContributionsModal'
import { UserData } from '../../redux/user/user.model'
import {
  canDisplayFirstHomeBuyerTools,
  isKiwiSaverPlanAccount,
} from '../../common/accounts-helper'
import { HASH_MODALS } from '../../common/hash-helper'
import { isApexSwitchEnabled } from '../../common/switch-helper'
import { canWithdrawFunds } from '../../common/withdraw-funds-helper'

interface AccountDetailsMenuKiwisaverProps {
  currentAccount: Account
  user: UserData
  authToken: string
}

export default function AccountDetailsMenuKiwisaver(
  props: AccountDetailsMenuKiwisaverProps
) {
  const { accountID } = props.currentAccount
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [
    changeContributionsModalOpen,
    setChangeContributionsModalOpen,
  ] = useState(false)
  const showFirstHomeBuyersLetter = canDisplayFirstHomeBuyerTools([
    props.currentAccount,
  ])
  const ENABLE_SWITCH =
    !isKiwiSaverPlanAccount(props.currentAccount) || isApexSwitchEnabled

  const handleShowAccountOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      {modalOpen && <FirstHomeStepsModal onClose={() => setModalOpen(false)} />}
      {changeContributionsModalOpen && (
        <ChangeContributionsModal
          accountId={accountID}
          onClose={() => {
            setChangeContributionsModalOpen(false)
          }}
        />
      )}
      <div className="action-buttons">
        <Button
          variant="outlined"
          color="white"
          iconLeft={MoreHorizIcon}
          aria-controls="account-options-menu"
          aria-haspopup="true"
          onClick={handleShowAccountOptions}
        >
          <span className="desktop">Account options</span>
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          id="account-options-menu"
          className="account-options-menu has-kiwisaver"
        >
          {ENABLE_SWITCH && (
            <MenuItem disableRipple>
              <InvestmentStrategySwitch
                currentAccount={props.currentAccount}
                onClose={handleClose}
                className="color-dusk"
              />
            </MenuItem>
          )}
          {showFirstHomeBuyersLetter && (
            <MenuItem disableRipple>
              <MuiLink
                onClick={() => {
                  setModalOpen(true)
                  handleClose()
                }}
                underline="none"
                className="text-decoration-none color-dusk"
              >
                First home buyer's letter
              </MuiLink>
            </MenuItem>
          )}
          <MenuItem disableRipple>
            <MuiLink
              href={`#${HASH_MODALS.ADD_FUNDS}`}
              className="text-decoration-none color-dusk"
              onClick={handleClose}
            >
              Add funds
            </MuiLink>
          </MenuItem>
          {canWithdrawFunds(props.currentAccount, props.user?.birthDate) && (
            <MenuItem disableRipple>
              <MuiLink
                className="text-decoration-none color-dusk"
                href={`#${HASH_MODALS.WITHDRAW}`}
                onClick={handleClose}
              >
                Withdraw funds
              </MuiLink>
            </MenuItem>
          )}
          <MenuItem disableRipple>
            <MuiLink
              onClick={() => {
                setChangeContributionsModalOpen(true)
                handleClose()
              }}
              underline="none"
              className="text-decoration-none color-dusk"
            >
              Change your employee contribution rate
            </MuiLink>
          </MenuItem>
        </Menu>
      </div>
    </>
  )
}
