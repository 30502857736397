import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  tabClassName: string
  title: string
  handleBack: () => void
}

export const TabPanel = (props: TabPanelProps) => {
  const {
    children,
    value,
    index,
    tabClassName,
    title,
    handleBack,
    ...other
  } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`security-tabpanel-${index}`}
      aria-labelledby={`security-tab-${index}`}
      className={`MuiPaper-root ${tabClassName}`}
      {...other}
    >
      <Box p={3} className={value !== index ? 'hidden' : ''}>
        <Typography
          component="h1"
          variant="h6"
          className="mobile-sm-only mb-md"
        >
          {title}
          <IconButton
            color="default"
            className="back-button"
            onClick={() => handleBack()}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Typography>
        {children}
      </Box>
    </Typography>
  )
}
