import { ActionsObservable, Epic } from 'redux-observable'
import 'rxjs'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import { interval } from 'rxjs/observable/interval'
import * as actions from './switch-notifications.actions'
import { SwitchNotificationsState } from './switch-notifications.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type LightStore = { getState: Function; dispatch: Function }

export const switchNotificationsEpic: Epic<
  actions.SwitchNotificationsActions,
  SwitchNotificationsState
> = (
  action$: ActionsObservable<actions.SwitchNotificationsRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.SWITCH_NOTIFICATIONS_REQUEST)
    .throttle(() => interval(1000))
    .mergeMap((action) => {
      const switchNotifications = store.getState().switchNotifications
      if (switchNotifications.notifications) {
        return Observable.of(
          actions.SwitchNotificationsRequestSuccessAction(
            switchNotifications.notifications
          )
        )
      }

      return ajax
        .get(`${ffmAppServerUrl}/api/notification/switch`, {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        })
        .map((response) => {
          return actions.SwitchNotificationsRequestSuccessAction(
            response.response
          )
        })
        .catch((err) =>
          Observable.of(
            actions.SwitchNotificationsRequestFailureAction(
              'Switch notification request failed'
            )
          )
        )
    })
