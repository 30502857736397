import { KIWISAVER_PLAN } from '../../common/product-variables'

export const FFKP_IPQ_ENABLED =
  process.env.REACT_APP_FEATURE_TOGGLE_FFKP_IPQ !== 'false'

// This controls access for the Advice Tool IPQ in FFO and not external links to IPQ's
const PRODUCTS_WITH_IPQ = [FFKP_IPQ_ENABLED && KIWISAVER_PLAN]

export const canUseIPQTool = (productExternalRef: string) =>
  PRODUCTS_WITH_IPQ.includes(productExternalRef)
