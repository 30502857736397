import { User } from '../contact-details/ContactDetails'
import TextField from '@material-ui/core/TextField/TextField'
import { useForm } from 'react-hook-form'
import {
  emailValidation,
  getEmailError,
  getPhoneNumberError,
  phoneNumberValidation,
} from '../../common/forms-helper'
import { Button } from '../clickable/button/Button'
import { AppState } from '../../redux/app-state'
import {
  UserActions,
  UserRequestDetailsAction,
} from '../../redux/user/user.actions'
import { Dispatch, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useEffect } from 'react'

interface SwitchModalPremiumServiceProps {
  user?: User
  onCancel: () => void
  actions?: {
    getUserDetails: (uuid: string) => void
  }
}

const SwitchModalPremiumServiceForm = ({
  user,
  onCancel,
  actions,
}: SwitchModalPremiumServiceProps) => {
  const {
    firstName,
    surname,
    email,
    mobilePhoneNumber,
    homePhoneNumber,
    userid,
  } = user

  useEffect(() => {
    actions.getUserDetails(userid)
  }, [actions, userid])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName,
      surname,
      email,
      phoneNumber: mobilePhoneNumber ?? homePhoneNumber,
    },
  })

  const onSubmitEvent = (data: any) => {
    //TODO FSC integration required
    console.log('data', data)
  }

  return (
    <div>
      <p>
        Please confirm your contact details and your adviser will be in touch to
        discuss your options.
      </p>
      <form
        className="switch__modal-form"
        onSubmit={handleSubmit(onSubmitEvent)}
      >
        <div>
          <h6>First name</h6>
          <TextField
            id="firstName"
            type="text"
            fullWidth
            variant="outlined"
            placeholder="Please enter your first name"
            {...{
              ...register('firstName', { required: true, maxLength: 40 }),
              ref: undefined,
              inputRef: register('firstName', {
                required: 'Please enter your first name',
                maxLength: 40,
              }).ref,
            }}
            helperText={
              !!errors.firstName ? 'Please enter your first name' : ''
            }
            error={!!errors.firstName}
          />
        </div>

        <div>
          <h6>Last name</h6>
          <TextField
            id="surname"
            type="text"
            variant="outlined"
            fullWidth
            placeholder="Please enter your last name"
            {...{
              ...register('surname'),
              ref: undefined,
              inputRef: register('surname', {
                required: 'Please enter your last name',
                maxLength: 80,
              }).ref,
            }}
            helperText={!!errors.surname ? 'Please enter your last name' : ''}
            error={!!errors.surname}
          />
        </div>
        <div>
          <h6>Email</h6>
          <TextField
            id="email"
            type="email"
            fullWidth
            variant="outlined"
            placeholder="Enter your email address"
            {...{
              ...register('email', emailValidation),
              ref: undefined,
              inputRef: register('email', emailValidation).ref,
            }}
            helperText={getEmailError(errors)}
            error={getEmailError(errors) ? true : false}
          />
        </div>
        <div>
          <h6>Phone number</h6>
          <TextField
            id="phoneNumber"
            type="tel"
            variant="outlined"
            fullWidth
            placeholder="Enter your phone number"
            {...{
              ...register('phoneNumber'),
              ref: undefined,
              inputRef: register('phoneNumber', phoneNumberValidation).ref,
            }}
            helperText={getPhoneNumberError(errors)}
            error={getPhoneNumberError(errors) ? true : false}
          />
        </div>
        <Button
          className="mt-md"
          variant="link"
          aria-controls="account-options-menu"
          aria-haspopup="true"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="mt-md"
          type="submit"
          variant="filled"
          aria-controls="account-options-menu"
          aria-haspopup="true"
        >
          <span>Confirm</span>
        </Button>
      </form>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  const user = { ...state.user }
  return {
    user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<UserActions>) => ({
  actions: {
    getUserDetails: bindActionCreators(UserRequestDetailsAction, dispatch),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(SwitchModalPremiumServiceForm)
