import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
  Legend,
} from 'recharts'
import { RetirementProjectorYear } from './RetirementProjector.types'
import { useMedia } from '../../hooks/use-media'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { formatCurrency } from '../../common/currency-helper'

const loadCustomLegend = () => {
  // The <hr /> below is a green line before the text
  return (
    <div className="retirement-projector__results__graph__legend">
      <hr />
      <p>Your retirement projection</p>
    </div>
  )
}

const getXTicks = (age: number) => {
  const ticks = [90]

  if (age < 65) {
    ticks.push(65)
  }
  if (age < 55 || age >= 65) {
    ticks.push(age)
  }
  return ticks
}

export type RetirementProjectorGraphProps = {
  data: RetirementProjectorYear[]
}
export const RetirementProjectorGraph = ({
  data,
}: RetirementProjectorGraphProps) => {
  const renderTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (!active || !payload || payload.length === 0) {
      return null
    }

    const { age, startValueAdjusted } = payload[0].payload
    return (
      <div className="retirement-projector__results__graph__tooltip">
        <span>Age</span>
        <p className="mt-0 mb-sm">{age}</p>
        <span>Balance</span>
        <p className="my-0">{formatCurrency(startValueAdjusted, '$0,0')}</p>
      </div>
    )
  }

  const { isMobile } = useMedia()
  const graphPaddingTop = isMobile ? 30 : 50

  return (
    <ResponsiveContainer width="100%" minHeight={300 + graphPaddingTop}>
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid fill="#f4f7f9" horizontal={false} vertical={false} />
        <Area
          animationDuration={4000}
          name="Your retirement projection"
          dataKey="startValueAdjusted"
          stroke="#00AE9E"
          fill="#B8F2EB"
          fillOpacity={0.25}
          baseValue={0}
          activeDot={{ fill: 'black', r: 6, strokeWidth: 6 }}
        />
        <Tooltip
          defaultIndex={data.findIndex((d) => d.age === 65)}
          content={renderTooltip}
        />
        <XAxis
          dataKey="age"
          scale="point"
          axisLine={false}
          interval="preserveStartEnd"
          ticks={getXTicks(data[0].age)}
          tickFormatter={(value) => `${value}y`}
          tickLine={false}
        />
        <YAxis padding={{ top: graphPaddingTop }} hide={true} />
        <Legend content={loadCustomLegend} />
      </AreaChart>
    </ResponsiveContainer>
  )
}
