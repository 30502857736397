import { combineReducers } from 'redux'

import { accountDetailsReducer } from './account-details/account-details.reducer'

import { accountFundsReducer } from './account-funds/account-funds.reducer'
import { accountGrowthReducer } from './account-growth/account-growth.reducer'
import { accountTransactionsReducer } from './account-transactions/account-transactions.reducer'
import { accountsReducer } from './accounts/accounts.reducer'
import { authorisationReducer } from './authorisation/authorisation.reducer'
import { capsLockReducer } from './capslock/capslock.reducer'
import { chartColorsReducer } from './chart-colors/chart-colors.reducer'
import { onboardingingReducer } from './onboarding/onboarding.reducer'
import { pirReducer } from './pir/pir.reducer'
import { ReportsReducer } from './reports/reports.reducer'
import { ThirdPartyAppReducer } from './third-party-app/third-party-app.reducer'
import { layoutReducer } from './layout/layout.reducer'

import {
  userForgotPasswordReducer,
  userReducer,
  userResetPasswordReducer,
} from './user/user.reducer'
import { routerReducer } from 'react-router-redux'
import { usernameRecoveryReducer } from './username-recovery/username-recovery.reducer'
import { usernameReducer } from './username/username.reducer'
import { switchNotificationsReducer } from './switch-notifications/switch-notifications.reducer'

import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { AccountBalanceReducer } from './account-balance/account-balance.reducer'

const onboardingPersistConfig = {
  key: 'onboarding',
  storage: storage,
}

export default combineReducers({
  onboarding: persistReducer(onboardingPersistConfig, onboardingingReducer),
  accounts: accountsReducer,
  accountBalance: AccountBalanceReducer,
  accountDetails: accountDetailsReducer,
  accountGrowth: accountGrowthReducer,
  accountFunds: accountFundsReducer,
  accountTransactions: accountTransactionsReducer,
  authorisation: authorisationReducer,
  capslock: capsLockReducer,
  user: userReducer,
  username: usernameReducer,
  usernameRecovery: usernameRecoveryReducer,
  forgotPassword: userForgotPasswordReducer,
  resetPassword: userResetPasswordReducer,
  pir: pirReducer,
  reports: ReportsReducer,
  chartColors: chartColorsReducer,
  thirdPartyApp: ThirdPartyAppReducer,
  routing: routerReducer,
  switchNotifications: switchNotificationsReducer,
  layout: layoutReducer,
})
