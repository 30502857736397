import InfoIcon from '@material-ui/icons/Info'
import './CurrentIndicator.scss'

const CurrentIndicator = () => (
  <div className="current-indicator">
    <span className="current-indicator-label py-2xs">
      <InfoIcon /> Current
    </span>
  </div>
)
export default CurrentIndicator
