import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts'
import './GlidePathGraph.scss'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const growthAsset = Number(payload[0].value).toFixed(2)
    const incomeAsset = (100 - Number(payload[0].value)).toFixed(2)
    return (
      <div className="glide-path-graph-tooltip">
        <span className="glide-path-graph-tooltip__label">
          <p className="glide-path-graph-tooltip__text--title">
            {`Allocations at age ${label}`}
          </p>
        </span>
        <span className="glide-path-graph-tooltip__label">
          <p className="glide-path-graph-tooltip__text">Growth assets</p>
          <p className="glide-path-graph-tooltip__text--right">
            {growthAsset}%
          </p>
        </span>
        <span className="glide-path-graph-tooltip__label">
          <p className="glide-path-graph-tooltip__text">Income assets</p>
          <p className="glide-path-graph-tooltip__text--right">
            {incomeAsset}%
          </p>
        </span>
      </div>
    )
  }

  return null
}

const CustomizedDot = (props: any) => {
  // eslint-disable-line
  const { cx, cy, payload, fill } = props

  // only show dot in every 10th number and skip age 30
  if (payload.age % 10 === 0 && payload.age !== 30) {
    return <circle cx={cx} cy={cy} r={3} fill={fill} />
  }

  return null
}

const CustomizedXAxisTick = (props: any) => {
  // eslint-disable-line
  const { x, y, payload, fill } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={10} dy={7} textAnchor="end" fill={fill}>
        {payload.value}
      </text>
    </g>
  )
}

export type GlidePathGraphProps = {
  data: {
    age: number
    growth: number
  }[]
  xTicks: number[]
  yTicks: number[]
  minHeight?: number
  maxHeight?: number
}

const GlidePathGraph = ({
  data,
  xTicks,
  yTicks,
  minHeight = 130,
}: GlidePathGraphProps) => (
  <ResponsiveContainer minHeight={minHeight}>
    <LineChart
      data={data}
      margin={{ left: 0, right: 20, top: 20, bottom: 160 }}
    >
      <CartesianGrid horizontal={false} strokeDasharray="6" />
      <XAxis
        dataKey="age"
        type="number"
        domain={[20, 'dataMax']}
        ticks={xTicks}
        tick={<CustomizedXAxisTick fill="#666" />}
        tickLine={false}
        strokeWidth={0.5}
        label={{
          value: 'Age',
          dx: -15,
          dy: 9,
          fontSize: 10,
          fill: '#728998',
        }}
      />
      <YAxis
        dataKey="growth"
        unit="%"
        padding={{ top: 5 }}
        domain={[0, 'dataMax + 5']}
        ticks={yTicks}
        tickLine={false}
        axisLine={false}
        label={{
          value: 'Allocation to growth assets',
          angle: -90,
          dx: -20,
          dy: 5,
          fontSize: 10,
          fill: '#728998',
        }}
      />
      <Tooltip coordinate={{ x: 45, y: 20 }} content={<CustomTooltip />} />
      <Line
        dataKey="growth"
        type="monotone"
        stroke="#00AE9E"
        strokeWidth={2}
        activeDot={{ r: 8 }}
        fill="#00AE9E"
        name="growth"
        dot={<CustomizedDot fill="#00AE9E" />}
      />
    </LineChart>
  </ResponsiveContainer>
)

export default GlidePathGraph
