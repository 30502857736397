export interface State {
  isLoading: boolean
  hasError: boolean
  errorMessage?: string
  errorStatus?: number
}

export const getDefaultState = (): State => {
  return {
    isLoading: false,
    hasError: false,
    errorMessage: null,
  }
}

export const setFailState = (
  errorMessage?: string,
  errorStatus?: number
): State => {
  return {
    isLoading: false,
    hasError: true,
    errorMessage,
    errorStatus,
  } as State
}
