import 'rxjs'
import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import * as actions from './third-party-app.actions'
import { ThirdPartyAppState } from './third-party-app.model'

type LightStore = { getState: Function; dispatch: Function }

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

export const getThirdPartyAppSecureUrlEpic: Epic<
  actions.ThirdPartyAppActions,
  ThirdPartyAppState
> = (
  action$: ActionsObservable<actions.ThirdPartyAppRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.THIRD_PARTY_APP_REQUEST)
    .switchMap((action) => {
      return ajax
        .post(
          `${ffmAppServerUrl}/api/users/${action.payload.userId}/third-party/encode`,
          {
            accountId: action.payload.accountId,
            url: encodeURIComponent(action.payload.url),
          },
          {
            Authorization:
              'Bearer ' + store.getState().authorisation.authorisationToken,
          }
        )
        .map((response) =>
          actions.ThirdPartyAppRequestSuccessAction(response.response.SecureUrl)
        )
        .catch((err) =>
          Observable.of(
            actions.ThirdPartyAppRequestFailureAction(
              'Third party request failed use original url instead'
            )
          )
        )
    })

export const getThirdPartyFormDataEpic: Epic<
  actions.ThirdPartyAppActions,
  ThirdPartyAppState
> = (
  action$: ActionsObservable<actions.ThirdPartyFormDataRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.THIRD_PARTY_FORM_DATA_REQUEST)
    .switchMap((action) => {
      return ajax
        .post(
          `${ffmAppServerUrl}/api/users/${action.payload.userId}/third-party/resource`,
          {
            accountId: action.payload.accountId,
            resource: action.payload.resource,
          },
          {
            Authorization:
              'Bearer ' + store.getState().authorisation.authorisationToken,
          }
        )
        .map((response) =>
          actions.ThirdPartyFormDataSuccessAction(response.response.FormData)
        )
        .catch((err) =>
          Observable.of(
            actions.ThirdPartyAppRequestFailureAction(
              'Third party request failed use original url instead'
            )
          )
        )
    })
