import Joi from 'joi'
import {
  ContributionFrequency,
  EmploymentStatus,
} from './RetirementProjector.types'

export const retirementProjectorAboutYouForm = Joi.object({
  withdrawForFirstHome: Joi.boolean().required(),
  employmentStatus: Joi.string()
    .valid(...Object.values(EmploymentStatus))
    .required(),
  salary: Joi.when('employmentStatus', {
    is: EmploymentStatus.EMPLOYED,
    then: Joi.number().min(1).required().messages({
      'number.empty': 'Salary is required',
      'any.required': 'Salary is required',
      'number.base': 'Salary is required',
      'number.min': 'Salary must be greater than 0',
    }),
  }),
  salaryFrequency: Joi.when('employmentStatus', {
    is: EmploymentStatus.EMPLOYED,
    then: Joi.string()
      .valid(...Object.values(ContributionFrequency))
      .required(),
  }),
  contributionRate: Joi.number().optional(),
  employerContributionRate: Joi.number().optional(),
  fund: Joi.required().messages({ 'any.required': 'Fund is required' }),
  voluntaryContribution: Joi.number().optional().messages({
    'number.base': 'Please enter a valid amount',
    'number.min': 'Please enter a valid amount',
    'number.empty': 'Please enter a valid amount',
  }),
  voluntaryContributionFrequency: Joi.string()
    .valid(...Object.values(ContributionFrequency))
    .optional(),
})
