import React from 'react'
import cn from 'classnames'
import { SelectOption } from './Select'
import { IconChevronDown } from '../iconography'

import './AmountSelectInput.scss'

export interface AmountSelectInputProps {
  amountValue?: number
  amountName?: string
  onAmountChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectValue?: string | number
  selectName?: string
  options: SelectOption[]
  onSelectChange?: ((value: string | number) => void) | null | undefined
  onBlur?: () => void
  className?: string
  dark?: boolean
  error?: boolean
  isDisabled?: boolean
  step?: number
}

export const AmountSelectInput = ({
  amountValue,
  amountName,
  onAmountChange,
  selectValue,
  selectName,
  options,
  onSelectChange,
  onBlur,
  className,
  dark,
  error,
  isDisabled,
  step = 10,
}: AmountSelectInputProps) => {
  const handleOnChange = (target: string) => {
    if (onSelectChange) {
      const option = options.find((o) => o.value.toString() === target)

      if (option) {
        onSelectChange(option.value)
      }
    }
  }

  return (
    <div
      className={cn('amount-select__wrapper', {
        'amount-select__wrapper--error': error,
        'amount-select__wrapper--dark': dark,
        [`${className}`]: !!className,
      })}
    >
      <div className="amount-select__label">
        <span className="amount-select__text">&#36;</span>
      </div>
      <input
        className="amount-select__amount-input"
        type="number"
        step={step}
        value={amountValue}
        onChange={(e) => onAmountChange && onAmountChange(e)}
        name={amountName}
      />
      <select
        className="amount-select__select"
        value={selectValue}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={onBlur}
        disabled={isDisabled}
        name={selectName}
      >
        {options.map((option: SelectOption) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="position-relative">
        <IconChevronDown size="xs" className="amount-select__icon" />
      </div>
    </div>
  )
}
