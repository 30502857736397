import { PirRate } from '../redux/pir/pir.model'

export const trackGTMHistoryChange = () => {
  let clientId = null
  const jsonRoot = sessionStorage.getItem('persist:root')
  if (jsonRoot) {
    const rootUser = JSON.parse(jsonRoot).user
    clientId = JSON.parse(rootUser).clientId
  }

  if (clientId) {
    if (!(window as any).hasOwnProperty('dataLayer')) {
      ;(window as any).dataLayer = []
    }
    ;(window as any).dataLayer.push({ event: 'custom.historyChange' })
  }
}

export const trackPIRChange = (oldPIR: PirRate, newPIR: PirRate) => {
  if (!(window as any).hasOwnProperty('dataLayer')) {
    ;(window as any).dataLayer = []
  }
  ;(window as any).dataLayer.push({
    event: 'custom.pirChange',
    data: {
      oldPIR,
      newPIR,
    },
  })
}

export const trackUserId = (userId?: string) => {
  if (!(window as any).hasOwnProperty('dataLayer')) {
    ;(window as any).dataLayer = []
  }
  ;(window as any).dataLayer.push({
    event: 'login',
    userId,
  })
}
