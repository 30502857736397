import React, { useState } from 'react'
import FFMAlert from '../../components/ffm-alert/FFMAlert'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { MuiThemeProvider } from '@material-ui/core/styles'
import MuiTheme from '../../common/MuiTheme'
import { FooterLinks } from './components'
import Logo from '../../assets/images/fisher-funds-logo.svg'
import { Link } from 'react-router'

export default function MainTheme(props: any) {
  const [pageTitle, setPageTitle] = useState('Online login')

  const [activeMenu, setActiveMenu] = useState('login')
  const isFullWidth: boolean = ['terms', 'privacy'].indexOf(activeMenu) > -1
  const mainClassname: string = `login-component${
    isFullWidth ? ' full-content-layout' : ''
  }`

  const children = React.Children.map(props.children, (child: JSX.Element) =>
    React.cloneElement(child, { setTitle: setPageTitle, setActiveMenu })
  )

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <Grid container component="main" className="main-theme-container">
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          className="left-content main-theme-container__content"
        >
          <div className={mainClassname}>
            <Typography component="p">
              <Link to="/accounts" className="logo-link">
                <img src={Logo} alt="Fisher Funds logo" className="logo" />
              </Link>
            </Typography>

            {!isFullWidth ? (
              <Typography component="h1" variant="h4" align="left">
                {pageTitle}
              </Typography>
            ) : null}

            <FFMAlert />

            {children}

            <FooterLinks isFullWidth={isFullWidth} />
          </div>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className="main-theme-container-image"
        />
      </Grid>
    </MuiThemeProvider>
  )
}
