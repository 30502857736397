import { ForwardedRef, forwardRef } from 'react'
import cn from 'classnames'
import './Input.scss'

interface InpuptProps extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  inputText?: string
  size?: 'small' | 'medium' | 'large'
  theme?: 'white' | 'gray'
  error?: boolean
}

const Input = forwardRef(
  (props: InpuptProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      inputText,
      size = 'medium',
      theme = 'gray',
      error = false,
      ...inputProps
    } = props

    return (
      <div
        className={cn(`form-input form-input--${size} form-input--${theme}`, {
          [`${className}`]: !!className,
          'form-input--disabled': inputProps.disabled,
          'form-input--has-text': !!inputText,
          'form-input--error': error,
        })}
      >
        <input className="form-input__input" ref={ref} {...inputProps} />
        {!!inputText && <span className="form-input__text">{inputText}</span>}
      </div>
    )
  }
)

export default Input
