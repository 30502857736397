import React from 'react'
import MainTheme from '../main-theme'
import Redirecting from '../../components/redirecting/Redirecting'

const PageRedirecting = () => {
  return (
    <MainTheme>
      <Redirecting />
    </MainTheme>
  )
}

export default PageRedirecting
