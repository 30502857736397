import * as React from 'react'

import { Dispatch, connect } from 'react-redux'
import {
  OnboardingActions,
  OnboardingRequest,
  OnboardingClearState,
} from '../../redux/onboarding/onboarding.actions'

import Alert from 'react-s-alert'
import { AppState } from '../../redux/app-state'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'

interface OnboardingProps {
  actions: {
    checkOnboarding: typeof OnboardingRequest
    clearOnboarding: typeof OnboardingClearState
    next: (path: string) => void
  }
  reference: string
  onboarding: {
    hash: string
    isValid: boolean
    errorMessage: string
  }
}

export class Onboard extends React.Component<OnboardingProps> {
  UNSAFE_componentWillMount() {
    // Clear state when the hash is in the url is different from the hash that is persisted in the onboarding state
    if (this.props.reference !== this.props.onboarding.hash) {
      this.props.actions.clearOnboarding()
    }
  }

  componentDidMount() {
    this.props.actions.checkOnboarding(this.props.reference)
  }

  componentDidUpdate() {
    if (
      !this.props.onboarding.isValid &&
      this.props.onboarding.errorMessage !== null
    ) {
      Alert.error(this.props.onboarding.errorMessage, {
        onClose: () => {
          this.props.actions.next('/login')
        },
      })
    }
  }

  render() {
    return <main>{this.props.onboarding.isValid && this.props.children}</main>
  }
}

export default connect(
  (state: AppState) => ({
    reference: state.routing.locationBeforeTransitions.query.q,
    onboarding: {
      hash: state.onboarding.hash,
      isValid: state.onboarding.isValid,
      errorMessage: state.onboarding.errorMessage,
    },
  }),
  (dispatch: Dispatch<OnboardingActions>) => ({
    actions: {
      checkOnboarding: bindActionCreators(OnboardingRequest, dispatch),
      clearOnboarding: bindActionCreators(OnboardingClearState, dispatch),
      next: (path: string) => dispatch(routerActions.push(path)),
    },
  }),
  null
)(Onboard)
