import * as React from 'react'
import DateNZ from '../date-nz/DateNZ'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import HourglassTopIcon from '@material-ui/icons/HourglassEmptyTwoTone'

import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import { download } from '../../common/downloaders'
import { Button, ButtonProps } from '../clickable/button/Button'
import CustomIcon from '../../common/CustomIcon'

interface ReportItemProps {
  isFirst?: boolean
  date: string
  title: string
  type: string
  url: string
  accountId: string
  authToken?: string
}

export const ReportItem = (props: ReportItemProps) => {
  const { authToken } = props
  const labelText = 'View Report'
  const [buttonLabel, setButtonLabel] = React.useState(labelText)

  const renderStartIcon = () => {
    return buttonLabel === labelText ? (
      <ArrowForwardIcon />
    ) : (
      <HourglassTopIcon />
    )
  }

  const getReportBlob = async () => {
    const fullUrl = props.url
    const result = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: 'application/pdf',
      },
    })
    return await result.blob()
  }

  const handleDownload = async () => {
    document.body.style.cursor = 'wait'
    setButtonLabel('Fetching....')

    try {
      await download(getReportBlob())
    } catch (error) {
      alert('Unable to fetch report. Please try again')
    }

    document.body.style.cursor = 'default'
    setButtonLabel('View Report')
  }

  const commonDownloadButtonProps = {
    variant: 'link',
    color: 'primary',
    onClick: handleDownload,
  } as Pick<ButtonProps, 'variant' | 'color' | 'onClick'>

  return (
    <TableRow>
      <TableCell>
        <DateNZ value={props.date} />
      </TableCell>
      <TableCell>{props.accountId}</TableCell>
      <TableCell>{props.title}</TableCell>
      <TableCell>{props.type}</TableCell>
      <TableCell>
        <Button {...commonDownloadButtonProps} className="download-mobile">
          <CustomIcon icon="download" />
        </Button>
        <Button
          {...commonDownloadButtonProps}
          className="download-desktop"
          iconRight={() => renderStartIcon()}
        >
          {buttonLabel}
        </Button>
      </TableCell>
    </TableRow>
  )
}

const mapStateToProps = (state: AppState) => ({
  authToken: state.authorisation.authorisationToken,
})

export default connect(mapStateToProps, null, null)(ReportItem)
