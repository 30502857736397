const numeral = require('numeral')

export const formatWithDecimalPlaces = (
  value: number | string | undefined,
  decimalPlaces: number
) => {
  const format = `0,0.${'0'.repeat(decimalPlaces)}`
  return numeral(value).format(format, Math.floor)
}

export const formatPercentage = (value: number, fractionDigits: number) =>
  new Intl.NumberFormat('en-NZ', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value)
