import * as React from 'react'
import { useLiveChatContext } from '../live-chat/useLiveChatContext'

// Provide an interface for using new properties of the global
// 'window' variable
declare global {
  interface Window {
    liveagent: LiveAgent
    _laq: Function[]
  }
}

// Tell TypeScript what functions the 'liveagent' object created by
// the imported script provides
type LiveAgent = {
  init: Function
  showWhenOnline: Function
  showWhenOffline: Function
  setChatWindowHeight: Function
  setChatWindowWidth: Function
  startChat: Function
}

const SalesforceLiveAgent = () => {
  const {
    isLoaded: isLiveChatLoaded,
    open: openLiveChat,
    buttonText,
    buttonIcon,
  } = useLiveChatContext()

  return (
    isLiveChatLoaded && (
      <li className="contact-menu-item">
        <button
          type="button"
          onClick={() => openLiveChat()}
          className="chat-button"
        >
          <span className="label">{buttonText}</span>
          <span className="icon">{buttonIcon}</span>
        </button>
      </li>
    )
  )
}

export default SalesforceLiveAgent
