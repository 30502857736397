import subMonths from 'date-fns/subMonths'
import numeral from 'numeral'
import { AccountFund } from '../redux/account-funds/account-funds.model'
import {
  Fund,
  StrategyFundAllocation,
} from '../components/switch-contentful-modal/SwitchContentfulModal.types'

// These need to match the ones we have in our CMS.
export const FUNDS_TEL_CODES = {
  MF_INCOME: 'FFM0003010',
  MF_PROPERTY_INFRASTRUCTURE: 'FFM0003012',
  MF_NZ_GROWTH: 'FFM0003000',
  MF_AUS_GROWTH: 'FFM0003001',
  MF_INTERNATIONAL_GROWTH: 'FFM0003007',
  PM_INCOME: 'FFM0003013',
  PM_PROPERTY_INFRASTRUCTURE: 'FFM0003009',
  PM_NZ: 'FFM0003004',
  PM_AUS: 'FFM0003005',
  PM_INTERNATIONAL: 'FFM0003006',
  MF_CONSERVATIVE_FUND: 'FFM0003049',
  MF_GROWTH_FUND: 'FFM0003050',
  KS_GROWTH_FUND: 'FFM0003008',
  KS_CONSERVATIVE_FUND: 'FFM0003011',
  FFTWO_PRESERVATION: 'FFM0003019',
  FFTWO_CONSERVATIVE: 'FFM0003016',
  FFTWO_BALANCED: 'FFM0003014',
  FFTWO_GROWTH: 'FFM0003018',
  FFTWO_EQUITY: 'FFM0003017',
  LIFE_BALANCED: 'FFM0003020',
  LIFE_CONSERVATIVE: 'FFM0003021',
  LIFE_GROWTH: 'FFM0003023',
}

export const APEX_FUNDS_CODES = {
  FFKP_CASH: '800300',
  FFKP_CASH_PLUS: '800301',
  FFKP_DEFAULT_CONSERVATIVE: '800302',
  FFKP_CONSERVATIVE: '800303',
  FFKP_DEFAULT: '800304',
  FFKP_BALANCED: '800305',
  FFKP_GROWTH: '800306',
  FFIF_OR_FFKP_MIXED_MANDATE: 'custom',
}

const MIXED_PORTFOLIO_FUNDS_CODES = [
  FUNDS_TEL_CODES.KS_CONSERVATIVE_FUND,
  FUNDS_TEL_CODES.KS_GROWTH_FUND,
  FUNDS_TEL_CODES.MF_CONSERVATIVE_FUND,
  FUNDS_TEL_CODES.MF_GROWTH_FUND,
]

export const isSingleSectorFunds = (telCode: string) =>
  !MIXED_PORTFOLIO_FUNDS_CODES.includes(telCode)

export const isMixedPortfolioFunds = (telCode: string) =>
  MIXED_PORTFOLIO_FUNDS_CODES.includes(telCode)

// We show these on the fund pickers and on the graph
export const FUNDS_NAMES = {
  [FUNDS_TEL_CODES.MF_CONSERVATIVE_FUND]: 'Managed Funds Conservative Fund',
  [FUNDS_TEL_CODES.MF_GROWTH_FUND]: 'Managed Funds Growth Fund',
  [FUNDS_TEL_CODES.MF_INCOME]: 'Managed Funds Income Fund',
  [FUNDS_TEL_CODES.MF_PROPERTY_INFRASTRUCTURE]:
    'Managed Funds Property & Infrastructure Fund',
  [FUNDS_TEL_CODES.MF_NZ_GROWTH]: 'Managed Funds New Zealand Growth Fund',
  [FUNDS_TEL_CODES.MF_AUS_GROWTH]: 'Managed Funds Australian Growth Fund',
  [FUNDS_TEL_CODES.MF_INTERNATIONAL_GROWTH]:
    'Managed Funds International Growth Fund',
  [FUNDS_TEL_CODES.PM_INCOME]: 'Premium Income Fund',
  [FUNDS_TEL_CODES.PM_PROPERTY_INFRASTRUCTURE]:
    'Premium Property & Infrastructure Fund',
  [FUNDS_TEL_CODES.PM_NZ]: 'Premium New Zealand Fund',
  [FUNDS_TEL_CODES.PM_AUS]: 'Premium Australian Fund',
  [FUNDS_TEL_CODES.PM_INTERNATIONAL]: 'Premium International Fund',
  [FUNDS_TEL_CODES.KS_GROWTH_FUND]: 'KiwiSaver Growth Fund',
  [FUNDS_TEL_CODES.KS_CONSERVATIVE_FUND]: 'KiwiSaver Conservative Fund',
}

export const FUNDS_WITH_PERFORMANCE_CHARTS = [
  FUNDS_TEL_CODES.MF_INCOME,
  FUNDS_TEL_CODES.MF_NZ_GROWTH,
  FUNDS_TEL_CODES.MF_AUS_GROWTH,
  FUNDS_TEL_CODES.MF_INTERNATIONAL_GROWTH,
  FUNDS_TEL_CODES.MF_PROPERTY_INFRASTRUCTURE,
  FUNDS_TEL_CODES.PM_INCOME,
  FUNDS_TEL_CODES.PM_PROPERTY_INFRASTRUCTURE,
  FUNDS_TEL_CODES.PM_NZ,
  FUNDS_TEL_CODES.PM_AUS,
  FUNDS_TEL_CODES.PM_INTERNATIONAL,
]

export const hasPerformanceChart = (fund: string): boolean =>
  FUNDS_WITH_PERFORMANCE_CHARTS.includes(fund)

export const BENCHMARK_NAMES = {
  INCOME_FUND_COMPOSITE_INDEX: 'Income Fund Composite Index*',
  PROPERTY_AND_INFRASTRUCTURE_FUND_COMPOSITE_INDEX:
    'Property and Infrastructure Fund Composite Index*',
  AUSTRALIAN_EQUITY_COMPOSITE_INDEX: 'Australian Equity Composite Index*',
  INTERNATIONAL_EQUITY_COMPOSITE_INDEX: 'Equity Composite Index*',
  NZ_50_GROSS_DOLLAR_VALUE: 'S&P/NZX50 Gross Index',
  PREMIUM_INCOME_FUND_COMPOSITE_INDEX: 'Premium Income Fund Composite Index*',
  NINETY_DAY_BANK_BILL_RATE: '90 Day Bank Bill Rate',
  MF_CONSERVATIVE_COMPOSITE: 'Managed Fund Conservative Composite Index',
  MF_GROWTH_COMPOSITE: 'Managed Fund Growth Composite Index',
  KS_GROWTH_COMPOSITE: 'KiwiSaver Growth Composite Index',
  KS_CONSERVATIVE_COMPOSITE: 'KiwiSaver Conservative Composite Index',
}

const BENCHMARKS_AVAILABLE = {
  [FUNDS_TEL_CODES.MF_INCOME]: [
    BENCHMARK_NAMES.INCOME_FUND_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.MF_PROPERTY_INFRASTRUCTURE]: [
    BENCHMARK_NAMES.PROPERTY_AND_INFRASTRUCTURE_FUND_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.MF_NZ_GROWTH]: [
    BENCHMARK_NAMES.NZ_50_GROSS_DOLLAR_VALUE,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.MF_AUS_GROWTH]: [
    BENCHMARK_NAMES.AUSTRALIAN_EQUITY_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.MF_INTERNATIONAL_GROWTH]: [
    BENCHMARK_NAMES.INTERNATIONAL_EQUITY_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.PM_INCOME]: [
    BENCHMARK_NAMES.PREMIUM_INCOME_FUND_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.PM_PROPERTY_INFRASTRUCTURE]: [
    BENCHMARK_NAMES.PROPERTY_AND_INFRASTRUCTURE_FUND_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.PM_NZ]: [
    BENCHMARK_NAMES.NZ_50_GROSS_DOLLAR_VALUE,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.PM_AUS]: [
    BENCHMARK_NAMES.AUSTRALIAN_EQUITY_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.PM_INTERNATIONAL]: [
    BENCHMARK_NAMES.INTERNATIONAL_EQUITY_COMPOSITE_INDEX,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.MF_CONSERVATIVE_FUND]: [
    BENCHMARK_NAMES.MF_CONSERVATIVE_COMPOSITE,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.MF_GROWTH_FUND]: [
    BENCHMARK_NAMES.MF_GROWTH_COMPOSITE,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.KS_GROWTH_FUND]: [
    BENCHMARK_NAMES.KS_GROWTH_COMPOSITE,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
  [FUNDS_TEL_CODES.KS_CONSERVATIVE_FUND]: [
    BENCHMARK_NAMES.KS_CONSERVATIVE_COMPOSITE,
    BENCHMARK_NAMES.NINETY_DAY_BANK_BILL_RATE,
  ],
}

export const getBenchmarksForFund = (fund: string) => {
  const benchmarks = BENCHMARKS_AVAILABLE[fund]
  if (!benchmarks) {
    throw new Error(`Benchmarks not found for fund: ${fund}.`)
  }
  return benchmarks
}

/* eslint-disable max-len */
const BENCHMARKS_DESCRIPTION = {
  [BENCHMARK_NAMES.PROPERTY_AND_INFRASTRUCTURE_FUND_COMPOSITE_INDEX]:
    '* 65% S&P Global Infrastructure Index (70% hedged to NZD), 15% S&P/ASX200 A-REIT Index (70% hedged to NZD) and 20% S&P/NZX All Real Estate Index',
  [BENCHMARK_NAMES.INCOME_FUND_COMPOSITE_INDEX]:
    '* S&P/NZX 2 Year Swap Index (1/11/2016 to now)\nNew Zealand Government Stock Index (Inception to 31/10/2016)',
  [BENCHMARK_NAMES.AUSTRALIAN_EQUITY_COMPOSITE_INDEX]:
    '* S&P/ASX 200 Accumulation Index 70% hedged into NZD (1/4/2015 to now)\nS&P ASX 300 Industrials ex top 20 70% hedged to NZD (1/2/2012 - 31/3/2015)\nS&P/ASX Small Industrials Index (Inception to 31/1/2012)',
  [BENCHMARK_NAMES.INTERNATIONAL_EQUITY_COMPOSITE_INDEX]:
    '* S&P Global LargeMidCap Index (50% hedged into NZD)',
  [BENCHMARK_NAMES.PREMIUM_INCOME_FUND_COMPOSITE_INDEX]:
    '* S&P/NZX 2 Year Swap Index (1/11/2016 to now)\nNew Zealand Government Stock Index (Inception to 31/10/2016)',
}

const DEFAULT_BALANCED_TARGET_CLIENT_SUMMARY =
  'Our Balanced Fund and our Default Fund are most suitable if you are a medium to long term investor who wants a balance between volatility of returns and achieving higher potential returns.\nAn enhanced passive investment style may be used at times in the Default Fund.'
const CONSERVATIVE_DEFAULT_CONSERVATIVE_TARGET_CLIENT_SUMMARY =
  'Conservative funds are most suitable if you are a short term or naturally cautious investor or value lower volatility of returns over achieving higher potential returns. We show you two conservative options below.\nWe also have a Cash Fund option, which people who are making a withdrawal in the short term might like to consider. The risk indicator of the Cash Fund is 1 and there is no minimum timeframe for this fund. You can find more information about the Cash Fund in the Product Disclosure Statement (PDS).'
const IPQ_TARGET_CLIENT_SUMMARY: { [key: string]: string } = {
  FTCO:
    'This investment strategy is most suitable if you are a short term or naturally cautious investor who is looking to make a withdrawal within the short term, or who values lower volatility of returns over achieving potential higher returns.\nWhile this strategy is designed to be conservative in nature, in times of heightened market volatility the value of your investment may go up or down.',
  FCMF:
    'This investment strategy is most suitable if you are a short term or naturally cautious investor,  you are looking to make a withdrawal within the short term, or you value lower volatility of returns over achieving potential higher returns.\nWhile this strategy is designed to be conservative in nature, in times of heightened market volatility the value of your investment may go up or down.',
  FB21:
    'This investment strategy is most suitable if you are a medium to long term investor who wants a balance between volatility of returns and achieving potential higher returns.',
  FLGS:
    'This investment strategy is most suitable if you are a long term investor who can tolerate volatility of returns in the expectation of potential higher returns and has time on their side.',
  FLCS:
    'This investment strategy is most suitable if you are a short term or naturally cautious investor who is looking to make a withdrawal within the short term, or who values lower volatility of returns over achieving potential higher returns.\nWhile this strategy is designed to be conservative in nature, in times of heightened market volatility the value of your investment may go up or down.',
  FLBS:
    'This investment strategy is most suitable if you are a medium to long term investor who wants a balance between volatility of returns and achieving potential higher returns.',
  FGMF:
    'This investment strategy is most suitable if you are a long term investor who can tolerate volatility of returns in the expectation of potential higher returns, and has time on their side',
  FTGS:
    'This investment strategy is most suitable if you are a long term investor who can tolerate volatility of returns in the expectation of potential higher returns and who has time on their side.',
  FTBA:
    'This investment strategy is most suitable if you are a medium or long term investor who wants a balance between volatility of returns and achieving higher potential returns.',
  FGKS:
    'This investment strategy is most suitable if you are a long term investor who can tolerate volatility of returns in the expectation of potential higher returns and who has time on their side.',
  FBSK:
    'This investment strategy is most suitable if you are a medium to long term investor who wants a balance between volatility of returns and achieving potential higher returns.',
  FCKS:
    'This investment strategy is most suitable if you are a short term or naturally cautious investor who is looking to make a withdrawal in the short term, or who values lower volatility of returns over achieving potential higher returns.\nWhile this strategy is designed to be conservative in nature, in times of heightened market volatility the value of your investment may go up or down.',
  [APEX_FUNDS_CODES.FFKP_CASH]:
    'This Fund is suitable for a short term or naturally cautious investor who is looking to make a withdrawal within 12 months or who is most interested in low volatility of returns over achieving potential higher returns.',
  [APEX_FUNDS_CODES.FFKP_DEFAULT_CONSERVATIVE]: CONSERVATIVE_DEFAULT_CONSERVATIVE_TARGET_CLIENT_SUMMARY,
  [APEX_FUNDS_CODES.FFKP_CONSERVATIVE]: CONSERVATIVE_DEFAULT_CONSERVATIVE_TARGET_CLIENT_SUMMARY,
  [APEX_FUNDS_CODES.FFKP_DEFAULT]: DEFAULT_BALANCED_TARGET_CLIENT_SUMMARY,
  [APEX_FUNDS_CODES.FFKP_BALANCED]: DEFAULT_BALANCED_TARGET_CLIENT_SUMMARY,
  [APEX_FUNDS_CODES.FFKP_GROWTH]:
    'This investment fund is most suitable if you are a long term investor who can tolerate volatility of returns in the expectation of potential higher returns, or who has time on their side.',
}
/* eslint-enable max-len */

export const APEX_MIX_MANDATE_SHORTNAME = 'Mix of Funds'

export const getIPQTargetClientSummary = (result: string) =>
  IPQ_TARGET_CLIENT_SUMMARY[result]

export const getBenchmarkDescription = (benchmark: string) =>
  BENCHMARKS_DESCRIPTION[benchmark]

export const isMoreThan5Years = (inceptionDate: Date) =>
  subMonths(Date.now(), 5 * 12) > inceptionDate

/**
 * Funds don't have Salesforce code by default, but sometimes we want to link the fund to
 * their mapped strategy (the strategy only has one fund) section on product investment options page.
 * This function returns the mapped strategy's Salesforce code which is used as anchor
 * on product investment options page.
 * @param telCode
 */
export const getStrategyAnchorForMixedAssetFund = (telCode: string) => {
  switch (telCode) {
    case FUNDS_TEL_CODES.KS_CONSERVATIVE_FUND:
    case FUNDS_TEL_CODES.MF_CONSERVATIVE_FUND:
      return 'Conservative'
    case FUNDS_TEL_CODES.KS_GROWTH_FUND:
    case FUNDS_TEL_CODES.MF_GROWTH_FUND:
      return 'Growth'
    default:
      return null
  }
}

/**
 * The "No Strategy" label is just in case we ever want to scroll/link to a
 * fund with no strategy so URL hash fails nicely.
 * Not happening currently AFAIK.
 */
export const getSalesforceCode = (telCode: string) =>
  getStrategyAnchorForMixedAssetFund(telCode) || 'No Strategy'

type PatialFund = Pick<AccountFund, 'assetCode' | 'dollarBalance'>

export const hasFundBalance = (fundCode: string, funds: PatialFund[]) =>
  (funds || [])
    .filter((f) => f.assetCode === fundCode)
    .some((x) => x.dollarBalance > 0)

export const getFundPath = () => `${process.env.REACT_APP_WEBSITE_URL}/`

export const isFundAvailable = (fund: Fund, accountFunds: AccountFund[]) =>
  !fund.isSuspended &&
  (!fund.legacy || hasFundBalance(fund.telCode, accountFunds))

export type Allocation = Pick<
  StrategyFundAllocation,
  'allocationPercentage'
> & {
  fund: {
    name: string
    shortName: string
  }
}

export const calculateFundsAllocations = (
  funds: AccountFund[],
  accountTotal: number
) =>
  funds
    .sort((a, b) => a.fundDisplayName.localeCompare(b.fundDisplayName))
    .map((fund) => {
      const percentageFormatted = numeral(fund.dollarBalance)
        .subtract(fund.pieTaxLiability)
        .divide(accountTotal)
        .multiply(100)
        .format('0,0.00')

      return {
        allocationPercentage: Number(percentageFormatted),
        fund: {
          name: fund.fundDisplayName,
          shortName: fund.fundDisplayName,
        },
      } as Allocation
    })

export const mapAccountDetailsFundsToAllocations = (
  funds: any[] = []
): Allocation[] =>
  funds
    .sort((a, b) => a.fundName.localeCompare(b.fundName))
    .map((f) => ({
      allocationPercentage: (f.investmentPercentageSplit || 0).toString(),
      fund: {
        name: f.fundName,
        shortName: f.fundShortName,
      },
    }))
