import {
  AccountTransactionsState,
  TransactionFilters,
} from './account-transactions.model'
export enum actionTypes {
  ACCOUNT_TRANSACTIONS_REQUEST = 'ACCOUNT_TRANSACTIONS_REQUEST',
  ACCOUNT_TRANSACTIONS_REQUEST_SUCCESS = 'ACCOUNT_TRANSACTIONS_REQUEST_SUCCESS',
  ACCOUNT_ALL_TRANSACTIONS_REQUEST_SUCCESS = 'ACCOUNT_ALL_TRANSACTIONS_REQUEST_SUCCESS',
  ACCOUNT_TRANSACTIONS_REQUEST_FAILURE = 'ACCOUNT_TRANSACTIONS_REQUEST_FAILURE',
  ACCOUNT_TRANSACTIONS_UPDATE_FILTERS = 'ACCOUNT_TRANSACTIONS_UPDATE_FILTERS',
  ACCOUNT_TRANSACTIONS_SET_IS_LOADING_PDF = 'ACCOUNT_TRANSACTIONS_SET_IS_LOADING_PDF',
}

// Payload
export type TransactionsPayload = {
  userId: string
  accountId: string
  offset: number
  shouldReturnAll: boolean
  filters: TransactionFilters
  isCSV: boolean
}

// Types

export type AccountTransactionsRequestActionType = {
  type: actionTypes.ACCOUNT_TRANSACTIONS_REQUEST
  payload: TransactionsPayload
}
export type AccountTransactionsRequestSuccessActionType = {
  type: actionTypes.ACCOUNT_TRANSACTIONS_REQUEST_SUCCESS
  payload: AccountTransactionsState
}
export type AccountAllTransactionsRequestSuccessActionType = {
  type: actionTypes.ACCOUNT_ALL_TRANSACTIONS_REQUEST_SUCCESS
  payload: AccountTransactionsState
}
export type AccountTransactionsSetIsLoadingPDFActionType = {
  type: actionTypes.ACCOUNT_TRANSACTIONS_SET_IS_LOADING_PDF
  payload: AccountTransactionsState
}
export type AccountTransactionsRequestFailureActionType = {
  type: actionTypes.ACCOUNT_TRANSACTIONS_REQUEST_FAILURE
  payload: string
}
export type AccountTransactionsUpdateFiltersActionType = {
  type: actionTypes.ACCOUNT_TRANSACTIONS_UPDATE_FILTERS
  payload: TransactionsPayload
}

// Actions

export const AccountTransactionsRequestAction = (
  payload: TransactionsPayload
): AccountTransactionsRequestActionType => ({
  type: actionTypes.ACCOUNT_TRANSACTIONS_REQUEST,
  payload,
})

export const AccountTransactionsRequestSuccessAction = (
  accountTransactions: AccountTransactionsState
): AccountTransactionsRequestSuccessActionType => ({
  type: actionTypes.ACCOUNT_TRANSACTIONS_REQUEST_SUCCESS,
  payload: accountTransactions,
})

export const AccountAllTransactionsRequestSuccessAction = (
  accountTransactions: AccountTransactionsState
): AccountAllTransactionsRequestSuccessActionType => ({
  type: actionTypes.ACCOUNT_ALL_TRANSACTIONS_REQUEST_SUCCESS,
  payload: accountTransactions,
})

export const AccountTransactionsRequestFailureAction = (
  message: string
): AccountTransactionsRequestFailureActionType => ({
  type: actionTypes.ACCOUNT_TRANSACTIONS_REQUEST_FAILURE,
  payload: message,
})

export const AccountTransactionsSetIsLoadingPDFAction = (
  payload: AccountTransactionsState
): AccountTransactionsSetIsLoadingPDFActionType => ({
  type: actionTypes.ACCOUNT_TRANSACTIONS_SET_IS_LOADING_PDF,
  payload,
})

export const UpdateAccountTransactionsFiltersAction = (
  payload: TransactionsPayload
): AccountTransactionsUpdateFiltersActionType => ({
  type: actionTypes.ACCOUNT_TRANSACTIONS_UPDATE_FILTERS,
  payload,
})

export type AccountTransactionsActions =
  | AccountTransactionsRequestActionType
  | AccountTransactionsRequestSuccessActionType
  | AccountAllTransactionsRequestSuccessActionType
  | AccountTransactionsRequestFailureActionType
  | AccountTransactionsUpdateFiltersActionType
