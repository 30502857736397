import 'rxjs'
import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import { interval } from 'rxjs/observable/interval'
import * as actions from './accounts.actions'
import { AccountsState, Account } from './accounts.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type LightStore = { getState: Function; dispatch: Function }

export const accountsEpic: Epic<actions.AccountsActions, AccountsState> = (
  action$: ActionsObservable<actions.AccountsOverviewRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNTS_OVERVIEW_REQUEST)
    .switchMap((action) => {
      // Accounts data
      const accountData = store.getState().accounts

      // If account data has already been cached then use that instead of making a request to the server.
      if (accountData && accountData.accounts && accountData.accounts.length) {
        return Observable.of(
          actions.AccountsOverviewRequestSuccessAction(accountData)
        )
      }

      return ajax
        .get(`${ffmAppServerUrl}/api/users/${action.payload}/accounts`, {
          'x-requesting-platform': 'FFO_WEB',
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        })
        .map((response) =>
          actions.AccountsOverviewRequestSuccessAction(
            response.response.accountOverview
          )
        )
        .catch((err) => {
          if (err.status === 404) {
            return Observable.of(
              actions.AccountsOverviewRequestNoAccountsAction(
                err.response.message
              )
            )
          }
          return Observable.throw(
            actions.AccountsOverviewRequestFailureAction(
              `Oops, something has gone wrong! Please try again shortly. If this keeps happening, please get in touch on 0508 347 437 or email us on <a href="mailto:enquiries@fisherfunds.co.nz">enquiries@fisherfunds.co.nz</a>.`
            )
          )
        })
    })

export const accountsFundsEpic: Epic<actions.AccountsActions, AccountsState> = (
  action$: ActionsObservable<actions.AccountsOverviewFundsRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNTS_OVERVIEW_FUNDS_REQUEST)
    .throttle(() => interval(1000))
    .switchMap((action) => {
      // Retrieving store data
      const stateStore = store.getState()
      const storeAccounts = stateStore.accounts
      const clientID = stateStore.user.clientId
      let accounts: { number: any; name: any }[] = []
      let hasAccountFunds: boolean = false

      // Getting all accounts product name and name
      if (storeAccounts.accounts && storeAccounts.accounts.length) {
        storeAccounts.accounts.forEach((account: any) => {
          // Checking if funds were loaded
          hasAccountFunds =
            hasAccountFunds || !!(account.funds && account.funds.length)

          if (!account.isDepositMode)
            accounts.push({
              number: account.accountNumber,
              name: account.productName,
            })
        })
      }

      // Has funds account available
      if (hasAccountFunds) {
        return Observable.concat(
          Observable.of(actions.AccountsOverviewRequestSkipAction()),
          Observable.of(
            action.payload.nextAction(action.payload.nextActionParams)
          )
        )
      }

      // API post to load rest of the funds account data
      return ajax
        .post(
          `${ffmAppServerUrl}/api/users/${action.payload.userId}/accounts/funds`,
          {
            accounts,
            clientID,
          },
          {
            'Content-Type': 'application/json',
            'x-requesting-platform': 'FFO_WEB',
            Authorization:
              'Bearer ' + store.getState().authorisation.authorisationToken,
          }
        )
        .map((response) => {
          // accounts response data
          const accountsResponse = response.response

          // Store accounts
          storeAccounts.accounts.forEach((account: any, key: any) => {
            // Getting new fund data
            const fundAccount = accountsResponse.accounts.find(
              (acc: Account) => acc.accountID === account.accountID
            )

            // Updating funds
            if (fundAccount && fundAccount.funds) {
              storeAccounts.accounts[key].funds = fundAccount.funds
            }

            // Updating owners
            if (fundAccount && fundAccount.owners) {
              storeAccounts.accounts[key].owners = fundAccount.owners
            }
          })

          // Return account success
          return actions.AccountsOverviewRequestSuccessAction(storeAccounts)
        })
        .mergeMap(() => {
          return Observable.concat(
            Observable.of(
              action.payload.nextAction(action.payload.nextActionParams)
            )
          )
        })
        .catch((err) =>
          Observable.of(
            actions.AccountsOverviewRequestFailureAction(
              'Accounts request failed: ' + err
            )
          )
        )
    })
