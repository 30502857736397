import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import * as actions from './account-funds.actions'
import * as accountsActions from '../accounts/accounts.actions'
import * as reportsActions from '../reports/reports.actions'
import { AccountFundsState, AccountFund } from './account-funds.model'
import { Account } from '../accounts/accounts.model'

type LightStore = { getState: Function; dispatch: Function }

export function hasFundsForAccount(
  accountId: String,
  funds: Array<AccountFund>
): boolean {
  const fund = funds.find((fund) => fund.accountNumber === accountId)
  return Boolean(fund)
}

export const accountFundsEpic: Epic<
  actions.AccountFundsActions | accountsActions.AccountsActions,
  AccountFundsState
> = (
  action$: ActionsObservable<actions.AccountFundsRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNT_FUNDS_REQUEST)
    .switchMap((action) => {
      // state store
      const stateStore = store.getState()

      // accounts from store
      const accountsFromStore = stateStore.accounts

      // get current account
      const currentAccount = accountsFromStore.accounts.find(
        (acc: Account) => acc.accountID === action.payload.accountId
      )

      const accountFunds = {
        productName: currentAccount.productName,
        accountTotal: currentAccount.accountTotal,
        accountNumber: currentAccount.accountNumber,
        switchURL: currentAccount.switchURL,
        funds: currentAccount?.funds || [],
      } as AccountFundsState

      return Observable.of(
        actions.AccountFundsRequestSuccessAction(accountFunds)
      )
    })
    .catch((err) =>
      Observable.of(
        accountsActions.AccountsOverviewRequestFailureAction(
          'Accounts request failed: ' + err
        )
      )
    )

export const investmentProfileEpic: Epic<
  | actions.AccountFundsActions
  | accountsActions.AccountsActions
  | reportsActions.ReportsActions,
  AccountFundsState
> = (
  action$: ActionsObservable<
    actions.AccountFundsInvestmentProfileRequestActionType
  >,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNT_FUNDS_INVESTMENT_PROFILE_REQUEST)
    .mergeMap((action) => {
      return Observable.of(
        accountsActions.AccountsOverviewFundsRequestAction(
          action.payload.userId,
          actions.AccountFundsRequestAction,
          { userId: action.payload.userId, accountId: action.payload.accountId }
        )
      )
    })
    .catch((err) => {
      return Observable.of(
        accountsActions.AccountsOverviewRequestFailureAction(
          'Accounts request failed: ' + err
        )
      )
    })
