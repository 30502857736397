import * as React from 'react'

import Button from '@material-ui/core/Button'
import MuiLink from '@material-ui/core/Link'

import InfoMessage from '../../components/info-message/InfoMessage'
import NewPasswordFields from '../form/new-password/NewPasswordFields'
import RegisterLoginLinkBox from '../../components/register-login-link-box/RegisterLoginLinkBox'

// @ts-ignore
import { useForm } from 'react-hook-form'

export type FormValues = {
  newPassword: {
    newPassword: string
    confirmPassword: string
  }
}

interface ResetPasswordFormProps {
  isLoading: boolean
  submit: (submittedValues: FormValues) => void
}

export default function ResetPasswordForm(props: ResetPasswordFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const { isLoading, submit } = props

  const onSubmitEvent = (data: any) => {
    if (
      !data.newPassword ||
      !data.confirmPassword ||
      data.newPassword !== data.confirmPassword
    ) {
      return
    }

    submit({
      newPassword: {
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      },
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitEvent)} className="form">
        <InfoMessage>
          Your new password needs to be comprised of a minimum of 12 characters,
          containing at least 1 uppercase letter, 1 lowercase letter and 1
          digit. Passwords that could be easily guessed or you've used in the
          past may not be accepted. If you require any assistance, please{' '}
          <MuiLink
            href="https://fisherfunds.co.nz/contact/"
            underline="always"
            rel="noopener noreferrer"
          >
            contact us
          </MuiLink>
          .
        </InfoMessage>

        <NewPasswordFields
          disabled={isLoading}
          register={register}
          watch={watch}
          errors={errors}
        />

        <div className="action-group mt-md">
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Set New Password
          </Button>
        </div>
      </form>
      <RegisterLoginLinkBox type="login" />
    </>
  )
}
