import { formatCurrency } from '../../common/currency-helper'
import './Currency.scss'

interface CurrencyProps {
  value: number
  format?: string
}

export default function CurrencyTotal(props: CurrencyProps) {
  const { value, format = '0,0.00' } = props
  return (
    <span className="currency__total">
      <span className="currency__dollar-sign">$</span>
      {formatCurrency(value, format)}
    </span>
  )
}
