export enum actionTypes {
  SET_CAPS_LOCK = 'SET_CAPS_LOCK',
}

export type SetCapsLockType = {
  type: actionTypes.SET_CAPS_LOCK
  payload: boolean
}
export const SetCapsLock = (capsLockEnabled: boolean): SetCapsLockType => ({
  type: actionTypes.SET_CAPS_LOCK,
  payload: capsLockEnabled,
})

export type CapsLockActions = SetCapsLockType
