export default {
  PIR_RATES: [
    {
      code: 'PIR0',
      shortDescription: 'PIR 0%',
      longDescription: 'PIR 0%',
    },
    {
      code: 'PIR3',
      shortDescription: 'PIR 10.5%',
      longDescription: 'Low PIR 10.5%',
    },
    {
      code: 'PIR1',
      shortDescription: 'PIR 17.5%',
      longDescription: 'Mid PIR 17.5%',
    },
    {
      code: 'PIR2',
      shortDescription: 'PIR 28%',
      longDescription: 'Top PIR 28%',
    },
  ],
}
