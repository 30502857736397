import * as React from 'react'
import './IFrame.scss'
import FFMAlert from '../ffm-alert/FFMAlert'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import Alert from 'react-s-alert'

const threeWebFeetMessage: string =
  process.env.REACT_APP_3_WEB_FEET_APPS_MESSAGE

interface IFrameProps {
  title: string
  url: string
  isTest?: boolean
  token: string
}

interface IFrameState {
  width: number
  height: number
  iframeLoading: boolean
  iframeSource: string
}

const CONTAINER_CLASS = 'main-container'
const IFRAME_HTML_ID = 'iframe-embed'

export default class IFrame extends React.Component<IFrameProps, IFrameState> {
  constructor(props: IFrameProps) {
    super(props)

    this.state = {
      width: 0,
      height: 0,
      iframeLoading: true,
      iframeSource: '',
    }

    this.updateDimensions = this.updateDimensions.bind(this)
    this.iframeLoaded = this.iframeLoaded.bind(this)
  }

  updateDimensions() {
    const iframe = document.getElementById(IFRAME_HTML_ID)
    if (!iframe) {
      return // page doesn't have an iframe!
    }
    if (typeof iframe.getBoundingClientRect === 'undefined') {
      return // browser doesn't support getting the size of the container via JS
    }
    const iframePosition = iframe.getBoundingClientRect()
    const containers = document.getElementsByClassName(CONTAINER_CLASS)
    if (containers.length === 0) {
      return // page doesn't have the main container that sets the iframe height
    }
    const container = containers[0]
    if (typeof container.getBoundingClientRect === 'undefined') {
      return // browser doesn't support getting the size of the container via JS
    }
    // hide the iframe - otherwise if the iframe is already bigger than the container, our
    // iframe can never shrink
    const oldDisplay = iframe.style.display
    iframe.style.display = 'none'
    // measure the container
    const containerPosition = container.getBoundingClientRect()
    // restore the iframe
    iframe.style.display = oldDisplay
    this.setState({
      width: containerPosition.width,
      height: containerPosition.bottom - iframePosition.top - 80,
    })
  }

  async componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)

    if (threeWebFeetMessage) {
      this.setState({ iframeLoading: false })
    } else if (!this.props.isTest) {
      document.getElementById(IFRAME_HTML_ID).onload = () => this.iframeLoaded()
    }

    const fetchData = async () => {
      if (this.props.token) {
        await fetch(this.props.url, {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        })
          .then(function (response) {
            const r = response.text()
            if (response.status && response.status !== 200) {
              Alert.info(
                'There has been an unexpected issue with this form. Please try again or get in touch.',
                {
                  timeout: 'none',
                }
              )
            }
            return r
          })
          .then((url) => {
            if (url.startsWith('https://')) {
              this.setState({ iframeSource: url })
            }
          })
          .catch((err) => {
            console.error('Failed to fetch page: ', err)
            this.setState({ iframeSource: this.props.url })
          })
      } else {
        this.setState({ iframeSource: this.props.url })
      }
    }

    await fetchData()
  }

  iframeLoaded() {
    this.setState({ iframeLoading: false })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render() {
    if (threeWebFeetMessage) {
      Alert.info(threeWebFeetMessage, {
        timeout: 'none',
      })
    }
    const { title } = this.props

    return (
      <>
        <FFMAlert />

        {threeWebFeetMessage && (
          <div className="scroll-zone text-align-left">
            <div className="alert alert-info">App currently available.</div>
          </div>
        )}

        {this.state.iframeLoading && !threeWebFeetMessage && (
          <LoadingSpinner center={true} className="my-md" />
        )}

        {!threeWebFeetMessage && (
          <div
            className={`scroll-zone text-align-left iframe${
              this.state.iframeLoading ? ' iframe-loading' : ''
            }`}
          >
            <iframe
              id={IFRAME_HTML_ID}
              height={this.state.height}
              title={title}
              src={this.state.iframeSource}
            />
          </div>
        )}
      </>
    )
  }
}
