import React from 'react'
import cn from 'classnames'

type AdvicePercentageLineProps = {
  percentageComplete: number
  className?: string
}
const AdvicePercentageLine = ({
  percentageComplete,
  className,
}: AdvicePercentageLineProps) => (
  <div
    className={cn(
      {
        [`${className}`]: !!className,
      },
      'progress__bar'
    )}
  >
    <div
      className="progress__bar__percentage"
      style={{ width: `${percentageComplete}%` }}
    />
  </div>
)

export default AdvicePercentageLine
