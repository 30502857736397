import kebabCase from 'lodash/kebabCase'
import './RadioMenu.scss'
import Radio from '../../common/forms/Radio'

export type RadioMenuOption<T> = {
  label: string
  disabled?: boolean
  value: T
}

export type RadioMenuProps<T> = {
  options: RadioMenuOption<T>[]
  name: string
  value?: RadioMenuOption<T>
  size?: 'small' | 'medium'
  direction: 'horizontal' | 'vertical'
  onSelect?: (option: RadioMenuOption<T>) => void
}
export const RadioMenu = <T,>({
  options,
  value,
  direction,
  size = 'medium',
  name,
  onSelect,
}: RadioMenuProps<T>) => (
  <div className={`input-radio-menu__container--${direction}`}>
    {options.map((option) => (
      <span
        key={option.label}
        className={`input-radio-menu__item input-radio-menu__item-${size}`}
      >
        <Radio
          id={`${kebabCase(option.label)}-${name}`}
          border="gray"
          theme="white"
          size={size}
          label={option.label}
          checked={value?.label === option.label}
          onChange={() => {
            if (onSelect) {
              onSelect(option)
            }
          }}
        />{' '}
        <label htmlFor={`${kebabCase(option.label)}-${name}`}>
          {option.label}
        </label>
      </span>
    ))}
  </div>
)
