import React from 'react'
import { useGovernmentContribution } from '../accounts-overview/useGovernmentContribution'
import { EligibleView, EligilbleViewProps } from './EligibleView'
import { IneligibleView } from './IneligibleView'
import { GovernmentContributionProps } from '../accounts-overview/GovernmentContribution'

export type GovernmentContributionInDetailProps = EligilbleViewProps &
  GovernmentContributionProps

export const GovernmentContributionInDetail: React.FC<GovernmentContributionInDetailProps> = (
  props
) => {
  const {
    accountId,
    externalRef,
    productExternalName,
    ...governmentContributionProps
  } = props
  const { isEligible } = useGovernmentContribution(governmentContributionProps)
  if (isEligible) {
    return <EligibleView {...props} />
  }
  return <IneligibleView {...governmentContributionProps} />
}
