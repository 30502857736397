import React, { useRef } from 'react'
import cn from 'classnames'
import { ClickableProps } from '../clickable.model'
import '../clickable.scss'

interface SpecificLinkProps extends ClickableProps {
  focused?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
}

export type ButtonProps = SpecificLinkProps &
  Omit<React.HTMLProps<HTMLButtonElement>, 'size'>

const ICON_SIZES = {
  sm: 'small',
  md: 'default',
  lg: 'large',
}

export const Button = ({
  variant = 'default',
  color = 'primary',
  size = 'md',
  children,
  disabled = false,
  focused = false,
  iconLeft: IconLeft,
  iconRight: IconRight,
  className,
  type = 'button',
  inline = false, // only work when the variant is link
  ...rest
}: ButtonProps) => {
  const buttonRef = useRef(null)
  const iconFontSize = ICON_SIZES[size] as any
  return (
    <button
      className={cn('button', `button--${variant}`, `button--${size}`, {
        [`button--${color}`]: !!color,
        'button--disabled': disabled,
        'button--focused': focused,
        'button--link--inline': inline,
        [`${className}`]: !!className,
      })}
      disabled={disabled}
      type={type}
      ref={buttonRef}
      {...rest}
    >
      {IconLeft && (
        <span className="button__icon-left">
          <IconLeft fontSize={iconFontSize} />
        </span>
      )}
      <span className="button__text">{children}</span>
      {IconRight && (
        <span className="button__icon-right">
          <IconRight fontSize={iconFontSize} />
        </span>
      )}
    </button>
  )
}
