import React, { useLayoutEffect, useEffect, useState } from 'react'

import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { Dispatch, bindActionCreators } from 'redux'

import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import ChangePassword from '../../components/change-password/ChangePassword'
import ChangePIR from '../../components/change-pir/'
import ContactDetails from '../../components/contact-details/ContactDetails'
import PersonalDetails from '../../components/personal-details/PersonalDetails'
import ChangeUsername from '../../components/change-username/ChangeUsername'

import { TabPanel } from './components'

import './MyDetails.scss'
import {
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
} from '../../redux/layout/layout.actions'
import { Divider, Typography } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'
import { ChevronRight, ExitToApp } from '@material-ui/icons'

interface MyDetailsProps {
  nextStep: (path: string) => void
  setActiveMenu?: (name: string) => void
  setActiveAccountTab?: (value: string) => void
  params: {
    type: string
  }
  isUsernameChangeable: boolean
}

const detailsTabs = [
  {
    type: 'contact-details',
    label: 'Contact details',
    content: <ContactDetails />,
  },
  {
    type: 'change-username',
    label: 'Change username',
    content: <ChangeUsername />,
  },
  {
    type: 'change-password',
    label: 'Change password',
    content: <ChangePassword />,
  },
]

export function MyDetails(props: MyDetailsProps) {
  const { logout } = useAuth0()
  const [tabValue, setTabValue] = useState(0)
  const [activeBlock, setActiveBlock] = useState('')
  const [filteredTabs, setFilteredTabs] = useState([])
  const tabs = detailsTabs.filter((tab) => !filteredTabs.includes(tab.type))

  useEffect(() => {
    if (
      !props.isUsernameChangeable &&
      !filteredTabs.includes('change-username')
    ) {
      setFilteredTabs([...filteredTabs, 'change-username'])
    }
  }, [props.isUsernameChangeable, filteredTabs])

  const setActiveTab = (tabIndex: number) => {
    setTabValue(tabIndex)
    props.nextStep('/details/' + tabs[tabIndex].type)
  }

  const logoutWithRedirect = () => {
    logout({
      returnTo: `${window.location.origin}/logged-out`,
    })
  }

  function a11yProps(index: any) {
    return {
      id: `security-tab-${index}`,
      'aria-controls': `security-tabpanel-${index}`,
    }
  }

  function resetHtmlAndBodyStyles() {
    document.documentElement.style.overflow = null
    document.body.style.overflow = null
  }

  function handleWindowResize() {
    setTimeout(() => {
      // Exclude iPhone 4 & 5
      if (window.outerWidth > 320 && window.outerWidth < 481) {
        document.documentElement.style.overflow = 'hidden'
        document.body.style.overflow = 'hidden'
      } else {
        resetHtmlAndBodyStyles()
      }
    }, 300)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowResize, false)

    handleWindowResize()

    return () => {
      resetHtmlAndBodyStyles()

      window.removeEventListener('resize', handleWindowResize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { params } = props
    const mainContent = document.querySelector('.main-content')

    if (mainContent && !params.type) {
      window.scrollTo(0, 0)
      mainContent.scrollTo(0, 0)
    }

    props.setActiveMenu('details')

    // Update tab & mobile active block when there is a type on the parameter
    if (params.type && tabs.map((tab) => tab.type).includes(params.type)) {
      const tabIndex = tabs.findIndex((tab) => tab.type === params.type)
      setTabValue(tabIndex)
      setActiveBlock(params.type)

      setTimeout(
        () => {
          const element: HTMLDivElement = document.getElementById(
            'security-tabs-content'
          ) as HTMLDivElement

          if (element && window.outerWidth >= 481) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        },
        props.params.type === 'change-pin' ? 575 : 300
      )
    } else {
      setTabValue(0)
    }
    props.setActiveAccountTab('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params, tabs])

  const isActiveContent = (value: string) =>
    activeBlock === value ? ' active-content' : ''

  return (
    <Container maxWidth={false} className="my-details-container">
      <div className="content">
        <Paper
          elevation={0}
          className="left-content my-details-content my-details-card"
        >
          <PersonalDetails />
          <ul className="my-details-menu mobile-sm-only">
            <li className="mb-md">
              <Link onClick={() => setActiveBlock('change-pir')}>
                Prescribed Investor Rate (PIR) <ChevronRight />
              </Link>
              <Divider />
              <Link onClick={() => setActiveTab(0)}>
                Change details <ChevronRight />
              </Link>
            </li>
            <Typography variant="caption">Security</Typography>
            <li className="my-md">
              {props.isUsernameChangeable && (
                <Link onClick={() => setActiveTab(1)}>
                  Change username <ChevronRight />
                </Link>
              )}
              <Divider />
              <Link onClick={() => setActiveTab(2)}>
                Change password <ChevronRight />
              </Link>
            </li>
            <li>
              <Link
                onClick={() => logoutWithRedirect()}
                className="logout-button"
              >
                <ExitToApp className="mr-sm" /> Log out
              </Link>
            </li>
          </ul>
        </Paper>
        <ChangePIR
          className={`right-content ${isActiveContent('change-pir')}`}
          handleBack={() => setActiveBlock('')}
        />
      </div>
      <div className="security-tabs-container">
        <Paper
          elevation={0}
          className="security-tabs-content my-details-card"
          id="security-tabs-content"
        >
          <Tabs
            value={tabValue}
            variant="scrollable"
            onChange={(_, value) => setActiveTab(value)}
            className="dark-oval"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                disableRipple
                label={tab.label}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>

          {tabs.map((tab, index) => (
            <TabPanel
              title={tab.label}
              key={index}
              handleBack={() => setActiveBlock('')}
              value={tabValue}
              index={index}
              tabClassName={
                'security-tab-panel-content' + isActiveContent(tab.type)
              }
            >
              {tab.content}
            </TabPanel>
          ))}
        </Paper>
      </div>
    </Container>
  )
}

const mapStateToProps = (state: AppState, props: MyDetailsProps) => {
  return {
    userid: state.user.userid,
    isUsernameChangeable: state.username.isChangeable,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  nextStep: (path: string) => dispatch(routerActions.push(path)),
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails)
