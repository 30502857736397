import React, { SyntheticEvent, useEffect } from 'react'

import { Dispatch, bindActionCreators } from 'redux'
import { getUsernameError, usernameValidation } from '../../common/forms-helper'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import DetailsFormAlert from '../details-form-alert/DetailsFormAlert'

import {
  UsernameRequestAction,
  UsernameChangeAction,
  UsernameResetSuccessAction,
  UsernameActions,
} from '../../redux/username/username.actions'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'

import './ChangeUsername.scss'
import {
  AuthorizationFormValues,
  useAuthorizedRequest,
} from '../authorization-modal/use-authorized-request'
import { useForm } from 'react-hook-form'
import { Grid } from '@material-ui/core'

export interface ChangeUsernameProps {
  userid?: string
  currentUsername?: string
  hasUsernameChanged?: boolean
  hasError?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  errorMessage?: string
  resetSuccessMessage?: typeof UsernameResetSuccessAction
  getUsername?: typeof UsernameRequestAction
  changeUsername?: typeof UsernameChangeAction
  nextStep?: (path: string) => void
}

export function ChangeUsernameForm(props: ChangeUsernameProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: 'onTouched',
  })
  const [authElements, protectFunction, setModalOpen] = useAuthorizedRequest()

  const onSubmitEvent = (formValues: AuthorizationFormValues) => (
    data: any
  ) => {
    if (!data.username) {
      return
    }
    //TODO: REAUTHENTICATE
    props.changeUsername({
      userid: props.userid,
      currentUsername: props.currentUsername,
      newUsername: data.username,
      ...formValues,
    })

    reset({
      username: '',
    })
    setModalOpen(false)
  }

  useEffect(() => {
    if (props.userid && !props.currentUsername) {
      props.getUsername({ userid: props.userid })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.hasUsernameChanged) {
      setTimeout(() => {
        props.resetSuccessMessage()
      }, 5000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hasUsernameChanged])

  let usernameErrorMessage = getUsernameError(errors)

  return (
    <Container maxWidth={false} className="change-username-container">
      {authElements}
      <form
        onSubmit={protectFunction((e: SyntheticEvent) => {
          e.preventDefault()
          e.persist()
          return (formValues) => handleSubmit(onSubmitEvent(formValues))(e)
        })}
        className="form change-username"
      >
        <DetailsFormAlert
          isVisible={!(props.isLoading || props.isSubmitting)}
          hasSucceed={props.hasUsernameChanged}
          successMessage={'Your username has been changed successfully.'}
          hasError={props.hasError}
          errorMessage={props.errorMessage}
          onClose={() => {}}
        />
        <SpinnerOnLoad isLoading={props.isLoading}>
          <Typography component="p" variant="caption">
            Your current username
          </Typography>
          <Typography component="p" variant="h5">
            {props.currentUsername}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div className="fieldGroup">
                <TextField
                  label="New username"
                  name="username"
                  id="new-username"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled={props.isSubmitting}
                  {...{
                    ...register('username', usernameValidation),
                    ref: undefined,
                    inputRef: register('username', usernameValidation).ref,
                  }}
                  placeholder="Enter a new username"
                  helperText={usernameErrorMessage}
                  error={usernameErrorMessage ? true : false}
                  autoComplete="off"
                />
              </div>
            </Grid>
          </Grid>

          <div className="action-buttons">
            <Button
              disableRipple
              disabled={!isDirty || errors.username || props.isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              {props.isSubmitting ? 'Updating...' : 'Confirm update'}
            </Button>
          </div>
        </SpinnerOnLoad>
      </form>
    </Container>
  )
}

export default connect(
  (state: AppState) => {
    return {
      userid: state.user.userid,
      currentUsername: state.username.username,
      hasUsernameChanged: state.username.hasUsernameChanged,
      errorMessage: state.username.errorMessage,
      hasError: state.username.hasError,
      isLoading: state.username.isLoading,
      isSubmitting: state.username.isSubmitting,
    }
  },
  (dispatch: Dispatch<UsernameActions>) => ({
    getUsername: bindActionCreators(UsernameRequestAction, dispatch),
    changeUsername: bindActionCreators(UsernameChangeAction, dispatch),
    resetSuccessMessage: bindActionCreators(
      UsernameResetSuccessAction,
      dispatch
    ),
    nextStep: (path: string) => dispatch(routerActions.push(path)),
  }),
  null
)(ChangeUsernameForm)
