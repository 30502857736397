import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Modal from '../modal/Modal'

import { ReactComponent as MailIcon } from '../../assets/images/icons/icon_mail.svg'
import { ReactComponent as PinpointIcon } from '../../assets/images/icons/icon_pinpoint.svg'
import { ReactComponent as SpeechIcon } from '../../assets/images/icons/icon_speech.svg'
import { ReactComponent as PhoneCallIcon } from '../../assets/images/icons/icon_phonecall.svg'

import './ContactUsModal.scss'
import SalesforceLiveAgent from '../salesforce-live-agent/SalesforceLiveAgent'

type Props = {
  open: boolean
  onClose: () => void
}

export const COMPANY_PHONE_NUMBER = '0508 347 437'
export const COMPANY_ENQUIRY_EMAIL = 'enquiries@fisherfunds.co.nz'

const ContactUsModal = (props: Props) => {
  return (
    <Modal
      open={props.open}
      title="Here's how you can contact us"
      className="contact-us-popup-dialog auto-height"
      onClose={props.onClose}
    >
      <Container maxWidth={false} className="contact-us-popup-container">
        <Grid container spacing={2} alignItems="stretch" className="mb-0">
          <Grid item xs={12} sm={6}>
            <div className="contact-us-popup-card">
              <SpeechIcon />
              <Typography variant="h5">Chat with us</Typography>
              <Typography variant="body2">
                Our friendly team is here to help.
              </Typography>
              <ul className="contact-menu-list modal-live-agent">
                <SalesforceLiveAgent />
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="gridItem">
            <div className="contact-us-popup-card">
              <MailIcon />
              <Typography variant="h5">Email us</Typography>
              <Typography variant="body2">
                Our friendly team is here to help.
              </Typography>
              <a
                className="contact-menu-item email-link"
                href="mailto:enquiries@fisherfunds.co.nz"
              >
                {COMPANY_ENQUIRY_EMAIL}
              </a>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="contact-us-popup-card">
              <PhoneCallIcon />
              <Typography variant="h5">Give us a call</Typography>
              <Typography variant="body2">
                Mon-Fri from 8:30 am to 5:00 pm.
              </Typography>
              <ul className="contact-menu-list">
                <li className="contact-menu-item">
                  <a href="tel:0508347437">{COMPANY_PHONE_NUMBER}</a>
                </li>
                <li className="contact-menu-item">
                  <a href="tel:6494453377">+64 9 445 3377</a>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="gridItem">
            <div className="contact-us-popup-card">
              <PinpointIcon />
              <Typography variant="h5">Postal address</Typography>
              <Typography variant="body2" className="postal-address">
                Fisher Funds Management Limited, <br />
                Private Bag 93502, Takapuna, <br />
                Auckland 0740, New Zealand
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  )
}

export default ContactUsModal
