import { Account } from '../redux/accounts/accounts.model'
import { isOverRetirementAge } from './user-helper'
import { UserState } from '../redux/user/user.model'
import {
  FUTUREPLAN,
  INVESTMENT_FUNDS,
  INVESTMENT_SERIES,
  KIWISAVER_ONE,
  KIWISAVER_PLAN,
  KIWISAVER_TWO,
  LIFE_SAVER,
  MANAGED_FUNDS,
  PREMIUM_SERVICE,
  ProductDisplayOrder,
  ProductSlug,
} from './product-variables'
import { APEX_FUNDS_CODES } from '../utils/fund'

const externalRefMap = {
  kiwiSaver: [KIWISAVER_ONE, KIWISAVER_TWO, KIWISAVER_PLAN],
  managedFunds: ['04', '05', '06', '07', '08'],
}

export const hasOwnKiwiSaverAccount = (
  accounts: Account[],
  needsToBeOwner: boolean = true
): boolean => {
  let hasOwnKiwiSaver = false
  if (accounts) {
    hasOwnKiwiSaver = accounts.some((account) =>
      isKiwisaverAccount(account, needsToBeOwner)
    )
  }
  return hasOwnKiwiSaver
}
export const hasKiwiSaverOneAccount = (accounts: Account[]): boolean => {
  let hasOwnKiwiSaver = false
  if (accounts) {
    hasOwnKiwiSaver = accounts.some((account) =>
      isKiwiSaverOneAccount(account, true)
    )
  }
  return hasOwnKiwiSaver
}

export const hasAccountThatWillRebalance = (accounts: Account[]): boolean =>
  accounts && accounts.some(({ rebalance }) => rebalance)

export const getKiwisaverAccount = (accounts: Account[]): Account =>
  accounts && accounts.find((account) => isKiwisaverAccount(account, true))

export const isKiwisaverAccount = (
  account: Account,
  needsToBeOwner: boolean
): boolean => {
  return (
    (account.isOwner || !needsToBeOwner) &&
    (externalRefMap.kiwiSaver.includes(account.productExternalRef) ||
      /kiwisaver/i.test(account.productDisplayName))
  )
}

export const isKiwiSaverOneAccount = (
  account: Account,
  needsToBeOwner: boolean
): boolean => {
  return (
    (account.isOwner || !needsToBeOwner) &&
    account.productExternalRef === KIWISAVER_ONE
  )
}

export const isKiwiSaverTwoAccount = (
  account: Account,
  needsToBeOwner: boolean
): boolean => {
  return (
    (account.isOwner || !needsToBeOwner) &&
    account.productExternalRef === KIWISAVER_TWO
  )
}

export const isOwnedManagedFundsAccount = (account: Account): boolean => {
  return account.isOwner && isManagedFundsAccount(account)
}

export const hasManagedFundsAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some((account) => isManagedFundsAccount(account))

export const hasInvestmentFundsAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some((account) => isInvestmentFundsAccount(account))

export const hasOwnAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some(({ isOwner }) => isOwner)

export const hasCompanyAccount = (accounts: Account[]): boolean =>
  accounts && accounts.some(({ isOwner }) => !isOwner)

const isManagedFundsAccount = ({ productExternalRef }: Account): boolean =>
  externalRefMap.managedFunds.includes(productExternalRef)

export const isManagedFundProductAccount = ({
  productExternalRef,
}: Account): boolean => {
  return productExternalRef === MANAGED_FUNDS
}

export const isLifeSaverAccount = ({ productName }: Account): boolean => {
  return productName.toLowerCase().includes('lifesaver')
}

export const isFuturePlanAccount = ({ productName }: Account): boolean => {
  return productName.toLowerCase().includes('futureplan')
}

export const isInvestmentSeries = ({ productName }: Account): boolean => {
  return productName.toLowerCase().includes('investment series')
}

export const isPremiumServiceAccount = ({
  productExternalRef,
}: Account): boolean => {
  return productExternalRef === PREMIUM_SERVICE
}

export const hasOwnKiwiSaverPlanAccount = (accounts: Account[]) =>
  accounts &&
  accounts.some((account) => account.isOwner && isKiwiSaverPlanAccount(account))

export const isInvestmentFundsAccount = ({ productExternalRef }: Account) => {
  return productExternalRef === INVESTMENT_FUNDS
}
export const isKiwiSaverPlanAccount = ({ productExternalRef }: Account) => {
  return productExternalRef === KIWISAVER_PLAN
}

export const getOwnKiwiSaverPlanAccount = (accounts: Account[]): Account =>
  accounts &&
  accounts.find((account) => account.isOwner && isKiwiSaverPlanAccount(account))

export const hasApexProductAccount = (accounts: Account[]) =>
  accounts &&
  accounts.some((account) => isApexProductAccount(account.productExternalRef))

export const isApexProductAccount = (productExternalRef: string) =>
  [KIWISAVER_PLAN, INVESTMENT_FUNDS].includes(productExternalRef)

/**
 * This is only used for AccountDetailsHeader and AccountCard and it only
 * identifies a few product types and for the rest it returns "default"
 * If you need something like this but for all product types use the one in product-helper
 * */

export const getAccountProductClass = (account: Account): string => {
  if (isKiwisaverAccount(account, false)) {
    return ProductSlug.FFKS
  }
  if (isManagedFundProductAccount(account)) {
    return ProductSlug.FFMF
  }
  if (isPremiumServiceAccount(account)) {
    return ProductSlug.FF_PREMIUM
  }
  return 'default'
}

export const getAccountByID = (
  accountID: string,
  accounts: { accountID: string }[]
): any => {
  return accounts.find((account) => account.accountID === accountID)
}

export const hasWriteAuthorisation = (account: Account): boolean => {
  return account.isAuthRep || account.isOwner || account.canWrite
}

const isFFKPRetirementProjectorEnabled =
  process.env.REACT_APP_FEATURE_TOGGLE_FFKP_RETIREMENT_PROJECTOR === 'true'

export const canDisplayRetirementTool = (
  accounts: Account[] = [],
  user: UserState
) => {
  const fundedAccounts = accounts.filter((account) => !account.isDepositMode)
  return (
    !isOverRetirementAge(user.birthDate) &&
    (fundedAccounts.some(
      (a) => isKiwiSaverOneAccount(a, true) || isKiwiSaverTwoAccount(a, true)
    ) ||
      fundedAccounts.some(
        (a) => isKiwiSaverPlanAccount(a) && isFFKPRetirementProjectorEnabled
      ))
  )
}

export const canDisplayFirstHomeBuyerTools = (accounts: Account[]) =>
  hasOwnKiwiSaverAccount(accounts, false) &&
  !hasOwnKiwiSaverPlanAccount(accounts)

export const isMixMandate = (account: Account) =>
  (account?.funds || []).length > 1

export const isCashPlusLegacy = (account: Account) =>
  !isMixMandate(account) &&
  (account?.funds || [])[0]?.assetCode === APEX_FUNDS_CODES.FFKP_CASH_PLUS

export const getSimpleProductName = (accountExternalRef: String) => {
  switch (accountExternalRef) {
    case KIWISAVER_ONE:
      return 'KiwiSaver Scheme'
    case KIWISAVER_TWO:
      return 'KiwiSaver TWO'
    case KIWISAVER_PLAN:
      return 'KiwiSaver Plan'
    case FUTUREPLAN:
      return 'FuturePlan'
    case LIFE_SAVER:
      return 'LifeSaver'
    case INVESTMENT_SERIES:
      return 'Investment Series'
    case INVESTMENT_FUNDS:
      return 'Investment Funds'
    case MANAGED_FUNDS:
      return 'Managed Funds'
    case PREMIUM_SERVICE:
      return 'Premium'
    default:
      return "Can't find product name for $accountExternalRef"
  }
}

export const orderAccountsByProduct = (accounts: Account[]): Account[] =>
  accounts.sort(
    (a, b) =>
      ProductDisplayOrder.indexOf(a.productExternalRef) -
      ProductDisplayOrder.indexOf(b.productExternalRef)
  )
