import { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'
import './modal.scss'

export type ModalProps = {
  open?: boolean
  onClose?: () => void
  title: string
  subtitle?: string
  className?: string
  fullScreen?: boolean
}

const resetHtmlAndBodyStyles = () => {
  document.documentElement.style.overflow = null
  document.body.style.overflow = null
}

// Fix for iframe scroller inside a popup modal
const handleIframeResize = () => {
  setTimeout(() => {
    const iframe: HTMLDivElement = document.querySelector(
      '.material-modal .iframe'
    ) as HTMLDivElement

    if (!iframe) {
      return
    }

    const modalContent: HTMLDivElement = document.querySelector(
      '.material-modal .content'
    ) as HTMLDivElement

    iframe.style.height = null
    modalContent.style.overflow = null

    resetHtmlAndBodyStyles()

    iframe.style.height = modalContent.offsetHeight + 'px'

    setTimeout(() => {
      modalContent.style.overflow = 'hidden'

      // Exclude iPhone 4 and 5
      if (window.outerHeight <= 320 || window.outerWidth <= 320) {
        modalContent.style.overflow = null
      }
    }, 100)
  }, 300)
}

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const { fullScreen = true, className, open } = props

  // This shouldn't be here and instead of using <Modal> <Iframe> we should use <IframeModal>
  useEffect(() => {
    handleIframeResize()
    window.addEventListener('resize', handleIframeResize, false)
    return () => {
      resetHtmlAndBodyStyles()
      window.removeEventListener('resize', handleIframeResize)
    }
  })

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={cn('material-modal', {
        [`${className}`]: !!className,
      })}
      maxWidth={false}
      open={open}
      onClose={props.onClose}
      onKeyDown={(e) => {
        const key = e.code || e.key
        const isEscapeKey = key.toLocaleLowerCase() === 'escape'
        if (isEscapeKey) {
          props.onClose()
        } else {
          e.stopPropagation()
        }
      }}
    >
      <MuiDialogTitle disableTypography className="header">
        <h4 className="color-midnight mb-0">{props.title}</h4>
        <p className="text-large color-dusk my-0">{props.subtitle}</p>
        <IconButton
          aria-label="close"
          className="close-button"
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <MuiDialogContent className={'content'}>
        {props.children}
      </MuiDialogContent>
    </Dialog>
  )
}

export default Modal
