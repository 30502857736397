import React from 'react'
import cn from 'classnames'
import './RiskTooltip.scss'
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go'

export type RiskTooltipProps = {
  value: number
  name: string
}

export const RiskChart = ({
  value,
  theme = 'dark',
}: {
  value: number
  theme?: 'dark' | 'light'
}) => {
  const numbers = Array.from(Array(7).keys()).map((i) => i + 1)
  return (
    <span
      className={cn('risk-chart-container', {
        'risk-chart--light': theme === 'light',
      })}
    >
      <span className="risk-chart__risk-labels">
        <span className="risk-chart__text">
          <span className="risk-chart__text--bold"> Lower </span>
          risk
        </span>
        <span className="risk-chart__text--right">
          <span className="risk-chart__text--bold"> Higher </span>
          risk
        </span>
      </span>
      <span className="risk-chart__chart-container">
        {numbers.map((num) => (
          <div
            key={num}
            className={
              value === num ? 'risk-chart__value' : 'risk-chart__option'
            }
          >
            {value === num && (
              <GoTriangleDown size="1rem" className="risk-chart__arrow-down" />
            )}
            {num}
            {value === num && (
              <GoTriangleUp size="1rem" className="risk-chart__arrow-up" />
            )}
          </div>
        ))}
      </span>
      <span className="risk-chart__return-labels">
        <span className="risk-chart__text">
          Potential
          <span className="risk-chart__text--bold"> lower </span>
          returns
        </span>
        <span className="risk-chart__text--right">
          Potential
          <span className="risk-chart__text--bold"> higher </span>
          returns
        </span>
      </span>
    </span>
  )
}

export const RiskTooltip = ({ value, name }: RiskTooltipProps) => (
  <div className="risk-tooltip__container">
    <p className="risk-tooltip__title">{`Risk indicator for the ${name}:`}</p>

    <RiskChart value={value} />
    <p className="risk-tooltip__description">
      The risk indicator is rated from 1 (low) to 7 (high). The rating reflects
      how much the value of the fund’s assets goes up and down (volatility). A
      higher risk generally means higher potential returns over time, but more
      ups and downs along the way.
    </p>
  </div>
)
