export default {
  root: {
    color: '#218094',
  },
  colorSecondary: {
    color: '#218094',
    '&$checked': {
      color: '#218094',
    },
  },
}
