import 'rxjs'
import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import * as actions from './account-growth.actions'
import { AccountGrowthState } from './account-growth.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

export const accountGrowthEpic: Epic<
  actions.AccountGrowthActions,
  AccountGrowthState
> = (action$: ActionsObservable<actions.AccountGrowthRequestActionType>) =>
  action$
    .ofType(actions.actionTypes.ACCOUNT_GROWTH_REQUEST)
    .switchMap((action) =>
      ajax
        .get(
          `${ffmAppServerUrl}/api/users/${action.payload.userId}/accounts/${action.payload.accountId}/growth`
        )
        .map((response) =>
          actions.AccountGrowthRequestSuccessAction(
            response.response.accountGrowth
          )
        )
        .catch((err) =>
          Observable.of(
            actions.AccountGrowthRequestFailureAction(
              'Account growth request failed'
            )
          )
        )
    )
