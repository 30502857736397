import { ActionsObservable, Epic } from 'redux-observable'
import {
  AccountBalanceActions,
  AccountBalanceRequestActionType,
  AccountBalanceRequestFailureAction,
  AccountBalanceRequestSuccessAction,
  actionTypes,
} from './account-balance.actions'
import { AccountBalanceState, BalanceGraphData } from './account-balance.model'
import { Observable } from 'rxjs'
import { ajax } from 'rxjs/observable/dom/ajax'

const apiUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type LightStore = { getState: Function; dispatch: Function }

export const accountBalanceEpic: Epic<
  AccountBalanceActions,
  AccountBalanceState
> = (
  action$: ActionsObservable<AccountBalanceRequestActionType>,
  store: LightStore
) =>
  action$.ofType(actionTypes.ACCOUNT_BALANCE_REQUEST).switchMap((action) => {
    const graphConfiguration = store.getState().accountBalance
      .graphConfiguration

    const existingBalanceGraph = store
      .getState()
      .accountBalance.balanceGraphs.find(
        (balanceGraph: BalanceGraphData) =>
          balanceGraph.accountNumber === action.payload.accountNumber &&
          balanceGraph.graphConfigurationDateType ===
            graphConfiguration.dateType
      )

    if (existingBalanceGraph) {
      return Observable.of(
        AccountBalanceRequestSuccessAction(existingBalanceGraph)
      )
    }

    return ajax
      .post(
        `${apiUrl}/api/users/${action.payload.userId}/accounts/${action.payload.accountNumber}/balance`,
        {
          filters: {
            dateType: graphConfiguration.dateType,
          },
        },
        {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
          'Content-Type': 'application/json',
        }
      )
      .map((response) => {
        const balanceGraphData: BalanceGraphData = {
          accountNumber: action.payload.accountNumber,
          graphConfigurationDateType: graphConfiguration.dateType,
          balancePeriods: response.response.balances,
        }

        return AccountBalanceRequestSuccessAction(balanceGraphData)
      })
      .catch((error) => {
        return Observable.of(
          AccountBalanceRequestFailureAction('Account balance request failed')
        )
      })
  })

export default accountBalanceEpic
