import { Reducer, AnyAction } from 'redux'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './account-funds.actions'
import { AccountFundsState } from './account-funds.model'

export const initialState: AccountFundsState = {
  productName: null,
  accountTotal: 0,
  accountNumber: null,
  switchURL: '',
  funds: [],
  ...getDefaultState(),
}

export const accountFundsReducer: Reducer<AccountFundsState> = (
  state: AccountFundsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_FUNDS_REQUEST:
      return { ...state, isLoading: true, hasError: false, errorMessage: null }

    case actionTypes.ACCOUNT_FUNDS_INVESTMENT_PROFILE_REQUEST:
      return { ...state, isLoading: true, hasError: false, errorMessage: null }

    case actionTypes.ACCOUNT_FUNDS_REQUEST_SUCCESS:
      return { ...state, ...action.payload, isLoading: false }

    case actionTypes.ACCOUNT_FUNDS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }

    // Logging out resets the account funds state
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
