import { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import cn from 'classnames'
import Alert from 'react-s-alert'
import { Account } from '../../redux/accounts/accounts.model'
import Modal, { ModalProps } from '../modal/Modal'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import { SwitchNotification } from '../../redux/switch-notifications/switch-notifications.model'
import { Notification } from '../notification/Notification'
import { AccountFund } from '../../redux/account-funds/account-funds.model'
import {
  getAccountByID,
  isKiwiSaverPlanAccount,
  isApexProductAccount,
  isKiwisaverAccount,
} from '../../common/accounts-helper'
import ZeroBalanceMessage from '../zero-balance-message/ZeroBalanceMessage'
import { useContentfulProduct } from '../../api/contentful/useContentfulProduct'
import {
  SwitchFormData,
  createApiDataApex,
  createApiDataTel,
  createApiRemainDataApex,
  mapUserAllocationToSwitchData,
  requestInvestmentSwitch,
} from './SwitchContentfulModal.api'
import { AppState } from '../../redux/app-state'
import { COMPANY_PHONE_NUMBER } from '../contact-us-modal/ContactUsModal'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import {
  IInvestmentOption,
  castToInvestmentOption,
  findFundByCode,
  getFilteredInvestmentOptions,
  getInvestmentOptionByCode,
  isCustomProfile,
} from '../../utils/investmentOption'
import InvestmentOptionSelector from './InvestmentOptionSelector'
import CompareInvestmentOptions from '../compare-investment-options/CompareInvestmentOptions'
import { Button } from '../clickable/button/Button'
import BuildYourOwnStrategy, {
  BuildYourOwnSplit,
  FundSplit,
} from './BuildYourOwnStrategy'
import ReviewSwitch from './ReviewChange'
import {
  containsGlidePath,
  isGlidePath,
  STRATEGY_SALESFORCECODE,
} from '../../utils/strategy'
import IPQ from '../ipq/IPQ'
import { ProgressBarContextProvider } from '../../hooks/useProgressBar'
import { canUseIPQTool } from '../ipq/ipq-helper'
import {
  getIPQLink,
  productHasStrategies,
  removeFisherFundsFromProductName,
} from '../../common/product-helper'
import { StrategyFundAllocation } from './SwitchContentfulModal.types'
import { ProductShortCodes } from '../../common/product-variables'
import SwitchPendingErrorMessage from './SwitchPendingErrorMessage'
import { InvestmentOptionType } from '../compare-investment-options/InvestmentOption'
import {
  Allocation,
  APEX_FUNDS_CODES,
  calculateFundsAllocations,
  isFundAvailable,
  mapAccountDetailsFundsToAllocations,
} from '../../utils/fund'
import './SwitchModal.scss'
import InvestmentOptionInfoModal from '../investment-option-info/InvestmentOptionInfoModal'
import { UserData } from '../../redux/user/user.model'
import { getMandate, Mandate } from '../../api/mandate/mandate-api'
import AdviceDisclosure from '../advice/AdviceDisclosure'
import { useMedia } from '../../hooks/use-media'

enum SWITCH_STEP {
  SUGGEST_IPQ = 'SUGGEST_IPQ',
  IPQ = 'IPQ',
  INTRO = 'INTRO',
  CONFIRM_STRATEGY = 'CONFIRM_STRATEGY',
  BUILD_YOUR_OWN = 'BUILD_YOUR_OWN',
  REVIEW_SWITCH = 'REVIEW_SWITCH',
  SWITCH_PENDING = 'SWITCH_PENDING',
}

type SwitchStatus = {
  isSwitchAllowed: boolean
  lastChangeRequest: string
}

type PropsFromRedux = {
  authToken: string
  currentFundAllocations: Allocation[]
  futureFundAllocations: Allocation[]
  investorDetails?: AccountDetail
  switchNotifications?: SwitchNotification[]
  user: UserData
  userId: string
}

export type SwitchModalProps = {
  account: Account
  // Below 2 props `switchFromOption`, `switchToOption` are for place like Retirement Projector to jump to fund comparison step directly, and continue switch process from there
  switchFromOption?: InvestmentOptionType
  switchToOption?: InvestmentOptionType
} & Pick<ModalProps, 'onClose'>

const SwitchModal = (props: SwitchModalProps & PropsFromRedux) => {
  const {
    account,
    authToken,
    currentFundAllocations,
    futureFundAllocations,
    investorDetails,
    onClose,
    switchNotifications,
    userId,
    user,
    switchFromOption,
    switchToOption,
  } = props
  const { contentfulEntry: contentfulProduct } = useContentfulProduct(
    account.productExternalName
  )
  const [switchSuspended, setSwitchSuspended] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [showLargeModal, setShowLargeModal] = useState<boolean>(false)
  const [investmentOptionInfoCode, setInvestmentOptionInfoCode] = useState('')
  const [showingStep, setShowingStep] = useState(SWITCH_STEP.INTRO)
  const { isTabletLandscape } = useMedia()
  const accountCanUseIPQTool = canUseIPQTool(account.productExternalRef)
  const [shouldSkipCompareOptions, setShouldSkipCompareOptions] = useState<
    boolean
  >(false)

  const [mandate, setMandate] = useState<Mandate>(null)
  useEffect(() => {
    if (isKiwiSaverPlanAccount(account)) {
      setLoading(true)
      getMandate(account.accountID, authToken)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw Error()
          }
          setMandate(response.data)
        })
        .catch(() => {
          setApiErrorMsg(
            `<span class="color-midnight">Sorry, we are currently unable to process your request. Please try again in a few minutes or <a href='#contact-us'>contact us</a>.</span>`
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const switchStatus: SwitchStatus = useMemo(() => {
    if (mandate) {
      return {
        isSwitchAllowed: mandate.isMandateChangeAllowed,
        lastChangeRequest: mandate.addedDate,
      }
    }
    return null
  }, [mandate])

  const isSwitchAllowed =
    !isKiwiSaverPlanAccount(account) || switchStatus?.isSwitchAllowed

  // This useEffect decides if we show the SUGGEST_IPQ
  useEffect(() => {
    if (loading || !!switchToOption) {
      return
    }
    if (
      accountCanUseIPQTool &&
      isSwitchAllowed &&
      showingStep === SWITCH_STEP.INTRO
    ) {
      setShowingStep(SWITCH_STEP.SUGGEST_IPQ)
    }
  }, [loading, switchToOption]) // eslint-disable-line react-hooks/exhaustive-deps

  const [
    newInvestmentOption,
    setNewInvestmentOption,
  ] = useState<InvestmentOptionType | null>(null)
  const [switchFormData, setSwitchFormData] = useState<SwitchFormData | null>(
    null
  )

  useEffect(() => {
    if (!!switchFormData) {
      setShowingStep(SWITCH_STEP.REVIEW_SWITCH)
    }
  }, [switchFormData])

  useEffect(() => {
    if (switchToOption && contentfulProduct) {
      setNewInvestmentOption(switchToOption)
    }
  }, [switchToOption, contentfulProduct])

  const currentInvestmentOption: InvestmentOptionType | null = useMemo(() => {
    if (switchFromOption) {
      return switchFromOption
    }
    const { profileCode, profileName } = investorDetails?.profile || {}
    let investmentOption: InvestmentOptionType = null
    if (containsGlidePath(profileName) && !!contentfulProduct) {
      return getInvestmentOptionByCode(contentfulProduct, 'GlidePath')
    }
    if (!!profileCode && !!contentfulProduct) {
      investmentOption = getInvestmentOptionByCode(
        contentfulProduct,
        profileCode
      ) as InvestmentOptionType
    }

    if (!investmentOption) {
      return null
    }

    if (isCustomProfile(profileCode)) {
      investmentOption = {
        ...investmentOption,
        isCustom: true,
        allocations: currentFundAllocations,
        futureAllocations: futureFundAllocations,
      } as InvestmentOptionType
    }
    return investmentOption
  }, [
    switchFromOption,
    contentfulProduct,
    investorDetails?.profile,
    currentFundAllocations,
    futureFundAllocations,
  ])

  const isKiwisaver = isKiwisaverAccount(props.account, props.account.isOwner)
  const customStrategy = contentfulProduct?.strategies?.find(
    (x) => x.customStrategy
  )
  const shortenedProductName = removeFisherFundsFromProductName(
    contentfulProduct?.name
  )

  const customFundAllocations: StrategyFundAllocation[] = useMemo(() => {
    if (!contentfulProduct?.slug) {
      return []
    }
    return productHasStrategies(contentfulProduct.slug)
      ? customStrategy.strategyFundAllocations
      : contentfulProduct.funds.map((fund) => ({
          allocationPercentage: Number(
            futureFundAllocations.find(
              (f) => f.fund.shortName === fund.shortName
            )?.allocationPercentage || 0
          ),
          fund,
        }))
  }, [
    contentfulProduct?.slug,
    contentfulProduct?.funds,
    customStrategy,
    futureFundAllocations,
  ])

  const suspensionMessage = useMemo(() => {
    // Product suspension
    if (contentfulProduct?.isSuspended) {
      setSwitchSuspended(true)
      return `${contentfulProduct.name} is suspended until further notice.`
    }

    // Current Fund suspension
    const accountFundsAssetCodes = account.funds?.map(
      (fund: AccountFund) => fund.assetCode
    )
    if (
      contentfulProduct?.strategies?.some((strategy) =>
        strategy.strategyFundAllocations?.some(
          (allocation) =>
            allocation.fund.isSuspended &&
            accountFundsAssetCodes?.includes(allocation.fund.telCode)
        )
      )
    ) {
      setSwitchSuspended(true)
      return (
        `Some funds are currently suspended and you are therefore unable to withdraw or switch from those funds.` +
        ` We are working hard to resolve the issues that have caused the funds to be suspended and will re-open the funds as soon as possible.`
      )
    }
    // New Fund suspension
    if (
      contentfulProduct?.strategies?.some((strategy) =>
        strategy.strategyFundAllocations?.some(
          (allocation) => allocation.fund.isSuspended
        )
      )
    ) {
      setSwitchSuspended(isKiwisaver)
      return `Some funds are currently suspended. We are working hard to resolve the issues that have caused the funds to be suspended and will re-open the funds as soon as possible.`
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.account.funds, contentfulProduct, contentfulProduct?.strategies])

  const investmentOptionInfoModal = useMemo(() => {
    const strategy = contentfulProduct?.strategies?.find(
      (s) => s.salesforceCode === investmentOptionInfoCode
    )
    if (strategy) {
      return castToInvestmentOption(strategy)
    }
    const fund = contentfulProduct?.funds.find(
      (f) => f.telCode === investmentOptionInfoCode
    )
    if (fund) {
      return castToInvestmentOption(null, fund)
    }
    return null
  }, [
    investmentOptionInfoCode,
    contentfulProduct?.strategies,
    contentfulProduct?.funds,
  ])

  const onInvestmentOptionCodeSelected = (newInvestmentOptionCode: string) => {
    const selectedInvestmentOption = getInvestmentOptionByCode(
      contentfulProduct,
      newInvestmentOptionCode
    )
    setNewInvestmentOption(selectedInvestmentOption)
  }

  useEffect(() => {
    if (!newInvestmentOption) {
      return
    }

    // For some investment options we don't have information so we skip the compare screen.
    // These are: GlidePath, and unrecognised investment profiles.
    // We also not show the compare screen unless we come from the intro step or build your own step
    // this is to avoid choosing a fund in the compare screen and forcing the compare screen again
    const shouldWeSkipCompare =
      !isApexProduct || // We only show compare for Apex accounts
      ![SWITCH_STEP.INTRO, SWITCH_STEP.BUILD_YOUR_OWN].includes(showingStep) ||
      !currentInvestmentOption ||
      shouldSkipCompareOptions

    if (shouldWeSkipCompare) {
      onNewInvestmentOptionConfirmed(newInvestmentOption)
    } else {
      setShowingStep(SWITCH_STEP.CONFIRM_STRATEGY)
    }
  }, [newInvestmentOption]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOptionsChange = (needsLargeModal: boolean) => {
    setShowLargeModal(needsLargeModal)
  }

  const onNewInvestmentOptionConfirmed = (
    investmentOption?: InvestmentOptionType
  ) => {
    const selectedInvestmentOption: InvestmentOptionType =
      investmentOption || newInvestmentOption
    if (
      !selectedInvestmentOption.futureAllocations?.length &&
      !selectedInvestmentOption.isCustom
    ) {
      const singleFundAllocation = {
        allocationPercentage: 100,
        fund: findFundByCode(
          contentfulProduct.funds,
          selectedInvestmentOption.telCode
        ),
      }
      selectedInvestmentOption.futureAllocations = [singleFundAllocation]
    }
    setNewInvestmentOption(selectedInvestmentOption)
    const {
      isCustom,
      allocations,
      futureAllocations,
      strategy,
      telCode,
    } = selectedInvestmentOption

    const strategyFundAllocations = (
      strategy?.strategyFundAllocations || []
    ).filter((allocation) => isFundAvailable(allocation.fund, account?.funds))

    const data: SwitchFormData = isCustom
      ? {
          strategytype: 'Custom',
          fundsext: mapUserAllocationToSwitchData(
            strategyFundAllocations,
            allocations
          ),
          fundsfut: mapUserAllocationToSwitchData(
            strategyFundAllocations,
            futureAllocations
          ),
        }
      : {
          strategytype: isGlidePath(strategy) ? 'GlidePath' : 'Current',
          strategy: productHasStrategies(contentfulProduct.slug)
            ? strategy?.salesforceCode
            : telCode,
        }
    setSwitchFormData(data)
  }

  const isApexProduct = isApexProductAccount(props.account.productExternalRef)

  const onCustomStrategySubmit = async (customForm: BuildYourOwnSplit) => {
    const transformCustomSplitToAllocation = (fundSplit: FundSplit[] = []) =>
      fundSplit
        .filter((f) => f.ratio > 0)
        .map((f) => ({
          allocationPercentage: f.ratio,
          fund: findFundByCode(contentfulProduct.funds, f.id),
        }))

    const investmentOptionSubmitted: InvestmentOptionType = getInvestmentOptionByCode(
      contentfulProduct,
      isApexProduct
        ? APEX_FUNDS_CODES.FFIF_OR_FFKP_MIXED_MANDATE
        : STRATEGY_SALESFORCECODE.CUSTOM
    )
    investmentOptionSubmitted.isCustom = true
    investmentOptionSubmitted.allocations = transformCustomSplitToAllocation(
      customForm.currentSplit
    )
    investmentOptionSubmitted.futureAllocations = transformCustomSplitToAllocation(
      customForm.futureSplit
    )
    setNewInvestmentOption(investmentOptionSubmitted)
  }

  const accountNumber = account.accountID

  const handleSelectRemain = () => {
    if (isApexProduct) {
      const remainSwitchPayload = createApiRemainDataApex(
        ProductShortCodes[account.productExternalRef]
      )
      requestInvestmentSwitch(
        userId,
        accountNumber,
        remainSwitchPayload,
        authToken
      )
    }
    onClose()
  }

  const onSubmit = async () => {
    setLoading(true)

    const payload = isApexProduct
      ? createApiDataApex({
          switchType: switchFormData.strategytype as any,
          fundAllocation: newInvestmentOption.futureAllocations,
          product: ProductShortCodes[account.productExternalRef],
        })
      : createApiDataTel(switchFormData, contentfulProduct, account)

    await requestInvestmentSwitch(userId, accountNumber, payload, authToken)
      .then(function (res) {
        if (res.data.switchStatus === 409) {
          setApiErrorMsg(
            `It appears you already have a funds switch instruction, or a transaction currently being processed. If you wish to speak to a Fisher Funds staff member about this, please get in touch with us on ${COMPANY_PHONE_NUMBER} to discuss.`
          )
        } else {
          Alert.success(
            "<h6>Thanks!</h6><p> We'll get your switch sorted. If you have any questions about your investment, <a href='#contact-us'>get in touch</a>.</p>",
            { timeout: 15000 }
          )
          onClose()
        }
      })
      .catch((err: any) => {
        setApiErrorMsg(
          `Sorry, we appear to be experiencing problems processing your request. Please try again or get in touch with us on ${COMPANY_PHONE_NUMBER}.`
        )
      })
      .finally(function () {
        setLoading(false)
      })
  }

  const isZeroBalanceAccount = account?.accountTotal === 0

  const renderNotifications = () => (
    <>
      {apiErrorMsg && (
        <Notification className="mb-sm" type="error">
          <p dangerouslySetInnerHTML={{ __html: apiErrorMsg }} />
        </Notification>
      )}
      {isZeroBalanceAccount && (
        <Box className="switch-zero-balance" onClick={onClose}>
          <ZeroBalanceMessage account={account} switchRequested />
        </Box>
      )}
      {suspensionMessage && (
        <Notification type="warning">{suspensionMessage}</Notification>
      )}
    </>
  )

  const ipqLink = getIPQLink(account)

  const renderBody = () => {
    if (!contentfulProduct || (!isZeroBalanceAccount && loading)) {
      return <SpinnerOnLoad className="my-md" isLoading centerSpinner={true} />
    }

    const investmentOptions = getFilteredInvestmentOptions(contentfulProduct)

    if (apiErrorMsg || !isSwitchAllowed) {
      return (
        <SwitchPendingErrorMessage
          onClose={onClose}
          lastSwitchRequest={switchStatus?.lastChangeRequest}
          errorMessage={!!apiErrorMsg && apiErrorMsg}
        />
      )
    }

    if (showingStep === SWITCH_STEP.INTRO) {
      const onOpenIPQ = () => {
        if (accountCanUseIPQTool) {
          return setShowingStep(SWITCH_STEP.IPQ)
        } else {
          return window.open(ipqLink, '_blank')
        }
      }
      const isStrategy = productHasStrategies(contentfulProduct.slug)
      return (
        <>
          <InvestmentOptionSelector
            className="switch-investment-option"
            isSuspended={switchSuspended}
            onOpenIPQ={(accountCanUseIPQTool || ipqLink) && onOpenIPQ}
            investmentOptions={investmentOptions}
            onSelect={onInvestmentOptionCodeSelected}
            onMoreInfoClick={setInvestmentOptionInfoCode}
            productName={shortenedProductName}
            isApexProductAccount={isApexProduct}
            profile={investorDetails?.profile}
            switchNotifications={switchNotifications}
          />
          <div className="build-your-own-strategy switch-strategy-option">
            <h6 className="color-midnight mt-md mb-sm">
              {isStrategy
                ? 'Or build your own strategy'
                : 'Or create your own mix of funds'}
            </h6>
            <div className="strategy-option">
              <p className="text-small color-text-light mt-0 mb-2xs">
                {isStrategy ? 'Strategy' : 'Fund mix'}
              </p>
              <div className="strategy-option__actions">
                <h5 className="color-midnight">
                  {isStrategy
                    ? 'Build your own strategy'
                    : 'Create your own fund mix'}
                </h5>
                <Button
                  className="ml-auto"
                  variant="outlined"
                  size="sm"
                  onClick={() => setShowingStep(SWITCH_STEP.BUILD_YOUR_OWN)}
                  disabled={switchSuspended}
                >
                  {switchSuspended ? 'Suspended' : 'Build'}
                </Button>
              </div>
            </div>
          </div>
        </>
      )
    }

    if (showingStep === SWITCH_STEP.CONFIRM_STRATEGY && !!newInvestmentOption) {
      return (
        <>
          <CompareInvestmentOptions
            product={contentfulProduct}
            currentInvestmentOption={currentInvestmentOption}
            newInvestmentOption={newInvestmentOption}
            onCancel={handleSelectRemain}
            onSelect={(option) => onNewInvestmentOptionConfirmed(option)}
            onOptionsChange={handleOptionsChange}
            isApexProduct={isApexProduct}
          />
          <AdviceDisclosure className="mt-sm" />
        </>
      )
    }

    if (showingStep === SWITCH_STEP.BUILD_YOUR_OWN) {
      return (
        <BuildYourOwnStrategy
          className="switch-strategy-option"
          depositsLabel={isKiwisaver ? 'contributions' : 'investments'}
          onCancel={() => setShowingStep(SWITCH_STEP.INTRO)}
          onSubmit={(formData) => onCustomStrategySubmit(formData)}
          onMoreInfoClick={setInvestmentOptionInfoCode}
          isApexProductAccount={isApexProduct}
          strategyFundAllocations={customFundAllocations}
        />
      )
    }
    if (showingStep === SWITCH_STEP.REVIEW_SWITCH && !!newInvestmentOption) {
      return (
        <ReviewSwitch
          account={account}
          user={user}
          isApexProductAccount={isApexProduct}
          newInvestmentOption={newInvestmentOption}
          onCancel={() => setShowingStep(SWITCH_STEP.INTRO)}
          onSubmit={() => onSubmit()}
        />
      )
    }

    return <SpinnerOnLoad className="my-md" isLoading centerSpinner={true} />
  }

  if (showingStep === SWITCH_STEP.SUGGEST_IPQ) {
    const renderBody = () => {
      if (loading || (isKiwiSaverPlanAccount(account) && !switchStatus)) {
        return (
          <SpinnerOnLoad className="my-md" isLoading centerSpinner={true} />
        )
      }

      return (
        <>
          <p className="mt-0 mb-lg">
            Use our online tool to help you decide which fund might suit you
            best.
          </p>

          <div className="modal__footer-buttons">
            <Button
              className="mr-md"
              variant="link"
              onClick={() => setShowingStep(SWITCH_STEP.INTRO)}
            >
              No, I’ll review and manage it myself
            </Button>
            <Button
              onClick={() => setShowingStep(SWITCH_STEP.IPQ)}
              size={isTabletLandscape ? 'md' : 'sm'}
            >
              Yes, I need some help
            </Button>
          </div>
        </>
      )
    }

    return (
      <Modal
        className="ipq-modal"
        title={`Need some help choosing the right ${
          isApexProduct ? 'fund' : 'strategy'
        }?`}
        open
        onClose={onClose}
      >
        {renderBody()}
      </Modal>
    )
  }

  if (showingStep === SWITCH_STEP.IPQ) {
    const onIPQSubmit = (investmentOption: IInvestmentOption) => {
      setNewInvestmentOption(investmentOption)
      onNewInvestmentOptionConfirmed(investmentOption)
      // IPQ does not need to show the compare screen
      setShouldSkipCompareOptions(true)
    }

    return (
      <ProgressBarContextProvider>
        <IPQ
          onClose={() => onClose()}
          onResult={onIPQSubmit}
          account={props.account}
        />
      </ProgressBarContextProvider>
    )
  }

  return (
    <Modal
      title={`Change your ${
        productHasStrategies(contentfulProduct?.slug) ? 'strategy' : 'fund'
      }`}
      subtitle={account.accountID}
      open
      onClose={onClose}
      className={cn(`switch-modal`, {
        large: showingStep === SWITCH_STEP.CONFIRM_STRATEGY && showLargeModal,
        'switch-suspended': switchSuspended,
      })}
    >
      {renderNotifications()}
      {renderBody()}
      <InvestmentOptionInfoModal
        onClose={() => setInvestmentOptionInfoCode(null)}
        product={contentfulProduct}
        investmentOption={investmentOptionInfoModal}
      />
    </Modal>
  )
}

const mapStateToProps = (
  state: AppState,
  props: SwitchModalProps
): PropsFromRedux => {
  const investorDetails = getAccountByID(
    props.account.accountID,
    state.accountDetails.accounts
  )
  return {
    authToken: state.authorisation.authorisationToken,
    currentFundAllocations: calculateFundsAllocations(
      state.accountFunds.funds,
      state.accountFunds.accountTotal
    ),
    futureFundAllocations: mapAccountDetailsFundsToAllocations(
      investorDetails?.profile?.funds
    ),
    investorDetails,
    user: state.user,
    userId: state.user.userid,
    switchNotifications: state.switchNotifications.notifications,
  }
}

export default connect(mapStateToProps)(SwitchModal)
