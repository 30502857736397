export const HASH_MODALS = {
  ADD_FUNDS: 'add-funds',
  CONTACT_US: 'contact-us',
  SWITCH: 'switch',
  IPQ: 'ipq',
  WITHDRAW: 'withdraw-funds',
}

export const removeHash = () => {
  window.location.hash = '' // This leaves a # in the url but triggers the useEffect for window.location.hash
  window.history.replaceState(
    '',
    document.title,
    window.location?.pathname + window.location?.search
  ) // This removes the #
}

export const hashIncludes = (value: string) =>
  window.location?.hash.includes(value) ?? false
