import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import './Toast.scss'

export type ToastProps = {
  msg: string
  type: 'info' | 'warning' | 'error' | 'success'
  onClose: () => void
}

const Toast = (props: ToastProps) => {
  const { msg, type, onClose } = props

  const RenderIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon className="toast-notification__icon" />
      case 'success':
        return <CheckCircleIcon className="toast-notification__icon" />
      case 'info':
        return <InfoIcon className="toast-notification__icon" />
      case 'warning':
        return <ErrorIcon className="toast-notification__icon" />
      default:
        return <InfoIcon className="toast-notification__icon" />
    }
  }

  return (
    <div
      className={`toast-notification toast-notification__${type} mediumShadow`}
      aria-live="assertive"
    >
      {RenderIcon()}
      <div className="toast-content">
        <p>{msg}</p>
      </div>
      <CloseIcon className="notification__close ml-lg" onClick={onClose} />
    </div>
  )
}

export default Toast
