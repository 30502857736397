import { AccountGrowthState } from './account-growth.model'
export enum actionTypes {
  ACCOUNT_GROWTH_REQUEST = 'ACCOUNT_GROWTH_REQUEST',
  ACCOUNT_GROWTH_REQUEST_SUCCESS = 'ACCOUNT_GROWTH_REQUEST_SUCCESS',
  ACCOUNT_GROWTH_REQUEST_FAILURE = 'ACCOUNT_GROWTH_REQUEST_FAILURE',
}

export type AccountGrowthRequestActionType = {
  type: actionTypes.ACCOUNT_GROWTH_REQUEST
  payload: { userId: string; accountId: number }
}
export type AccountGrowthRequestSuccessActionType = {
  type: actionTypes.ACCOUNT_GROWTH_REQUEST_SUCCESS
  payload: AccountGrowthState
}
export type AccountGrowthRequestFailureActionType = {
  type: actionTypes.ACCOUNT_GROWTH_REQUEST_FAILURE
  payload: string
}

export const AccountGrowthRequestAction = (
  accountId: number,
  userId: string
): AccountGrowthRequestActionType => ({
  type: actionTypes.ACCOUNT_GROWTH_REQUEST,
  payload: {
    userId: userId,
    accountId: accountId,
  },
})

export const AccountGrowthRequestSuccessAction = (
  accountGrowth: AccountGrowthState
): AccountGrowthRequestSuccessActionType => ({
  type: actionTypes.ACCOUNT_GROWTH_REQUEST_SUCCESS,
  payload: accountGrowth,
})

export const AccountGrowthRequestFailureAction = (
  message: string
): AccountGrowthRequestFailureActionType => ({
  type: actionTypes.ACCOUNT_GROWTH_REQUEST_FAILURE,
  payload: message,
})

export type AccountGrowthActions =
  | AccountGrowthRequestActionType
  | AccountGrowthRequestSuccessActionType
  | AccountGrowthRequestFailureActionType
