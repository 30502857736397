import { UsernameRecoveryCredentialsType } from './username-recovery.model'

export enum actionTypes {
  USERNAME_RECOVERY_SUBMIT = 'USERNAME_RECOVERY_SUBMIT',
  USERNAME_RECOVERY_SUBMIT_SUCCESS = 'USERNAME_RECOVERY_SUBMIT_SUCCESS',
  USERNAME_RECOVERY_SUBMIT_FAILURE = 'USERNAME_RECOVERY_SUBMIT_FAILURE',
  USERNAME_RECOVERY_CLEAR_STATE = 'USERNAME_RECOVERY_CLEAR_STATE',
  USERNAME_RECOVERY_VERIFICATION = 'USERNAME_RECOVERY_VERIFICATION',
  USERNAME_RECOVERY_VERIFICATION_SUCCESS = 'USERNAME_RECOVERY_VERIFICATION_SUCCESS',
  USERNAME_RECOVERY_VERIFICATION_FAILURE = 'USERNAME_RECOVERY_VERIFICATION_FAILURE',
}

type UsernameRecoverySubmitType = {
  credentials: UsernameRecoveryCredentialsType
  method: 'sms' | 'email'
}

export type UsernameRecoverySubmitActionType = {
  type: actionTypes.USERNAME_RECOVERY_SUBMIT
  payload: UsernameRecoverySubmitType
}
export const UsernameRecoverySubmitAction = (
  payload: UsernameRecoverySubmitType
): UsernameRecoverySubmitActionType => ({
  type: actionTypes.USERNAME_RECOVERY_SUBMIT,
  payload,
})

export type UsernameRecoverySubmitSuccessActionType = {
  type: actionTypes.USERNAME_RECOVERY_SUBMIT_SUCCESS
}
export const UsernameRecoverySubmitSuccessAction = (): UsernameRecoverySubmitSuccessActionType => ({
  type: actionTypes.USERNAME_RECOVERY_SUBMIT_SUCCESS,
})

export type UsernameRecoverySubmitFailureActionType = {
  type: actionTypes.USERNAME_RECOVERY_SUBMIT_FAILURE
  payload: string
}
export const UsernameRecoverySubmitFailureAction = (
  message: string
): UsernameRecoverySubmitFailureActionType => ({
  type: actionTypes.USERNAME_RECOVERY_SUBMIT_FAILURE,
  payload: message,
})

export type UsernameRecoveryVerificationActionType = {
  type: actionTypes.USERNAME_RECOVERY_VERIFICATION
  payload: { credentials: UsernameRecoveryCredentialsType }
}
export const UsernameRecoveryVerificationAction = (payload: {
  credentials: UsernameRecoveryCredentialsType
}): UsernameRecoveryVerificationActionType => ({
  type: actionTypes.USERNAME_RECOVERY_VERIFICATION,
  payload,
})

export type UsernameRecoveryVerificationSuccessActionType = {
  type: actionTypes.USERNAME_RECOVERY_VERIFICATION_SUCCESS
  payload: {
    credentials: UsernameRecoveryCredentialsType
    mobilePhoneNumber: string
  }
}
export const UsernameRecoveryVerificationSuccessAction = (payload: {
  credentials: UsernameRecoveryCredentialsType
  mobilePhoneNumber: string
}): UsernameRecoveryVerificationSuccessActionType => ({
  type: actionTypes.USERNAME_RECOVERY_VERIFICATION_SUCCESS,
  payload,
})

export type UsernameRecoveryVerificationFailureActionType = {
  type: actionTypes.USERNAME_RECOVERY_VERIFICATION_FAILURE
  payload: {
    errorMessage?: string
    errorStatus?: number
  }
}

export const UsernameRecoveryVerificationFailureAction = ({
  errorStatus,
  errorMessage,
}: {
  errorStatus?: number
  errorMessage?: string
}): UsernameRecoveryVerificationFailureActionType => ({
  type: actionTypes.USERNAME_RECOVERY_VERIFICATION_FAILURE,
  payload: {
    errorMessage,
    errorStatus,
  },
})

export type UsernameRecoveryClearStateActionType = {
  type: actionTypes.USERNAME_RECOVERY_CLEAR_STATE
}
export const UsernameRecoveryClearStateAction = (): UsernameRecoveryClearStateActionType => ({
  type: actionTypes.USERNAME_RECOVERY_CLEAR_STATE,
})

export type UsernameRecoveryActions =
  | UsernameRecoverySubmitActionType
  | UsernameRecoverySubmitSuccessActionType
  | UsernameRecoverySubmitFailureActionType
  | UsernameRecoveryVerificationActionType
  | UsernameRecoveryVerificationSuccessActionType
  | UsernameRecoveryVerificationFailureActionType
