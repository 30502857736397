import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import {
  AuthorisationActions,
  CheckAuthorisationRequestAction,
} from '../../redux/authorisation/authorisation.actions'
import { AppState } from '../../redux/app-state'

interface CheckAuthorisationProps {
  userid: string
  location: string
  checkAuthorisation: typeof CheckAuthorisationRequestAction
}

class CheckAuthorisation extends React.Component<CheckAuthorisationProps> {
  private intervalId: number

  constructor(props: CheckAuthorisationProps) {
    super(props)

    this.intervalId = null

    this.checkAuthorisation = this.checkAuthorisation.bind(this)
  }

  // Check authorisation on load, and every 60 seconds
  componentDidMount() {
    this.intervalId = window.setInterval(this.checkAuthorisation, 600 * 100)
    this.checkAuthorisation()
  }

  // Check authorisation every time the page location changes
  UNSAFE_componentWillReceiveProps(nextProps: CheckAuthorisationProps) {
    if (this.props.location !== nextProps.location) {
      this.checkAuthorisation()
    }
  }

  componentWillUnmount() {
    if (!this.intervalId) {
      return
    }
    window.clearInterval(this.intervalId)
    this.intervalId = null
  }

  checkAuthorisation() {
    if (this.props.userid) {
      this.props.checkAuthorisation(this.props.userid)
    }
  }

  render() {
    // Don't render anything
    return false
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    // This state is not rendered, but triggers a re-render of this component which
    // re-checks if our session has expired on 'screen change' or 'user change'
    location: state.routing.locationBeforeTransitions.pathname,
    userid: state.user.userid,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AuthorisationActions>) => ({
  checkAuthorisation: bindActionCreators(
    CheckAuthorisationRequestAction,
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckAuthorisation)
