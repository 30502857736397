import React, { useState, useEffect } from 'react'

import TextField from '@material-ui/core/TextField'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'

interface NewPasswordProps {
  disabled?: boolean
  serverErrorMessage?: string
  shouldResetFields?: boolean
  clearServerErrorMessage?: () => void
  register: UseFormRegister<FieldValues>
  watch: any
  errors: any
}

export default function NewPasswordFields(props: NewPasswordProps) {
  const { disabled } = props

  const [hasUpdated, setHasUpdated] = useState(false)

  useEffect(() => {
    if (props.shouldResetFields) {
      setHasUpdated(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldResetFields])

  const getNewPasswordErrorMessage = (fieldName: string) => {
    let currentValue = props.watch(fieldName)

    // only validate if has an error
    if (!hasUpdated && (!props.errors || !props.errors[fieldName])) {
      return ''
    }

    if (!currentValue) {
      return 'New password is a required field'
    } else if (!/(?=.*[A-Z])/.test(currentValue)) {
      return 'Your new password must contain at least 1 uppercase character (A-Z).'
    } else if (!/(?=.*\d)/.test(currentValue)) {
      return 'Your new password must contain at least 1 number (0-9).'
    } else if (currentValue.length < 12) {
      return 'Your new password must be at least 12 characters in length.'
    }

    return ''
  }

  const validatePassword = () => {
    let errorMessage = getNewPasswordErrorMessage('newPassword')

    if (errorMessage) {
      return false
    }

    return true
  }

  const getNewConfirmErrorMessage = (fieldName: string) => {
    let currentValue = props.watch(fieldName)

    // only validate if has an error
    if (!props.errors || !props.errors[fieldName]) {
      return ''
    }

    if (currentValue) {
      if (
        props.errors &&
        props.errors.confirmPassword &&
        props.errors.confirmPassword.type === 'validate'
      ) {
        return 'New password and confirm new password must match.'
      }
    } else {
      return 'Confirm new password is a required field.'
    }

    return ''
  }

  const newPasswordErrorMessage = getNewPasswordErrorMessage('newPassword')
  const confirmPasswordErrorMessage = getNewConfirmErrorMessage(
    'confirmPassword'
  )

  return (
    <>
      <div className="fieldGroup login-field-group mt-md">
        <Typography component="p" variant="body1">
          New password
        </Typography>
        <TextField
          name="newPassword"
          id="newPassword"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={() => setHasUpdated(true)}
          disabled={disabled}
          {...{
            ...props.register('newPassword', {
              required: true,
              minLength: 12,
              validate: validatePassword,
            }),
            ref: undefined,
            inputRef: props.register('newPassword', {
              required: true,
              minLength: 12,
              validate: validatePassword,
            }).ref,
          }}
          helperText={newPasswordErrorMessage}
          error={!!newPasswordErrorMessage}
          inputProps={{
            autoCapitalize: 'none',
          }}
        />
      </div>

      <div className="fieldGroup login-field-group">
        <Typography component="p" variant="body1">
          Confirm your password
        </Typography>
        <TextField
          name="confirmPassword"
          id="confirmPassword"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          disabled={disabled}
          {...{
            ...props.register('confirmPassword', {
              required: true,
              validate: (value: string) => value === props.watch('newPassword'),
            }),
            ref: undefined,
            inputRef: props.register('confirmPassword', {
              required: true,
              validate: (value: string) => value === props.watch('newPassword'),
            }).ref,
          }}
          helperText={confirmPasswordErrorMessage}
          error={!!confirmPasswordErrorMessage}
          inputProps={{
            autoCapitalize: 'none',
          }}
        />
      </div>
    </>
  )
}
