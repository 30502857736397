import * as React from 'react'
import { IndexRoute, Route } from 'react-router'

import { userIsNotAuthenticated } from './auth'
import ConfirmDOB from '../containers/onboard/confirm-dob/ConfirmDOB'
import CreatePassword from '../containers/onboard/create-password/CreatePassword'
import CreateUsername from '../containers/onboard/create-user/CreateUsername'
import Onboard from '../containers/onboard/Onboard'
import Welcome from '../containers/onboard/welcome/Welcome'
import { confirmDOBHook, onboardingHashHook } from './routing.hooks'
import Complete from '../containers/onboard/complete/Complete'

export const OnboardingRoutes = () => {
  return (
    <Route path="onboard" component={Onboard} onEnter={onboardingHashHook()}>
      <IndexRoute component={userIsNotAuthenticated(Welcome)} />
      <Route path="welcome" component={userIsNotAuthenticated(Welcome)} />
      <Route
        path="confirm-dob"
        component={userIsNotAuthenticated(ConfirmDOB)}
      />
      <Route
        path="create-username"
        component={userIsNotAuthenticated(CreateUsername)}
        onEnter={confirmDOBHook()}
      />
      <Route
        path="create-password"
        component={userIsNotAuthenticated(CreatePassword)}
        onEnter={confirmDOBHook()}
      />
      <Route path="complete" component={userIsNotAuthenticated(Complete)} />
    </Route>
  )
}
