/* eslint-disable compat/compat */
export type DownloaderProps = {
  downloadFunc: () => Promise<Blob>
}

export abstract class BaseDownloader {
  protected blob: Blob
  protected blobPromise: Promise<Blob>

  constructor(blobPromise: Promise<Blob>) {
    this.blobPromise = blobPromise
  }

  protected async download() {
    this.blob = await this.blobPromise
  }

  async execute() {
    await this.download()

    if (!this.blob) {
      throw new Error('Download failed')
    }
  }
}

class DefaultDownloader extends BaseDownloader {
  async download() {
    await super.download()

    window.open(URL.createObjectURL(this.blob))
  }
}

class SafariDownloader extends BaseDownloader {
  newTab: Window

  async download() {
    this.newTab = window.open()

    try {
      await super.download()

      this.newTab.location.href = URL.createObjectURL(this.blob)
    } catch (e) {
      this.newTab.close()
      throw e
    }
  }
}

class IEDownloader extends BaseDownloader {
  navigator: any

  async download() {
    this.navigator = window.navigator
    await super.download()

    this.navigator.msSaveOrOpenBlob(this.blob)
  }
}

const isSafari = () =>
  navigator.userAgent.indexOf('Safari') > -1 &&
  navigator.userAgent.indexOf('Chrome') <= -1

const isIE = () => /*@cc_on!@*/ false || !!(document as any).documentMode

const getDownloader = (blobPromise: Promise<Blob>): BaseDownloader => {
  if (isSafari()) {
    return new SafariDownloader(blobPromise)
  }

  if (isIE()) {
    return new IEDownloader(blobPromise)
  }

  return new DefaultDownloader(blobPromise)
}

export const download = async (blobPromise: Promise<Blob>) => {
  const downloader: BaseDownloader = getDownloader(blobPromise)

  return downloader.execute()
}
