export enum actionTypes {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  CHANGE_ACTIVE_ACCOUNT_TAB = 'CHANGE_ACTIVE_ACCOUNT_TAB',
  CHANGE_SHOW_HEADER = 'CHANGE_SHOW_HEADER',
  CHANGE_ACTIVE_MENU = 'CHANGE_ACTIVE_MENU',
}

export const LayoutToggleSidebar = (show?: boolean) => ({
  type: actionTypes.TOGGLE_SIDEBAR,
  payload: show,
})

export const LayoutChangeActiveAccountTab = (activeAccountTab: string) => ({
  type: actionTypes.CHANGE_ACTIVE_ACCOUNT_TAB,
  payload: activeAccountTab,
})

export const LayoutChangeActiveMenu = (activeMenu: string) => ({
  type: actionTypes.CHANGE_ACTIVE_MENU,
  payload: activeMenu,
})

export const LayoutChangeShowHeader = (show?: boolean) => ({
  type: actionTypes.CHANGE_SHOW_HEADER,
  payload: show,
})
