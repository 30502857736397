import 'rxjs'

import * as actions from './authorisation.actions'
import * as userActions from '../user/user.actions'

import { AccountsOverviewRequestActionType } from '../accounts/accounts.actions'
import { ActionsObservable, Epic } from 'redux-observable'
import {
  OnboardingActions,
  OnboardingClearState,
} from '../onboarding/onboarding.actions'
import {
  UsernameActions,
  UsernameClearStateAction,
} from '../username/username.actions'

import { AccountsState } from '../accounts/accounts.model'
import { AuthorisationState } from './authorisation.model'
import { Observable } from 'rxjs/Observable'
import { PirRateState } from '../pir/pir.model'
import { UserState } from '../user/user.model'
import { ajax } from 'rxjs/observable/dom/ajax'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type LightStore = { getState: Function; dispatch: Function }

export const authorisationEpic: Epic<
  | actions.AuthorisationActions
  | userActions.UserActions
  | AccountsOverviewRequestActionType,
  AuthorisationState | UserState | AccountsState | PirRateState
> = (
  action$: ActionsObservable<actions.AuthorisationRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.AUTHORISATION_REQUEST)
    .switchMap((action) =>
      ajax
        .post(`${ffmAppServerUrl}/api/users/authorisation`, action.payload)
        .mergeMap((response) =>
          Observable.concat(
            Observable.of(
              userActions.UserAuthorisationSuccessAction(
                response.response.userId
              )
            ),
            Observable.of(
              actions.AuthorisationRequestSuccessAction({
                authorisationToken: response.response.token,
                lastAuthenticationTime:
                  response.response.lastAuthenticationTime,
                filesToken: response.response.filesToken,
              })
            ),
            Observable.of(
              userActions.UserRequestDetailsAction(response.response.userId)
            )
          )
        )
        .catch((err, caught) => {
          // 401 status gets the error from the server.
          if (
            err.status === 401 &&
            typeof err.response === 'object' &&
            err.response.hasOwnProperty('message')
          ) {
            return Observable.of(
              actions.AuthorisationRequestFailureAction(err.response.message)
            )
          }
          return Observable.of(
            actions.AuthorisationRequestFailureAction(
              'Authorisation request failed'
            )
          )
        })
    )

export const deauthorisationEpic: Epic<
  actions.AuthorisationActions | OnboardingActions | UsernameActions,
  AuthorisationState
> = (
  action$: ActionsObservable<actions.DeauthorisationRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.DEAUTHORISATION_REQUEST)
    .switchMap((action) =>
      ajax
        .delete(
          `${ffmAppServerUrl}/api/users/${action.payload}/authorisation`,
          {
            Authorization:
              'Bearer ' + store.getState().authorisation.authorisationToken,
          }
        )
        .mergeMap((response) =>
          Observable.concat(
            Observable.of(actions.DeauthorisationRequestSuccessAction()),
            Observable.of(OnboardingClearState()),
            Observable.of(UsernameClearStateAction())
          )
        )
        .catch((err, caught) =>
          Observable.of(actions.DeauthorisationRequestFailureAction())
        )
    )

export const checkAuthorisationEpic: Epic<
  actions.AuthorisationActions,
  AuthorisationState
> = (
  action$: ActionsObservable<actions.CheckAuthorisationRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.CHECK_AUTHORISATION_REQUEST)
    .switchMap((action) =>
      ajax
        .get(`${ffmAppServerUrl}/api/users/${action.payload}/authorisation`, {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        })
        .map((response) => actions.CheckAuthorisationRequestSuccessAction())
        .catch((err, caught): any => {
          // Only deauthorise on 400 series - ignore occasional server errors
          if (err.status === 401 || err.status === 404 || err.status === 403) {
            return Observable.concat(
              Observable.of(actions.DeauthorisationRequestSuccessAction()),
              Observable.of(actions.DeauthorisationRequestFailureAction())
            )
          } else {
            return Observable.of(
              actions.CheckAuthorisationRequestSuccessAction()
            )
          }
        })
    )
