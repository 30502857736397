import { AuthorizationFormValues } from '../../components/authorization-modal/use-authorized-request'
import { PirRate } from './pir.model'

export enum actionTypes {
  PIR_USER_RATE_REQUEST = 'PIR_USER_RATE_REQUEST',
  PIR_USER_RATE_REQUEST_SUCCESS = 'PIR_USER_RATE_REQUEST_SUCCESS',
  PIR_USER_RATE_REQUEST_FAILURE = 'PIR_USER_RATE_REQUEST_FAILURE',
  PIR_USER_RATE_SAVE = 'PIR_USER_RATE_SAVE',
  PIR_USER_RATE_SAVE_SUCCESS = 'PIR_USER_RATE_SAVE_SUCCESS',
  PIR_USER_RATE_SAVE_FAILURE = 'PIR_USER_RATE_SAVE_FAILURE',
  PIR_USER_RESET_SUCCESS = 'PIR_USER_RESET_SUCCESS',
}

export type PIRUserRateRequestActionType = {
  type: actionTypes.PIR_USER_RATE_REQUEST
  payload: { uuid: string }
}
export type PIRUserRateRequestSuccessActionType = {
  type: actionTypes.PIR_USER_RATE_REQUEST_SUCCESS
  payload: PirRate
}
export type PIRUserRateRequestGetFailureActionType = {
  type: actionTypes.PIR_USER_RATE_REQUEST_FAILURE
  payload: string
}

export type PIRUserRateSaveActionType = {
  type: actionTypes.PIR_USER_RATE_SAVE
  payload: { pirRate: string; uuid: string } & AuthorizationFormValues
}
export type PIRUserRateSaveSuccessActionType = {
  type: actionTypes.PIR_USER_RATE_SAVE_SUCCESS
  payload: string
}
export type PIRUserRateSaveFailureActionType = {
  type: actionTypes.PIR_USER_RATE_SAVE_FAILURE
  payload: string
}

// Get the user's current PIR rate from the API
export const PIRUserRateRequestAction = (
  userid: string
): PIRUserRateRequestActionType => ({
  type: actionTypes.PIR_USER_RATE_REQUEST,
  payload: {
    uuid: userid,
  },
})

export const PIRUserRateRequestSuccessAction = (
  rate: PirRate
): PIRUserRateRequestSuccessActionType => ({
  type: actionTypes.PIR_USER_RATE_REQUEST_SUCCESS,
  payload: rate,
})

export const PIRUseRateRequestFailureAction = (
  message: string
): PIRUserRateRequestGetFailureActionType => ({
  type: actionTypes.PIR_USER_RATE_REQUEST_FAILURE,
  payload: message,
})

// Save the user's new PIR rate to the API
export const PIRUserRateSaveAction = (
  values: { pirRate: string } & AuthorizationFormValues,
  uuid: string
): PIRUserRateSaveActionType => ({
  type: actionTypes.PIR_USER_RATE_SAVE,
  payload: {
    ...values,
    uuid,
  },
})

export const PIRUserRateSaveSuccessAction = (
  pirRate: string
): PIRUserRateSaveSuccessActionType => ({
  type: actionTypes.PIR_USER_RATE_SAVE_SUCCESS,
  payload: pirRate,
})

export const PIRUserRateSaveFailureAction = (
  message: string
): PIRUserRateSaveFailureActionType => ({
  type: actionTypes.PIR_USER_RATE_SAVE_FAILURE,
  payload: message,
})

export type PIRResetSuccessActionType = {
  type: actionTypes.PIR_USER_RESET_SUCCESS
}
export const PIRResetSuccessAction = (): PIRResetSuccessActionType => ({
  type: actionTypes.PIR_USER_RESET_SUCCESS,
})

export type PirRateActions =
  | PIRUserRateRequestActionType
  | PIRUserRateRequestSuccessActionType
  | PIRUserRateRequestGetFailureActionType
  | PIRUserRateSaveActionType
  | PIRUserRateSaveSuccessActionType
  | PIRUserRateSaveFailureActionType
