import CircularProgress from '@material-ui/core/CircularProgress'
import cn from 'classnames'
import './loading-spinner.scss'

interface LoadingSpinnerProps {
  className?: string
  center?: boolean
}

export const LoadingSpinner = ({ className, center }: LoadingSpinnerProps) => {
  const divClass = cn('loading-spinner', {
    [`${className}`]: !!className,
    center: center,
  })

  return (
    <div className={divClass}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default LoadingSpinner
