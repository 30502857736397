/* eslint-disable @typescript-eslint/no-explicit-any, react/no-unused-prop-types */
import React, { ReactNode } from 'react'
import { FormikContextType } from 'formik'
import { MenuPlacement } from 'react-select'
import get from 'lodash/get'
import {
  InputWrapper,
  TextInput,
  Select,
  SelectProps,
  AutocompleteType,
  RadioMenu,
  RadioMenuProps,
  InputWrapperProps,
} from '.'
import { NumberInput } from './NumberInput'
import { AmountInput } from './AmountInput'
import { AmountSelectInput, AmountSelectInputProps } from './AmountSelectInput'
import { OptionButtons, OptionButtonsProps } from './OptionButtons'

type SharedFormProps = {
  form: FormikContextType<any>
  name: string
  className?: string
  disabled?: boolean
  dark?: boolean
  hidden?: boolean
}

export type ControlledInputProps = {
  inputClass?: string
  autoComplete?: AutocompleteType
} & InputWrapperProps &
  SharedFormProps

export const ControlledInput = ({
  form,
  name,
  label,
  action,
  className,
  inputClass,
  disabled,
  autoComplete,
  dark,
  labelSize,
  hidden,
}: ControlledInputProps) => {
  const {
    values: formValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = form

  const wrapperError =
    get(touched, name) && get(errors, name) ? (get(errors, name) as string) : ''

  return (
    <InputWrapper
      label={label || name}
      labelSize={labelSize}
      action={action}
      className={className}
      error={wrapperError}
      hidden={hidden}
    >
      <TextInput
        value={get(formValues, name, '')}
        name={name}
        autoComplete={autoComplete}
        className={inputClass}
        onChange={(val) => {
          setFieldTouched(name, true, true)
          handleChange(val)
        }}
        onBlur={handleBlur}
        error={!!(get(touched, name) && get(errors, name))}
        disabled={disabled}
        dark={dark}
      />
    </InputWrapper>
  )
}

export type ControlledNumberInputProps = {
  inputClass?: string
  min?: number
  max?: number
} & InputWrapperProps &
  SharedFormProps

export const ControlledNumberInput = ({
  form,
  name,
  label,
  labelSize,
  action,
  className,
  inputClass,
  disabled,
  dark,
  min,
  max,
  hidden,
}: ControlledNumberInputProps) => {
  const {
    values: formValues,
    errors,
    touched,
    setFieldTouched,
    handleBlur,
    handleChange,
  } = form

  const wrapperError =
    get(touched, name) && get(errors, name) ? (get(errors, name) as string) : ''

  return (
    <InputWrapper
      label={label || name}
      labelSize={labelSize}
      action={action}
      className={className}
      error={wrapperError}
      hidden={hidden}
    >
      <NumberInput
        value={get(formValues, name, '')}
        name={name}
        className={inputClass}
        onChange={(val) => {
          setFieldTouched(name, true, true)
          handleChange(val)
        }}
        onBlur={handleBlur}
        error={!!(get(touched, name) && get(errors, name))}
        disabled={disabled}
        dark={dark}
        min={min}
        max={max}
      />
    </InputWrapper>
  )
}
export type ControlledAmountInputProps = {
  inputClass?: string
} & InputWrapperProps &
  SharedFormProps

export const ControlledAmountInput = ({
  form,
  name,
  label,
  labelSize,
  action,
  className,
  inputClass,
  disabled,
  dark,
  hidden,
}: ControlledAmountInputProps) => {
  const {
    values: formValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldTouched,
  } = form

  const wrapperError =
    get(touched, name) && get(errors, name) ? (get(errors, name) as string) : ''

  return (
    <InputWrapper
      label={label || name}
      labelSize={labelSize}
      action={action}
      className={className}
      error={wrapperError}
      hidden={hidden}
    >
      <AmountInput
        value={get(formValues, name, '')}
        name={name}
        className={inputClass}
        onChange={(val) => {
          setFieldTouched(name, true, true)
          handleChange(val)
        }}
        onBlur={handleBlur}
        error={!!(get(touched, name) && get(errors, name))}
        disabled={disabled}
        dark={dark}
      />
    </InputWrapper>
  )
}

export type ControlledSelectProps = {
  label?: ReactNode
  selectClass?: string
  onSelect?: (value: any) => void
  menuPlacement?: MenuPlacement
} & Pick<SelectProps, 'options'> &
  InputWrapperProps &
  SharedFormProps

export const ControlledSelect = ({
  form,
  name,
  label,
  options,
  onSelect,
  className,
  selectClass,
  dark,
  menuPlacement,
  labelSize,
  hidden,
}: ControlledSelectProps) => {
  const {
    values: formValues,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = form

  const wrapperError =
    get(touched, name) && get(errors, name) ? (get(errors, name) as string) : ''

  const currentOption = get(formValues, name, '')

  return (
    <InputWrapper
      label={label}
      className={className}
      error={wrapperError}
      labelSize={labelSize}
      hidden={hidden}
    >
      <Select
        onChange={(value) => {
          if (onSelect) {
            onSelect(value)
          } else {
            setFieldValue(name, value)
          }
        }}
        onBlur={() => setFieldTouched(name, true, true)}
        value={currentOption}
        options={options}
        name={name}
        className={selectClass}
        dark={dark}
        error={!!(get(touched, name) && get(errors, name))}
        menuPlacement={menuPlacement}
      />
    </InputWrapper>
  )
}

export type ControlledAmountSelectInputProps = {
  amountName: string
  selectName: string
  label?: ReactNode
} & AmountSelectInputProps &
  InputWrapperProps &
  Omit<SharedFormProps, 'name'>

export const ControlledAmountSelectInput = ({
  form,
  amountName,
  selectName,
  label,
  options,
  className,
  dark,
  labelSize,
  hidden,
}: ControlledAmountSelectInputProps) => {
  const {
    values: formValues,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = form

  const wrapperError =
    get(touched, amountName) && get(errors, amountName)
      ? (get(errors, amountName) as string)
      : ''

  const currentAmount = get(formValues, amountName, '')
  const currentOption = get(formValues, selectName, '')

  return (
    <InputWrapper
      label={label}
      className={className}
      error={wrapperError}
      labelSize={labelSize}
      hidden={hidden}
    >
      <AmountSelectInput
        amountValue={currentAmount}
        amountName={amountName}
        onAmountChange={(val) => {
          setFieldTouched(amountName, true, true)
          setFieldValue(amountName, parseFloat(val.target.value))
        }}
        selectValue={currentOption}
        selectName={selectName}
        options={options}
        onSelectChange={(value) => setFieldValue(selectName, value)}
        onBlur={() => setFieldTouched(amountName, true, true)}
        dark={dark}
        error={!!(get(touched, amountName) && get(errors, amountName))}
      />
    </InputWrapper>
  )
}

export type ControlledRadioMenuProps<T> = RadioMenuProps<T> &
  InputWrapperProps &
  SharedFormProps

export const ControlledRadioMenu = <T,>({
  form,
  name,
  label,
  options,
  className,
  dark,
  direction = 'vertical',
  labelSize,
  size = 'medium',
  hidden,
}: ControlledRadioMenuProps<T>) => {
  const {
    values: formValues,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = form

  const wrapperError =
    get(touched, name) && get(errors, name) ? (get(errors, name) as string) : ''

  const currentOption = options?.find(
    (option) => option.value === get(formValues, name, '')
  )

  return (
    <InputWrapper
      label={label}
      className={className}
      error={wrapperError}
      labelSize={labelSize}
      hidden={hidden}
    >
      <RadioMenu
        onSelect={(value) => {
          setFieldTouched(name, true, true)
          setFieldValue(name, value?.value)
        }}
        value={currentOption}
        options={options}
        name={name}
        direction={direction}
        size={size}
      />
    </InputWrapper>
  )
}

export type ControlledButtonGroupProps<T> = OptionButtonsProps<T> &
  InputWrapperProps &
  SharedFormProps

export const ControlledButtonGroup = <T,>({
  form,
  name,
  label,
  options,
  className,
  dark,
  labelSize,
  hidden,
}: ControlledButtonGroupProps<T>) => {
  const { values: formValues, errors, touched, setFieldValue } = form

  const wrapperError =
    get(touched, name) && get(errors, name) ? (get(errors, name) as string) : ''

  const currentOption = options?.find(
    (option) => option.value === get(formValues, name, '')
  )

  return (
    <InputWrapper
      label={label}
      className={className}
      error={wrapperError}
      labelSize={labelSize}
      hidden={hidden}
    >
      <OptionButtons
        setSelected={(value) => setFieldValue(name, value)}
        value={currentOption?.value}
        options={options}
        dark={dark}
      />
    </InputWrapper>
  )
}
