const prefix = 'FFO'

const buildKey = (key: string) => `${prefix}_${key}`

export const DISMISSED_NOTIFICATIONS_STORAGE_KEY = 'dismissedNotifications'

export const storage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem: (key: string, value: any) =>
    localStorage.setItem(buildKey(key), JSON.stringify(value)),
  getItem: (key: string) => {
    const value = localStorage.getItem(buildKey(key))
    if (!value) {
      return null
    }

    try {
      return JSON.parse(value)
    } catch {
      console.error(`storage for key ${key} not found`)
      return null
    }
  },
  removeItem: (key: string) => localStorage.removeItem(buildKey(key)),
}
