import React, { useEffect, useState, useCallback } from 'react'
import ReportItem from '../report-item/ReportItem'
import './ReportList.scss'

import { ReportFilters as ReportFiltersModel } from '../../redux/reports/reports.model'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

interface ReportListProps {
  reports: Report[]
  filters?: ReportFiltersModel
}

type Report = {
  date: string
  title: string
  type: string
  url: string
  accountId: string
}

type SortOrderOptions = 'asc' | 'desc'

export default function ReportList(props: ReportListProps) {
  const [sortOrder, setSortOrder] = useState('desc')

  const [reportsToShow, setReportsToShow] = useState(props.reports)

  const compareReports = (sortOrder: string, a: Report, b: Report) => {
    if (sortOrder === 'asc') {
      return a.date > b.date ? 1 : a.date === b.date ? 0 : -1
    } else {
      return a.date < b.date ? 1 : a.date === b.date ? 0 : -1
    }
  }

  const updateSortOrder = useCallback(
    (sortOrder: string) => {
      return props.reports.sort((a, b) => compareReports(sortOrder, a, b))
    },
    [props.reports]
  )

  const changeSortOrder = () => {
    // New sort order
    let newSortOrder =
      sortOrder === 'asc' ? 'desc' : ('asc' as SortOrderOptions)

    // set sort order
    setSortOrder(newSortOrder)
    setReportsToShow(updateSortOrder(newSortOrder))
  }

  useEffect(() => {
    const sortedReports = updateSortOrder(sortOrder)
    const { accountNumber } = props.filters

    if (!accountNumber) {
      setReportsToShow(sortedReports)
    } else {
      const filteredReports = sortedReports.filter(
        (report) => report.accountId === props.filters.accountNumber
      )
      setReportsToShow(filteredReports)
    }
  }, [setReportsToShow, updateSortOrder, sortOrder, props.filters])

  return (
    <div className="table-content-wrapper report-list-component">
      <div className="table-content">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => changeSortOrder()}>
                <div className="date-cell">
                  Date{' '}
                  {sortOrder === 'desc' ? (
                    <ArrowDownwardIcon />
                  ) : (
                    <ArrowUpwardIcon />
                  )}
                </div>
              </TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Investment type</TableCell>
              <TableCell>Report</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsToShow.map((report, index) => (
              <ReportItem key={index} isFirst={index === 0} {...report} />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
