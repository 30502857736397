import { Link } from '@material-ui/core'
import Modal from '../modal/Modal'
import './GovernmentContributionModal.scss'

export type LearnMoreModalProps = {
  open: boolean
  onClose: () => void
}

export const LearnMoreModal: React.FC<LearnMoreModalProps> = ({
  open,
  onClose,
}: LearnMoreModalProps) => {
  return (
    <Modal
      open={open}
      fullScreen={true}
      title="Government contribution"
      onClose={onClose}
      className="government-contribution-modal"
    >
      <span>
        <b>Who is eligible to receive the Government contribution?</b>
      </span>

      <p>
        KiwiSaver members who are:
        <ul>
          <li>18 years of age or older;</li>
          <li>Mainly living in NZ; and</li>
          <li>Not yet entitled to withdraw for retirement.</li>
        </ul>
      </p>
      <span>
        To be eligible for the Government contribution you must be aged between
        18 and 65, and be a resident in New Zealand for most of the KiwiSaver
        year (1 July to 30 June). If you’re only eligible for part of the year,
        your Government contribution will be prorated based on the number of
        days you were eligible in the KiwiSaver year.
      </span>
      <p>
        Based on this information, if you believe you should be eligible, please
        feel free to get in touch with us on{' '}
        <a
          className="MuiTypography-colorSecondary"
          href="mailto:enquiries@fisherfunds.co.nz"
        >
          enquiries@fisherfunds.co.nz
        </a>
        .
      </p>
      <p>
        Find out more about{' '}
        <Link
          color="secondary"
          underline="always"
          target="_blank"
          href="https://fisherfunds.co.nz/investment/kiwisaver/how-kiwisaver-works/kiwisaver-government-contribution"
        >
          Government contribution
        </Link>
        .
      </p>
    </Modal>
  )
}
