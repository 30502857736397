import * as React from 'react'

import {
  getDobError,
  getEmailError,
  emailValidation,
} from '../../common/forms-helper'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { useForm } from 'react-hook-form'
import DobInput from '../dob-input/DobInput'
import { Typography } from '@material-ui/core'

interface NameEmailDOBFormProps {
  isLoading: boolean
  success: boolean
  submitButtonText: string
  submittingButtonText: string
  onSubmit: (
    firstName: string,
    surname: string,
    email: string,
    dob: string
  ) => void
}

export default function NameEmailDOBForm(props: NameEmailDOBFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()
  const { isLoading, onSubmit } = props

  const onSubmitEvent = (data: any) => {
    if (!data.firstName || !data.surname || !data.email || !data.dob) {
      return
    }

    onSubmit(
      data.firstName.trim(),
      data.surname.trim(),
      data.email.trim(),
      data.dob.trim()
    )
  }

  let emailErrorMessage = getEmailError(errors)

  return (
    <form onSubmit={handleSubmit(onSubmitEvent)} className="form">
      <div className="fieldGroup login-field-group">
        <Typography component="p" variant="body1">
          First name
        </Typography>
        <TextField
          name="firstName"
          id="firstName"
          variant="outlined"
          margin="normal"
          fullWidth
          disabled={isLoading}
          {...{
            ...register('firstName', { required: true }),
            ref: undefined,
            inputRef: register('firstName', { required: true }).ref,
          }}
          helperText={
            errors && errors.firstName ? 'First name is a required field' : ''
          }
          error={!!errors?.firstName}
        />
      </div>
      <div className="fieldGroup login-field-group">
        <Typography component="p" variant="body1">
          Surname
        </Typography>
        <TextField
          name="surname"
          id="surname"
          variant="outlined"
          margin="normal"
          fullWidth
          disabled={isLoading}
          {...{
            ...register('surname', { required: true }),
            ref: undefined,
            inputRef: register('surname', { required: true }).ref,
          }}
          helperText={
            errors && errors.surname ? 'Surname is a required field' : ''
          }
          error={!!errors?.surname}
        />
      </div>
      <div className="fieldGroup login-field-group">
        <Typography component="p" variant="body1">
          Email
        </Typography>
        <TextField
          name="email"
          id="email"
          variant="outlined"
          margin="normal"
          fullWidth
          disabled={isLoading}
          {...{
            ...register('email', emailValidation),
            ref: undefined,
            inputRef: register('email', emailValidation).ref,
          }}
          helperText={emailErrorMessage}
          error={!!emailErrorMessage}
        />
      </div>
      <div className="fieldGroup login-field-group mb-md">
        <Typography component="p" variant="body1">
          Date of birth
        </Typography>
        <DobInput
          name="dob"
          placeholder="01/01/1990"
          register={register}
          setValue={setValue}
          error={!!errors?.dob}
          helperText={
            errors?.dob && getDobError(errors, errors?.dob?.ref?.value)
          }
          margin="normal"
          fullWidth
        />
      </div>
      <div className="action-group">
        <Button
          disableRipple
          disabled={isLoading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {props.isLoading
            ? props.submittingButtonText
            : props.submitButtonText}
        </Button>
      </div>
    </form>
  )
}
