import * as React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { bindActionCreators, Dispatch } from 'redux'
import ForgotPasswordCredentials from '../../components/forgot-password/ForgotPasswordCredentials'
import NotFound from '../../components/not-found/NotFound'
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner'
import Alert from 'react-s-alert'
import { AppState } from '../../redux/app-state'
import {
  UserInitForgotProcessType,
  UserInitForgotProcess,
  UserForgotPasswordCredentials,
  UserSetForgotPasswordCredentialsActionType,
  UsernameConfirmationAction,
  UserRequestConfirmCodeCredentials,
  UserRequestConfirmCodeActionType,
  UserRequestConfirmCodeAction,
} from '../../redux/user/user.actions'
import { SMSOrEmailNotification } from '../../components/sms-or-email-notification/SMSOrEmailNotification'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'

interface ForgotPasswordProps {
  setTitle: (newTitle: string) => void
  // Whole process
  isLoading: boolean
  hasError: boolean
  errorMessage: string
  // State machine representing screen we should see
  screen: 'credentials' | 'method' | 'confirm'
  // Credentials
  username: string
  dob: string
  // Request confirm code
  method: 'email' | 'sms'
  confirmId: string
  params: {
    action: 'pin' | 'password'
  }
  mobilePhoneNumber: string
  actions: {
    init: () => UserInitForgotProcessType
    setCredentials: (
      payload: UserForgotPasswordCredentials
    ) => UserSetForgotPasswordCredentialsActionType
    requestConfirmCode: (
      payload: UserRequestConfirmCodeCredentials
    ) => UserRequestConfirmCodeActionType
    nextStep: (path: string) => void
  }
  setActiveMenu: (activeMenu: string) => any
}

export class ForgotPassword extends React.Component<ForgotPasswordProps> {
  UNSAFE_componentWillMount() {
    this.props.setActiveMenu('login')
    this.props.actions.init()

    if (this.props.params.action === 'pin') {
      this.props.setTitle('Forgot PIN')
    } else {
      this.props.setTitle('Forgot password?')
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ForgotPasswordProps) {
    // If we just changed screen to 'confirm', redirect to the confirm screen
    if (
      this.props.screen !== nextProps.screen &&
      nextProps.screen === 'confirm'
    ) {
      this.props.actions.nextStep('/forgot/confirm/' + nextProps.confirmId)
    }
  }

  componentDidUpdate(prevProps: ForgotPasswordProps) {
    if (!prevProps.hasError && this.props.hasError) {
      Alert.error(this.props.errorMessage)
    }
  }

  render() {
    if (
      this.props.params.action !== 'pin' &&
      this.props.params.action !== 'password'
    ) {
      return <NotFound {...this.props} />
    }
    switch (this.props.screen) {
      default:
      case 'credentials':
        return (
          <ForgotPasswordCredentials
            heading={
              this.props.params.action === 'pin'
                ? "Let's get your PIN sorted out."
                : "Let's get your password sorted out."
            }
            buttonText={
              this.props.params.action === 'pin'
                ? 'Reset PIN'
                : 'Reset password'
            }
            submit={this.props.actions.setCredentials}
            isLoading={this.props.isLoading}
          />
        )
      case 'method':
        return (
          <SMSOrEmailNotification
            isLoading={this.props.isLoading}
            handleChoice={(method: 'email' | 'sms') => {
              this.props.actions.requestConfirmCode({
                username: this.props.username,
                method,
              })
            }}
            title="Where would you like us to send your reset code to?"
            titleNoMobile="We will send you a reset code to your email address."
            emailButtonLabelNoMobile="Email me the code"
            submitted={false} // This prop is set to false as it automatically redirects after submission
            mobilePhoneNumber={this.props.mobilePhoneNumber}
          />
        )
      case 'confirm':
        return <LoadingSpinner center={true} className="my-md" />
    }
  }
}

export default connect(
  (state: AppState) => {
    return {
      ...state.forgotPassword,
    }
  },
  (dispatch: Dispatch<{}>) => ({
    actions: {
      ...bindActionCreators(
        {
          init: UserInitForgotProcess,
          setCredentials: UsernameConfirmationAction,
          requestConfirmCode: UserRequestConfirmCodeAction,
        },
        dispatch
      ),
      nextStep: (path: string) => dispatch(routerActions.push(path)),
      setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
    },
  }),
  null
)(ForgotPassword)
