import React from 'react'
import cn from 'classnames'

import './OptionButtons.scss'

export interface OptionButtonsProps<T> {
  value?: T
  className?: string
  setSelected?: (value: T) => void
  options: { label: string; value: T }[]
  dark?: boolean
}

export const OptionButtons = <T,>({
  value,
  className,
  setSelected,
  options,
  dark,
}: OptionButtonsProps<T>) => (
  <div
    className={cn('option-buttons', {
      'option-buttons--dark': dark,
      [`${className}`]: !!className,
    })}
  >
    {options.map((option: { label: string; value: T }) => (
      <button
        key={option.label}
        className={cn('option-buttons__button', {
          'option-buttons__button--selected': value === option.value,
        })}
        onClick={() => setSelected && setSelected(option.value)}
      >
        <span>{option.label}</span>
      </button>
    ))}
  </div>
)
