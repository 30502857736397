import * as React from 'react'
import Alert from 'react-s-alert'
import SiteAlert from '../site-alert/SiteAlert'

interface FFMAlertProps {
  stackLimit: number
}

export default class FFMAlert extends React.Component<FFMAlertProps> {
  static defaultProps = {
    stackLimit: 1,
  }

  render() {
    const { stackLimit } = this.props
    return (
      <div id="ffm-alert">
        <Alert
          stack={{ limit: stackLimit }}
          contentTemplate={SiteAlert}
          position="top"
          beep={false}
          timeout={10000}
          html={true}
        />
      </div>
    )
  }
}
