import React, { useState } from 'react'

import './PrivacyStatement.scss'
import Modal from '../modal/Modal'
import { Button } from '../clickable/button/Button'

const PrivacyStatement = () => {
  const [showPrivacyStatement, setShowPrivacyStatement] = useState(false)
  return (
    <>
      <Button
        className="privacy-statement py-xs px-2xs"
        variant="link"
        onClick={() => {
          setShowPrivacyStatement(true)
        }}
        size={'md'}
      >
        Privacy Statement
      </Button>
      <Modal
        open={showPrivacyStatement}
        onClose={() => setShowPrivacyStatement(false)}
        className="privacy-statement-modal"
        title={''}
      >
        <h4 className="privacy-statement-modal__title">Privacy Statement</h4>
        <p className="privacy-statement-modal__paragraph">
          The information that you provide us in this form and which you may
          provide us in the future may be used by Fisher Funds, the Supervisor
          and any related entities of either, your financial adviser or the
          distribution entity through which you joined the Scheme (if
          applicable) and by other service providers to the Scheme to provide
          services in relation to your investment and to promote to you other
          products and services. You have the right to access the information
          held by us and you may also request that it be corrected by contacting
          us. We will provide you (on request) with the name and address of any
          entity to which information has been disclosed.
        </p>
      </Modal>
    </>
  )
}

export default PrivacyStatement
