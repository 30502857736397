import React, { useEffect, useState } from 'react'
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'
import moment from 'moment'
import { dobValidation } from '../../common/forms-helper'

type DobInputProps = {
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
} & Omit<KeyboardDatePickerProps, 'onChange' | 'value'>

export default function DobInput(props: DobInputProps) {
  const { register, setValue, ...otherProps } = props
  const [internalStateValue, setInternalStateValue] = useState<Date>(null)
  useEffect(() => {
    register('dob', dobValidation)
  }, [register])

  return (
    <KeyboardDatePicker
      value={internalStateValue}
      onChange={(date) => {
        setInternalStateValue(date)
        setValue('dob', moment(date).format('DD/MM/YYYY'))
      }}
      format="dd/MM/yyyy"
      inputVariant="outlined"
      variant="inline"
      openTo="year"
      maxDate={new Date()}
      autoOk
      disableToolbar
      {...otherProps}
    />
  )
}
