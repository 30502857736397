import locationHelperBuilder from 'redux-auth-wrapper/history3/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect'

import { AppState } from '../redux/app-state'

locationHelperBuilder({})

/**
 * Higher-order components to add authentication protection to
 * a component.
 *
 * If authenticatedSelector is false, the current route will
 * be changed to the redirectPath.
 *
 * Otherwise, the component will be rendered.
 */
export const userIsNotAuthenticated: any = connectedRouterRedirect({
  authenticatedSelector: (state: AppState) =>
    !state.authorisation.isAuthenticated,
  redirectPath: (state: AppState, ownProps: object) => {
    return '/'
  },
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
})
