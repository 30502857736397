import Modal from '../modal/Modal'
import { IInvestmentOption } from '../../utils/investmentOption'
import InvestmentOptionInfoDetail from './InvestmentOptionInfoDetail'
import './SingleInvestmentOptionInfoModal.scss'
import { Product } from '../../common/product-helper'

type SingleInvestmentOptionInfoModalProps = {
  onClose: () => void
  investmentOption: IInvestmentOption
  product: Product
}

const getStrategyName = (investmentOption: IInvestmentOption): string => {
  return investmentOption.strategyFundAllocations?.length === 1
    ? investmentOption.shortName.replace('Strategy', 'Fund')
    : investmentOption.shortName
}

const SingleInvestmentOptionInfoModal = (
  props: SingleInvestmentOptionInfoModalProps
) => {
  const { investmentOption, product } = props

  return (
    <Modal
      title={getStrategyName(investmentOption)}
      open
      className="switch-sub-modal"
      onClose={props.onClose}
    >
      <InvestmentOptionInfoDetail
        investmentOption={investmentOption}
        product={product}
      />
    </Modal>
  )
}

export default SingleInvestmentOptionInfoModal
