import { Reducer, AnyAction } from 'redux'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './account-details.actions'
import { AccountDetailsState, AccountDetail } from './account-details.model'

export const initialState: AccountDetailsState = {
  accounts: [] as AccountDetail[],
  ...getDefaultState(),
}

export const accountDetailsReducer: Reducer<AccountDetailsState> = (
  state: AccountDetailsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_DETAILS_REQUEST:
      return { ...state, isLoading: true, hasError: false, errorMessage: null }

    case actionTypes.ACCOUNT_DETAILS_REQUEST_SUCCESS:
      const accounts = state.accounts.filter(
        (acc: AccountDetail) => acc.accountID !== action.payload.accountID
      )
      accounts.push(action.payload)
      return { ...state, accounts, isLoading: false }

    case actionTypes.ACCOUNT_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }
    // Logging out resets the account details
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
