import React from 'react'
import { Link } from 'react-router'
import './NavigationDesktop.scss'
import Drawer from '@material-ui/core/Drawer'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CustomIcon from '../../common/CustomIcon'
import Logo from '../../assets/images/fisher-funds-logo-white.svg'
import { useMedia } from '../../hooks/use-media'
import cn from 'classnames'
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from '../contentful-terms-and-conditions'

type NavigationDesktopProps = {
  showInvestmentTools?: boolean
  showSidebar: boolean
  toggleSidebar: (show?: boolean) => any
  activeMenu: string
}

const NavigationDesktop = ({
  activeMenu,
  showInvestmentTools,
  showSidebar,
  toggleSidebar,
}: NavigationDesktopProps) => {
  const { isMobile, isTabletLandscape } = useMedia()
  const isActive = (currentMenu: string) => currentMenu === activeMenu

  const isAccountsSelected = isActive('accounts') || isActive('account-details')

  if (isMobile) {
    return null
  }

  return (
    <Drawer
      variant={isTabletLandscape ? 'permanent' : 'temporary'}
      open={showSidebar}
      onClose={() => {
        toggleSidebar(false)
      }}
    >
      <Link to="/accounts" className="logo-link">
        <img src={Logo} alt="Fisher Funds logo" className="logo" />
      </Link>
      <ul>
        <li className={cn({ 'nav-selected': isAccountsSelected })}>
          <Link title="My accounts" to="/accounts">
            <ListItemIcon>
              <CustomIcon
                icon="accounts"
                className="MuiSvgIcon-root"
                light={!isAccountsSelected}
              />
            </ListItemIcon>
            <ListItemText primary="My accounts" />
          </Link>
        </li>

        <li className={cn({ 'nav-selected': isActive('reports') })}>
          <Link title="My reports" to="/reports">
            <ListItemIcon>
              <CustomIcon
                icon="reports"
                className="MuiSvgIcon-root"
                light={!isActive('reports')}
              />
            </ListItemIcon>
            <ListItemText primary="My reports" />
          </Link>
        </li>

        <li className={cn({ 'nav-selected': isActive('details') })}>
          <Link title="My details" to="/details">
            <ListItemIcon>
              <CustomIcon
                icon="details"
                className="MuiSvgIcon-root"
                light={!isActive('details')}
              />
            </ListItemIcon>
            <ListItemText primary="My details" />
          </Link>
        </li>

        {showInvestmentTools && (
          <li className={cn({ 'nav-selected': isActive('investment') })}>
            <Link title="Investment tools" to="/investment-tools">
              <ListItemIcon>
                <CustomIcon
                  icon="calculator"
                  className="MuiSvgIcon-root"
                  light={!isActive('investment')}
                />
              </ListItemIcon>
              <ListItemText primary="Investment tools" />
            </Link>
          </li>
        )}
      </ul>
      <ul className="bottom">
        <li className={cn({ 'nav-selected': isActive('terms') })}>
          <TermsAndConditionsLink />
        </li>
        <li className={cn({ 'nav-selected': isActive('privacy') })}>
          <PrivacyPolicyLink />
        </li>
      </ul>
    </Drawer>
  )
}

export default NavigationDesktop
