import * as React from 'react'
import '../change-password/ChangePassword.scss'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import RegisterLoginLinkBox from '../../components/register-login-link-box/RegisterLoginLinkBox'

/**
 * Asks the user whether they would like notification via SMS or Email.
 */

interface SMSOrEmailNotificationProps {
  isLoading: boolean
  handleChoice: (method: 'email' | 'sms') => void
  title: string
  titleNoMobile: string
  emailButtonLabelNoMobile: string
  submitted: boolean
  mobilePhoneNumber: string
}

export class SMSOrEmailNotification extends React.Component<
  SMSOrEmailNotificationProps
> {
  getTitle() {
    return this.props.mobilePhoneNumber
      ? this.props.title
      : this.props.titleNoMobile
  }

  getEmailButton() {
    let buttonLabelNoMobile =
      this.props.emailButtonLabelNoMobile || 'Send Email'
    return (
      <Button
        className="mt-md"
        disabled={this.props.isLoading}
        onClick={() => this.props.handleChoice('email')}
        type="button"
        id="EmailButton"
        variant="contained"
        color="primary"
      >
        {this.props.mobilePhoneNumber ? 'Email' : buttonLabelNoMobile}
      </Button>
    )
  }

  getSMSButton() {
    return (
      <Button
        className="mt-md"
        disabled={this.props.isLoading}
        onClick={() => this.props.handleChoice('sms')}
        type="button"
        id="SMSButton"
        variant="contained"
        color="primary"
      >
        Text
      </Button>
    )
  }

  renderSubmitted() {
    return <RegisterLoginLinkBox type="login" />
  }

  renderMobileNumberText() {
    if (!this.props.mobilePhoneNumber) {
      return null
    }

    return (
      <Typography component="p" variant="body1">
        Your mobile ending in:{' '}
        <span className="no-wrap">{this.props.mobilePhoneNumber}</span>
      </Typography>
    )
  }

  renderForm() {
    return (
      <div className="fieldGroup login-field-group">
        <Grid container spacing={2}>
          <Grid item xs={this.props.mobilePhoneNumber ? 6 : 12}>
            {this.getEmailButton()}
          </Grid>
          {this.props.mobilePhoneNumber ? (
            <Grid item xs={6}>
              {this.getSMSButton()}
            </Grid>
          ) : null}
        </Grid>
      </div>
    )
  }

  render() {
    if (this.props.submitted) {
      return this.renderSubmitted()
    }

    return (
      <div className="forgot-password-component">
        <Typography variant="h6">{this.getTitle()}</Typography>
        {this.renderMobileNumberText()}

        {this.renderForm()}

        <RegisterLoginLinkBox type="login" />
      </div>
    )
  }
}
