import React, { useState } from 'react'
import cn from 'classnames'
import Currency from '../currency/Currency'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Detail, Section } from './AccountPerformanceSummary'
import { TableCell, TableRow } from '@material-ui/core'
import { SummaryDetailLabel } from './SummaryDetailLabel'
import { Button } from '../clickable/button/Button'

type sectionProps = {
  section: Section
  index: number
}
const AccountPerformanceSummarySection: React.FC<sectionProps> = ({
  section,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <>
      <TableRow className="summary-details">
        <TableCell component="th" className="label">
          {section.label}
        </TableCell>
        <TableCell>
          <Currency value={section.total} />
        </TableCell>
        <TableCell padding="none">
          <Button
            size="lg"
            variant="link"
            iconLeft={ExpandMoreIcon}
            onClick={() => {
              setExpanded(!expanded)
            }}
            className={cn('performance-summary-icon', {
              expanded: expanded,
            })}
          />
        </TableCell>
      </TableRow>
      {expanded &&
        section.details.map((detail: Detail, detailIndex: number) => {
          return (
            <TableRow
              className={cn('details-row ', {
                first: detailIndex === 0,
                expanded: expanded,
              })}
              key={detail.detailLabel}
            >
              <TableCell>
                <SummaryDetailLabel detail={detail} />
              </TableCell>
              <TableCell>
                <Currency value={detail.detailTotal} className="total-value" />
              </TableCell>
            </TableRow>
          )
        })}
    </>
  )
}

export default AccountPerformanceSummarySection
