//only extracting the fields from contentful, removing metadata and system from objects
export function extractFields(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      if (typeof item === 'object' && item !== null) {
        return extractFields(item)
      }
      return item
    })
  } else if (obj !== null && typeof obj === 'object') {
    if ('fields' in obj) {
      return extractFields(obj.fields)
    } else {
      const newObj: { [key: string]: any } = {}
      let hasFields = false
      for (const key of Object.keys(obj)) {
        const value = extractFields(obj[key])
        if (value !== undefined) {
          newObj[key] = value
          hasFields = true
        }
      }
      return hasFields ? newObj : undefined
    }
  } else {
    return obj
  }
}
