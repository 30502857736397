import { Box } from '@material-ui/core'
import { Notification } from '../notification/Notification'
import Checkbox from '@material-ui/core/Checkbox'
import { COMPANY_PHONE_NUMBER } from '../contact-us-modal/ContactUsModal'
import { useForm } from 'react-hook-form'
import { useMemo, useState } from 'react'
import { TermsModalProps } from './SwitchContentfulModal.termsModal.tel'
import { Button } from '../clickable/button/Button'
import { Account } from '../../redux/accounts/accounts.model'
import { IconArrowLeft } from '../../shared-components/iconography'
import { getProductPdsUrl } from '../../common/product-helper'
import { InvestmentOptionType } from '../compare-investment-options/InvestmentOption'
import { isGlidePath } from '../../utils/strategy'
import { UserData } from '../../redux/user/user.model'
import { getShortName } from '../../utils/investmentOption'
import './ReviewChange.scss'
import TermsModalTel from './SwitchContentfulModal.termsModal.tel'
import TermsModalApex from './SwitchContentfulModal.termsModal.apex'

const Row = ({ title, value }: { title: string; value: string }) => (
  <p className="color-midnight text-regular my-md">
    <strong className="fixed-width-col m-0">{title}</strong>
    <span>{value}</span>
  </p>
)

const renderAllocation = (
  shortName: string,
  allocationPercentage: number = 0
) => (
  <p key={shortName}>
    <strong className="font-fund fixed-width-col">{shortName}</strong>
    <span>{allocationPercentage}%</span>
  </p>
)

type ReviewSwitchProps = {
  account: Account
  user: UserData
  newInvestmentOption: InvestmentOptionType
  isApexProductAccount: boolean
  onCancel: () => void
  onSubmit: () => void
} & Omit<TermsModalProps, 'onClose'>

const ReviewChange = (props: ReviewSwitchProps) => {
  const {
    account,
    user,
    newInvestmentOption,
    isApexProductAccount,
    onCancel,
    onSubmit,
    ...termsModalProps
  } = props
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)
  const form = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })
  const {
    register,
    formState: { errors },
  } = form

  const renderInvestmentOptionsDetails = () => {
    if (isGlidePath(newInvestmentOption.strategy)) {
      return <Row title="GlidePath" value="100%" />
    }

    if (newInvestmentOption.isCustom) {
      return (
        <div className="funds-distribution">
          {!!newInvestmentOption.allocations?.length && (
            <div>
              <strong>Current Balance</strong>
              {newInvestmentOption.allocations.map((allocation) =>
                renderAllocation(
                  allocation.fund.shortName,
                  allocation.allocationPercentage
                )
              )}
            </div>
          )}

          {!!newInvestmentOption.futureAllocations?.length && (
            <div>
              <strong>Future Investments</strong>
              {newInvestmentOption.futureAllocations?.map((allocation) =>
                renderAllocation(
                  allocation.fund.shortName,
                  allocation.allocationPercentage
                )
              )}
            </div>
          )}
        </div>
      )
    }

    if (!!newInvestmentOption.strategy) {
      return newInvestmentOption.strategy.strategyFundAllocations.map(
        (allocation) =>
          renderAllocation(
            allocation.fund.shortName,
            allocation.allocationPercentage
          )
      )
    }

    return renderAllocation(newInvestmentOption.shortName, 100)
  }

  const switchToLabel = useMemo(() => {
    if (newInvestmentOption.shortName) {
      const shortName = getShortName(newInvestmentOption)
      // The lines below can probably be deleted and just leave return `Switch to ${shortName}`
      if (!isApexProductAccount && !/strategy/i.test(shortName)) {
        return `Switch to ${shortName} strategy`
      }
      return `Change to ${shortName}`
    }
    return 'Switch'
  }, [newInvestmentOption, isApexProductAccount])

  const renderApexOrTelSwitchProcessDetail = () => {
    if (isApexProductAccount) {
      return (
        <>
          <p className="text-small">
            Please allow a minimum of three business days for your switch to be
            processed.
          </p>
          <ol>
            <li>Once requested, this change cannot be stopped or altered.</li>
            <li>
              You can only make one fund change request at a time. If you'd like
              to make another fund change request, you'll need to wait until
              this one has been completed.
            </li>
          </ol>
        </>
      )
    }
    return (
      <>
        <p className="text-small">
          Please allow a minimum of two business days for your switch to be
          processed however before completing your switch, please note:
        </p>
        <ol>
          <li>
            If you have any transactions currently pending i.e. contributions or
            withdrawal, your switch will begin processing once this is complete.
          </li>
          <li>
            If you have submitted a tax rate update, your switch will be
            processed once your PIR has been updated.
          </li>
          <li>
            If none of the above applies, any requests received after 3pm may
            not be processed until the next working day. Your{' '}
            {props.account.productDisplayName} account is an investment and a
            change to how your funds are invested will take a minimum of 2
            working days to be completed. The unit price is calculated after
            both local and overseas markets have closed, so an investment switch
            processed on Monday, for example, can only be completed once all
            markets have closed - which would normally fall on the Wednesday.
          </li>
        </ol>
      </>
    )
  }

  const renderTermsModal = () =>
    isApexProductAccount ? (
      <TermsModalApex
        account={account}
        onClose={() => setShowTermsAndConditions(false)}
        {...termsModalProps}
      />
    ) : (
      <TermsModalTel
        account={account}
        onClose={() => setShowTermsAndConditions(false)}
        {...termsModalProps}
      />
    )

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="review-your-strategy-change">
          <div className="pds-disclaimer">
            <h6>
              {props.account.productDisplayName} Product Disclosure Statement
              (PDS)
            </h6>
            <p>
              This document gives you important information about this
              investment to help you decide where you want to invest. There is
              other useful information about this offer on{' '}
              <a
                href="https://disclose-register.companiesoffice.govt.nz/"
                target="_blank"
                rel="noreferrer"
              >
                disclose-register.companiesoffice.govt.nz
              </a>
              . Fisher Funds {isApexProductAccount ? 'Wealth' : 'Management'}{' '}
              Limited has prepared this document in accordance with the
              Financial Markets Conduct Act 2013. If you have any questions
              regarding your investment or want advice, please contact us.
            </p>

            <p>
              <a
                href={getProductPdsUrl(props.account)}
                target="_blank"
                rel="noreferrer"
              >
                Download the Product Disclosure Statement (PDS)
              </a>
            </p>
          </div>
          <h6>Review your details</h6>
          <div className="review-your-strategy-change client-details">
            <div>
              <p className="text-small my-0">First name</p>
              <p className="text-bold my-0">{user.firstName}</p>
            </div>
            <div>
              <p className="text-small color-dark my-0">Surname</p>
              <p className="text-bold my-0">{user.surname}</p>
            </div>
            <div>
              <p className="text-small my-0">
                {account.productDisplayName} account number
              </p>
              <p className="text-bold my-0">{account.accountID}</p>
            </div>
            <div>
              <p className="text-small my-0">Email address</p>
              <p className="text-bold my-0">{user.email}</p>
            </div>
            <div>
              <p className="text-small my-0">Phone number</p>
              <p className="text-bold my-0">
                {user.mobilePhoneNumber ||
                  user.homePhoneNumber ||
                  user.businessPhoneNumber}
              </p>
            </div>
          </div>
          <hr />
          <h6 className="my-sm">
            Changing your{' '}
            {isApexProductAccount ? 'fund(s)' : 'investment options'}
          </h6>
          <p>
            I want to change my current balance and
            {isApexProductAccount ? '' : '/or'} future investments to a
            different {isApexProductAccount ? 'fund' : 'strategy'}.
          </p>
          {renderInvestmentOptionsDetails()}
          <hr />

          <div className="color-text-dark text-small">
            {renderApexOrTelSwitchProcessDetail()}
            <p className="text-small">
              If you are unsure if you have transactions or updates pending,
              feel free to contact us on {COMPANY_PHONE_NUMBER}, or email{' '}
              <a
                className="color-dusk"
                href="mailto:mailto:enquiries@fisherfunds.co.nz?subject=Online changing your investment enquiry"
              >
                enquiries@fisherfunds.co.nz
              </a>{' '}
              or visit{' '}
              <a
                className="color-dusk"
                href="https://fisherfunds.co.nz"
                target="_blank"
                rel="noopener noreferrer"
              >
                our website
              </a>{' '}
              and speak to us via Live Chat.
            </p>

            <hr />

            {(errors.pds || errors.tnch) && (
              <Notification className="mb-sm" type="error">
                {errors.tnch && (
                  <p>
                    Please authorise{' '}
                    {isApexProductAccount ? 'fund' : 'strategy'} changes and
                    acknowledge the terms and conditions that apply.
                  </p>
                )}
                {errors.pds && (
                  <p>
                    Please confirm you've downloaded the Product Disclosure
                    Statement (PDS).
                  </p>
                )}
              </Notification>
            )}

            <Box ml={'-13px'} display="flex">
              <Box mt={'-8px'}>
                <Checkbox
                  id="pds"
                  name="pds"
                  {...{
                    ...register('pds', { required: true }),
                    ref: undefined,
                    inputRef: register('pds').ref,
                  }}
                />
              </Box>
              <Box>
                <label className="text-regular" htmlFor="pds">
                  I have downloaded a copy of the Product Disclosure Statement
                  (PDS).
                </label>
              </Box>
            </Box>

            <Box ml={'-13px'} display="flex">
              <Box mt={'-8px'}>
                <Checkbox
                  id="tnch"
                  name="tnch"
                  {...{
                    ...register('tnch', { required: true }),
                    ref: undefined,
                    inputRef: register('tnch').ref,
                  }}
                />
              </Box>
              <Box>
                <label className="text-regular" htmlFor="tnch">
                  I authorise you to make the changes requested to the
                  investment {isApexProductAccount ? 'fund' : 'strategy'} and
                  acknowledge the{' '}
                  <Button
                    className="terms-and-conditions"
                    onClick={() => setShowTermsAndConditions(true)}
                    variant="link"
                  >
                    terms and conditions
                  </Button>{' '}
                  that apply.
                </label>
              </Box>
            </Box>
          </div>
        </div>
        <div className="modal__footer-buttons mt-md">
          <Button
            className="mr-md"
            onClick={onCancel}
            variant="link"
            iconLeft={IconArrowLeft}
          >
            I want to make a change
          </Button>
          <Button type="submit">{switchToLabel}</Button>
        </div>
      </form>
      {showTermsAndConditions && renderTermsModal()}
    </>
  )
}

export default ReviewChange
