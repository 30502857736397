import axios from 'axios'
import { Product, productHasStrategies } from '../../common/product-helper'
import {
  IPQFormValues,
  IPQPostRequest,
  IPQPostResponse,
  IPQPutRequest,
  IPQPutResponse,
  IPQSection,
} from './ipq.model'
import { ProductShortCodes, ProductSlug } from '../../common/product-variables'
import { Account } from '../../redux/accounts/accounts.model'
import { UserData } from '../../redux/user/user.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type ProductCodeMap = {
  product: ProductSlug
  [investmentOption: string]: string
}

export type IPQQuestionnaire = {
  id: number
  name: string
  description?: string
  sections: IPQSection[]
}

const productCodeMap: ProductCodeMap[] = [
  {
    product: ProductSlug.FFKS,
    conservative: 'FCKS',
    balanced: 'FBSK',
    growth: 'FGKS',
  },
  {
    product: ProductSlug.FF_TWO,
    conservative: 'FTCO',
    balanced: 'FTBA',
    growth: 'FTGS',
  },
  {
    product: ProductSlug.FFMF,
    conservative: 'FCMF',
    balanced: 'FB21',
    growth: 'FGMF',
  },
  {
    product: ProductSlug.FF_LIFE,
    conservative: 'FLCS',
    balanced: 'FLBS',
    growth: 'FLGS',
  },
  {
    product: ProductSlug.FFKP,
    cash: '800300',
    defaultConservative: '800302',
    conservative: '800303',
    default: '800304',
    balanced: '800305',
    growth: '800306',
  },
]

export const getIPQResultCode = (
  productSlug: ProductSlug,
  result:
    | 'cash'
    | 'defaultConservative'
    | 'conservative'
    | 'balanced'
    | 'growth'
): string => {
  const productCode = productCodeMap.find((p) => p.product === productSlug)

  return productCode ? productCode[result] : ''
}

const IPQ_RESULTS_STRATEGIES_SHORT_NAMES = [
  'Conservative Strategy',
  'Balanced Strategy',
  'Growth Strategy',
]
const IPQ_RESULTS_FUNDS_SHORT_NAMES = [
  'Cash Fund',
  'Default Conservative Fund',
  'Conservative Fund',
  'Default Fund',
  'Balanced Fund',
  'Growth Fund',
]
export const getIPQInvestmentOptions = (product: Product) => {
  if (productHasStrategies(product.slug)) {
    return (product.strategies || []).filter((strategy) =>
      IPQ_RESULTS_STRATEGIES_SHORT_NAMES.includes(strategy.shortName)
    )
  }
  return (product.funds || []).filter((fund) =>
    IPQ_RESULTS_FUNDS_SHORT_NAMES.includes(fund.shortName)
  )
}

export const getIndex = (num: number, IPQQuestions: IPQSection[]): number => {
  let index = 0
  for (let i = 0; i < num; i += 1) {
    index += IPQQuestions[i]?.questions.length
  }
  return index
}

export const getIPQQuestionnaire = async (
  templateId: number,
  authToken: string
) => {
  try {
    const response = await axios.get(
      `${ffmAppServerUrl}/api/v1/public/advice/investor-profile-questionnaire/${templateId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    return {
      id: templateId,
      name: response.data.name,
      sections: response.data.sections,
      status: response.status,
    }
  } catch (e) {
    return e
  }
}

export const initIPQ = async (
  account: Account,
  user: UserData,
  authToken: string
): Promise<string> => {
  const request: IPQPostRequest = {
    firstName: user.firstName,
    lastName: user.surname,
    email: user.email,
    mobile: user.mobilePhoneNumber,
    productShortCode: ProductShortCodes[account.productExternalRef],
    source: 'FFO Web',
    status: 'Incomplete',
  }

  const response = await axios.post<IPQPostResponse>(
    `${ffmAppServerUrl}/api/v1/advice/investor-profile-questionnaire`,
    request,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  )

  return response.data.dalId
}

export const createIPQPutRequest = (
  status: 'Complete' | 'Incomplete',
  account: Account,
  outcome?: { type: 'Remain' | 'Switch'; option: string },
  values?: IPQFormValues,
  templateId?: number
): IPQPutRequest => {
  const { accountNumber, productExternalRef } = account
  const answers = values
    ? values.answers.map((answer) => ({
        question: {
          id: answer.questionId,
          text: answer.questionLabel,
        },
        option: {
          id: answer.optionId,
          text: answer.optionLabel,
        },
      }))
    : undefined
  return {
    answers,
    templateId,
    productShortCode: ProductShortCodes[productExternalRef],
    status,
    outcome: outcome ?? undefined,
    accountNumber,
  }
}

export const updateIPQQuestionnaire = async (
  dalId: string,
  authToken: string,
  status: 'Complete' | 'Incomplete',
  account: Account,
  outcome?: { type: 'Remain' | 'Switch'; option: string },
  values?: IPQFormValues,
  templateId?: number
): Promise<IPQPutResponse> => {
  const ipqRequest = createIPQPutRequest(
    status,
    account,
    outcome,
    values,
    templateId
  )

  try {
    const response = await axios.put<IPQPutResponse>(
      `${ffmAppServerUrl}/api/v1/advice/investor-profile-questionnaire/${dalId}`,
      ipqRequest,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )

    return response.data
  } catch (e) {
    console.error(`Omnimax submitIPQQuestionnaire failure: ${e}`)
    throw e
  }
}
