import React from 'react'
import { IconProps, Size, THEME_COLOURS } from './BrandIcon'

const IconPhone = ({
  size = 'md',
  theme = 'light',
  ...restProps
}: IconProps) => {
  const iconSize = Size[size as keyof typeof Size]
  const { fill } = THEME_COLOURS[theme]

  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 23 23"
      fill="none"
      {...restProps}
    >
      <path
        stroke={fill}
        d="M7.11719 11.65C8.01759 13.5094 9.52112 15.0091 11.3828 15.9047C11.5201 15.9697 11.672 15.9979 11.8235 15.9864C11.975 15.9748 12.1209 15.9241 12.2469 15.8391L14.9813 14.0125C15.1021 13.9306 15.2417 13.8806 15.387 13.8672C15.5324 13.8538 15.6788 13.8775 15.8125 13.9359L20.9313 16.1344C21.1062 16.2072 21.2524 16.3355 21.3472 16.4995C21.4421 16.6636 21.4804 16.8542 21.4563 17.0422C21.294 18.3085 20.6759 19.4722 19.7177 20.3158C18.7594 21.1593 17.5266 21.6247 16.25 21.625C12.3049 21.625 8.52139 20.0578 5.73179 17.2682C2.94218 14.4786 1.375 10.6951 1.375 6.75001C1.37529 5.47338 1.84073 4.24059 2.68425 3.28233C3.52776 2.32407 4.69154 1.70599 5.95781 1.54376C6.14576 1.51961 6.33643 1.55792 6.50047 1.65279C6.66451 1.74765 6.79281 1.89381 6.86563 2.06876L9.06406 7.19844C9.12123 7.33007 9.14515 7.47374 9.13371 7.6168C9.12226 7.75985 9.07581 7.89789 8.99844 8.01876L7.17188 10.7969C7.09056 10.9225 7.04283 11.0669 7.03325 11.2163C7.02368 11.3657 7.05258 11.515 7.11719 11.65Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPhone
