import React, { useEffect, useState } from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Entry, createClient } from 'contentful'
import { GovernmentContributionProps } from '../accounts-overview/GovernmentContribution'
import { LearnMoreModal } from './LearnMoreModal'
import { useGovernmentContribution } from '../accounts-overview/useGovernmentContribution'
import { Notification } from '../notification/Notification'
import { Button } from '../clickable/button/Button'

export const IneligibleView: React.FC<GovernmentContributionProps> = (
  props: GovernmentContributionProps
) => {
  const [officePhone, setOfficePhone] = useState()
  const [learnMoreOpen, setLearnMoreOpen] = useState(false)
  const { kiwiSaverYear, isLateCommence } = useGovernmentContribution(props)

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY,
      environment: process.env.REACT_APP_CONTENTFUL_ENV,
    })

    client
      .getEntries<any>({
        content_type: 'office',
        'fields.name[match]': 'Auckland',
        include: 1,
      })
      .then((res) => {
        const officeEntry: Entry<any> = res?.items?.[0]
        const phoneNumberEntry: Entry<any> =
          officeEntry?.fields.phoneNumbers?.[0]
        const phoneNumber = phoneNumberEntry?.fields.label
        if (phoneNumber) {
          setOfficePhone(phoneNumber)
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <p className="text-large details-label">
          {kiwiSaverYear} Government contribution
        </p>
      </div>

      <Notification
        className="mt-md"
        actions={
          <>
            <Button
              className="see-disclaimer"
              variant="link"
              color="primary"
              iconRight={ArrowForwardIcon}
              onClick={() => setLearnMoreOpen(true)}
            >
              Learn more
            </Button>
          </>
        }
      >
        {isLateCommence ? (
          <span>
            As you transferred to Fisher Funds during this KiwiSaver year, we do
            not have visibility of any KiwiSaver contributions you may have made
            before joining us. The easiest way to view your previous
            contributions from salary or wages is to log into MyIR from the{' '}
            <a href="https://www.ird.govt.nz/" target="_blank" rel="noreferrer">
              IRD website
            </a>
            . Please note that any voluntary contributions made directly to your
            previous provider will not be viewable in MyIR.
          </span>
        ) : (
          <span>
            To be eligible for the Government contribution you must be aged
            between 18 and 65, and be a resident in New Zealand for most of the
            KiwiSaver year (1 July to 30 June). Please contact us{' '}
            {!!officePhone && (
              <span>
                on <b>{officePhone}</b>
              </span>
            )}{' '}
            if you have any questions.
          </span>
        )}
      </Notification>
      <LearnMoreModal
        open={learnMoreOpen}
        onClose={() => setLearnMoreOpen(false)}
      />
    </>
  )
}
