import React from 'react'
import { connect } from 'react-redux'
import { WithRouterProps } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { AppState } from '../../redux/app-state'
import Redirecting from '../../components/redirecting/Redirecting'

type OnlyUnauthenticatedProps = {
  authToken: string
  children: any
  setTitle: (newTitle: string) => void
  setActiveMenu: (activeMenu: string) => any
} & WithRouterProps

const OnlyUnauthenticated = ({
  authToken,
  children,
  setTitle,
  setActiveMenu,
  router,
}: OnlyUnauthenticatedProps) => {
  const { user, isLoading } = useAuth0()

  if (isLoading) {
    return <Redirecting />
  }
  if (!!user || !!authToken) {
    router.replace('/accounts')
    return <Redirecting />
  }
  return React.cloneElement(children, { setTitle, setActiveMenu })
}

const mapStateToProps = (state: AppState) => ({
  authToken: state.authorisation.authorisationToken,
})

export default connect(mapStateToProps)(OnlyUnauthenticated)
