import { Button } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'

export const LogInButton = (props: any) => {
  const { loginWithRedirect } = useAuth0()

  const handleLogin = async () => {
    await loginWithRedirect()
  }

  return (
    <Button
      disableRipple
      variant="contained"
      color="primary"
      onClick={handleLogin}
    >
      Login
    </Button>
  )
}

export default LogInButton
