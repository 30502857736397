export enum actionTypes {
  AUTHORISATION_REQUEST = 'AUTHENTICATION_REQUEST',
  AUTHORISATION_REQUEST_SUCCESS = 'AUTHORISATION_REQUEST_SUCCESS',
  AUTHORISATION_REQUEST_FAILURE = 'AUTHORISATION_REQUEST_FAILURE',

  AUTHORISATION_FORM_RESET = 'AUTHORISATION_FORM_RESET',

  DEAUTHORISATION_REQUEST = 'DEAUTHORISATION_REQUEST',
  DEAUTHORISATION_REQUEST_SUCCESS = 'DEAUTHORISATION_REQUEST_SUCCESS',
  DEAUTHORISATION_REQUEST_FAILURE = 'DEAUTHORISATION_REQUEST_FAILURE',
  DEAUTHORISATION_RESET_LOGOUT_MESSAGE = 'DEAUTHORISATION_RESET_LOGOUT_MESSAGE',

  CHECK_AUTHORISATION_REQUEST = 'CHECK_AUTHORISATION_REQUEST',
  CHECK_AUTHORISATION_REQUEST_SUCCESS = 'CHECK_AUTHORISATION_REQUEST_SUCCESS',
}

export type Credentials = { username: string; password: string }
export type AuthorisationRequestActionType = {
  type: actionTypes.AUTHORISATION_REQUEST
  payload: Credentials
}
export type AuthorisationRequestSuccessActionType = {
  type: actionTypes.AUTHORISATION_REQUEST_SUCCESS
  payload: {
    authorisationToken: string
    lastAuthenticationTime: string
    filesToken: string
  }
}
export type AuthorisationRequestFailureActionType = {
  type: actionTypes.AUTHORISATION_REQUEST_FAILURE
  payload: string
}

export type AuthorisationFormResetActionType = {
  type: actionTypes.AUTHORISATION_FORM_RESET
}

export type DeauthorisationRequestActionType = {
  type: actionTypes.DEAUTHORISATION_REQUEST
  payload: string
}
export type DeauthorisationRequestSuccessActionType = {
  type: actionTypes.DEAUTHORISATION_REQUEST_SUCCESS
}
export type DeauthorisationRequestFailureActionType = {
  type: actionTypes.DEAUTHORISATION_REQUEST_FAILURE
}

export type DeauthorisationResetLogoutMessageActionType = {
  type: actionTypes.DEAUTHORISATION_RESET_LOGOUT_MESSAGE
}

export type CheckAuthorisationRequestActionType = {
  type: actionTypes.CHECK_AUTHORISATION_REQUEST
  payload: string
}
export type CheckAuthorisationRequestSuccessActionType = {
  type: actionTypes.CHECK_AUTHORISATION_REQUEST_SUCCESS
}

export const AuthorisationRequestAction = (
  payload: Credentials
): AuthorisationRequestActionType => ({
  type: actionTypes.AUTHORISATION_REQUEST,
  payload,
})

export const AuthorisationRequestSuccessAction = (payload: {
  authorisationToken: string
  lastAuthenticationTime: string
  filesToken: string
}): AuthorisationRequestSuccessActionType => ({
  type: actionTypes.AUTHORISATION_REQUEST_SUCCESS,
  payload,
})

export const AuthorisationRequestFailureAction = (
  message: string
): AuthorisationRequestFailureActionType => ({
  type: actionTypes.AUTHORISATION_REQUEST_FAILURE,
  payload: message,
})

export const AuthorisationFormResetAction = (): AuthorisationFormResetActionType => ({
  type: actionTypes.AUTHORISATION_FORM_RESET,
})

export const DeauthorisationRequestAction = (
  userId: string
): DeauthorisationRequestActionType => ({
  type: actionTypes.DEAUTHORISATION_REQUEST,
  payload: userId,
})

export const DeauthorisationRequestSuccessAction = (): DeauthorisationRequestSuccessActionType => ({
  type: actionTypes.DEAUTHORISATION_REQUEST_SUCCESS,
})

export const DeauthorisationRequestFailureAction = (): DeauthorisationRequestFailureActionType => ({
  type: actionTypes.DEAUTHORISATION_REQUEST_FAILURE,
})

export const DeauthorisationResetLogoutMessageAction = (): DeauthorisationResetLogoutMessageActionType => ({
  type: actionTypes.DEAUTHORISATION_RESET_LOGOUT_MESSAGE,
})

export const CheckAuthorisationRequestAction = (
  userId: string
): CheckAuthorisationRequestActionType => ({
  type: actionTypes.CHECK_AUTHORISATION_REQUEST,
  payload: userId,
})

export const CheckAuthorisationRequestSuccessAction = (): CheckAuthorisationRequestSuccessActionType => ({
  type: actionTypes.CHECK_AUTHORISATION_REQUEST_SUCCESS,
})

export type AuthorisationActions =
  | AuthorisationRequestActionType
  | AuthorisationRequestSuccessActionType
  | AuthorisationRequestFailureActionType
  | AuthorisationFormResetActionType
  | DeauthorisationRequestActionType
  | DeauthorisationRequestSuccessActionType
  | DeauthorisationRequestFailureActionType
  | DeauthorisationResetLogoutMessageActionType
  | CheckAuthorisationRequestActionType
  | CheckAuthorisationRequestSuccessActionType
