import { useEffect, useState } from 'react'
import { getIPQResultCode } from './utils'
import {
  IInvestmentOption,
  getInvestmentOptionByCode,
} from '../../utils/investmentOption'
import { getIPQTargetClientSummary } from '../../utils/fund'
import { Notification } from '../notification/Notification'
import { Product } from '../../common/product-helper'
import { IPQPutResponse } from './ipq.model'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import './IPQResult.scss'
import CompareInvestmentOptions from '../compare-investment-options/CompareInvestmentOptions'
import { Button } from '../clickable/button/Button'
import {
  IconChatBubblesOutline,
  IconDownload,
  IconPhoneAlt,
  IconRefresh,
} from '../../shared-components/iconography'
import Link from '../clickable/link/Link'

export type IPQResultsProps = {
  product: Product
  result: IPQPutResponse
  onCancel: (values: IInvestmentOption) => void
  onSelect: (values: IInvestmentOption) => void
  onOptionsChange: (needsLargeModal: boolean) => void
  pdsLink: string
  onReset: () => void
  currentInvestment: IInvestmentOption
}

const IPQResult = ({
  product,
  result,
  onCancel,
  onSelect,
  onReset,
  pdsLink,
  onOptionsChange,
  currentInvestment,
}: IPQResultsProps) => {
  const [recommendedOption, setRecommendedOption] = useState<
    IInvestmentOption
  >()
  const [displayedInvestmentOption, setDisplayedInvestmentOption] = useState<
    IInvestmentOption
  >()

  useEffect(() => {
    if (result) {
      const ipqResultCode = getIPQResultCode(product.slug, result?.result)
      const recommendedOption = getInvestmentOptionByCode(
        product,
        ipqResultCode
      )
      setRecommendedOption(recommendedOption)
      setDisplayedInvestmentOption(recommendedOption)
    }
  }, [result, product])

  const isViewingRecommendedResult =
    recommendedOption?.telCode === displayedInvestmentOption?.telCode

  const isLoading = !displayedInvestmentOption

  if (isLoading) {
    return <LoadingSpinner className="mb-md" center={true} />
  }

  return (
    <div className="ipq-results-modal">
      <div className="results">
        {!!currentInvestment && (
          <Notification className="mb-md">
            <p>
              You are currently invested in the{' '}
              <strong>{currentInvestment.shortName}</strong>
            </p>
          </Notification>
        )}
        {!isViewingRecommendedResult ? (
          <Notification className="mb-md">
            You are currently viewing the {displayedInvestmentOption?.shortName}
            . Please note that your answers indicate the{' '}
            {recommendedOption?.shortName} is appropriate.
          </Notification>
        ) : (
          <>
            <p className="results-description mb-lg">
              It looks like the {recommendedOption?.shortName} could be right
              for you.
            </p>
            {!!recommendedOption.telCode && (
              <p className="results-description mb-lg">
                {getIPQTargetClientSummary(recommendedOption.telCode)}
              </p>
            )}
          </>
        )}
        <div className="results-wrapper">
          {' '}
          <CompareInvestmentOptions
            product={product}
            currentInvestmentOption={currentInvestment}
            newInvestmentOption={displayedInvestmentOption}
            onSelect={onSelect}
            onCancel={onCancel}
            setDisplayedInvestmentOption={setDisplayedInvestmentOption}
            onOptionsChange={onOptionsChange}
          />
        </div>
        <div className="ipq__links mb-sm">
          <Button variant="link" iconLeft={IconRefresh} onClick={onReset}>
            Review questions
          </Button>
          <Link
            variant="link"
            to={`${process.env.REACT_APP_WEBSITE_URL}/support#contact`}
            target="_blank"
            rel="noreferrer noopener"
            iconLeft={IconChatBubblesOutline}
          >
            Chat with an adviser
          </Link>
          <Link
            iconLeft={IconPhoneAlt}
            variant="link"
            target="_blank"
            rel="noreferrer noopener"
            to={`${process.env.REACT_APP_WEBSITE_URL}/request-a-call`}
          >
            Request a call
          </Link>
          <Link
            iconLeft={IconDownload}
            variant="link"
            target="_blank"
            rel="noreferrer noopener"
            to={pdsLink}
          >
            Download the PDS
          </Link>
        </div>
      </div>
    </div>
  )
}

export default IPQResult
