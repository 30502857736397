import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'

import * as actions from './account-transactions.actions'
import { Account } from '../accounts/accounts.model'
import { AccountTransactionsState } from './account-transactions.model'

import getTransactionApi from '../../api/accounts/transactions'
import deserializeTransactions from '../../deserializers/account/transactions'

type LightStore = { getState: Function; dispatch: Function }

export const accountTransactionsEpic: Epic<
  actions.AccountTransactionsActions,
  AccountTransactionsState
> = (
  action$: ActionsObservable<actions.AccountTransactionsRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNT_TRANSACTIONS_REQUEST)
    .switchMap((action) => {
      // getting filters from store state
      const filters = store.getState().accountTransactions.filters

      let accounts = []

      // Getting all accounts product name and name
      const stateStore = store.getState()
      const storeAccounts = stateStore.accounts
      if (storeAccounts.accounts && storeAccounts.accounts.length) {
        accounts = storeAccounts.accounts.map((account: any) => {
          return {
            number: account.accountNumber,
            name: account.productName,
          }
        })
      }

      // form data
      let postData = {
        filters: {
          dateType: filters.dateType,
          startDate: filters.startDate.format('YYYY-MM-DD'),
          endDate: filters.endDate.format('YYYY-MM-DD'),
        },
        offset: action.payload.offset,
        shouldReturnAll: action.payload.shouldReturnAll,
        accounts,
      }

      const { userId, accountId } = action.payload
      const api = getTransactionApi(
        store.getState().authorisation.authorisationToken
      )

      return api
        .getTransactions({
          userId,
          accountNumber: accountId,
          data: postData,
        })
        .then((response: any) => {
          const { pageInfo } = response.data.accountTransactions.transactions
          const transactions = deserializeTransactions(response.data)

          const accounts = store.getState().accounts.accounts
          const account = accounts.find(
            (acc: Account) => acc.accountID === action.payload.accountId
          )

          const success = {
            transactions,
            productName: account.productName,
            accountNumber: account.accountNumber,
            productTotal: account.accountTotal,
            hasMoreTransactions: pageInfo.hasNextPage,
          } as AccountTransactionsState

          // Returns all transactions for exports
          if (action.payload.isCSV) {
            return actions.AccountAllTransactionsRequestSuccessAction(success)
          }

          return actions.AccountTransactionsRequestSuccessAction(success)
        })
        .catch((_err) => {
          return actions.AccountTransactionsRequestFailureAction(
            'Account transactions request failed'
          )
        })
    })

export const updateTransactionFiltersEpic: Epic<
  actions.AccountTransactionsActions,
  actions.TransactionsPayload
> = (
  action$: ActionsObservable<
    actions.AccountTransactionsUpdateFiltersActionType
  >,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNT_TRANSACTIONS_UPDATE_FILTERS)
    .mergeMap((action) => {
      return Observable.concat(
        Observable.of(
          actions.AccountTransactionsRequestAction({
            userId: action.payload.userId,
            accountId: action.payload.accountId,
            offset: 0,
            shouldReturnAll: action.payload.shouldReturnAll,
            filters: action.payload.filters,
            isCSV: false,
          })
        )
      )
    })
    .catch((err) => {
      return Observable.of(
        actions.AccountTransactionsRequestFailureAction('Failed to update.')
      )
    })
