import { AccountDetail } from './account-details.model'
export enum actionTypes {
  ACCOUNT_DETAILS_REQUEST = 'ACCOUNT_DETAILS_REQUEST',
  ACCOUNT_DETAILS_REQUEST_SUCCESS = 'ACCOUNT_DETAILS_REQUEST_SUCCESS',
  ACCOUNT_DETAILS_REQUEST_FAILURE = 'ACCOUNT_DETAILS_REQUEST_FAILURE',
}

export type AccountDetailsRequestActionType = {
  type: actionTypes.ACCOUNT_DETAILS_REQUEST
  payload: { userId: string; accountId: string }
}
export type AccountDetailsRequestSuccessActionType = {
  type: actionTypes.ACCOUNT_DETAILS_REQUEST_SUCCESS
  payload: AccountDetail
}
export type AccountDetailsRequestFailureActionType = {
  type: actionTypes.ACCOUNT_DETAILS_REQUEST_FAILURE
  payload: string
}

export const AccountDetailsRequestAction = (
  accountId: string,
  userId: string
): AccountDetailsRequestActionType => ({
  type: actionTypes.ACCOUNT_DETAILS_REQUEST,
  payload: {
    userId: userId,
    accountId: accountId,
  },
})

export const AccountDetailsRequestSuccessAction = (
  accountDetails: AccountDetail
): AccountDetailsRequestSuccessActionType => ({
  type: actionTypes.ACCOUNT_DETAILS_REQUEST_SUCCESS,
  payload: accountDetails,
})

export const AccountDetailsRequestFailureAction = (
  message: string
): AccountDetailsRequestFailureActionType => ({
  type: actionTypes.ACCOUNT_DETAILS_REQUEST_FAILURE,
  payload: message,
})

export type AccountDetailsActions =
  | AccountDetailsRequestActionType
  | AccountDetailsRequestSuccessActionType
  | AccountDetailsRequestFailureActionType
