import * as moment from 'moment'

// Used by reducers to set the 'expiresAt' time via moment
// eg.
// expiresAt: expiresAt: moment().add(10, 'minutes').format()
type expiresAtConfig = {
  value: number
  unit: moment.unitOfTime.DurationConstructor
}
export const userExpiresAtConfig: expiresAtConfig = {
  value: 30,
  unit: 'minutes',
}
export const accountsExpiresAtConfig: expiresAtConfig = {
  value: 30,
  unit: 'minutes',
}
