import getApiCore from '../core'

const accountPath = (userId: string, accountNumber: string) =>
  `users/${userId}/accounts/${accountNumber}`

const firstHomeLetterPath = (userId: string, accountNumber: string) =>
  `${accountPath(userId, accountNumber)}/first-home-pdf`

const firstHomeEnquiryPath = (userId: string, accountNumber: string) =>
  `${accountPath(userId, accountNumber)}/first-home-enquiry`

const investmentSwitchPath = (userId: string, accountNumber: string) =>
  `${accountPath(userId, accountNumber)}/investment-switch`

const renameAccountPath = (userId: string, accountNumber: string) =>
  `${accountPath(userId, accountNumber)}/rename`

type AccountParams = {
  userId: string
  accountNumber: string
}

export default function getAccountsApi(authToken: string) {
  const apiCore = getApiCore(authToken)

  return {
    getFirstHomeLetter(params: AccountParams) {
      return apiCore.get(
        firstHomeLetterPath(params.userId, params.accountNumber),
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        }
      )
    },
    sendEnquiryEmail(params: AccountParams, data: any) {
      return apiCore.post(
        firstHomeEnquiryPath(params.userId, params.accountNumber),
        { data }
      )
    },
    setInvestmentStrategy(params: AccountParams, data: any) {
      return apiCore.post(
        investmentSwitchPath(params.userId, params.accountNumber),
        data
      )
    },
    renameAccount(params: AccountParams, data: any) {
      return apiCore.put(
        renameAccountPath(params.userId, params.accountNumber),
        data
      )
    },
  }
}
