const INITIAL_STEP = 'INITIAL_STEP'
const PREVIOUS_WITHDRAWAL = 'PREVIOUS_WITHDRAWAL'
const PREVIOUS_PROPERTY = 'PREVIOUS_PROPERTY'
const SECOND_CHANCE = 'SECOND_CHANCE'
const NOT_MINIMUM_MEMBERSHIP_LENGTH = 'NOT_MINIMUM_MEMBERSHIP_LENGTH'
const PRIMARY_RESIDENCY = 'PRIMARY_RESIDENCY'
const ESTATE_IN_LAND = 'ESTATE_IN_LAND'
const UK_PENSION = 'UK_PENSION'
const GENERATE_LETTER = 'GENERATE_LETTER'
const DOWNLOAD_LETTER = 'DOWNLOAD_LETTER'
const NON_CONCLUSIVE = 'NON_CONCLUSIVE'
const CONTACT = 'CONTACT'
const CONTACT_FORM = 'CONTACT_FORM'
const CONTACT_REQUEST_SENT = 'CONTACT_REQUEST_SENT'
const EXIT = 'EXIT'

const steps: Steps = {
  INITIAL_STEP: {
    question:
      'Before you can download your letter, we’ll first check your eligibility for a first home buyer withdrawal.<br /><br />You can learn more about making a first home buyer withdrawal <a title="Buying a home with KiwiSaver" target="_blank" href="https://fisherfunds.co.nz/investment/kiwisaver/ways-to-use-kiwisaver/buying-a-home-with-kiwisaver">here</a>.',
    yes: {
      nextStep: PREVIOUS_WITHDRAWAL,
      label: 'Continue',
    },
    no: { nextStep: EXIT, label: 'Cancel' },
  },
  PREVIOUS_WITHDRAWAL: {
    question:
      'Have you made a withdrawal from this or a previous KiwiSaver account for the purchase of a property before?',
    yes: {
      nextStep: CONTACT,
      label: 'Yes',
      message: `According to the KiwiSaver Act, each KiwiSaver member is only entitled to make one
      first home withdrawal from their KiwiSaver. If you have made a first home
      withdrawal previously, unfortunately (even if you have received approval from
      Kāinga Ora) you would not be able to apply once more. Please note, Kāinga Ora can
      only assess that your financial position is the same as a first home buyer even if
      you have owned property previously - they do not have visibility over whether you
      have made this type of withdrawal before.`,
    },
    no: { nextStep: PREVIOUS_PROPERTY, label: 'No' },
  },
  PREVIOUS_PROPERTY: {
    question: 'Have you owned a property before?',
    yes: {
      nextStep: SECOND_CHANCE,
      label: 'Yes',
    },
    no: { nextStep: PRIMARY_RESIDENCY, label: 'No' },
  },
  SECOND_CHANCE: {
    question:
      "Do you have confirmation from Kāinga Ora that you are eligible to apply as a 'second chance home buyer'?",
    yes: {
      nextStep: PRIMARY_RESIDENCY,
      label: 'Yes',
    },
    no: {
      nextStep: CONTACT,
      label: 'No',
      message: `If you have owned property previously you can only withdraw your KiwiSaver funds if
      Kāinga Ora consider your current financial position the same as a first home buyer
      (i.e. you have little or no equity remaining from your first home). If so, you may
      be able to apply as a 'second chance first home buyer'. To look into this option, please 
      <a class="MuiTypography-colorPrimary" target="_blank" title="KiwiSaver First Home Withdrawal" href="https://kaingaora.govt.nz/home-ownership/kiwisaver-first-home-withdrawal/">click here</a> 
      to visit Kāinga Ora's website for more information.`,
    },
  },
  NOT_MINIMUM_MEMBERSHIP_LENGTH: {
    question: `It looks like you have not been a KiwiSaver member for at least 3 years. This is
    necessary to be eligible to apply for a first home withdrawal of your KiwiSaver account. You
    can find out more about this
    <a class="MuiTypography-colorPrimary" target="_blank" title="Buying a Home with KiwiSaver" href="https://fisherfunds.co.nz/investment/kiwisaver/ways-to-use-kiwisaver/buying-a-home-with-kiwisaver">here</a>.
    `,
    yes: {
      nextStep: CONTACT,
      label: 'Continue',
    },
  },
  PRIMARY_RESIDENCY: {
    question: 'Do you intend to live in the property?',
    yes: { nextStep: ESTATE_IN_LAND, label: 'Yes' },
    no: {
      nextStep: CONTACT,
      label: 'No',
      message: `To be eligible to apply for a first home withdrawal of your KiwiSaver account,
      you must intend to live in the property for at least 6-12 months.`,
    },
  },
  ESTATE_IN_LAND: {
    question: `Are you purchasing an estate in land?<br /><br />An estate in land covers bare land, a "house and land package" and property with a dwelling already on it.`,
    yes: { nextStep: UK_PENSION, label: 'Yes' },
    no: {
      nextStep: CONTACT,
      label: 'No',
      message: `Unfortunately, if you are not purchasing estate in land, you would not be eligible
      to apply for a first home withdrawal of your KiwiSaver account.`,
    },
  },
  UK_PENSION: {
    question:
      'Have you previously transferred your UK pension to your KiwiSaver account?',
    yes: { nextStep: NON_CONCLUSIVE, label: 'Yes' },
    no: { nextStep: GENERATE_LETTER, label: 'No' },
  },
  GENERATE_LETTER: {
    question:
      'Would you like to download your first home letter to this device?',
    yes: { nextStep: DOWNLOAD_LETTER, label: 'Yes' },
    no: {
      nextStep: EXIT,
      label: 'No',
    },
  },
  DOWNLOAD_LETTER: {
    question:
      'Your first home letter download will start shortly. Please check the downloads destination on this device to retrieve your letter.<br /><br />You can learn more about making a first home buyer withdrawal <a class="MuiTypography-colorPrimary" target="_blank" title="Buying a Home with KiwiSaver" href="https://fisherfunds.co.nz/investment/kiwisaver/ways-to-use-kiwisaver/buying-a-home-with-kiwisaver">here</a>.',
    yes: { nextStep: EXIT, label: 'Done' },
  },
  NON_CONCLUSIVE: {
    question:
      'It seems we may need a bit more information from you. Would you like one of our team to get in contact with you?',
    yes: { nextStep: CONTACT_FORM, label: 'Yes' },
    no: {
      nextStep: EXIT,
      label: 'No, thanks',
    },
  },
  CONTACT: {
    question:
      'Would you like us to get in contact with you to discuss your application?',
    yes: { nextStep: CONTACT_FORM, label: 'Yes' },
    no: {
      nextStep: EXIT,
      label: 'No',
    },
  },
  CONTACT_FORM: {
    question:
      'Great! Please enter the best phone number for us to contact you on.',
    yes: { nextStep: EXIT, label: 'Yes' },
    no: {
      nextStep: EXIT,
      label: 'Cancel',
    },
  },
  CONTACT_REQUEST_SENT: {
    question:
      'Thank you for your enquiry. We will be in touch as soon as possible.',
    yes: { nextStep: EXIT, label: 'Finished' },
  },
}

type Steps = {
  [step in StepName]?: StepOptions
}

export type StepOptions = {
  question: string
  yes: Answer
  no?: Answer
}

type Answer = {
  nextStep: StepName
  label: string
  message?: string
}

export type StepName =
  | typeof INITIAL_STEP
  | typeof PREVIOUS_WITHDRAWAL
  | typeof PREVIOUS_PROPERTY
  | typeof SECOND_CHANCE
  | typeof NOT_MINIMUM_MEMBERSHIP_LENGTH
  | typeof PRIMARY_RESIDENCY
  | typeof ESTATE_IN_LAND
  | typeof UK_PENSION
  | typeof GENERATE_LETTER
  | typeof DOWNLOAD_LETTER
  | typeof NON_CONCLUSIVE
  | typeof CONTACT_FORM
  | typeof CONTACT
  | typeof CONTACT_REQUEST_SENT
  | typeof EXIT

export default steps
