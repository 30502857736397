import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import './Tooltip.scss'

export type TooltipProps = {
  label: React.ReactNode
  direction: Placement
  className?: string
  containerClassName?: string
  referenceElement: HTMLElement | null
}

export const Tooltip = ({
  label,
  referenceElement,
  direction,
  className = '',
}: TooltipProps) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: direction,
      strategy: 'absolute',
      modifiers: [
        { name: 'offset', options: { offset: [0, 12] } },
        { name: 'arrow', options: { element: arrowElement, padding: 4 } },
      ],
    }
  )
  useEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      if (update) {
        update()
      }
    })
    mutationObserver.observe(document, { subtree: true, childList: true })
    return () => mutationObserver.disconnect()
  }, [update])
  const elements = (
    <div
      className="input-tooltip__wrapper"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className={`input-tooltip__content ${className}`}>
        {label}
        <div
          ref={setArrowElement}
          className="arrow-container"
          style={styles.arrow}
        >
          <div className="arrow" />
        </div>
      </div>
    </div>
  )
  return elements
}
