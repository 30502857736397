export enum actionTypes {
  ONBOARDING_CONFIRM_DOB = 'ONBOARDING_CONFIRM_DOB',
  ONBOARDING_CONFIRM_DOB_SUCCESS = 'ONBOARDING_CONFIRM_DOB_SUCCESS',
  ONBOARDING_CONFIRM_DOB_FAILURE = 'ONBOARDING_CONFIRM_DOB_FAILURE',
  ONBOARDING_CHECK_USERNAME = 'ONBOARDING_CHECK_USERNAME',
  ONBOARDING_CHECK_USERNAME_SUCCESS = 'ONBOARDING_CHECK_USERNAME_SUCCESS',
  ONBOARDING_CHECK_USERNAME_FAILURE = 'ONBOARDING_CHECK_USERNAME_FAILURE',
  ONBOARDING_CREATE_CREDENTIALS = 'ONBOARDING_CREATE_CREDENTIALS',
  ONBOARDING_CREATE_CREDENTIALS_SUCCESS = 'ONBOARDING_CREATE_CREDENTIALS_SUCCESS',
  ONBOARDING_CREATE_CREDENTIALS_FAILURE = 'ONBOARDING_CREATE_CREDENTIALS_FAILURE',
  ONBOARDING_REQUEST = 'ONBOARDING_REQUEST',
  ONBOARDING_REQUEST_SUCCESS = 'ONBOARDING_REQUEST_SUCCESS',
  ONBOARDING_REQUEST_FAILURE = 'ONBOARDING_REQUEST_FAILURE',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  ONBOARDING_COMPLETE_SUCCESS = 'ONBOARDING_COMPLETE_SUCCESS',
  ONBOARDING_COMPLETE_FAILURE = 'ONBOARDING_COMPLETE_FAILURE',
  ONBOARDING_SEARCH = 'ONBOARDING_SEARCH',
  ONBOARDING_SEARCH_SUCCESS = 'ONBOARDING_SEARCH_SUCCESS',
  ONBOARDING_SEARCH_FAILURE = 'ONBOARDING_SEARCH_FAILURE',
  ONBOARDING_SET_PREFER_PIN = 'ONBOARDING_SET_PREFER_PIN',
  ONBOARDING_CLEAR_CREDENTIALS = 'ONBOARDING_CLEAR_CREDENTIALS',
  ONBOARDING_CLEAR_STATE = 'ONBOARDING_CLEAR_STATE',
  ONBOARDING_CLEAR_ERROR_MESSAGE = 'ONBOARDING_CLEAR_ERROR_MESSAGE',
}

export type OnboardingUser = {
  dateOfBirth: string
  username: string
  password: string
  hash: string
}
export type UserCredentials = {
  dateOfBirth: string
  username: string
  password: string
}
export type ConfirmDOBCredentials = { dateOfBirth: string; hash: string }
export type CheckUsernameCredentials = { username: string; hash: string }
export type OnboardingConfirmDobActionType = {
  type: actionTypes.ONBOARDING_CONFIRM_DOB
  payload: ConfirmDOBCredentials
}
export type OnboardingConfirmDobSuccessActionType = {
  type: actionTypes.ONBOARDING_CONFIRM_DOB_SUCCESS
  dateOfBirth: string
}
export type OnboardingConfirmDobFailureActionType = {
  type: actionTypes.ONBOARDING_CONFIRM_DOB_FAILURE
  payload: string
}
export type OnboardingCheckUsernameActionType = {
  type: actionTypes.ONBOARDING_CHECK_USERNAME
  payload: CheckUsernameCredentials
}
export type OnboardingCheckUsernameSuccessActionType = {
  type: actionTypes.ONBOARDING_CHECK_USERNAME_SUCCESS
  username: string
}
export type OnboardingCheckUsernameFailureActionType = {
  type: actionTypes.ONBOARDING_CHECK_USERNAME_FAILURE
  payload: string
}
export type OnboardingCreateCredentialsActionType = {
  type: actionTypes.ONBOARDING_CREATE_CREDENTIALS
  payload: OnboardingUser
}
export type OnboardingCreateCredentialsSuccessActionType = {
  type: actionTypes.ONBOARDING_CREATE_CREDENTIALS_SUCCESS
  payload: UserCredentials
}
export type OnboardingCreateCredentialsFailureActionType = {
  type: actionTypes.ONBOARDING_CREATE_CREDENTIALS_FAILURE
  payload: string
}
export type OnboardingRequestActionType = {
  type: actionTypes.ONBOARDING_REQUEST
  payload: string
}
export type OnboardingRequestSuccessActionType = {
  type: actionTypes.ONBOARDING_REQUEST_SUCCESS
  payload: string
}
export type OnboardingRequestFailureActionType = {
  type: actionTypes.ONBOARDING_REQUEST_FAILURE
  payload: string
}
export type OnboardingSearchActionType = {
  type: actionTypes.ONBOARDING_SEARCH
  surname: string
  firstName: string
  email: string
  dob: string
}
export type OnboardingSearchSuccessActionType = {
  type: actionTypes.ONBOARDING_SEARCH_SUCCESS
}
export type OnboardingSearchFailureActionType = {
  type: actionTypes.ONBOARDING_SEARCH_FAILURE
  payload: {
    errorMessage?: string
    errorStatus?: number
  }
}
export type OnboardingClearCredentialsActionType = {
  type: actionTypes.ONBOARDING_CLEAR_CREDENTIALS
}
export type OnboardingClearStateActionType = {
  type: actionTypes.ONBOARDING_CLEAR_STATE
}
export type OnboardingClearErrorMessageActionType = {
  type: actionTypes.ONBOARDING_CLEAR_ERROR_MESSAGE
}

export const OnboardingConfirmDobAction = (
  payload: ConfirmDOBCredentials
): OnboardingConfirmDobActionType => ({
  type: actionTypes.ONBOARDING_CONFIRM_DOB,
  payload,
})

export const OnboardingConfirmDobSuccessAction = (
  dateOfBirth: string
): OnboardingConfirmDobSuccessActionType => ({
  type: actionTypes.ONBOARDING_CONFIRM_DOB_SUCCESS,
  dateOfBirth,
})

export const OnboardingConfirmDobFailureAction = (
  message: string
): OnboardingConfirmDobFailureActionType => ({
  type: actionTypes.ONBOARDING_CONFIRM_DOB_FAILURE,
  payload: message,
})

export const OnboardingCheckUsernameAction = (
  payload: CheckUsernameCredentials
): OnboardingCheckUsernameActionType => ({
  type: actionTypes.ONBOARDING_CHECK_USERNAME,
  payload,
})

export const OnboardingCheckUsernameSuccessAction = (
  username: string
): OnboardingCheckUsernameSuccessActionType => ({
  type: actionTypes.ONBOARDING_CHECK_USERNAME_SUCCESS,
  username,
})

export const OnboardingCheckUsernameFailureAction = (
  message: string
): OnboardingCheckUsernameFailureActionType => ({
  type: actionTypes.ONBOARDING_CHECK_USERNAME_FAILURE,
  payload: message,
})

export const OnboardingCreateCredentials = (
  userCredentials: OnboardingUser
): OnboardingCreateCredentialsActionType => ({
  type: actionTypes.ONBOARDING_CREATE_CREDENTIALS,
  payload: userCredentials,
})

export const OnboardingCreateCredentialsSuccess = (
  credentials: UserCredentials
): OnboardingCreateCredentialsSuccessActionType => ({
  type: actionTypes.ONBOARDING_CREATE_CREDENTIALS_SUCCESS,
  payload: credentials,
})

export const OnboardingCreateCredentialsFailure = (
  message: string
): OnboardingCreateCredentialsFailureActionType => ({
  type: actionTypes.ONBOARDING_CREATE_CREDENTIALS_FAILURE,
  payload: message,
})

export const OnboardingClearErrorMessage = (): OnboardingClearErrorMessageActionType => ({
  type: actionTypes.ONBOARDING_CLEAR_ERROR_MESSAGE,
})

export const OnboardingRequest = (
  hash: string
): OnboardingRequestActionType => ({
  type: actionTypes.ONBOARDING_REQUEST,
  payload: hash,
})

export const OnboardingRequestSuccess = (
  hash: string
): OnboardingRequestSuccessActionType => ({
  type: actionTypes.ONBOARDING_REQUEST_SUCCESS,
  payload: hash,
})

export const OnboardingRequestFailure = (
  message: string
): OnboardingRequestFailureActionType => ({
  type: actionTypes.ONBOARDING_REQUEST_FAILURE,
  payload: message,
})

export const OnboardingSearch = (
  firstName: string,
  surname: string,
  email: string,
  dob: string
): OnboardingSearchActionType => ({
  type: actionTypes.ONBOARDING_SEARCH,
  surname,
  firstName,
  email,
  dob,
})

export const OnboardingSearchSuccess = (): OnboardingSearchSuccessActionType => ({
  type: actionTypes.ONBOARDING_SEARCH_SUCCESS,
})

export const OnboardingSearchFailure = ({
  errorStatus,
  errorMessage,
}: {
  errorStatus?: number
  errorMessage?: string
}): OnboardingSearchFailureActionType => ({
  type: actionTypes.ONBOARDING_SEARCH_FAILURE,
  payload: {
    errorMessage,
    errorStatus,
  },
})

export const OnboardingClearCredentials = (): OnboardingClearCredentialsActionType => ({
  type: actionTypes.ONBOARDING_CLEAR_CREDENTIALS,
})

export const OnboardingClearState = (): OnboardingClearStateActionType => ({
  type: actionTypes.ONBOARDING_CLEAR_STATE,
})

export type OnboardingActions =
  | OnboardingConfirmDobActionType
  | OnboardingConfirmDobSuccessActionType
  | OnboardingConfirmDobFailureActionType
  | OnboardingCheckUsernameActionType
  | OnboardingCheckUsernameSuccessActionType
  | OnboardingCheckUsernameFailureActionType
  | OnboardingCreateCredentialsActionType
  | OnboardingCreateCredentialsSuccessActionType
  | OnboardingCreateCredentialsFailureActionType
  | OnboardingRequestActionType
  | OnboardingRequestSuccessActionType
  | OnboardingRequestFailureActionType
  | OnboardingSearchActionType
  | OnboardingSearchSuccessActionType
  | OnboardingSearchFailureActionType
  | OnboardingClearCredentialsActionType
  | OnboardingClearStateActionType
