import { SwitchNotification } from './switch-notifications.model'

export enum actionTypes {
  SWITCH_NOTIFICATIONS_REQUEST = 'SWITCH_NOTIFICATIONS_REQUEST',
  SWITCH_NOTIFICATIONS_REQUEST_SUCCESS = 'SWITCH_NOTIFICATIONS_REQUEST_SUCCESS',
  SWITCH_NOTIFICATIONS_REQUEST_FAILURE = 'SWITCH_NOTIFICATIONS_REQUEST_FAILURE',
}

export type SwitchNotificationsRequestActionType = {
  type: actionTypes.SWITCH_NOTIFICATIONS_REQUEST
}
export type SwitchNotificationsRequestSuccessActionType = {
  type: actionTypes.SWITCH_NOTIFICATIONS_REQUEST_SUCCESS
  payload: SwitchNotification[]
}
export type SwitchNotificationsRequestFailureActionType = {
  type: actionTypes.SWITCH_NOTIFICATIONS_REQUEST_FAILURE
  payload: string
}

export const SwitchNotificationsRequestAction = (): SwitchNotificationsRequestActionType => ({
  type: actionTypes.SWITCH_NOTIFICATIONS_REQUEST,
})

export const SwitchNotificationsRequestSuccessAction = (
  notifications: SwitchNotification[]
): SwitchNotificationsRequestSuccessActionType => ({
  type: actionTypes.SWITCH_NOTIFICATIONS_REQUEST_SUCCESS,
  payload: notifications,
})

export const SwitchNotificationsRequestFailureAction = (
  message: string
): SwitchNotificationsRequestFailureActionType => ({
  type: actionTypes.SWITCH_NOTIFICATIONS_REQUEST_FAILURE,
  payload: message,
})

export type SwitchNotificationsActions =
  | SwitchNotificationsRequestActionType
  | SwitchNotificationsRequestSuccessActionType
  | SwitchNotificationsRequestFailureActionType
