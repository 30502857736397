import * as React from 'react'
import * as GoogleAnalytics from 'react-ga'
import { trackGTMHistoryChange } from '../../common/gtm-helper'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'

export interface WithTrackerHOCProps {
  router: {
    location: {
      pathname: string
    }
  }
  clientId: string
}

const gaId = (window as any).hasOwnProperty('gaId')
  ? (window as any).gaId
  : 'UA-4282732-10'
GoogleAnalytics.initialize(gaId)

const withTracker = (WrappedComponent: React.ComponentClass, options = {}) => {
  const trackPage = (path: string) => {
    GoogleAnalytics.set({ path, ...options })
    GoogleAnalytics.pageview(path)
  }

  const withTrackerHOC = class WithTrackerHOC extends React.Component<
    WithTrackerHOCProps
  > {
    private currentPath: string

    constructor(props: WithTrackerHOCProps) {
      super(props)
      this.currentPath = this.props.router.location.pathname
    }

    componentDidMount() {
      trackPage(this.currentPath)
    }

    componentDidUpdate(prevProps: WithTrackerHOCProps) {
      const newPath = this.props.router.location.pathname

      if (newPath !== this.currentPath) {
        trackPage(newPath)
        this.currentPath = newPath
        trackGTMHistoryChange()
      } else if (this.props.clientId !== prevProps.clientId) {
        // Timeout to postpone the GTM action call a bit so the data from redux/sessionStorage are updated
        setTimeout(() => trackGTMHistoryChange())
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return connect(mapStateToProps, null, null)(withTrackerHOC)
}

const mapStateToProps = (state: AppState) => {
  return {
    // This state is not rendered, but triggers a re-render of this component which
    // re-checks if our session has expired on 'screen change' or 'user change'
    clientId: state.user.clientId,
  }
}

export default withTracker
