// Notice: If you are changing values here, you might also want to change the values in assets/values/breakpoints.scss
import { useMediaQuery } from 'react-responsive'

export enum ScreenSize {
  sm = 480,
  md = 768,
  lg = 1024,
  xl = 1280,
  xxl = 1536,
}

export const useMedia = () => {
  const isMobile = !useMediaQuery({ query: `(min-width: ${ScreenSize.md}px)` })
  const isTabletPortrait = useMediaQuery({
    query: `(min-width: ${ScreenSize.md}px)`,
  })
  const isTabletLandscape = useMediaQuery({
    query: `(min-width: ${ScreenSize.lg}px)`,
  })
  const isDesktop = useMediaQuery({ query: `(min-width: ${ScreenSize.xl}px)` })

  return {
    screenSize: ScreenSize,
    isMobile,
    isTabletPortrait,
    isTabletLandscape,
    isDesktop,
  }
}
