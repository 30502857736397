import { AnyAction, Reducer } from 'redux'

import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'
import { getDefaultState, setFailState } from '../state/state.model'
import { actionTypes } from './reports.actions'
import { ReportsState, ReportFilters } from './reports.model'

export const initialFiltersState: ReportFilters = {
  accountNumber: '',
}

export const initialState: ReportsState = {
  filters: initialFiltersState,
  reports: [],
  ...getDefaultState(),
}

export const ReportsReducer: Reducer<ReportsState> = (
  state: ReportsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.REPORTS_REQUEST:
      return { ...state, ...getDefaultState(), isLoading: true }

    case actionTypes.REPORTS_REQUEST_SUCCESS:
      return { ...state, ...getDefaultState(), reports: action.payload.reports }

    case actionTypes.REPORTS_REQUEST_FAILURE:
      return { ...state, ...setFailState(action.payload) }

    case actionTypes.REPORTS_SKIP_REQUEST:
      return { ...state }

    case actionTypes.ALL_REPORTS_REQUEST:
      return { ...state, ...getDefaultState(), isLoading: true }

    case actionTypes.REPORTS_DOWNLOAD:
      return { ...state, ...getDefaultState(), isLoading: true }

    case actionTypes.REPORTS_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
        accountNumber: action.payload.filters.accountNumber,
      }

    case actionTypes.REPORTS_DOWNLOAD_SUCCESS:
      return { ...state, ...getDefaultState() }

    case actionTypes.REPORTS_DOWNLOAD_FAILURE:
      return { ...state, ...setFailState(action.payload) }

    // Logging out resets the reports list
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return {
        ...initialState,
        isLoading: true,
        filters: { accountNumber: '' },
      }

    default:
      return state
  }
}
