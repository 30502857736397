import React from 'react'
import { Payload } from '../../common/graph-helper'
import { formatCurrency } from '../../common/currency-helper'
import CurrencyDifference from '../currency/CurrencyDifference'
import moment from 'moment'

interface TooltipContentProps {
  payload?: Payload[]
  label?: string
  active?: boolean
}

export const TooltipContent = (props: TooltipContentProps) => {
  const { payload, label, active } = props

  if (!active || !payload || payload.length === 0) {
    return null
  }

  payload.sort((a: Payload) => (a.name === 'marketValue' ? -1 : 1))
  const [marketPrice, accumCashFlow] = payload
  return (
    <div className="balance-graph-tooltip">
      <div className="balance-graph-tooltip__date">
        {moment(label).format('DD/MM/yyyy')}
      </div>
      <div className="balance-graph-tooltip__value">
        {formatCurrency(marketPrice.value, '$0,0.00')}
      </div>
      {!!accumCashFlow && (
        <div className="balance-graph-tooltip__return">
          <CurrencyDifference value={marketPrice.value - accumCashFlow.value} />
        </div>
      )}
    </div>
  )
}
