import cn from 'classnames'
import DateNZ from '../date-nz/DateNZ'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Currency, { FOUR_DECIMAL_PLACES_FORMAT } from '../currency/Currency'
import { Transaction } from '../../redux/account-transactions/account-transactions.model'
import './AccountTransactionsTable.scss'
import { Fragment } from 'react'

type AccountTransactionsTableProps = {
  transactions: Transaction[]
}

const AccountTransactionsTable = ({
  transactions,
}: AccountTransactionsTableProps) => (
  <>
    <Table className="account-transactions-table desktop-hidden">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Total Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((transaction, index) => (
          <Fragment key={`mobile-transaction-row-${index}`}>
            <TableRow className="header-row">
              <TableCell>
                <DateNZ value={transaction.effectiveDatetime} />
              </TableCell>
              <TableCell className="description-content">
                {transaction.description}
              </TableCell>
              <TableCell>
                <Currency value={transaction.grossAmount} />
              </TableCell>
            </TableRow>
            {transaction.transactionAssets.map((asset, assetIndex) => {
              const isLast =
                assetIndex === transaction.transactionAssets.length - 1

              return (
                <TableRow
                  className={cn({ 'is-transaction-last-asset': isLast })}
                  key={`mobile-asset-row-${index}-${assetIndex}`}
                >
                  <TableCell>{asset.displayName}</TableCell>
                  <TableCell className="no-color">
                    {asset.units !== null && asset.price !== null && (
                      <>
                        <span>{`${asset.units} at${' '}`}</span>
                        <Currency
                          value={asset.price}
                          format={FOUR_DECIMAL_PLACES_FORMAT}
                        />
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Currency value={asset.amount} />
                  </TableCell>
                </TableRow>
              )
            })}
          </Fragment>
        ))}
      </TableBody>
    </Table>

    <Table className="account-transactions-table desktop-only">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Fund</TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Unit Price</TableCell>
          <TableCell>Total Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((transaction, index) => {
          return transaction.transactionAssets.map((asset, assetIndex) => {
            const isFirst = assetIndex === 0
            const isLast =
              assetIndex === transaction.transactionAssets.length - 1

            return (
              <TableRow
                className={cn({
                  'is-transaction-last-asset': isLast,
                  'header-row': isFirst,
                })}
                key={`transaction-asset-row-${index}-${assetIndex}`}
              >
                <TableCell>
                  {isFirst && <DateNZ value={transaction.effectiveDatetime} />}
                </TableCell>
                <TableCell className="description-content">
                  {isFirst && transaction.description}
                </TableCell>
                <TableCell>{asset.displayName}</TableCell>
                <TableCell>{asset.units}</TableCell>
                <TableCell className="no-color">
                  {asset.price !== null && (
                    <Currency
                      value={asset.price}
                      format={FOUR_DECIMAL_PLACES_FORMAT}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Currency value={asset.amount} />
                </TableCell>
              </TableRow>
            )
          })
        })}
      </TableBody>
    </Table>
  </>
)

export default AccountTransactionsTable
