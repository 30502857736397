import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'

import * as actions from './reports.actions'
import { ReportsResponse, ReportsState } from './reports.model'
import { Account } from '../accounts/accounts.model'

type LightStore = { getState: Function; dispatch: Function }

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

export const reportsEpic: Epic<actions.ReportsActions, ReportsState> = (
  action$: ActionsObservable<actions.ReportsRequestActionType>,
  store: LightStore
) =>
  action$.ofType(actions.actionTypes.REPORTS_REQUEST).switchMap((action) => {
    let accountOwnersQueryVal = ''
    const accountsData = store.getState().accounts
    // If there is cached account data then extract the account owner IDs to use for report retrieval.
    if (accountsData && accountsData.accounts) {
      const accountOwnerClientIDs = accountsData.accounts.reduce(
        (ownerClientIDs: string[], account: Account) => {
          if (account.owners) {
            const clientIDs = account.owners.map((owner) => owner.clientID)
            return [...ownerClientIDs, ...clientIDs]
          }
          return ownerClientIDs
        },
        []
      )
      // Remove duplicate account owner IDs.
      const uniqueAccountOwnerClientIDs = accountOwnerClientIDs.filter(
        (id: string, i: number) => accountOwnerClientIDs.indexOf(id) === i
      )
      accountOwnersQueryVal = uniqueAccountOwnerClientIDs.join(',')
    }

    return ajax
      .get(
        `${ffmAppServerUrl}/api/users/${action.payload.userId}/reports${
          accountOwnersQueryVal ? `?clients=${accountOwnersQueryVal}` : ''
        }`,
        {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        }
      )
      .map((response) => response.response as ReportsResponse)
      .map((response) => {
        const reports = response.items.map((item) => ({
          date: item.date,
          title: item.additional1,
          clientId: item.additional2, // eg "400269141"
          accountId: item.additional3, // eg "FI165467"
          type: item.type,
          id: item.id,
        }))
        return actions.ReportsRequestSuccessAction(reports)
      })
      .catch((err) => {
        return Observable.of(
          actions.ReportsRequestFailureAction('Failed to get reports.')
        )
      })
  })
