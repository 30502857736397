import MuiCheckbox from './MuiCheckbox'
import MuiMenuItem from './MuiMenuItem'
import MuiRadio from './MuiRadio'
import MuiTab from './MuiTab'

export default {
  MuiCheckbox,
  MuiMenuItem,
  MuiRadio,
  MuiTab,
}
