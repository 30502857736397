import * as React from 'react'
import { AppState } from '../../redux/app-state'

import Button from '@material-ui/core/Button'
import MuiLink from '@material-ui/core/Link'
import Modal from '../modal/Modal'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'

import {
  ThirdPartyAppActions,
  ThirdPartyAppRequestAction,
  ThirdPartyAppRequestPayload,
  ThirdPartyAppUpdateHasLoadedPayload,
  ThirdPartyAppUpdateHasLoadedAction,
  ThirdPartyFormDataRequestAction,
  ThirdPartyFormDataRequestPayload,
} from '../../redux/third-party-app/third-party-app.actions'
import { Dispatch, bindActionCreators } from 'redux'
import IframeModal from '../iframe-modal'

export interface ThirdPartyLinkProps {
  url?: string
  userId?: string
  label?: string | React.ReactNode
  className?: string
  activeLinkId: string
  popupTitle?: string
  accountId?: string
  isActive?: boolean
  isLoading?: boolean
  type?: string
  buttonVariant?: any
  buttonIcon?: any
  buttonColor?: any
  hasLoaded?: boolean
  token?: string
  resource?: string
  resourceUrl?: string
  encodedModel?: string
  onClose?: () => void
  getThirdPartySecureUrl?: (payload: ThirdPartyAppRequestPayload) => void
  getThirdPartyFormData?: (payload: ThirdPartyFormDataRequestPayload) => void
  updateHasLoadedFlag?: (payload: ThirdPartyAppUpdateHasLoadedPayload) => void
}

export class ThirdPartyLink extends React.Component<ThirdPartyLinkProps> {
  protected popupTitle: string = 'Third Party Popup'
  protected linkLabel: string = 'Third Party Link'

  // default props
  static defaultProps = {
    buttonVariant: 'outlined',
    buttonColor: 'default',
  }

  // get secure url
  requestThirdPartyUrl() {
    // Fetch the base url - show modal
    this.props.getThirdPartySecureUrl({
      userId: this.props.userId,
      accountId: this.props.accountId,
      url: this.props.url,
      activeLinkId: this.props.activeLinkId,
    })
    // Fetch the base64 encoded model data
    this.props.getThirdPartyFormData({
      userId: this.props.userId,
      accountId: this.props.accountId,
      resource: this.props.resource,
      encodedModel: this.props.encodedModel,
    })
  }

  getIframeUrl(): string {
    if (this.props.url === '') {
      throw new Error(this.popupTitle + ' is empty')
    }
    return `${this.props.resourceUrl}&DA=${this.props.encodedModel}`
  }

  renderIframe() {
    if (!this.props.hasLoaded || !this.props.isActive) {
      return null
    }

    const { encodedModel, popupTitle } = this.props

    const commonModalProps = {
      open: true,
      title: popupTitle,
      onClose: () => this.onClose(),
      className: 'third-party-modal',
    }

    if (!!encodedModel) {
      return (
        <IframeModal {...commonModalProps} iframeUrl={this.getIframeUrl()} />
      )
    }

    return (
      <Modal {...commonModalProps}>
        <LoadingSpinner center={true} className="my-md" />
      </Modal>
    )
  }

  onClose() {
    this.props.updateHasLoadedFlag({ hasLoaded: false })
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }

  renderLinkLabel() {
    return this.props.label ? this.props.label : this.linkLabel
  }

  renderLink() {
    return (
      <MuiLink
        className={this.props.className || 'third-party-link'}
        underline="none"
        onClick={() => {
          this.requestThirdPartyUrl()
        }}
      >
        {this.renderLinkLabel()}
      </MuiLink>
    )
  }

  protected renderButton() {
    return (
      <Button
        className={this.props.className || 'third-party-link'}
        onClick={() => this.requestThirdPartyUrl()}
        disableRipple
        variant={this.props.buttonVariant}
        startIcon={this.props.buttonIcon}
      >
        {this.renderLinkLabel()}
      </Button>
    )
  }

  renderAction() {
    if (this.props.type === 'link') {
      return this.renderLink()
    }
    return this.renderButton()
  }

  render() {
    return (
      <>
        {this.renderAction()}
        {this.renderIframe()}
      </>
    )
  }
}

export const thirdPartyMapStateToProps = (
  state: AppState,
  props: ThirdPartyLinkProps
) => {
  return {
    url: '',
    accountId: '',
    resource: '',
    userId: state.user.userid,
    isActive: props.activeLinkId === state.thirdPartyApp.activeLinkId,
    isLoading: state.thirdPartyApp.isLoading,
    hasLoaded: state.thirdPartyApp.hasLoaded,
    resourceUrl: state.thirdPartyApp.resourceUrl,
    encodedModel: state.thirdPartyApp.encodedModel,
  }
}

export const thirdPartyMapDispatchToProps = (
  dispatch: Dispatch<ThirdPartyAppActions>
) => ({
  getThirdPartySecureUrl: bindActionCreators(
    ThirdPartyAppRequestAction,
    dispatch
  ),
  getThirdPartyFormData: bindActionCreators(
    ThirdPartyFormDataRequestAction,
    dispatch
  ),
  updateHasLoadedFlag: bindActionCreators(
    ThirdPartyAppUpdateHasLoadedAction,
    dispatch
  ),
})
