import { formatWithDecimalPlaces } from './utils'
import InvestmentMixGraph from './InvestmentMixGraph'
import { CHART_COLOURS } from '../../common/graph-helper'
import './InvestmentMixAssets.scss'

export const INCOME_ASSETS_COLOR = '#74DDBC'
export const GROWTH_ASSETS_COLOR = '#218094'

export type Asset = {
  name: string
  percentage: number
  color?: string
}

type InvestmentMixAssetsProps = {
  assets: Asset[]
}

const InvestmentMixAssets = ({ assets }: InvestmentMixAssetsProps) => (
  <>
    <InvestmentMixGraph
      assets={assets.map(({ name, percentage, color }, index) => ({
        name: name,
        value: percentage,
        color: color || CHART_COLOURS[index],
      }))}
    />
    <div className="assets-list mt-sm">
      {assets.map(({ name, percentage, color }, index) => (
        <div key={name} className="asset-info">
          <span className="asset-label">
            <span
              className="asset-color"
              style={{ background: color || CHART_COLOURS[index] }}
            />
            {name}
          </span>
          <span className="asset-split">
            {formatWithDecimalPlaces(percentage, 2)}%
          </span>
        </div>
      ))}
    </div>
  </>
)

export default InvestmentMixAssets
