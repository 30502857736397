import { useEffect, useState } from 'react'

import Container from '@material-ui/core/Container'
import Modal from '../modal/Modal'

import { createClient } from 'contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'

type Props = {
  contentfulEntryName: string
  title: string
  open: boolean
  onClose: () => void
}

const ContentfulTermsAndConditionsModal = (props: Props) => {
  const { contentfulEntryName, title, open, onClose } = props
  const [termAndConditions, setTermsAndConditions] = useState()

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY,
      environment: process.env.REACT_APP_CONTENTFUL_ENV,
    })

    client
      .getEntries<any>({
        content_type: 'termsAndConditions',
        'fields.name[match]': contentfulEntryName,
        include: 1,
      })
      .then((res) => {
        const content = res?.items?.[0].fields.content

        if (content) {
          setTermsAndConditions(content)
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Container maxWidth={false} className="text-content-layout">
        <SpinnerOnLoad
          className="spinner"
          isLoading={!termAndConditions}
          centerSpinner={true}
          showMessageAfter={5000}
        >
          {documentToReactComponents(termAndConditions)}
        </SpinnerOnLoad>
      </Container>
    </Modal>
  )
}

export default ContentfulTermsAndConditionsModal
