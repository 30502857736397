import Modal from '../modal/Modal'
import { isKiwiSaverTwoAccount } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { getProductPdsUrl } from '../../common/product-helper'

export type TermsModalProps = {
  onClose: () => void
  account: Account
}

const TermsModalTel = (props: TermsModalProps) => (
  <Modal
    title="Terms and conditions"
    open
    className="switch-sub-modal"
    onClose={props.onClose}
  >
    <div>
      <p>
        I hereby request Fisher Funds to direct future investments and/or
        transfer my current investments in accordance with my instructions in
        this form and declare that:
      </p>
      <ol>
        <li>I am authorised to make investment decisions for this account</li>
        <li>
          {' '}
          I have read the current{' '}
          <a
            href={getProductPdsUrl(props.account)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.account.productExternalName} Product Disclosure Statement
          </a>
        </li>
        <li>
          I understand that any change requested will be implemented as soon as
          practicable after receipt of this request
        </li>
        <li>
          I understand that the value of my investments upon withdrawal from any
          fund, and investment or reinvestment into any fund will be based on
          the unit price which applies when the transaction is processed and
          that any fees, taxes and expenses may be deducted from my account.
        </li>
        {!isKiwiSaverTwoAccount(props.account, props.account.isOwner) && (
          <li>
            If I am invested in the Balanced Strategy I authorise Fisher Funds
            to rebalance my target fund mix to within a range of 40% to 50%
            Conservative Fund and 50% to 60% Growth Fund on an annual basis.
          </li>
        )}
      </ol>
    </div>
  </Modal>
)

export default TermsModalTel
