import moment, { Moment } from 'moment-business-days'
import React from 'react'
import { GovernmentContributionEligibilityType as EligibilityType } from '../../redux/accounts/accounts.model'
import { GovernmentContributionProps } from './GovernmentContribution'

const KIWISAVER_END_MONTH = 6

const getKiwiSaverYear = (date: Moment): number => {
  return date.month() < KIWISAVER_END_MONTH ? date.year() : date.year() + 1
}

const LATE_COMMENCEMENT_MESSAGE: React.ReactNode = (
  <span>
    To maximise your Government contribution you need to contribute $1,042.86
    each KiwiSaver year. We do not have visibility of any contributions you may
    have made before joining Fisher Funds. Please click “View Details” above for
    more information.
  </span>
)

const INELIGIBLE_MESSAGE: React.ReactNode = (
  <span>
    To be eligible for the Government contribution you must be aged between 18
    and 65, and be a resident in New Zealand for most of the KiwiSaver year (1
    July to 30 June). Please contact us on <b>0800 FF KIWI (0800 335 494)</b> if
    you have any questions.
  </span>
)

const ELIGIBLE_TOOLTIP_MESSAGE: React.ReactNode = (
  <span>
    For every $1 you contribute to your KiwiSaver account, the Government will
    contribute $0.50, up to $521.43 each KiwiSaver year. To qualify, you must be
    18 years or older, living in NZ and not yet eligible to withdraw for
    retirement.
  </span>
)

export const calcIsLateCommence = (
  kiwiSaverYear: number,
  commenceDate: string,
  kiwiSaverOriginalStart: string
): boolean => {
  const commenceKiwiSaverYear = getKiwiSaverYear(moment(commenceDate))
  const diff = moment(commenceDate).diff(
    moment(kiwiSaverOriginalStart),
    'hours'
  )

  const isOriginalStartWithUs = Math.abs(diff) <= 24
  const isLateCommence: boolean =
    commenceKiwiSaverYear === kiwiSaverYear && !isOriginalStartWithUs

  return isLateCommence
}

const kiwiSaverYear = getKiwiSaverYear(moment())

export const lastContributionDay = moment(
  `${kiwiSaverYear}-07-01`,
  'YYYY-MM-DD'
).businessAdd(-5)

export const isApproachingContributionDeadline = (): boolean => {
  // true from a week before lastContributionDay until 30 June inclusive.
  const currentTime = moment()
  const currentYear = currentTime.year()
  const lastContributionDayForIRD = moment(`${currentYear}-06-30`, 'YYYY-MM-DD')
  return (
    currentTime.diff(lastContributionDay, 'days') > -7 &&
    currentTime.diff(lastContributionDayForIRD, 'days') <= 0
  )
}

export const useGovernmentContribution = ({
  governmentContributionDetail: {
    currentGvcAmount,
    eligibility,
    maxGcvEligibility,
  },
  commenceDate,
  kiwiSaverOriginalStart,
}: GovernmentContributionProps) => {
  const isLateCommence: boolean = calcIsLateCommence(
    kiwiSaverYear,
    commenceDate,
    kiwiSaverOriginalStart
  )

  const current: string = currentGvcAmount.toFixed(2)
  const max: string = (maxGcvEligibility / 2).toFixed(2)

  const width: string =
    ((currentGvcAmount / maxGcvEligibility) * 2 * 100).toFixed(0) + '%'

  const getEligibleTooltipMessage = (isEligible: boolean): React.ReactNode => {
    return isEligible
      ? ELIGIBLE_TOOLTIP_MESSAGE
      : isLateCommence
      ? LATE_COMMENCEMENT_MESSAGE
      : INELIGIBLE_MESSAGE
  }

  const getEligibility = (eligibility: EligibilityType): boolean => {
    return (
      (eligibility === EligibilityType.FULL_ELIGIBILITY ||
        eligibility === EligibilityType.PARTIAL_ELIGIBILITY_TURNED_18 ||
        eligibility === EligibilityType.PARTIAL_ELIGIBILITY_REACHED_AGE ||
        eligibility === EligibilityType.PARTIAL_ELIGIBILITY_NEW_TO_KS) &&
      !isLateCommence
    )
  }

  const isEligible = getEligibility(eligibility)
  const gcTrackerTooltipMessage = getEligibleTooltipMessage(isEligible)

  return {
    kiwiSaverYear,
    current,
    max,
    width,
    gcTrackerTooltipMessage,
    lastContributionDay,
    isEligible,
    isLateCommence,
  }
}
