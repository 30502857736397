import Modal from '../modal/Modal'
import IFrame from '../iframe/IFrame'

import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'

type Props = {
  className?: string
  title?: string
  iframeUrl: string
  open: boolean
  onClose: () => void
  authToken?: string
}

const IframeModal = (props: Props) => {
  const { authToken } = props
  return authToken ? (
    <Modal
      open={props.open}
      title={props.title}
      className={props.className}
      onClose={props.onClose}
    >
      <IFrame title={props.title} url={props.iframeUrl} token={authToken} />
    </Modal>
  ) : (
    <></>
  )
}

const mapStateToProps = (state: AppState) => ({
  authToken: state.authorisation.authorisationToken,
})

export default connect(mapStateToProps, null, null)(IframeModal)
