import Toast from '../toast/Toast'
import Alert from 'react-s-alert'

const SiteToast = (props: any) => {
  return (
    <Toast
      msg={props.message}
      type={props.condition}
      onClose={() => Alert.close(props.id)}
    />
  )
}

export default SiteToast
