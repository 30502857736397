import getApiCore from '../../core'

const transactionsPath = (userId: string, accountNumber: string) =>
  `users/${userId}/accounts/${accountNumber}/transactions`

type GetTransactionParams = {
  userId: string
  accountNumber: string
  data: any
}

export default function getTransactionApi(authToken: string) {
  const apiCore = getApiCore(authToken)

  return {
    getTransactions(params: GetTransactionParams) {
      return apiCore.post(
        transactionsPath(params.userId, params.accountNumber),
        { data: params.data }
      )
    },
  }
}
