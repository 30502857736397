import * as React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { bindActionCreators, Dispatch } from 'redux'

import ResetPasswordConfirm from '../../components/forgot-password/ResetPasswordConfirm'
import ResetPasswordForm from '../../components/forgot-password/ResetPasswordForm'
import NotFound from '../../components/not-found/NotFound'
import Alert from 'react-s-alert'
import { AppState } from '../../redux/app-state'
import {
  UserInitResetProcessType,
  UserInitResetProcess,
  UserCheckConfirmCodeCredentials,
  UserCheckConfirmCodeActionType,
  UserCheckConfirmCodeAction,
  UserResetCredentials,
  UserResetActionType,
  UserResetAction,
} from '../../redux/user/user.actions'
import LogInButton from '../../components/login-button/LogInButton'
import { Box, Typography } from '@material-ui/core'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'

interface ResetPasswordProps {
  setTitle: (newTitle: string) => void
  // Whole process
  isLoading: boolean
  hasError: boolean
  errorMessage: string
  // State machine representing screen we should see
  screen: 'confirm' | 'reset' | 'complete'
  // Check confirm code
  confirmCode: string
  // Reset password
  username: string
  newValue: string
  hasReset: boolean
  params: {
    id: string
  }
  passwordType: string
  actions: {
    init: () => UserInitResetProcessType
    checkConfirm: (
      payload: UserCheckConfirmCodeCredentials
    ) => UserCheckConfirmCodeActionType
    reset: (payload: UserResetCredentials) => UserResetActionType // Reset user password / PIN, not reset form
    nextStep: (path: string) => void
  }
  setActiveMenu: (activeMenu: string) => void
}

export class ResetPassword extends React.Component<ResetPasswordProps> {
  UNSAFE_componentWillMount() {
    const title = 'Reset password'
    this.props.setTitle(title)
    this.props.setActiveMenu('login')
    this.props.actions.init()
  }

  componentDidUpdate(prevProps: ResetPasswordProps) {
    // If we just changed state to 'complete', show a message
    if (
      this.props.screen !== prevProps.screen &&
      this.props.screen === 'complete'
    ) {
      // Alert.success('Your password was changed successfully')
    }
    // If we just set an error message, display it.
    if (!prevProps.hasError && this.props.hasError) {
      Alert.error(this.props.errorMessage)
    }
  }

  render() {
    if (!this.props.params.id) {
      return <NotFound {...this.props} />
    }
    switch (this.props.screen) {
      default:
      case 'confirm':
        return (
          <ResetPasswordConfirm
            submit={(submittedValues) => {
              this.props.actions.checkConfirm({
                id: this.props.params.id,
                confirmCode: submittedValues.confirmCode,
              })
            }}
            isLoading={this.props.isLoading}
            setTitle={this.props.setTitle}
          />
        )
      case 'reset':
        return (
          <ResetPasswordForm
            submit={(submittedValues) => {
              this.props.actions.reset({
                id: this.props.params.id,
                confirmCode: this.props.confirmCode,
                newValue: submittedValues.newPassword.newPassword,
              })
            }}
            isLoading={this.props.isLoading}
          />
        )

      case 'complete':
        return (
          <>
            <Box my={1.5}>
              <Typography>Your password was changed successfully.</Typography>
            </Box>
            <LogInButton />
          </>
        )
    }
  }
}

export default connect(
  (state: AppState) => {
    return {
      ...state.resetPassword,
      passwordType: state.routing.locationBeforeTransitions.query.type,
    }
  },
  (dispatch: Dispatch<{}>) => ({
    actions: {
      ...bindActionCreators(
        {
          init: UserInitResetProcess,
          checkConfirm: UserCheckConfirmCodeAction,
          reset: UserResetAction,
        },
        dispatch
      ),
      nextStep: (path: string) => dispatch(routerActions.push(path)),
      setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
    },
  }),
  null
)(ResetPassword)
