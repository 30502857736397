import React from 'react'
import { Link } from 'react-router'
import moment from 'moment'
import cn from 'classnames'
import './ProfileQuickActions.scss'

import { connect, Dispatch } from 'react-redux'
import { AppState } from '../../redux/app-state'

import { bindActionCreators } from 'redux'
import {
  DeauthorisationRequestAction,
  AuthorisationActions,
} from '../../redux/authorisation/authorisation.actions'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useAuth0 } from '@auth0/auth0-react'
import { Typography } from '@material-ui/core'
import CustomIcon from '../../common/CustomIcon'
import { trackUserId } from '../../common/gtm-helper'

interface ProfileQuickActionsProps {
  userId?: string
  userEmail?: string
  firstName?: string
  surname?: string
  lastAuthenticationTime?: string
  isUsernameChangeable?: boolean
  logoutRequest?: typeof DeauthorisationRequestAction
}

export function ProfileQuickActionsView(props: ProfileQuickActionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { logout } = useAuth0()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const getLastLoggedInTime = () => {
    return moment(props.lastAuthenticationTime, moment.ISO_8601).format(
      'DD/MM/YYYY LT'
    )
  }

  const logoutWithRedirect = () => {
    trackUserId(null)
    logout({
      returnTo: `${window.location.origin}/logged-out`,
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { firstName, surname } = props
  const userDataLoaded = !!firstName

  return (
    <div className="profile-quick-actions-component">
      {userDataLoaded && (
        <Button
          className={cn('profile-button', {
            'loading disabled': !userDataLoaded,
          })}
          disabled={!userDataLoaded}
          disableRipple
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <span className="profile-photo">
            {firstName ? firstName.charAt(0).toUpperCase() : ''}
          </span>
          <div className="profile-greeting">
            <Typography variant="body2">Welcome back</Typography>
            <Typography component="p" variant="h6" className="profile-name">
              {firstName + ' ' + surname}
            </Typography>
          </div>
          <KeyboardArrowDownIcon className="icon-arrow-dropdown" />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="profile-menu-list"
      >
        <MenuItem disableRipple onClick={handleClose}>
          <span className="label">Account Email</span>
          <span className="value">{props.userEmail}</span>
        </MenuItem>

        {!!props.lastAuthenticationTime && (
          <MenuItem className="last-logged" disableRipple onClick={handleClose}>
            <span className="label">Last logged in at</span>
            <span className="value">{getLastLoggedInTime()}</span>
          </MenuItem>
        )}

        <MenuItem disableRipple>
          <Divider />
        </MenuItem>

        <MenuItem disableRipple onClick={handleClose}>
          <Link to="/details" title="Update my details" className="link">
            Update my details
          </Link>
        </MenuItem>

        <MenuItem disableRipple>
          <Divider />
        </MenuItem>

        {props.isUsernameChangeable && (
          <MenuItem disableRipple onClick={handleClose}>
            <Link
              to="/details/change-username"
              title="Change username"
              className="link"
            >
              Change username
            </Link>
          </MenuItem>
        )}
        <MenuItem disableRipple onClick={handleClose}>
          <Link
            to="/details/change-password"
            title="Change password"
            className="link"
          >
            Change password
          </Link>
        </MenuItem>

        <MenuItem disableRipple>
          <Divider />
        </MenuItem>

        <MenuItem
          disableRipple
          onClick={logoutWithRedirect}
          title="Log out"
          className="link logout"
        >
          Log out <CustomIcon icon="logout" className="MuiSvgIcon-root" lake />
        </MenuItem>
      </Menu>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    userId: state.user.userid,
    userEmail: state.user.email,
    isUsernameChangeable: state.username.isChangeable,
    firstName: state.user.firstName,
    surname: state.user.surname,
    lastAuthenticationTime: state.authorisation.lastAuthenticationTime,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AuthorisationActions>) => ({
  logoutRequest: bindActionCreators(DeauthorisationRequestAction, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ProfileQuickActionsView)
