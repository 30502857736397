import { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import './Redirecting.scss'

interface RedirectingProps {
  setTitle?: (title: string) => void
}

const Redirecting = ({ setTitle }: RedirectingProps) => {
  useEffect(() => {
    if (setTitle) {
      setTitle('')
    }
  }, [setTitle])

  return (
    <Box alignItems="center" width="100%" textAlign="center">
      <Box margin="20px" position="relative">
        <CircularProgress className="redirecting-circle-bg" />
        <CircularProgress style={{ color: '#00AE9E' }} />
      </Box>
      <Typography component="h5" variant="h5">
        Loading...
      </Typography>
    </Box>
  )
}

export default Redirecting
