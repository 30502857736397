import React, { useState } from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { ProgressBar } from '../accounts-overview/ProgressBar'
import AddFundsModal, {
  AddFundsModalProps,
  LateKiwisaverTopUpNote,
} from '../add-funds-modal/AddFundsModal'
import {
  isApproachingContributionDeadline,
  useGovernmentContribution,
} from '../accounts-overview/useGovernmentContribution'
import { Notification } from '../notification/Notification'
import DisclaimerModal from './DisclaimerModal'
import { Button } from '../clickable/button/Button'
import { GovernmentContributionProps } from '../accounts-overview/GovernmentContribution'

export type EligilbleViewProps = GovernmentContributionProps &
  Omit<AddFundsModalProps, 'onClose'>

export const EligibleView: React.FC<EligilbleViewProps> = (
  props: EligilbleViewProps
) => {
  const [addFundsModalOpen, setAddFundsModalOpen] = useState<boolean>(false)
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState<boolean>(false)
  const {
    governmentContributionDetail: { topUpAmount, totalContributions },
  } = props
  const {
    accountId,
    externalRef,
    productExternalName,
    ...governmentContributionProps
  } = props
  const {
    kiwiSaverYear,
    current,
    max,
    width,
    lastContributionDay,
  } = useGovernmentContribution(governmentContributionProps)
  const SHOW_LATE_TOP_UP_NOTIFICATION = isApproachingContributionDeadline()

  return (
    <>
      <div>
        <p className="text-large details-label">
          {kiwiSaverYear} Government contribution
        </p>
        <p className="m-0">
          <span className="details-label">${current}</span>
          <span className="details-description text-large"> / ${max}</span>
        </p>
      </div>

      <ProgressBar
        className="government-contribution__progress-bar"
        width={width}
      />
      <Notification
        className="mt-md"
        actions={
          <>
            {current !== max && (
              <Button
                variant="link"
                color="primary"
                iconRight={ArrowForwardIcon}
                onClick={() => setAddFundsModalOpen(true)}
              >
                Add funds
              </Button>
            )}
            <Button
              className="see-disclaimer"
              variant="link"
              color="primary"
              iconRight={ArrowForwardIcon}
              onClick={() => setDisclaimerModalOpen(true)}
            >
              See disclaimer
            </Button>
          </>
        }
      >
        {topUpAmount === 0 ? (
          <span>
            Great! You have maximised your Government contribution of ${max}.
          </span>
        ) : (
          <>
            <span>
              If you top-up
              <span className="government-contribution__description__highlight">
                {' '}
                ${topUpAmount.toFixed(2)}
              </span>
              {!SHOW_LATE_TOP_UP_NOTIFICATION && (
                <>
                  {' '}
                  by{' '}
                  <span className="government-contribution__description__highlight">
                    {lastContributionDay.format('DD')}/06/{kiwiSaverYear}
                  </span>
                </>
              )}
              , you will maximise your Government contribution of ${max}. You
              have contributed ${totalContributions.toFixed(2)} and are on track
              to receive a Government contribution of ${current}.
            </span>
            {SHOW_LATE_TOP_UP_NOTIFICATION && <LateKiwisaverTopUpNote />}
          </>
        )}
      </Notification>
      {addFundsModalOpen && (
        <AddFundsModal
          accountId={accountId}
          externalRef={externalRef}
          productExternalName={productExternalName}
          onClose={() => setAddFundsModalOpen(false)}
        />
      )}
      <DisclaimerModal
        open={disclaimerModalOpen}
        onClose={() => setDisclaimerModalOpen(false)}
      />
    </>
  )
}
