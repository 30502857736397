import { Reducer, AnyAction } from 'redux'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './account-transactions.actions'
import {
  AccountTransactionsState,
  Transaction,
} from './account-transactions.model'

import moment from 'moment'

export const initialState: AccountTransactionsState = {
  productName: null,
  accountNumber: null,
  productTotal: 0,
  transactions: [],
  allTransactions: [],
  filters: {
    dateType: 'all',
    startDate: moment().subtract(3, 'months'),
    endDate: moment(),
  },
  hasMoreTransactions: false,
  isLoadingAllTransactions: false,
  isLoadingPDF: false,
  ...getDefaultState(),
}

export const accountTransactionsReducer: Reducer<AccountTransactionsState> = (
  state: AccountTransactionsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_TRANSACTIONS_REQUEST:
      // Vars
      let transactions: Transaction[] = state.transactions
      let isLoadingAllTransactions = false
      let isLoading = true
      let hasMoreTransactions = true

      if (action.payload.shouldReturnAll) {
        isLoadingAllTransactions = true
      }

      if (action.payload.isCSV) {
        isLoadingAllTransactions = true
        isLoading = false
        hasMoreTransactions = state.hasMoreTransactions
      } else if (action.payload.offset === 0) {
        // clear any existing transactions before fetch if request is for first block.
        transactions = []
      }

      return {
        ...state,
        isLoading,
        isLoadingAllTransactions,
        hasError: false,
        errorMessage: null,
        transactions,
        hasMoreTransactions,
      }

    case actionTypes.ACCOUNT_ALL_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        allTransactions: [...action.payload.transactions],
        isLoading: false,
        hasMoreTransactions: state.hasMoreTransactions, // shouldn't override this flag
        isLoadingAllTransactions: false,
      }

    case actionTypes.ACCOUNT_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        transactions: [...state.transactions, ...action.payload.transactions],
        isLoading: false,
        isLoadingAllTransactions: false,
      }

    case actionTypes.ACCOUNT_TRANSACTIONS_UPDATE_FILTERS:
      return { ...state, filters: action.payload.filters }

    case actionTypes.ACCOUNT_TRANSACTIONS_SET_IS_LOADING_PDF:
      return { ...state, isLoadingPDF: action.payload.isLoadingPDF }

    case actionTypes.ACCOUNT_TRANSACTIONS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
      }
    // Logging out resets the accounts transaction list
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
