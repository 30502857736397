import { AnyAction, Reducer } from 'redux'
import { PirRateState } from './pir.model'
import { getDefaultState, setFailState } from '../state/state.model'
import { actionTypes } from './pir.actions'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'
import Constants from './constants'

export const initialState: PirRateState = {
  currentPirRate: {
    code: null,
    longDescription: null,
    shortDescription: null,
  },
  pirRates: Constants.PIR_RATES,
  isSaving: false,
  hasPIRChanged: false,
  ...getDefaultState(),
}

export const pirReducer: Reducer<PirRateState> = (
  state: PirRateState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.PIR_USER_RATE_REQUEST:
      return {
        ...state,
        hasError: false,
        errorMessage: null,
      }
    case actionTypes.PIR_USER_RATE_REQUEST_SUCCESS:
      return {
        ...state,
        currentPirRate: action.payload,
      }
    case actionTypes.PIR_USER_RATE_REQUEST_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload),
      }
    case actionTypes.PIR_USER_RATE_SAVE:
      return {
        ...state,
        isSaving: true,
        hasError: false,
        errorMessage: null,
      }
    case actionTypes.PIR_USER_RATE_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        hasPIRChanged: true,
        currentPirRate: {
          code: action.payload,
        },
      }
    case actionTypes.PIR_USER_RATE_SAVE_FAILURE:
      return {
        ...state,
        isSaving: false,
        ...setFailState(action.payload),
      }

    case actionTypes.PIR_USER_RESET_SUCCESS:
      return {
        ...state,
        hasPIRChanged: false,
      }

    // Logging out resets the PIR state
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
