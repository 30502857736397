import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import FirstHomeStepsModalComponent, {
  OwnProps as ComponentProps,
} from './FirstHomeStepsModal.component'
import { AppState } from '../../redux/app-state'
import { getKiwisaverAccount } from '../../common/accounts-helper'
import { useTransactions } from './FirstHomeStepsModal.hooks'
import useAsync from '../../hooks/use-async'
import downloadFile from '../../common/downloads-helper'
import { Account } from '../../redux/accounts/accounts.model'

type StateProps = {
  authToken: string
  userId: string
  account: Account
}

const mapStateToProps = (state: AppState): StateProps => ({
  authToken: state.authorisation.authorisationToken,
  userId: state.user.userid,
  account: getKiwisaverAccount(state.accounts.accounts),
})

type ContainerProps = StateProps & ComponentProps

const FirstHomeStepsModalContainer = (props: ContainerProps) => {
  const { account, userId, authToken } = props
  const { accountNumber } = account
  const [loading, setLoading] = useState(true)
  const [hasKiwiSaverForThreeYears, setHasKiwiSaverForThreeYears] = useState(
    false
  )

  const {
    status: letterStatus,
    data: letterData,
    run: runGetLetterAsync,
  } = useAsync()
  const {
    status: callbackRequestStatus,
    run: runCallbackRequestAsync,
  } = useAsync()

  const { status: transactionStatus } = useTransactions(
    userId,
    accountNumber,
    authToken
  )

  useEffect(() => {
    const statuses = [transactionStatus, letterStatus, callbackRequestStatus]
    setLoading(statuses.some((status) => status === 'pending'))
  }, [letterStatus, transactionStatus, callbackRequestStatus])

  useEffect(() => {
    const yearsSinceKiwisaver = moment().diff(
      moment(account.kiwiSaverOriginalStart),
      'years'
    )
    setHasKiwiSaverForThreeYears(yearsSinceKiwisaver >= 3)
  }, [account.kiwiSaverOriginalStart])

  useEffect(() => {
    if (letterData) {
      const fileName = 'FirstHomeLetter.pdf'
      const blob: Blob = new Blob([letterData.data], {
        type: 'application/pdf',
      })
      downloadFile(blob, fileName)
    }
  }, [letterData])

  return (
    <FirstHomeStepsModalComponent
      userId={userId}
      authToken={authToken}
      accountNumber={accountNumber}
      onClose={props.onClose}
      hasKiwiSaverForThreeYears={hasKiwiSaverForThreeYears}
      loading={loading}
      runCallbackRequestAsync={runCallbackRequestAsync}
      runGetLetterAsync={runGetLetterAsync}
    />
  )
}

export default connect(mapStateToProps)(FirstHomeStepsModalContainer)
