import { HorizontalBar } from 'react-chartjs-2'
import { ChartDataSets } from 'chart.js'
import {
  CHART_COLOURS,
  horizontalBarChartOptions,
} from '../../common/graph-helper'
import './InvestmentMixGraph.scss'

const COMMON_BAR_DATASET_PROPS: Partial<ChartDataSets> = {
  barThickness: 10,
  stack: 'all',
  borderColor: 'white',
  borderWidth: () => ({ right: 1 } as any),
}

type AssetArrayItem = {
  name: string
  value: number
  color?: string
}

type InvestmentMixGraphProps = {
  assets: AssetArrayItem[]
}

const InvestmentMixGraph = ({ assets }: InvestmentMixGraphProps) => {
  const datasets: ChartDataSets[] = assets.map(
    ({ name, value, color }, index) => {
      const assetDataset = {
        data: [value],
        label: name,
        backgroundColor: color || CHART_COLOURS[index],
        ...COMMON_BAR_DATASET_PROPS,
      }
      const isLast = index === assets.length - 1
      return isLast ? { ...assetDataset, borderWidth: 0 } : assetDataset
    }
  )

  return (
    <div className="investment-mix-graph">
      <HorizontalBar
        height={5}
        data={{
          labels: [],
          datasets,
        }}
        options={horizontalBarChartOptions}
        legend={{ display: false }}
      />
    </div>
  )
}

export default InvestmentMixGraph
