import { ForwardedRef, forwardRef, useMemo } from 'react'
import cn from 'classnames'
import './Radio.scss'

interface RadioProps extends Omit<React.HTMLProps<HTMLInputElement>, 'size'> {
  size?: 'small' | 'medium'
  theme?: 'white' | 'gray' | 'dusk'
  border?: 'default' | 'gray'
}

const Radio = forwardRef(
  (props: RadioProps, ref: ForwardedRef<HTMLInputElement>) => {
    const randomNumber = useMemo(() => Math.floor(Math.random() * 10000), [])
    const {
      className,
      size = 'medium',
      theme = 'gray',
      border = 'default',
      id,
      ...inputProps
    } = props
    const inputId = id || `radio-${randomNumber}`
    return (
      <label
        htmlFor={inputId}
        className={cn(`form-radio form-radio--${size} form-radio--${theme}`, {
          [`${className}`]: !!className,
          'form-radio--disabled': inputProps.disabled,
          'form-radio--checked': inputProps.checked,
        })}
      >
        <div className={cn(`form-radio__wrapper border--${border}`)}>
          <input
            type="radio"
            className="form-radio__input"
            id={inputId}
            ref={ref}
            {...inputProps}
          />
          <span className="form-radio__icon"></span>
        </div>
      </label>
    )
  }
)

export default Radio
