import Modal from '../modal/Modal'
import './GovernmentContributionModal.scss'

export type DisclaimerModalProps = {
  open: boolean
  onClose: () => void
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({
  open,
  onClose,
}: DisclaimerModalProps) => (
  <Modal
    open={open}
    fullScreen={true}
    title="Disclaimer"
    onClose={onClose}
    className="government-contribution-disclaimer-modal"
  >
    <p>
      The calculations are estimated based on member and voluntary contributions
      that have been added to your KiwiSaver account with Fisher Funds. Member
      contributions can take some time to reach us from Inland Revenue, and do
      not reflect any contributions returned to Inland Revenue as an Employer
      Monthly Schedule (EMS) refund. If you’re currently only contributing via
      your salary/wages, we highly recommend checking your myIR account for your
      most accurate contributions summary.
    </p>
  </Modal>
)

export default DisclaimerModal
