import Dialog from '@material-ui/core/Dialog'
import { IInvestmentOption } from '../../utils/investmentOption'
import InvestmentOptionInfoDetail from './InvestmentOptionInfoDetail'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'
import './DualInvestmentOptionInfoModal.scss'
import Divider from '@material-ui/core/Divider'
import { Fragment, useState } from 'react'
import { Button } from '../clickable/button/Button'
import { Product } from '../../common/product-helper'

type DualInvestmentOptionInfoModalProps = {
  onClose: () => void
  investmentOptions: IInvestmentOption[]
  product: Product
  open?: boolean
  className?: string
  fullScreen?: boolean
}

const getStrategyName = (investmentOption: IInvestmentOption): string => {
  return investmentOption.strategyFundAllocations?.length === 1
    ? investmentOption.shortName.replace('Strategy', 'Fund')
    : investmentOption.shortName
}

const DualInvestmentOptionInfoModal = (
  props: DualInvestmentOptionInfoModalProps
) => {
  const {
    onClose,
    investmentOptions,
    open,
    className,
    fullScreen,
    product,
  } = props
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={cn('material-modal', 'dual-info-modal', {
        [`${className}`]: !!className,
      })}
      maxWidth={false}
      open={open}
      onClose={onClose}
      onKeyDown={(e) => {
        const key = e.code || e.key
        const isEscapeKey = key.toLocaleLowerCase() === 'escape'
        if (isEscapeKey) {
          onClose()
        } else {
          e.stopPropagation()
        }
      }}
    >
      <div className="dialog-desktop">
        <MuiDialogTitle disableTypography className="header">
          {investmentOptions.map((option, i) => (
            <h4 key={option.shortName} className="color-midnight mb-0">
              {getStrategyName(option)}
            </h4>
          ))}
          <IconButton
            aria-label="close"
            className="close-button"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <MuiDialogContent className={'content'}>
          {investmentOptions.map((option, i) => (
            <Fragment key={option.shortName}>
              <InvestmentOptionInfoDetail
                investmentOption={option}
                product={product}
              />
              {i < investmentOptions.length && (
                <Divider orientation="vertical" />
              )}
            </Fragment>
          ))}
        </MuiDialogContent>
      </div>
      <div className="dialog-mobile">
        <MuiDialogTitle disableTypography className="header">
          {investmentOptions.map((option, i) => (
            <div
              key={option.shortName}
              className={cn({
                'selected-tab-heading': selectedTab === i,
                'unselected-tab-heading': selectedTab !== i,
              })}
            >
              <Button
                variant="link"
                className="tab-button"
                onClick={() => setSelectedTab(i)}
              >
                {getStrategyName(option)}
              </Button>
              <hr />
            </div>
          ))}
          <IconButton
            aria-label="close"
            className="close-button"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>

        <MuiDialogContent className={'content'}>
          <InvestmentOptionInfoDetail
            investmentOption={investmentOptions[selectedTab]}
            product={product}
          />
        </MuiDialogContent>
      </div>
    </Dialog>
  )
}

export default DualInvestmentOptionInfoModal
