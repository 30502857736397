import './ProgressBar.scss'

export type ProgressBarProps = {
  width: number | string
  height?: number | string
  className?: string
  size?: 'sm' | 'md'
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  width,
  className,
  size = 'md',
}: ProgressBarProps) => {
  return (
    <div className={`progress-bar ${size} ${className}`}>
      <div className="progress-bar__indicator" style={{ width }} />
    </div>
  )
}
