import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { ProgressBar } from './ProgressBar'
import { useGovernmentContribution } from './useGovernmentContribution'
import { GovernmentContributionDetail } from '../../redux/accounts/accounts.model'
import './GovernmentContribution.scss'

export type GovernmentContributionProps = {
  governmentContributionDetail: GovernmentContributionDetail
  commenceDate: string
  kiwiSaverOriginalStart: string
}

export const GovernmentContribution: React.FC<GovernmentContributionProps> = (
  props: GovernmentContributionProps
) => {
  const {
    kiwiSaverYear,
    current,
    max,
    width,
    isEligible,
    gcTrackerTooltipMessage,
  } = useGovernmentContribution(props)

  return (
    <div className="government-contribution">
      <Tooltip
        title={gcTrackerTooltipMessage}
        disableFocusListener
        enterTouchDelay={200}
      >
        <div className="government-contribution__content">
          <div className="government-contribution__content__labels">
            <InfoIcon className="material-icons md-18" />
            <div className="government-contribution__content__info">
              <span>
                {isEligible ? kiwiSaverYear : 'Annual'} Government contribution
              </span>
              {isEligible && (
                <div className="government-contribution__numbers">
                  <span>${current}</span>
                  <span className="government-contribution__numbers__max">
                    {' '}
                    / ${max}
                  </span>
                </div>
              )}
            </div>
          </div>
          {isEligible && (
            <ProgressBar
              className="government-contribution__progress-bar"
              size="sm"
              width={width}
            />
          )}
        </div>
      </Tooltip>
    </div>
  )
}
