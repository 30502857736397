import './CreatePassword.scss'

import * as React from 'react'

import Alert from 'react-s-alert'
import { Dispatch, bindActionCreators } from 'redux'
import { AppState } from '../../../redux/app-state'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import NewPasswordFields from '../../../components/form/new-password/NewPasswordFields'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import {
  OnboardingActions,
  OnboardingCreateCredentials,
  OnboardingClearErrorMessage,
  OnboardingClearCredentials,
} from '../../../redux/onboarding/onboarding.actions'
import { useForm } from 'react-hook-form'

interface CreatePasswordProps {
  hash: string
  dateOfBirth: string
  username: string
  password: string
  isLoading?: boolean
  nextStep: (path: string) => void
  createCredentials: typeof OnboardingCreateCredentials
  clearOnboardingCredentials: typeof OnboardingClearCredentials
  errorMessage?: string
  clearErrorMessage?: () => void
}

export function CreatePasswordForm(props: CreatePasswordProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const onSubmitEvent = (data: any) => {
    if (!data.newPassword) {
      return
    }

    Alert.closeAll()

    props.createCredentials({
      dateOfBirth: props.dateOfBirth,
      password: data.newPassword,
      username: props.username,
      hash: props.hash,
    })
  }

  // redirect or display alert if there is an error message
  if (props.password) {
    props.nextStep('/onboard/complete')
  } else if (props.errorMessage && !props.isLoading) {
    Alert.error(props.errorMessage)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitEvent)} className="form">
      <Typography component="p" variant="body1">
        Step 3 of 3
      </Typography>
      <Typography variant="h6">
        Please enter a new secure password. Passwords that could be easily
        guessed may not be accepted.
      </Typography>

      <div className="fieldGroup login-field-group">
        <NewPasswordFields
          disabled={props.isLoading}
          register={register}
          watch={watch}
          errors={errors}
        />
      </div>
      <div className="action-group">
        <Button
          disableRipple
          disabled={props.isLoading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {props.isLoading ? 'Submitting...' : 'Next'}
        </Button>
      </div>
    </form>
  )
}

export default connect(
  (state: AppState) => {
    return {
      dateOfBirth: state.onboarding.dateOfBirth,
      username: state.onboarding.username,
      password: state.onboarding.password,
      hash: state.routing.locationBeforeTransitions.query.q,
      isLoading: state.onboarding.isLoading,
      errorMessage: state.onboarding.errorMessage,
    }
  },
  (dispatch: Dispatch<OnboardingActions>) => ({
    createCredentials: bindActionCreators(
      OnboardingCreateCredentials,
      dispatch
    ),
    nextStep: (path: string) => dispatch(routerActions.push(path)),
    clearErrorMessage: bindActionCreators(
      OnboardingClearErrorMessage,
      dispatch
    ),
  }),
  null
)(CreatePasswordForm)
