import cn from 'classnames'
import './Currency.scss'
import { formatCurrency } from '../../common/currency-helper'

export const FOUR_DECIMAL_PLACES_FORMAT = '$0,0.0000'

interface CurrencyProps {
  value: number
  format?: string
  className?: string
}

const getColorClass = (value: number) => {
  return value < 0 ? 'currency-red' : 'currency-black'
}

export default function Currency(props: CurrencyProps) {
  const { value, format = '$0,0.00', className } = props
  return (
    <span
      className={cn('currency', getColorClass(value), {
        [`${className}`]: !!className,
      })}
    >
      {formatCurrency(value, format)}
    </span>
  )
}
