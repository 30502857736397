import axios from 'axios'
const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

type MandateFund = {
  fundCode: string
  fundId: string
  name: string
  percentage: number
}

export type Mandate = {
  addedDate: string
  fundAllocations: MandateFund[]
  isMandateChangeAllowed: boolean
}

export const getMandate = async (accountId: string, authToken: string) => {
  try {
    const response = await axios.get(
      `${ffmAppServerUrl}/api/v1/financial/account/${accountId}/mandate`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    return {
      data: response.data,
      status: response.status,
    }
  } catch (e) {
    throw Error(`Failure to retrieve mandate: ${e}`)
  }
}
