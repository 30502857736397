import React from 'react'
import './NextBestAction.scss'

import { KiwiSaverAction, ManagedFundsAction } from './components'
import { UserData } from '../../redux/user/user.model'
import { isAdult } from '../../common/user-helper'

interface NextBestActionProps {
  hasOwnKiwiSaver: boolean
  hasManagedFunds?: boolean
  hasInvestmentFunds?: boolean
  birthDate: string
  user: UserData
}

const NextBestAction = (props: NextBestActionProps) => {
  const { user } = props

  const showKiwiSaverAction = () =>
    isAdult(user.birthDate) && !props.hasOwnKiwiSaver

  const showManagedFundsAction = () =>
    isAdult(user.birthDate) &&
    props.hasOwnKiwiSaver &&
    !props.hasManagedFunds &&
    !props.hasInvestmentFunds

  return (
    <>
      {showKiwiSaverAction() && <KiwiSaverAction user={user} />}
      {showManagedFundsAction() && <ManagedFundsAction user={user} />}
    </>
  )
}

export default NextBestAction
