import './ConfirmDOB.scss'

import Alert from 'react-s-alert'

import { getDobError } from '../../../common/forms-helper'

import { Dispatch, bindActionCreators } from 'redux'
import { AppState } from '../../../redux/app-state'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import {
  OnboardingActions,
  OnboardingConfirmDobAction,
} from '../../../redux/onboarding/onboarding.actions'

import { useForm } from 'react-hook-form'
import DobInput from '../../../components/dob-input/DobInput'

interface ConfirmDobProps {
  onboarding: {
    hash: string
    isDobConfirmed: boolean
    isLoading: boolean
    errorMessage: string
  }

  actions: {
    nextStep: (path: string) => void
    confirmDob: typeof OnboardingConfirmDobAction
  }
}

export function ConfirmDOBForm(props: ConfirmDobProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const { onboarding, actions } = props

  const onSubmitEvent = (data: any) => {
    if (!data.dob) {
      return
    }

    Alert.closeAll()

    actions.confirmDob({
      dateOfBirth: data.dob,
      hash: props.onboarding.hash,
    })
  }

  // redirect or display alert if there is an error message
  if (props.onboarding.isDobConfirmed && props.onboarding.hash) {
    props.actions.nextStep(
      '/onboard/create-username?q=' + props.onboarding.hash
    )
  } else if (props.onboarding.errorMessage && !props.onboarding.isLoading) {
    Alert.error(props.onboarding.errorMessage)
  }

  let dobErrorMessage =
    errors?.dob && getDobError(errors, errors?.dob?.ref?.value)

  return (
    <form
      onSubmit={handleSubmit(onSubmitEvent)}
      className="form onboarding-confirm-dob"
    >
      <Typography component="p" variant="body1">
        Step 1 of 3
      </Typography>
      <Typography variant="h6">Please confirm your date of birth.</Typography>
      <div className="fieldGroup login-field-group">
        <Typography component="p" variant="body1">
          Date of birth
        </Typography>
        <DobInput
          name={'dob'}
          register={register}
          setValue={setValue}
          helperText={dobErrorMessage}
          error={!!dobErrorMessage}
          margin="normal"
          fullWidth
        />
      </div>
      <div className="action-group">
        <Button
          disableRipple
          disabled={onboarding && onboarding.isLoading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {onboarding && onboarding.isLoading ? 'Confirming...' : 'Next'}
        </Button>
      </div>
    </form>
  )
}

export default connect(
  (state: AppState) => ({
    onboarding: {
      hash: state.routing.locationBeforeTransitions.query.q,
      isDobConfirmed: state.onboarding.hasBeenConfirmedByDob,
      errorMessage: state.onboarding.errorMessage,
      isLoading: state.onboarding.isLoading,
    },
  }),
  (dispatch: Dispatch<OnboardingActions>) => {
    return {
      actions: {
        confirmDob: bindActionCreators(OnboardingConfirmDobAction, dispatch),
        nextStep: (path: string) => dispatch(routerActions.push(path)),
      },
    }
  },
  null
)(ConfirmDOBForm)
