import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'

import {
  actionTypes,
  UserActions,
  UserChangePasswordActionType,
  UserChangePasswordSuccessAction,
  UserChangePasswordFailureAction,
  UserRequestConfirmCodeActionType,
  UserRequestConfirmCodeSuccessAction,
  UserRequestConfirmCodeFailureAction,
  UserCheckConfirmCodeActionType,
  UserCheckConfirmCodeSuccessAction,
  UserCheckConfirmCodeFailureAction,
  UsernameConfirmationActionType,
  UsernameConfirmationSuccessAction,
  UsernameConfirmationFailureAction,
  UserResetActionType,
  UserResetSuccessAction,
  UserResetFailureAction,
  UserRequestDetailsSuccessAction,
  UserRequestDetailsFailureAction,
  UserRequestDetailsActionType,
  UserUpdateDetailsActionType,
  UserUpdateDetailsSuccessAction,
  UserUpdateDetailsFailureAction,
} from './user.actions'
import { UserState } from './user.model'

import {
  AuthorisationRequestAction,
  AuthorisationRequestActionType,
} from '../authorisation/authorisation.actions'
import { AuthorisationState } from '../authorisation/authorisation.model'

type LightStore = { getState: Function; dispatch: Function }

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

export const changePasswordEpic: Epic<UserActions, UserState> = (
  action$: ActionsObservable<UserChangePasswordActionType>,
  store: LightStore
) =>
  action$.ofType(actionTypes.USER_CHANGE_PASSWORD).switchMap((action) =>
    ajax
      .put(
        ffmAppServerUrl + '/api/users/' + action.payload.userid + '/password',
        {
          currentPassword: encodeURIComponent(action.payload.currentPassword),
          newPassword: encodeURIComponent(action.payload.newPassword),
        },
        {
          Authorization:
            'Bearer ' + store.getState().authorisation.authorisationToken,
        }
      )
      .map(() => UserChangePasswordSuccessAction())
      .catch((err) => {
        return Observable.of(
          UserChangePasswordFailureAction(
            err.response.message || err.response.error
          )
        )
      })
  )

export const requestConfirmCodeEpic: Epic<UserActions, UserState> = (
  action$: ActionsObservable<UserRequestConfirmCodeActionType>
) =>
  action$.ofType(actionTypes.USER_REQUEST_CONFIRM_CODE).switchMap((action) =>
    ajax
      .post(ffmAppServerUrl + '/api/password/reset', {
        ...action.payload,
      })
      .map((response) =>
        UserRequestConfirmCodeSuccessAction(
          response.response.id,
          response.response.type
        )
      )
      .catch(() => {
        return Observable.of(
          UserRequestConfirmCodeFailureAction(
            'There was a problem sending your confirmation code. Please try again later.'
          )
        )
      })
  )

export const checkConfirmCodeEpic: Epic<UserActions, UserState> = (
  action$: ActionsObservable<UserCheckConfirmCodeActionType>
) =>
  action$.ofType(actionTypes.USER_CHECK_CONFIRM_CODE).switchMap((action) =>
    ajax
      .get(
        `${ffmAppServerUrl}/api/password/reset/${action.payload.id}?code=${action.payload.confirmCode}`
      )
      .map((response) =>
        UserCheckConfirmCodeSuccessAction(response.response.username)
      )
      .catch((err) => {
        return Observable.of(
          UserCheckConfirmCodeFailureAction(
            err.response.message || err.response.error
          )
        )
      })
  )

export const usernameConfirmationEpic: Epic<UserActions, UserState> = (
  action$: ActionsObservable<UsernameConfirmationActionType>
) =>
  action$.ofType(actionTypes.USERNAME_CONFIRMATION).switchMap((action) => {
    return ajax
      .post(`${ffmAppServerUrl}/api/users/forgot`, {
        ...action.payload,
      })
      .map((response) => {
        return UsernameConfirmationSuccessAction(
          response.response.mobilePhoneNumber
        )
      })
      .catch((err) => {
        return Observable.of(
          UsernameConfirmationFailureAction(
            `Sorry, we don't recognise that username. Please <a href="http://fisherfunds.co.nz/contact">contact us</a> to confirm we have your correct details.`
          )
        )
      })
  })

export const resetPasswordEpic: Epic<
  UserActions | AuthorisationRequestActionType,
  UserState | AuthorisationState
> = (action$: ActionsObservable<UserResetActionType>, store: LightStore) =>
  action$.ofType(actionTypes.USER_RESET).switchMap((action) =>
    ajax
      .put(`${ffmAppServerUrl}/api/password/reset/${action.payload.id}`, {
        code: action.payload.confirmCode,
        newValue: encodeURIComponent(action.payload.newValue),
      })
      .mergeMap((response) => {
        // Otherwise just log the user in with username/password
        return Observable.concat(
          // Show the loading spinner
          Observable.of(UserResetSuccessAction()),
          Observable.of(
            AuthorisationRequestAction({
              username: store.getState().resetPassword.username,
              password: store.getState().resetPassword.newValue,
            })
          )
        )
      })
      .catch((err) => {
        return Observable.of(
          UserResetFailureAction(err.response.message || err.response.error)
        )
      })
  )

export const requestDetailsEpic: Epic<UserActions, UserState> = (
  action$: ActionsObservable<UserRequestDetailsActionType>,
  store: LightStore
) =>
  action$.ofType(actionTypes.USER_REQUEST_DETAILS).switchMap((action) =>
    ajax
      .get(`${ffmAppServerUrl}/api/users/${action.payload}`, {
        Authorization:
          'Bearer ' + store.getState().authorisation.authorisationToken,
      })
      .map((response) => UserRequestDetailsSuccessAction(response.response))
      .catch(() =>
        Observable.of(
          UserRequestDetailsFailureAction(
            'Error collecting your Personal Details. Please try again later.'
          )
        )
      )
  )

export const updateDetailsEpic: Epic<UserActions, UserState> = (
  action$: ActionsObservable<UserUpdateDetailsActionType>,
  store: LightStore
) =>
  action$.ofType(actionTypes.USER_UPDATE_DETAILS).switchMap((action) => {
    const state = store.getState()
    const payload = action.payload
    return ajax
      .put(`${ffmAppServerUrl}/api/users/${action.ffmUserId}`, payload, {
        Authorization: 'Bearer ' + state.authorisation.authorisationToken,
      })
      .map(() => UserUpdateDetailsSuccessAction(payload))
      .catch(() =>
        Observable.of(
          UserUpdateDetailsFailureAction(
            'We were unable to update your details. Please try again.'
          )
        )
      )
  })
