import React from 'react'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import useOpenJoinApp from '../../hooks/use-open-join-app'
import { UserData } from '../../redux/user/user.model'

type ActionProps = {
  user: UserData
}

export const KiwiSaverAction = ({ user }: ActionProps) => {
  const { openKiwiSaverJoinApp } = useOpenJoinApp(user)

  return (
    <NextBestAction
      productClass="kiwisaver"
      onClickJoin={openKiwiSaverJoinApp}
      header="Join or Switch to the Fisher Funds KiwiSaver Scheme in Minutes"
      body="Fisher Funds Management Limited is the issuer of the Fisher Funds
            KiwiSaver Scheme."
      pdsLink="https://fisherfunds.co.nz/investment/kiwisaver"
    />
  )
}

export const ManagedFundsAction = ({ user }: ActionProps) => {
  const { openManagedFundsJoinApp } = useOpenJoinApp(user)
  return (
    <NextBestAction
      productClass="managed-funds"
      onClickJoin={openManagedFundsJoinApp}
      header="Looking for a better way to save?"
      body="Have you thought of starting a managed fund to get more out of your
            money than having it in the bank? Start from as little as $100 a
            month."
      pdsLink="https://fisherfunds.co.nz/investment/managed-funds"
    />
  )
}

type NextBestActionProps = {
  productClass: string
  onClickJoin: () => void
  header: string
  body: string
  pdsLink: string
}

const NextBestAction = ({
  productClass,
  onClickJoin,
  header,
  body,
  pdsLink,
}: NextBestActionProps) => {
  return (
    <Container maxWidth={false} className="next-best-action-component">
      <Paper className="has-wing-motif" elevation={0}>
        <div className={'action__wing-motif ' + productClass}>
          <div className="top-wing"></div>
          <div className="bottom-wing"></div>
        </div>
        <div className="action__content">
          <Typography component="h6" variant="h6" className="title">
            {header}
          </Typography>
          <Typography component="p" variant="body1" className="description">
            {body}
          </Typography>
          <div className="button-wrapper">
            <Button
              disableRipple
              variant="contained"
              color="primary"
              onClick={onClickJoin}
            >
              {productClass === 'kiwisaver' ? 'Join or switch now' : 'Join now'}
            </Button>
            <a href={pdsLink} target="_blank" rel="noreferrer noopener">
              Learn more or view the PDS here
            </a>
          </div>
        </div>
      </Paper>
    </Container>
  )
}
