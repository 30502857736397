import { createMuiTheme } from '@material-ui/core/styles'
import overrides from './mui-overrides'

const MuiTheme = createMuiTheme({
  palette: {
    common: {
      black: '#333',
    },
    primary: {
      main: '#00447C',
    },
    secondary: {
      main: '#00ae9e',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Graphik", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: '2.5em',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2.25em',
      fontWeight: 300,
    },
    h3: {
      fontSize: '2em',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.5em',
      fontWeight: 300,
    },
    h5: {
      color: '#00447c',
      fontSize: '1.25em',
      fontWeight: 600,
      lineHeight: 1,
    },
    h6: {
      color: '#00447c',
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 1,
    },
    body2: {
      fontSize: '1em',
      fontWeight: 300,
    },
  },
  overrides,
})

export default MuiTheme
