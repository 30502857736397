import { Link } from 'react-router'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import Card from '@material-ui/core/Card'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as IconRelatedAccount } from '../../assets/images/icons/icon_related_account.svg'
import { Account } from '../../redux/accounts/accounts.model'
import { GovernmentContribution } from './GovernmentContribution'
import { Divider, Grid, IconButton } from '@material-ui/core'
import { AppState } from '../../redux/app-state'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import { Dispatch, bindActionCreators } from 'redux'
import {
  AccountDetailsActions,
  AccountDetailsRequestAction,
} from '../../redux/account-details/account-details.actions'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import CurrencyDifference from '../currency/CurrencyDifference'
import CurrencyTotal from '../currency/CurrencyTotal'
import { getAccountProductSlug } from '../../common/product-helper'
import RenameAccountModal from '../rename-account/RenameAccountModal'
import { isManagedFundProductAccount } from '../../common/accounts-helper'

declare global {
  interface Window {
    joiningApp: Window
  }
}

interface AccountCardProps {
  userId: string
  account: Account
  authToken?: string
  marketEarnings?: number
  getAccountDetails?: (accountId: string, userId: string) => void
}

const ENABLE_RENAME = process.env.REACT_APP_ENABLE_RENAME_ACCOUNT === 'true'

const AccountCard = (props: AccountCardProps) => {
  const {
    userId,
    account,
    authToken,
    marketEarnings,
    getAccountDetails,
  } = props

  const path: string = `/accounts/${account.accountNumber}`
  const {
    governmentContributionDetail,
    commenceDate,
    kiwiSaverOriginalStart,
  } = account

  const [renameModalOpen, setRenameModalOpen] = useState(false)

  useEffect(() => {
    if (account.status !== 'pending') {
      getAccountDetails(account.accountID, userId)
    }
  }, [userId, account, getAccountDetails])

  return (
    <div className="account-card">
      {renameModalOpen && (
        <RenameAccountModal
          userId={userId}
          account={account}
          authToken={authToken}
          onClose={() => setRenameModalOpen(false)}
        />
      )}
      <Link to={path} title={account.accountMailingName}>
        <div
          className={
            'account-card__content account-' +
            (getAccountProductSlug(account) || 'default')
          }
        >
          <div className="account-card__header">
            <div className="account-card__header__details">
              <div className="account-card__header__name">
                <Typography>{account.accountMailingName}</Typography>
                {ENABLE_RENAME && isManagedFundProductAccount(account) && (
                  <IconButton
                    className="account-card__header__button"
                    color="inherit"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setRenameModalOpen(true)
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>
              <Typography className="account-card__header__number">
                {account.accountNumber}
              </Typography>
            </div>
            <div className="account-card__header__type">
              {!account.isOwner && (
                <Tooltip
                  arrow
                  disableFocusListener
                  disableTouchListener
                  placement="top"
                  title={'Related account'.toUpperCase()}
                >
                  <span className="type-icon">
                    <IconRelatedAccount />
                  </span>
                </Tooltip>
              )}
              <Typography className="account-card__header__product">
                {account.simpleProductName}
              </Typography>
            </div>
          </div>
          <Card elevation={0}>
            <Typography className="account-card__total">
              <CurrencyTotal value={account.accountTotal} />
            </Typography>
            <Typography className="account-card__total-label">
              {account.latestPriceDate
                ? 'as at ' +
                  moment(account.latestPriceDate).format('DD MMM YYYY')
                : 'at most recent valuation'}
            </Typography>
            <Divider />
            <Grid container className="account-card__returns">
              {!account.isDepositMode && (
                <>
                  <Grid item>
                    <Typography className="account-card__returns__label">
                      Since inception return
                    </Typography>
                    {isNumber(marketEarnings) ? (
                      <CurrencyDifference value={marketEarnings} />
                    ) : (
                      <div className="account-card__returns__loading-shimmer" />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography>After fees and tax</Typography>
                    {!!account.commenceDate && (
                      <Typography>
                        {'since ' +
                          moment(account.commenceDate).format('DD MMM YYYY')}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Card>
        </div>

        {governmentContributionDetail && (
          <GovernmentContribution
            governmentContributionDetail={governmentContributionDetail}
            commenceDate={commenceDate}
            kiwiSaverOriginalStart={kiwiSaverOriginalStart}
          />
        )}
      </Link>
    </div>
  )
}

const mapStateToProps = (state: AppState, props: AccountCardProps) => {
  const accountId = props.account.accountID.toString()
  const cachedAccountDetails = state.accountDetails.accounts.find(
    (acc: AccountDetail) => acc.accountID === accountId
  )

  return {
    authToken: state.authorisation.authorisationToken,
    marketEarnings: cachedAccountDetails?.marketEarnings,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountDetailsActions>) => ({
  getAccountDetails: bindActionCreators(AccountDetailsRequestAction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(AccountCard)
