import cn from 'classnames'
import kebabCase from 'lodash/kebabCase'
import './RetirementProjectorFundOption.scss'
import Radio from '../../common/forms/Radio'
import { Fund } from '../../components/switch-contentful-modal/SwitchContentfulModal.types'
import { Button } from '../../components/clickable/button/Button'
import CallMadeIcon from '@material-ui/icons/CallMade'

export interface RetirementProjectorFundOptionProps {
  fund: Fund
  isCurrent?: boolean
  onSelect: () => void
  onMoreInfoClick: (fund: Fund) => void
  selected: boolean
  error?: boolean
}

export const RetirementProjectorFundOption = ({
  fund,
  isCurrent = false,
  onSelect,
  onMoreInfoClick,
  selected,
  error,
}: RetirementProjectorFundOptionProps) => {
  const simplerName = fund.shortName.replace(/fund/i, '').trim()
  return (
    <label
      className={cn('retirement-projector__fund-option', {
        'retirement-projector__fund-option--error': error,
      })}
      htmlFor={`${kebabCase(fund.shortName)}-fund-option`}
    >
      <Radio
        id={`${kebabCase(fund.shortName)}-fund-option`}
        name="fund-option"
        border="gray"
        size="small"
        label={fund.shortName}
        checked={selected}
        onChange={onSelect}
      />
      <h6>{simplerName}</h6>
      {isCurrent && <span> &nbsp;(Current) </span>}
      <Button
        variant="link"
        size="sm"
        iconRight={CallMadeIcon}
        onClick={() => {
          onMoreInfoClick(fund)
        }}
      >
        More info
      </Button>
    </label>
  )
}
