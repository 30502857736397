import { datadogRum } from '@datadog/browser-rum'

const env = process.env.REACT_APP_DATADOG_ENV

export const initDatadog = () => {
  if (!!env) {
    datadogRum.init({
      applicationId: 'a02dba0f-8a34-4331-b326-a9e14c53488a',
      clientToken: 'pub90549e8f6fc900b4cbea5e9f29449dfd',
      site: 'datadoghq.com',
      service: 'ffo-portal-frontend',
      env,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
  }
}

export type UserInfo = {
  email?: string
  name?: string
  id?: string
}

export const setDatadogUser = (userInfo: UserInfo) => {
  datadogRum.setUser(userInfo)
}
