import { Observable } from 'rxjs/Observable'
import { ActionsObservable, Epic } from 'redux-observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import { UsernameRecoveryState } from './username-recovery.model'
import {
  actionTypes,
  UsernameRecoveryActions,
  UsernameRecoverySubmitActionType,
  UsernameRecoverySubmitFailureAction,
  UsernameRecoverySubmitSuccessAction,
  UsernameRecoveryVerificationActionType,
  UsernameRecoveryVerificationSuccessAction,
  UsernameRecoveryVerificationFailureAction,
} from './username-recovery.actions'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

export const usernameRecoverySubmitEpic: Epic<
  UsernameRecoveryActions,
  UsernameRecoveryState
> = (action$: ActionsObservable<UsernameRecoverySubmitActionType>) =>
  action$.ofType(actionTypes.USERNAME_RECOVERY_SUBMIT).switchMap((action) => {
    return ajax
      .post(`${ffmAppServerUrl}/api/username/recovery`, {
        firstname: action.payload.credentials.firstName,
        surname: action.payload.credentials.surname,
        email: action.payload.credentials.email,
        dateOfBirth: action.payload.credentials.dateOfBirth,
        method: action.payload.method,
      })
      .map(() => UsernameRecoverySubmitSuccessAction())
      .catch((err) => {
        // Only return error for 500, otherwise anonymise response
        const message =
          err.status === 500 ? 'Search request failed' : err.response.message
        return Observable.of(UsernameRecoverySubmitFailureAction(message))
      })
  })

export const usernameRecoveryVerificationEpic: Epic<
  UsernameRecoveryActions,
  UsernameRecoveryState
> = (action$: ActionsObservable<UsernameRecoveryVerificationActionType>) =>
  action$
    .ofType(actionTypes.USERNAME_RECOVERY_VERIFICATION)
    .switchMap((action) => {
      return ajax
        .post(`${ffmAppServerUrl}/api/username/recovery/verification`, {
          firstname: action.payload.credentials.firstName,
          surname: action.payload.credentials.surname,
          email: action.payload.credentials.email,
          dateOfBirth: action.payload.credentials.dateOfBirth,
        })
        .map((response) =>
          UsernameRecoveryVerificationSuccessAction({
            ...action.payload,
            mobilePhoneNumber: response.response.mobilePhoneNumber,
          })
        )
        .catch((err) => {
          const defaultErrorMessage = 'Search request failed'
          if (err && err.hasOwnProperty('status') && err.status < 500) {
            return Observable.of(
              UsernameRecoveryVerificationFailureAction({
                errorStatus: err.status,
              })
            )
          }
          return Observable.of(
            UsernameRecoveryVerificationFailureAction({
              errorMessage: defaultErrorMessage,
            })
          )
        })
    })
