import InvestmentMixAssets, {
  Asset,
} from '../investment-mix/InvestmentMixAssets'
import { StrategyFundAllocation } from '../switch-contentful-modal/SwitchContentfulModal.types'

const mapAllocationToAsset = ({
  fund,
  allocationPercentage,
}: StrategyFundAllocation): Asset => ({
  name: fund.shortName,
  percentage: allocationPercentage,
})

export type CustomInvestmentProps = {
  isCustom?: boolean
  allocations: StrategyFundAllocation[]
  futureAllocations?: StrategyFundAllocation[]
}
export type CustomInvestmentOptionDetailsProps = {
  investmentOption: CustomInvestmentProps
  isCurrent?: boolean
  isApexProduct?: boolean
}

const CustomInvestmentOptionDetails = ({
  investmentOption: { allocations, futureAllocations },
  isCurrent,
  isApexProduct,
}: CustomInvestmentOptionDetailsProps) => {
  const hasFutureAllocations = futureAllocations?.length
  const apexFutureAllocation = () =>
    `Your ${isCurrent ? 'current' : 'new'} mix of funds`

  return (
    <div className="allocation-details">
      {!isApexProduct && !!allocations?.length && (
        <>
          <p className="mt-0" data-test="current-allocations">
            {hasFutureAllocations
              ? 'Your current allocations'
              : 'Your allocations'}
          </p>
          <InvestmentMixAssets assets={allocations.map(mapAllocationToAsset)} />
        </>
      )}
      {!!hasFutureAllocations && (
        <>
          <p data-test="future-allocations">
            {isApexProduct ? apexFutureAllocation() : 'Your future allocations'}
          </p>
          <InvestmentMixAssets
            assets={futureAllocations.map(mapAllocationToAsset)}
          />
        </>
      )}
    </div>
  )
}

export default CustomInvestmentOptionDetails
