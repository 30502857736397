import Modal from '../modal/Modal'
import { Account } from '../../redux/accounts/accounts.model'
import { getProductPdsUrl } from '../../common/product-helper'

export type TermsModalProps = {
  onClose: () => void
  account: Account
}

const TermsModalApex = (props: TermsModalProps) => (
  <Modal
    title="Terms and conditions"
    open
    className="switch-sub-modal"
    onClose={props.onClose}
  >
    <div>
      <p>
        I hereby request Fisher Funds to direct future contributions to and
        change my current fund(s) in accordance with my instructions in this
        form and declare that:
      </p>
      <ol>
        <li>I am authorised to make investment decisions for this account</li>
        <li>
          {' '}
          I have read the current{' '}
          <a
            href={getProductPdsUrl(props.account)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.account.productExternalName} Product Disclosure Statement
          </a>
        </li>
        <li>
          I understand that it may take up to three working days for you to
          complete changing my funds(s)
        </li>
        <li>
          I understand that changing my fund(s) requires you to "sell"
          investments from my current fund(s) and "buy" investments in my chosen
          fund(s) and that any fees or taxes may be deducted from my account.
        </li>
      </ol>
    </div>
  </Modal>
)

export default TermsModalApex
