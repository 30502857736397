import { AnyAction, Reducer } from 'redux'
import { getDefaultState, setFailState } from '../state/state.model'
import { OnboardingState } from './onboarding.model'
import { actionTypes } from './onboarding.actions'

export const initialState: OnboardingState = {
  hash: null,
  hasBeenConfirmedByDob: false,
  dateOfBirth: null,
  username: null,
  password: null,
  isValid: false,
  canBeOnboarded: false,
  preferPin: false, // whether the user would like to set a pin after onboarding
  ...getDefaultState(),
}

export const onboardingingReducer: Reducer<OnboardingState> = (
  state: OnboardingState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ONBOARDING_CONFIRM_DOB:
      return {
        ...state,
        ...getDefaultState(),
        isLoading: true,
        hasBeenConfirmedByDob: false,
      }
    case actionTypes.ONBOARDING_CONFIRM_DOB_SUCCESS:
      return {
        ...state,
        hasBeenConfirmedByDob: true,
        dateOfBirth: action.dateOfBirth,
        ...getDefaultState(),
      }
    case actionTypes.ONBOARDING_CONFIRM_DOB_FAILURE:
      return {
        ...state,
        hasBeenConfirmedByDob: false,
        ...setFailState(action.payload),
      }

    case actionTypes.ONBOARDING_CHECK_USERNAME:
      return { ...state, ...getDefaultState(), isLoading: true }
    case actionTypes.ONBOARDING_CHECK_USERNAME_SUCCESS:
      return { ...state, username: action.username, ...getDefaultState() }
    case actionTypes.ONBOARDING_CHECK_USERNAME_FAILURE:
      return { ...state, ...setFailState(action.payload) }

    case actionTypes.ONBOARDING_CREATE_CREDENTIALS:
      return { ...state, ...getDefaultState(), isLoading: true }
    case actionTypes.ONBOARDING_CREATE_CREDENTIALS_SUCCESS:
      const credentialState = action.payload
      return { ...state, ...credentialState, ...getDefaultState() }
    case actionTypes.ONBOARDING_CREATE_CREDENTIALS_FAILURE:
      return {
        ...state,
        ...setFailState(action.payload),
      }

    case actionTypes.ONBOARDING_REQUEST:
      return { ...state, ...getDefaultState(), isLoading: true }
    case actionTypes.ONBOARDING_REQUEST_SUCCESS:
      return {
        ...state,
        ...getDefaultState(),
        isValid: true,
        hash: action.payload,
      }
    case actionTypes.ONBOARDING_REQUEST_FAILURE:
      return { ...state, ...setFailState(action.payload), isValid: false }

    case actionTypes.ONBOARDING_SEARCH:
      return {
        ...state,
        ...getDefaultState(),
        isLoading: true,
        canBeOnboarded: false,
      }
    case actionTypes.ONBOARDING_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        canBeOnboarded: true,
        hasError: false,
        errorMessage: null,
        errorStatus: null,
      }
    case actionTypes.ONBOARDING_SEARCH_FAILURE:
      return {
        ...state,
        ...setFailState(
          action.payload.errorMessage,
          action.payload.errorStatus
        ),
        canBeOnboarded: false,
      }

    case actionTypes.ONBOARDING_CLEAR_CREDENTIALS:
      return { ...state, username: null, password: null }

    case actionTypes.ONBOARDING_CLEAR_STATE:
      return { ...initialState }
    case actionTypes.ONBOARDING_CLEAR_ERROR_MESSAGE:
      return { ...state, errorMessage: null }

    default:
      return state
  }
}
