import React from 'react'
import cn from 'classnames'

import './StrategyRiskIndicator.scss'

export type StrategyRiskIndicatorProps = {
  className?: string
  minimum: number
  maximum: number
  value: number
  size?: 'small' | 'regular' | 'large'
}
export const StrategyRiskIndicator = (props: StrategyRiskIndicatorProps) => {
  const { className, minimum, maximum, value, size = 'regular' } = props
  const isMinimum = value === minimum
  const isMaximum = value === maximum
  return (
    <div
      className={cn(
        'strategy-risk-indicator',
        `strategy-risk-indicator--${size}`,
        {
          [`${className}`]: !!className,
        }
      )}
    >
      <div className="strategy-risk-indicator__bar" />

      <div
        className={cn('strategy-risk-indicator__minimum', {
          'strategy-risk-indicator__minimum--highlighted': isMinimum,
        })}
      >
        {minimum}
      </div>

      {!isMinimum && !isMaximum && (
        <div
          className="strategy-risk-indicator__value-container"
          style={{
            marginLeft: `calc(${
              ((value - minimum + 0.5) / (maximum - minimum + 1)) * 100
            }%`, // value size
          }}
        >
          <div className="strategy-risk-indicator__value">{value}</div>
        </div>
      )}

      <div
        className={cn('strategy-risk-indicator__maximum', {
          'strategy-risk-indicator__minimum--highlighted': isMaximum,
        })}
      >
        {maximum}
      </div>
    </div>
  )
}
