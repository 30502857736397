const downloadFile = (blob: Blob, fileName: string) => {
  if (navigator.msSaveBlob) {
    // IE browser
    window.navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    // Non IE browser
    const a = document.createElement('a')
    a.textContent = 'download'
    a.download = fileName
    a.href = URL.createObjectURL(blob)
    a.dispatchEvent(new MouseEvent('click', {}))
  }
}

export default downloadFile
