import { useState } from 'react'
import Alert from 'react-s-alert'
import SiteToast from '../site-toast/SiteToast'
import './FFMToast.scss'

interface FFMAlertProps {
  stackLimit?: number
}

const FFMToast = (props: FFMAlertProps) => {
  const { stackLimit = 1 } = props
  const [activeState, setActiveState] = useState(false)

  return (
    <div id="ffm-alert" className={activeState ? 'ffm-alert__active' : ''}>
      <Alert
        beep={false}
        contentTemplate={SiteToast}
        html={true}
        onShow={() => {
          setActiveState(true)
        }}
        onClose={() => {
          setActiveState(false)
        }}
        stack={{ limit: stackLimit }}
        timeout={10000}
      />
    </div>
  )
}

export default FFMToast
