import { useState } from 'react'

const useToggle = (initialState: boolean) => {
  const [toggle, setToggle] = useState(initialState)

  const toggleOn = () => setToggle(true)
  const toggleOff = () => setToggle(false)

  return { toggle, toggleOn, toggleOff }
}

export default useToggle
