import { Strategy } from '../components/switch-contentful-modal/SwitchContentfulModal.types'
import { AssetAllocation } from './investmentOption'

import glidePathDataFFKS from '../common/data/glidepath/kiwisaver.json'
import glidePathDataFFTWO from '../common/data/glidepath/fftwo.json'
import { Product } from '../common/product-helper'
import { ProductSlug } from '../common/product-variables'

export const buildAssets = (strategy: Strategy) => {
  let incomeAssets: AssetAllocation[] = []
  let growthAssets: AssetAllocation[] = []

  if (!strategy.ageRelatedStrategy && !strategy.customStrategy) {
    if (strategy.strategyFundAllocations.length > 1) {
      const incomeAssetsAllocationMap = new Map<string, AssetAllocation>()
      const growthAssetsAllocationMap = new Map<string, AssetAllocation>()

      strategy.strategyFundAllocations.forEach((strategyFundAllocation) => {
        const fundPercentage = strategyFundAllocation.allocationPercentage

        if (strategyFundAllocation.fund.incomeAssets) {
          strategyFundAllocation.fund.incomeAssets.forEach(
            (assetAllocation) => {
              const currentAllocation = incomeAssetsAllocationMap.get(
                assetAllocation.asset.name
              )
              if (currentAllocation) {
                incomeAssetsAllocationMap.set(assetAllocation.asset.name, {
                  asset: assetAllocation.asset,
                  allocationPercentage:
                    currentAllocation.allocationPercentage +
                    assetAllocation.allocationPercentage * fundPercentage,
                })
              } else {
                incomeAssetsAllocationMap.set(assetAllocation.asset.name, {
                  asset: assetAllocation.asset,
                  allocationPercentage:
                    assetAllocation.allocationPercentage * fundPercentage,
                })
              }
            }
          )
        }

        if (strategyFundAllocation.fund.growthAssets) {
          strategyFundAllocation.fund.growthAssets.forEach(
            (assetAllocation) => {
              const currentAllocation = growthAssetsAllocationMap.get(
                assetAllocation.asset.name
              )
              if (currentAllocation) {
                growthAssetsAllocationMap.set(assetAllocation.asset.name, {
                  asset: assetAllocation.asset,
                  allocationPercentage:
                    currentAllocation.allocationPercentage +
                    assetAllocation.allocationPercentage * fundPercentage,
                })
              } else {
                growthAssetsAllocationMap.set(assetAllocation.asset.name, {
                  asset: assetAllocation.asset,
                  allocationPercentage:
                    assetAllocation.allocationPercentage * fundPercentage,
                })
              }
            }
          )
        }
      })

      incomeAssets = Array.from(incomeAssetsAllocationMap.values()).map(
        (assetAllocation) => ({
          ...assetAllocation,
          allocationPercentage: parseFloat(
            (assetAllocation.allocationPercentage / 100).toFixed(2)
          ),
        })
      )
      growthAssets = Array.from(growthAssetsAllocationMap.values()).map(
        (assetAllocation) => ({
          ...assetAllocation,
          allocationPercentage: parseFloat(
            (assetAllocation.allocationPercentage / 100).toFixed(2)
          ),
        })
      )
    } else {
      incomeAssets = strategy.strategyFundAllocations[0].fund.incomeAssets
      growthAssets = strategy.strategyFundAllocations[0].fund.growthAssets
    }
  }

  return {
    incomeAssets,
    growthAssets,
  }
}

const GLIDEPATH_REGEX = /glidepath/i
export const containsGlidePath = (s: string) => GLIDEPATH_REGEX.test(s)

export const STRATEGY_SALESFORCECODE = {
  CUSTOM: 'Custom',
  GLIDEPATH: 'GlidePath',
}

export const isGlidePath = (strategy: Strategy) => {
  if (!strategy) {
    return false
  }
  return (
    strategy.salesforceCode === STRATEGY_SALESFORCECODE.GLIDEPATH ||
    containsGlidePath(strategy.shortName)
  )
}

export const getGlidePathData = (product: Product) => {
  if (![ProductSlug.FFKS, ProductSlug.FF_TWO].includes(product.slug)) {
    return null
  }
  return product.slug === ProductSlug.FFKS
    ? glidePathDataFFKS
    : glidePathDataFFTWO
}
