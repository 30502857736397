import { ChartOptions } from 'chart.js'
import {
  BalancePeriod,
  GraphConfigurationType,
} from '../redux/account-balance/account-balance.model'

export const GRAPH_COLORS = {
  SMOKE: '#646464',
  MIDNIGHT: '#132438',
  DUSK: '#218094',
  SKY: '#B8F2EB',
  TEXT: '#4F6B7E',
  LINE: '#C5C5C5',
}

export const commonAxisProps = {
  tickLine: false,
  axisLine: { stroke: GRAPH_COLORS.LINE },
  label: { fill: GRAPH_COLORS.SMOKE },
}

export interface Payload {
  name: string
  stroke: string
  value: number
  dataKey: string
}

const yAxisIntervals = [
  1000000,
  500000,
  250000,
  100000,
  50000,
  25000,
  10000,
  5000,
  2500,
  1000,
  500,
  250,
  100,
  50,
]

export const getYInterval = (min: number, max: number) => {
  const range = max - min
  const interval = yAxisIntervals.find((interval) => range / interval >= 3)

  return interval || yAxisIntervals[yAxisIntervals.length - 1]
}

export const getYAxisWidth = (yAxisMax: number) => {
  if (yAxisMax.toString().length >= 5) {
    return 95
  }

  return 70
}

/* 
We want to take 6 evenly spaced points from our data to display on the x axis, we do this by
mapping the index of each period to point between 0 - 5 and finding the closest to an interval.

For example, if we have 15 periods, we want to return 6 dates, so we divide the index by 14 (15 - 1) to get a number between 0 - 1.
We then multiply this by 6 to get a number between 0 - 6 to map where this point would lie between our 6 middle points.
We then compare the difference between this number and the previous number, if it is less than the previous difference we 
are approaching a interval and move to the next point. If greater, we have passed an interval and add the previous date to the array.

We then add the last date to the array and return it.
*/
export const getXIntervals = (dataPoints: BalancePeriod[]) => {
  if (dataPoints.length <= 1) {
    return dataPoints.map((dataPoint) => dataPoint.toDate)
  }

  let xIntervals = []
  const NUMBER_OF_INTERVALS = 6

  let difference = 1.0

  for (let i = 0; i < dataPoints.length; i++) {
    const mappedIndex = (i / (dataPoints.length - 1)) * NUMBER_OF_INTERVALS
    const newDifference = Math.abs(mappedIndex - xIntervals.length)

    if (newDifference < difference) {
      difference = newDifference
    } else {
      xIntervals.push(dataPoints[i - 1].toDate)
      difference = 1.0
    }
  }

  xIntervals.push(dataPoints[dataPoints.length - 1].toDate)

  return xIntervals
}

export const getGraphConfigurationTypeDateFormat = (
  graphConfigurationType: GraphConfigurationType
) => {
  switch (graphConfigurationType) {
    case GraphConfigurationType.DAILY:
      return 'DD MMM'
    case GraphConfigurationType.MONTHLY:
      return 'MMM YY'
  }
}

const noGridAxesOptions = {
  gridLines: {
    display: false,
    drawTicks: false,
  },
  ticks: {
    display: false,
  },
}

export const horizontalBarChartOptions: ChartOptions = {
  tooltips: {
    enabled: false,
  },
  hover: { mode: null as any },
  responsive: true,
  maintainAspectRatio: true,
  animation: {
    duration: 1000,
  },
  layout: {
    padding: 0,
  },
  showLines: false,
  scales: {
    display: false,
    xAxes: [
      {
        ...noGridAxesOptions,
        stacked: true,
      },
    ],
    yAxes: [noGridAxesOptions],
  },
}

export const CHART_COLOURS = [
  '#192447',
  '#003D68',
  '#2A5966',
  '#228093',
  '#64CEB3',
  '#B3E5E4',
  '#CCCCCC',
]
