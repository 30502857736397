import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { bindActionCreators } from 'redux'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'
import { Dispatch, connect } from 'react-redux'
import { useEffect } from 'react'

interface NotFoundProps {
  setTitle?: (title: string) => void
  setActiveMenu: (activeMenu: string) => any
}

const NotFound = ({ setTitle, setActiveMenu }: NotFoundProps) => {
  useEffect(() => {
    setTitle('Page Not Found')
    setActiveMenu('login')
  })

  return (
    <div className="not-found-component">
      <Typography component="h2" variant="h5">
        Sorry, it seems you were trying to access a page that may have moved.
      </Typography>
      <Typography component="p">
        For information about investing options visit{' '}
        <a
          className="MuiTypography-colorPrimary"
          title="Fisher Funds KiwiSaver Scheme"
          href="https://fisherfunds.co.nz/investment/kiwisaver/"
        >
          KiwiSaver
        </a>
        ,{' '}
        <a
          className="MuiTypography-colorPrimary"
          title="Fisher Funds Managed Funds"
          href="https://fisherfunds.co.nz/investment/managed-funds/"
        >
          Managed Funds
        </a>{' '}
        or our{' '}
        <a
          className="MuiTypography-colorPrimary"
          title="Fisher Funds Premium Service"
          href="https://fisherfunds.co.nz/investment/wealth-management/"
        >
          Premium Service
        </a>
        .
      </Typography>
      <Typography component="p">
        If you're looking for a form, annual reports, product disclosure
        statements or policies, please visit our{' '}
        <a
          className="MuiTypography-colorPrimary"
          title="Resources Section"
          href="https://fisherfunds.co.nz/investing/resources/"
        >
          resources section
        </a>
        .
      </Typography>
      <Typography component="p">
        For news articles please visit our{' '}
        <a
          className="MuiTypography-colorPrimary"
          title="Investing News Room"
          href="https://fisherfunds.co.nz/news-and-insights/"
        >
          news & insights
        </a>
        .
      </Typography>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default connect(null, mapDispatchToProps)(NotFound)
