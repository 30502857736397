import { useEffect } from 'react'
import { WithRouterProps, withRouter } from 'react-router'
import { HASH_MODALS } from '../../common/hash-helper'

const AccountDetailsIPQ = ({ params, router }: WithRouterProps) => {
  useEffect(() => {
    router.replace(`/accounts/${params.id}/performance#${HASH_MODALS.IPQ}`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return <></> // We don't need this but build gets mad without a return
}

export default withRouter(AccountDetailsIPQ)
