import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { Dispatch, bindActionCreators } from 'redux'
import {
  AuthorisationActions,
  AuthorisationRequestAction,
} from '../../redux/authorisation/authorisation.actions'
import { routerActions } from 'react-router-redux'

interface SSOProps {
  token: string
  userId: string
  actions: {
    next: (path: string) => void
  }
}

export class SSO extends React.Component<SSOProps> {
  UNSAFE_componentWillMount() {
    // if (!TokenStorage.getToken()) {
    //     if (!this.props.token) {
    //         this.props.actions.next('/login');
    //     } else {
    //       const auth: PersistedAuthentication = {
    //         userId: this.props.userId,
    //         token: this.props.token
    //       };
    //       TokenStorage.persistToken(auth);
    //       this.props.actions.next('/accounts');
    //     }
    // } else {
    //     this.props.actions.next('/accounts');
    // }
  }

  render() {
    return <div>{this.props.token}</div>
  }
}

export default connect(
  (state: AppState) => ({
    token: state.routing.locationBeforeTransitions.query.token,
    userId: state.user.userid,
  }),
  (dispatch: Dispatch<AuthorisationActions>) => {
    return {
      actions: {
        loginRequest: bindActionCreators(AuthorisationRequestAction, dispatch),
        next: (path: string) => dispatch(routerActions.push(path)),
      },
    }
  },
  null
)(SSO)
